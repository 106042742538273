<script>
import KalioFeedbackModal from '../shared/kalio-feedback-modal.vue';
import BaseOfficeHourOfferingCard from '../shared/base-office-hour-offering-card.vue';
import KalioOfficeHourModal from './kalio-office-hour-modal.vue';

export default {
  name: 'kalio-office-hour-offering',
  components: {
    KalioFeedbackModal,
    BaseOfficeHourOfferingCard,
    KalioOfficeHourModal,
  },
  props: {
    offering: { type: Object, required: true },
  },
  data() {
    return {
      contactModalOpen: false,
      success: false,
      error: false,
    };
  },
  methods: {
    openContactModal() {
      this.contactModalOpen = true;
    },
    closeContactModal() {
      this.contactModalOpen = false;
    },
    handleOfficeHourRequested() {
      this.closeContactModal();
      this.success = true;
    },
    handleError() {
      this.closeContactModal();
      this.error = true;
    },
  },
};
</script>

<template>
  <div class="flex w-full flex-col items-center justify-center">
    <div class="flex w-full flex-col space-y-8">
      <slot />
      <base-office-hour-offering-card
        :offering="offering"
        @schedule="openContactModal"
      />
    </div>
    <kalio-office-hour-modal
      v-if="contactModalOpen"
      :offering="offering"
      @close="closeContactModal"
      @office-hour-requested="handleOfficeHourRequested"
      @error="handleError"
    />
    <kalio-feedback-modal
      v-if="success"
      title="¡Office Hour pedido con exito!"
      body="Recuerda que puedes pedir todos los office hour que necesites."
      accept="Ok"
      @accept="success = false"
    />
    <kalio-feedback-modal
      v-if="error"
      status="error"
      title="Hubo un error, intenta de nuevo más tarde"
      accept="Ok"
      @accept="error = false"
    />
  </div>
</template>
