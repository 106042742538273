import api from '../api';

export default {
  create(id, body) {
    const path = `/internal/api/v1/startup_applications/${id}/discard_feedbacks`;

    return api({
      method: 'post',
      url: path,
      data: body,
    });
  },
};
