/* eslint-disable no-magic-numbers */
import { subMonths } from 'date-fns';

export const MONTHS = [
  'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
  'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre',
];

export function getLastMonths(n = 3) {
  const months = [];
  for (let i = 0; i < n; i++) {
    months.push(subMonths(new Date(), i + 1));
  }

  return months;
}
