<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import { format } from 'date-fns';
import { currentUserKey } from '@/utils/keys.js';

const UNSEEN_TIMEOUT_MS = 5000;

const props = defineProps({ comment: { type: Object, required: true } });
const dynamicComment = ref(props.comment);

onMounted(() => {
  setTimeout(() => {
    dynamicComment.value.unseen = false;
  }, UNSEEN_TIMEOUT_MS);
});

const formattedDate = computed(() => format(dynamicComment.value.createdAt, 'dd/MM/yyyy'));
const currentUser = inject(currentUserKey);
const isAuthor = computed(() => currentUser.value.email === dynamicComment.value.author.email);
</script>

<template>
  <div
    class="flex flex-col rounded-lg border border-pv-blue-700 bg-pv-blue-800 px-4 py-3"
    :class="{ 'border-blue-500': dynamicComment.unseen }"
  >
    <div class="mb-1 flex flex-row items-center space-x-2 text-xs font-light text-cool-gray-400">
      <span
        v-if="isAuthor"
        class="italic"
      >
        Tú
      </span>
      <span v-else>
        {{ dynamicComment.author.email }}
      </span>
      <span>
        -
      </span>
      <span>
        {{ formattedDate }}
      </span>
    </div>
    <div
      class="overflow-scroll text-sm text-cool-gray-50"
      v-html="dynamicComment.body"
    />
  </div>
</template>
