<script>
import KalioShortTextInput from '../form/kalio-short-text-input.vue';
import KalioModal from '../shared/kalio-modal.vue';
import perkSuggestionApi from '../../api/internal/perk_suggestion.js';

export default {
  name: 'kalio-perk-suggestion-modal',
  components: { KalioModal, KalioShortTextInput },
  emits: ['close', 'perk-suggestion-successfully-created'],
  data() {
    return {
      perkSuggestion: '',
      nameReference: '',
      loading: false,
      errorMessage: null,
    };
  },
  methods: {
    async createPerkSuggestion() {
      this.loading = true;
      const data = { description: this.perkSuggestion, nameReference: this.nameReference };
      try {
        await perkSuggestionApi.create(data);
        this.$emit('perk-suggestion-successfully-created');
        this.$emit('close');
      } catch (error) {
        this.errorMessage = this.$t('genericError');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <div class="flex flex-col rounded-lg bg-pv-blue-700 px-6 py-10">
      <kalio-alert
        v-if="!!errorMessage"
        :title="errorMessage"
        class="w-full"
        status="alert"
        @close="errorMessage = null"
      />
      <p class="mb-9 self-start text-2xl font-semibold text-blue-gray-50">
        {{ $t('perks.suggestions.do') }}
      </p>
      <vee-form
        class="flex flex-col space-y-6"
        @submit="createPerkSuggestion"
      >
        <kalio-short-text-input
          v-model="nameReference"
          name="name_reference"
          rules="required"
          :label="$t('perks.suggestions.nameReference')"
        />
        <kalio-long-text-input
          v-model="perkSuggestion"
          name="description"
          rules="required"
          :label="$t('perks.suggestions.description')"
        />
        <kalio-button
          label="Sugerir"
          type="submit"
          class="w-full sm:w-auto sm:self-center sm:px-24"
          :loading="loading"
        />
      </vee-form>
    </div>
  </kalio-modal>
</template>
