<script setup>
import { useVirtualRoundStore } from '../stores';
import DemoDayCompanyCard from '../components/demo-day-company-card.vue';
import DemoDayCompanyMobileAccordion from '../components/demo-day-company-mobile-accordion.vue';
import DemoDayCompaniesDashboard from '../components/demo-day-companies-dashboard';
import DemoDayMobileBlurredCompanyTabs from '../components/demo-day-mobile-blurred-company-tabs.vue';
import DemoDayCompanyDashboard from '../components/demo-day-company-dashboard.vue';
import DemoDayBlurredAuctionMenu from '../components/demo-day-blurred-auction-menu.vue';

const props = defineProps({
  demoDay: { type: Object, required: true },
  demoDayCompanies: { type: Array, required: true },
  batchSeason: { type: String, required: true },
  investorApplication: { type: Object, default: null },
});

const virtualRoundStore = useVirtualRoundStore();
virtualRoundStore.setDemoDay(props.demoDay);
</script>

<template>
  <div class="flex w-full max-w-screen-xl flex-col">
    <kalio-back-button
      icon-size-classes="w-3 h-3"
      href="/demo_day"
    >
      <span class="ml-2 text-base text-blue-gray-200">
        Home
      </span>
    </kalio-back-button>
    <div class="flex w-full flex-col">
      <span class="mt-4 text-base text-pv-gray md:text-2xl">
        Demo Day {{ batchSeason }}
      </span>
      <div class="flex flex-row items-center space-x-4">
        <span class="text-2xl font-medium capitalize md:mt-2 md:text-4xl">
          {{ $t('demoDay.virtualRound.title') }}
        </span>
      </div>
    </div>
    <div class="mt-8 flex flex-col divide-y divide-blue-gray-700 overflow-hidden rounded-xl border border-blue-gray-700 lg:hidden">
      <demo-day-company-mobile-accordion
        v-for="(demoDayCompany, index) in demoDayCompanies"
        :key="demoDayCompany.id"
        :demo-day-company="demoDayCompany"
      >
        <div class="bg-pv-blue-900 p-4">
          <demo-day-mobile-blurred-company-tabs
            :should-scroll-into-view-on-rewatch-event="index === 0"
            :demo-day-company="demoDayCompany"
            @open-community-application-modal="communityApplicationModalOpen = true"
          />
        </div>
      </demo-day-company-mobile-accordion>
    </div>
    <demo-day-companies-dashboard
      class="mt-8 hidden lg:flex"
      :demo-day-companies="demoDayCompanies"
    >
      <template #default="{ demoDayCompany }">
        <div class="flex w-full flex-row divide-x divide-blue-gray-700 bg-pv-blue-800 py-8">
          <demo-day-company-dashboard
            class="w-full pl-6 pr-4"
            :demo-day-company="demoDayCompany"
            :show-slide="false"
          >
            <template #card>
              <demo-day-company-card :demo-day-company="demoDayCompany" />
            </template>
          </demo-day-company-dashboard>
          <div class="flex w-112 flex-col px-4">
            <demo-day-blurred-auction-menu :demo-day-company="demoDayCompany" />
          </div>
        </div>
      </template>
    </demo-day-companies-dashboard>
  </div>
</template>
