import api from '@/api/api';
export const updatesApi = {
    async index(filters) {
        const path = '/build_sprint/internal/api/v1/build_sprint_updates';
        const response = await api({
            method: 'get',
            url: path,
            params: filters,
        });
        return response.buildSprintUpdates;
    },
    async show(id) {
        const path = `/build_sprint/internal/api/v1/build_sprint_updates/${id}`;
        const response = await api({
            method: 'get',
            url: path,
        });
        return response.buildSprintUpdate;
    },
};
