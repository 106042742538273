import api from './api';

export default {
  getAll() {
    const path = '/api/v1/apply/application_founder_references';

    return api({
      method: 'get',
      url: path,
    });
  },

  create(applicationFounderReference) {
    const path = '/api/v1/apply/application_founder_references';

    return api({
      method: 'post',
      data: applicationFounderReference,
      url: path,
    });
  },
};
