<script setup>
import { inject, ref } from 'vue';
import { currentUserKey } from '@/utils/keys.js';
import KalioShortTextInput from '@/components/form/kalio-short-text-input.vue';
import KalioModal from '@/components/shared/kalio-modal.vue';
import { useUIStore } from '@/stores';
import { useSendInviteAllQuery } from '@/queries/slack_channel_invites';

const props = defineProps({ show: { type: Boolean, default: false } });
const emit = defineEmits(['close']);

const uiStore = useUIStore();
const currentUser = inject(currentUserKey);
const email = ref(currentUser.value.email);

function successToast() {
  uiStore.toast({
    message: 'Invitaciones enviadas',
    position: 'top',
    type: 'success',
  });
}

function errorToast() {
  uiStore.toast({
    message: 'Algunas invitaciones no se pudieron enviar',
    position: 'top',
    type: 'error',
  });
}

function onMutateSuccess(data) {
  emit('close');
  const failed = data.filter(res => !res.success);
  if (failed.length === 0) successToast();
  else errorToast();
}

const { mutate, isLoading } = useSendInviteAllQuery(
  email, { retry: false, onSuccess: onMutateSuccess },
);

function inviteAll() {
  if (isLoading.value) return;
  mutate();
}

</script>

<template>
  <kalio-modal
    :show="props.show"
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <div class="flex w-full flex-col gap-2 rounded-lg bg-pv-blue-700 px-6 py-10">
      <kalio-header
        title="Unirse a todos los canales de Slack"
        icon="paper-plane.svg"
        size="medium"
        class="mb-2 grow"
      />

      <p class="text-left">
        Ingresa tu correo y te enviaremos una invitación para cada uno de nuestros canales de Slack.
      </p>

      <div class="flex w-full flex-col items-center">
        <vee-form
          class="flex w-full flex-col items-center gap-2 px-1 py-4 md:w-80"
          @submit="inviteAll"
        >
          <kalio-short-text-input
            v-model="currentUser.email"
            class="w-full"
            rules="email"
            name="email-invite"
            icon-name="social-media/email.svg"
            icon-classes="w-5 h-5 fill-current text-blue-gray-400"
          />

          <kalio-button
            label="Enviar invitaciones"
            :loading="isLoading"
            class="w-full"
          />
        </vee-form>
      </div>
    </div>
  </kalio-modal>
</template>
