<script>
export default {
  name: 'discard-email-no-cto',
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  computed: {
    soloFounderName() {
      return this.application.applicationFounders[0].user.firstName;
    },
    startupName() {
      return this.application.startupName;
    },
    isSoloFounder() {
      return this.application.applicationFounders.length === 1;
    },
  },
};
</script>

<template>
  <div>
    <p v-if="isSoloFounder">
      Hola {{ soloFounderName }},
    </p>
    <p v-else>
      Hola fundadores de {{ startupName }},
    </p>

    <br>

    <p>
      Primero que todo, gracias por postular a Platanus. {{ isSoloFounder ?
        'Lamento informarte que' : 'Lamento informarles que' }} {{ startupName }} no fue
      seleccionada para pasar a la siguiente etapa.
    </p>

    <br>

    <p>
      Decidimos pasar en esta oportunidad porque uno de los requisitos del programa es que
      uno de los fundadores pueda ser el CTO, y quedamos con dudas sobre si {{ isSoloFounder ?
        'ese rol lo asumías tu' : 'alguno de ustedes lo podía ser' }}.
    </p>

    <br>

    <p v-if="isSoloFounder">
      Es prácticamente un hecho que varias startups que rechacemos serán empresas exitosas en el futuro.
      Si llega a ser tu caso, te agradeceríamos enormemente si nos envías un correo contándonos.
      Queremos aprender de nuestros errores.
    </p>
    <p v-else>
      Es prácticamente un hecho que varias startups que rechacemos serán empresas exitosas en el futuro.
      Si llega a ser su caso, les agradeceríamos enormemente si nos envían un correo contándonos.
      Queremos aprender de nuestros errores.
    </p>

    <br>

    <p v-if="isSoloFounder">
      Nos encantaría que postules de nuevo para el siguiente proceso. Si vemos un gran avance en el
      estado de la startup o de los fundadores, seguramente nos llamará la atención.
    </p>
    <p v-else>
      Nos encantaría que postulen de nuevo para el siguiente proceso. Si vemos un gran avance en el
      estado de la startup o de los fundadores, seguramente nos llamará la atención.
    </p>

    <br>

    <p v-if="isSoloFounder">
      Sabemos que dedicaste tiempo y esfuerzo en responder las preguntas del formulario y
      creemos que tener las respuestas a mano te puede servir en el futuro.
      Puedes descargarlas si entras a <a
        href="/apply/status?state=submitted"
        class="text-blue-400"
      >
        este link
      </a>.
    </p>
    <p v-else>
      Sabemos que dedicaron tiempo y esfuerzo en responder las preguntas del formulario y
      creemos que tener las respuestas a mano les puede servir en el futuro.
      Pueden descargarlas si entran a <a
        href="/apply/status?state=submitted"
        class="text-blue-400"
      >
        este link
      </a>.
    </p>

    <br>

    <p>
      Mucho éxito.
    </p>
  </div>
</template>
