<script setup lang="ts">
</script>

<template>
  <div class="flex w-full gap-4 rounded-md bg-blue-gray-700 p-3 text-blue-gray-50">
    <div class="flex items-center justify-center">
      <inline-svg
        class="size-6 fill-current"
        :src="require('assets/images/icons/form.svg')"
      />
    </div>
    <p>
      Antes de postular revisa la
      <a
        class="font-bold transition hover:text-pv-yellow"
        href="https://ventures.platan.us/programa"
        target="_blank"
      >
        información de nuestro programa y sus requisitos
      </a>
    </p>
  </div>
</template>
