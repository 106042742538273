import api from './api';

export default {
  create(jobOfferId, jobApplication) {
    const path = `/api/v1/job_offers/${jobOfferId}/job_applications`;

    return api({
      method: 'post',
      data: jobApplication,
      url: path,
      decamelizeKeys: false,
    });
  },
};
