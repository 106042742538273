<script setup>
import { ref, computed } from 'vue';
import { useRolesStore } from '@/stores';
import BaseUnderlineTabs from '../shared/base-underline-tabs.vue';
import KalioGroupMeetingSidebarCompanies from './kalio-group-meeting-sidebar-companies.vue';
import KalioGroupMeetingsCompanyComments from './company-comments.vue';

const TABS = [
  { title: 'Startups', icon: 'rocket.svg' },
  { title: 'Comentarios', icon: 'comment.svg' },
];

const rolesStore = useRolesStore();
const commentsAllowed = computed(() => rolesStore.isPartner ||
      rolesStore.isMentor ||
      rolesStore.isPlatanusTeamMember);

const selectedTab = ref(0);
function selectTab(index) {
  selectedTab.value = index;
}
</script>

<template>
  <div class="flex size-full flex-col justify-start bg-pv-blue-800 pt-4">
    <div
      v-if="commentsAllowed"
      class="relative w-full"
    >
      <div class="absolute top-0 z-20 w-full bg-pv-blue-800 p-4">
        <base-underline-tabs
          :tabs="TABS"
          size="small"
          @select="selectTab"
        />
      </div>
      <div class="mt-24 flex w-full flex-col md:mb-12">
        <keep-alive>
          <kalio-group-meeting-sidebar-companies v-if="selectedTab === 0" />
          <kalio-group-meetings-company-comments
            v-else
            can-write
            consumer
          />
        </keep-alive>
      </div>
    </div>
    <div
      v-else
      class="h-full md:-mt-16 md:pb-4 md:pt-20"
    >
      <kalio-group-meeting-sidebar-companies />
    </div>
  </div>
</template>
