import api from '@/api/api';

export default {
  get(locale) {
    const path = `/api/v1/demo_day/live_demo_day_company/${locale}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  update(id, locale) {
    const path = `/api/v1/demo_day/live_demo_day_company/${locale}`;

    return api({
      method: 'put',
      url: path,
      data: { demoDayCompanyId: id },
    });
  },
};
