<script setup>
import { onMounted, ref, inject } from 'vue';
import DailyIframe from '@daily-co/daily-js';
import { currentUserKey } from '@/utils/keys';

const props = defineProps({
  dailyUrl: { type: String, required: true },
  isMuteAllowed: { type: Boolean, required: false, default: false },
  showFullscreenButton: { type: Boolean, required: false, default: false },
  ownerToken: { type: String, required: false, default: '' },
});

const VIDEOCALL_LOAD_DELAY_MS = 200;

const currentUser = inject(currentUserKey);
const videocall = ref(null);
let callFrame = null;

onMounted(() => {
  // no idea why this only works if we wait a bit
  setTimeout(() => {
    callFrame = DailyIframe.wrap(videocall.value);
    callFrame.join({
      url: props.dailyUrl,
      token: props.ownerToken,
      showLeaveButton: true,
      showFullscreenButton: props.showFullscreenButton,
      userName: currentUser.value.fullName,
    });
  }, VIDEOCALL_LOAD_DELAY_MS);
});

function handleMuteAll() {
  const updateList = {};
  for (const [id] of Object.entries(callFrame.participants())) {
    if (id === 'local') {
      continue;
    }
    updateList[id] = { setAudio: false };
  }
  callFrame.updateParticipants(updateList);
}
</script>

<template>
  <div class="flex size-full flex-col">
    <button
      v-if="props.isMuteAllowed"
      class="self-end p-2"
      @click="handleMuteAll"
    >
      Mute all
    </button>
    <iframe
      ref="videocall"
      class="size-full rounded-lg border"
      allow="camera; microphone; autoplay; display-capture"
    />
  </div>
</template>
