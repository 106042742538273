<script>

import { ref } from 'vue';
import moment from 'moment';

export default {
  name: 'ModelCompletions',
  props: {
    completions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const selectedCompletion = ref(null);

    const formattedDate = (date) => moment(date).calendar(null, { sameElse: 'DD/MM/YYYY hh:mm' });

    const selectCompletion = (completion) => {
      selectedCompletion.value = completion;
    };

    return {
      selectedCompletion,
      selectCompletion,
      formattedDate,
    };
  },
};
</script>

<template>
  <div class="container flex space-x-4">
    <div class="w-1/4 rounded-lg bg-pv-blue-900">
      <ul>
        <li
          v-for="completion in completions"
          :key="completion.id"
          class="m-2 flex cursor-pointer flex-col space-y-1 rounded-lg p-4 text-sm"
          :class="{
            'border bg-pv-blue-700': selectedCompletion && completion.id === selectedCompletion.id,
          }"
          @click="selectCompletion(completion)"
        >
          <span class="font-bold">
            Completion #{{ completion.id }}
          </span>
          <span class="text-xs text-pv-gray">
            {{ formattedDate(completion.created_at) }}
          </span>
          <div class="flex flex-wrap gap-2">
            <div
              v-for="tag in completion.tag_list"
              :key="tag"
            >
              <kalio-badge
                :text="tag"
                size="xs"
                with-border
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="w-3/4 bg-pv-blue-900 p-4 text-pv-gray">
      <div
        v-if="selectedCompletion"
        class="flex flex-col space-y-4"
      >
        <h1 class="text-3xl">
          Completion #{{ selectedCompletion.id }}
        </h1>
        <div class="flex">
          <div
            v-for="tag in selectedCompletion.tag_list"
            :key="tag"
          >
            <kalio-badge
              :text="tag"
              size="xs"
              with-border
            />
          </div>
        </div>
        <span class="text-xs text-pv-gray">
          {{ formattedDate(selectedCompletion.created_at) }}
        </span>
        <div>
          <p class="mb-1 text-xs">
            Prompt
          </p>
          <p class="rounded-lg bg-pv-blue-700 p-3 text-sm">
            {{ selectedCompletion.prompt }}
          </p>
        </div>
        <div>
          <p class="mb-1 text-xs">
            Completion
          </p>
          <p class="rounded-lg bg-pv-blue-700 p-3 text-sm">
            {{ selectedCompletion.completion }}
          </p>
        </div>
        <div class="w-full">
          <p class="mb-1 text-xs">
            Params
          </p>
          <pre class="whitespace-pre-wrap	break-words rounded-lg bg-pv-blue-700 p-2 text-left text-xs">
            {{ JSON.parse(selectedCompletion.params.trim()) }}
          </pre>
        </div>
        <div>
          <p class="mb-1 text-xs">
            Response
          </p>
          <pre class="whitespace-pre-wrap	break-words rounded-lg bg-pv-blue-700 p-2 text-left text-xs">
          {{ JSON.parse(selectedCompletion.response.trim()) }}
        </pre>
        </div>
      </div>
      <div
        v-else
        class="text-xs"
      >
        Please select a completion from the sidebar.
      </div>
    </div>
  </div>
</template>
