<script setup>
import { useQuery } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioComments from '@/components/shared/kalio-comments.vue';
import commentsApi from '@/api/comments.js';

defineEmits(['expand']);
defineProps({
  expanded: { type: Boolean, default: false },
  expandable: { type: Boolean, default: true },
});

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);

const { data, refetch } = useQuery(
  ['comments', startupApplication],
  () => commentsApi
    .getAll('StartupApplication', startupApplication.value.id)
    .then(response => response.comments),
);
</script>

<template>
  <div class="relative">
    <kalio-comments
      resource-type="StartupApplication"
      :resource="{
        ...startupApplication,
        comments: data || [],
      }"
      @comment-created="refetch"
    />
    <button
      v-if="expandable"
      class="absolute right-0 top-0 hidden opacity-50 hover:opacity-100 xl:block"
      @click="$emit('expand')"
    >
      <inline-svg
        :src="require(`assets/images/icons/${expanded ? 'shrink' : 'expand'}.svg`)"
        class="size-6 fill-current"
      />
    </button>
  </div>
</template>
