<script>
import KalioSocialMediaIcons from '../shared/kalio-social-media-icons.vue';

const NUMBER_OF_ISOTYPES_TO_SHOW = 3;

export default {
  components: {
    KalioSocialMediaIcons,
  },
  props: {
    investor: { type: Object, required: true },
  },
  computed: {
    isotypesToShow() {
      return this.investor.companiesInvestedIsotypes.slice(0, NUMBER_OF_ISOTYPES_TO_SHOW);
    },
    extraInvestedCompanies() {
      return this.isotypesToShow.length > NUMBER_OF_ISOTYPES_TO_SHOW ? (
        this.investor.companiesInvestedIsotypes.length - NUMBER_OF_ISOTYPES_TO_SHOW
      ) : 0;
    },
    totalReviews() {
      return this.investor.investorReviews.length;
    },
    totalInvestments() {
      return this.investor.companiesInvestedIsotypes.length;
    },
    investorPath() {
      return `investors/${this.investor.id}`;
    },
  },
};
</script>

<template>
  <div class="flex w-full flex-col space-y-6 rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4">
    <div class="flex flex-col justify-between space-y-4 md:flex-row md:space-y-0">
      <div class="flex space-x-3">
        <div class="size-24 overflow-hidden rounded-lg">
          <img
            :src="investor.avatarUrl"
            class="size-full object-cover"
            alt="user avatar"
          >
        </div>
        <div class="flex flex-col">
          <span class="text-xl font-medium">
            {{ investor.fullName }}
          </span>
          <span class="mb-1 text-xs text-cool-gray-300">
            {{ $t(`investors.kind.${investor.kind}`) }}
          </span>
          <span class="mb-1 text-xs text-cool-gray-300">
            {{ investor.email }}
          </span>
          <div class="mt-2 flex space-x-2">
            <div
              v-if="investor.country"
              class="flex items-center self-start whitespace-nowrap rounded-full bg-pv-light-blue/10 px-2 py-0.5 text-sm text-blue-400"
            >
              <country-flag
                :country="investor.country.code.toLowerCase()"
                size="small"
              />
              <span class="ml-1 text-sm">
                {{ investor.country.name }}
              </span>
            </div>
            <kalio-social-media-icons
              :social-media="investor.socialMedia"
              icon-classes="h-5 w-5"
              class="space-x-2"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center justify-between md:flex-col md:space-y-2 md:text-right">
        <div class="flex flex-col">
          <span
            v-if="totalReviews > 0"
            class="text-lg font-medium"
          >
            {{ $t('investors.calification') }} {{ investor.avgInvestorLetter }}
          </span>
          <span
            v-else
            class="text-lg font-medium"
          >
            {{ $t('investors.noCalification') }}
          </span>
          <span class="text-xs text-cool-gray-300">
            {{ totalReviews }} reviews
          </span>
        </div>
        <div class="flex flex-col">
          <div class="my-1 flex h-full items-center justify-end">
            <div
              v-for="(isotype, index) in isotypesToShow"
              :key="index"
              class="-ml-3 size-8 overflow-hidden rounded-full"
            >
              <img
                :src="isotype"
                class="size-full object-cover"
              >
            </div>
          </div>
          <span
            v-if="extraInvestedCompanies > 0"
            class="ml-1 text-base text-pv-gray"
          >
            + {{ extraInvestedCompanies }}
          </span>
          <span class="text-right text-xs text-cool-gray-300">
            {{ totalInvestments }} {{ $t('investors.investments') }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center">
      <a
        :href="investorPath"
        target="_blank"
        class="flex shrink-0 cursor-pointer items-center text-base text-pv-yellow hover:opacity-75 md:mr-0"
      >
        <span class="text-base font-light">
          {{ $t('people.goToProfile') }}
        </span>
        <inline-svg
          :src="require('assets/images/icons/arrow.svg')"
          class="ml-1 hidden w-5 rotate-180 fill-current md:inline"
        />
        <inline-svg
          :src="require('assets/images/icons/toggle-arrow.svg')"
          class="ml-1 size-3 rotate-90 fill-current md:hidden"
        />
      </a>
    </div>
  </div>
</template>
