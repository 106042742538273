<script>

export default {
  name: 'Tabs',
  components: {
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: 'underlined',
    },
  },
  data() {
    return { activeIndex: 0 };
  },
  computed: {
    active() {
      return this.tabs[this.activeIndex];
    },
    baseTabClasses() {
      const classes = {
        underlined: 'px-4 text-gray-800 py-2 md:py-1',
        folder: 'p-2 text-blue-gray-100 rounded-t-xl text-base',
      };

      return classes[this.variant];
    },
    activeTabClasses() {
      const classes = {
        underlined: 'border-banana-500 rounded-md md:rounded-none border-2 md:border-0 md:border-b-2 font-bold',
        folder: 'bg-blue-gray-700',
      };

      return classes[this.variant];
    },
    tabsContainerClasses() {
      const classes = {
        underlined: 'my-2',
        folder: 'bg-pv-blue-700 rounded-tl-xl',
      };

      return classes[this.variant];
    },
  },
};
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-col md:flex-row"
      :class="tabsContainerClasses"
    >
      <span
        v-for="(tab, index) in tabs"
        :key="tab"
        :data-tab-name="tab"
        class="cursor-pointer"
        :class="[baseTabClasses, { [activeTabClasses]: active === tab }]"
        @click="activeIndex = index"
      >
        {{ tab }}
      </span>
    </div>
    <hr class="md:hidden">
    <div
      v-for="tab in tabs"
      :key="`${tab}-slot`"
    >
      <div :class="{'hidden': active != tab }">
        <slot :name="tab" />
      </div>
    </div>
  </div>
</template>
