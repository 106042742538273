<script>
import * as moment from 'moment';
import KalioModal from '../shared/kalio-modal.vue';
import jobApplicationsApi from '../../api/job_applications.js';

export default {
  name: 'kalio-job-offer-apply-modal',
  components: { KalioModal },
  props: {
    position: { type: String, required: true },
    companyName: { type: String, required: true },
    jobOfferId: { type: Number, required: true },
  },
  emits: ['close', 'job-application-creation-succeeded'],
  data() {
    return {
      jobApplication: {
        resume: undefined,
        purpose: '',
      },
      loading: false,
      errorMessage: null,
    };
  },
  computed: {
    formattedDate() {
      return moment(new Date()).format('D [de] MMMM');
    },
  },
  methods: {
    async createJobApplication() {
      this.loading = true;
      const formData = new FormData();
      Object.entries(this.jobApplication).forEach(([key, value]) => {
        formData.append(`job_application[${key}]`, value);
      });
      try {
        await jobApplicationsApi.create(this.jobOfferId, formData);
        this.$emit('job-application-creation-succeeded');
        this.$emit('close');
      } catch (error) {
        this.errorMessage = this.$t('genericError');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <div class="flex flex-col rounded-lg bg-pv-blue-700 px-6 pb-8 pt-12">
      <kalio-alert
        v-if="!!errorMessage"
        :title="errorMessage"
        class="w-full"
        status="alert"
        @close="errorMessage = null"
      />
      <div class="my-2 flex space-x-3 text-blue-gray-300">
        <inline-svg
          :src="require('assets/images/icons/business.svg')"
          class="h-6 w-auto fill-current"
        />
        <span class="text-base">
          {{ companyName }}
        </span>
      </div>
      <p class="mb-10 text-left text-2xl font-medium">
        {{ position }}
      </p>
      <vee-form
        class="flex flex-col"
        @submit="createJobApplication"
      >
        <div class="mb-10 space-y-4">
          <kalio-file-input
            v-model="jobApplication.resume"
            name="resume"
            rules="required|ext:pdf"
            label="Resume"
          />
          <kalio-long-text-input
            v-model="jobApplication.purpose"
            name="purpose"
            :label="$t('jobOffers.application.aboutYou')"
            rules="required"
          />
        </div>
        <kalio-button
          :label="$t('jobOffers.actions.send')"
          class="w-full self-center px-10 sm:w-1/2 sm:px-16"
          type="submit"
          :loading="loading"
        />
      </vee-form>
    </div>
  </kalio-modal>
</template>
