<script>
import { ref, computed, watch, toRef, onMounted } from 'vue';
import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import KalioModal from '@/components/shared/kalio-modal.vue';
import KalioAlert from '@/components/shared/kalio-alert.vue';
import { toCurrency } from '@/filters';
import { UNCAPPED_VALUE } from '../utils/virtual_round.js';
import { useAddAuctionOrderMutation } from '../queries/auction_orders.js';

const AMOUNT_STEP = window.env.ORDER_AMOUNT_STEP;

const MINIMUM_AMOUNT_ERROR_TRANSLATIONS = {
  default: {
    es: ({ rule }) => `El monto mínimo de inversión es de ${toCurrency(rule.params[0])}.`,
    en: ({ rule }) => `The minimum amount is ${toCurrency(rule.params[0])}.`,
  },
  existing: {
    es: ({ rule }) => `Debe ser mayor a tu oferta existente
                      de ${toCurrency(Number(rule.params[0]) - AMOUNT_STEP)}.`,
    en: ({ rule }) => `Must be greater than your existing offer
                        of ${toCurrency(Number(rule.params[0]) - AMOUNT_STEP)}.`,
  },
  oversubscribed: {
    es: ({ rule }) => `La ronda está llena. Si quieres ser considerado, debes
                        invertir ${toCurrency(rule.params[0])} o más.`,
    en: ({ rule }) => `Round is full. If you want to be considered, you have to
                        invest ${toCurrency(rule.params[0])} or more.`,
  },
};

function configureMinValueRuleMessage(translationKey) {
  configure({
    generateMessage: localize({
      es: {
        messages: {
          min_value: MINIMUM_AMOUNT_ERROR_TRANSLATIONS[translationKey].es,
        },
      },
      en: {
        messages: {
          min_value: MINIMUM_AMOUNT_ERROR_TRANSLATIONS[translationKey].en,
        },
      },
    }),
  });
}

function configureMaxValueRuleMessage() {
  configure({
    generateMessage: localize({
      es: {
        messages: {
          max_value: ({ rule }) => `El monto máximo de inversión es de ${toCurrency(rule.params[0])}.`,
        },
      },
      en: {
        messages: {
          max_value: ({ rule }) => `The maximum amount is ${toCurrency(rule.params[0])}.`,
        },
      },
    }),
  });
}

export default {
  name: 'demo-day-auction-order-modal',
  components: { KalioModal, KalioAlert },
  props: {
    auction: { type: Object, required: true },
    lastAuctionOrder: { type: Object, default: null },
    company: { type: Object, required: true },
  },
  emits: ['close', 'success'],
  setup(props, { emit }) {
    const amount = ref('');
    const inConfirmationStep = ref(false);

    const {
      mutate: addAuctionOrder,
      isLoading: isAuctionOrderMutationLoading,
      error: auctionOrderMutationError,
    } = useAddAuctionOrderMutation();

    function submitOrder() {
      addAuctionOrder(
        {
          auction: toRef(props, 'auction'),
          order: { amount: amount.value },
        },
        {
          onSuccess: () => emit('success'),
        },
      );
    }

    const lastOrderInAMount = props.auction.lastOrderIn?.amount || 0;
    const existingOrderAmount = props.lastAuctionOrder?.amount || 0;

    const minOrderAmount = computed(() => {
      let _minOrderAmount;

      if (props.auction.oversubscribed) {
        _minOrderAmount = Math.max(lastOrderInAMount, existingOrderAmount) + AMOUNT_STEP;
      } else {
        const max = Math.max(existingOrderAmount, props.auction.minAmount - AMOUNT_STEP);
        _minOrderAmount = max + AMOUNT_STEP;
      }

      return _minOrderAmount;
    });

    watch([amount, minOrderAmount], () => {
      const max = Math.max(props.auction.minAmount, lastOrderInAMount, existingOrderAmount);

      if (max === props.auction.minAmount) {
        configureMinValueRuleMessage('default');
      } else if (max === existingOrderAmount) {
        configureMinValueRuleMessage('existing');
      } else if (max === lastOrderInAMount) {
        configureMinValueRuleMessage('oversubscribed');
      }
    });

    onMounted(configureMaxValueRuleMessage);

    const amountRules = computed(() => {
      const rules = [
        'required',
        'numeric',
        'multiple_of:1000',
        `min_value:${minOrderAmount.value}`,
        `max_value:${props.auction.targetAmount}`,
      ];

      return rules.join('|');
    });

    return {
      UNCAPPED_VALUE,
      isAuctionOrderMutationLoading,
      auctionOrderMutationError,
      submitOrder,
      amount,
      inConfirmationStep,
      amountRules,
      existingOrderAmount,
      minOrderAmount,
    };
  },
};
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    data-testid="demo-day-auction-order-modal"
    @close="$emit('close')"
  >
    <div class="rounded-lg bg-pv-blue-700">
      <div class="flex flex-col items-start p-3 sm:p-8">
        <span class="text-lg font-medium text-blue-gray-200">
          {{ $t('demoDay.virtualRound.investIn') }}
        </span>
        <div class="mt-2 flex flex-row items-center space-x-4">
          <img
            :src="company.isotypeUrl"
            class="size-14 rounded-lg"
          >
          <span class="text-3xl font-medium">
            {{ company.name }}
          </span>
        </div>
        <div
          v-if="!!auctionOrderMutationError"
          class="mt-6 w-full"
        >
          <kalio-alert
            status="alert"
            :title="auctionOrderMutationError.response.data.detail"
            :deletable="false"
          />
        </div>
        <div
          v-if="inConfirmationStep"
          class="my-4"
        >
          <span class="text-sm">
            {{ $t('demoDay.virtualRound.willMakeOffer') }}
          </span>
        </div>
        <span class="mt-4 text-base font-medium">
          {{ inConfirmationStep
            ? $t('demoDay.virtualRound.yourOfferDetails')
            : lastAuctionOrder
              ? $t('demoDay.virtualRound.existingOfferDetails')
              : $t('demoDay.virtualRound.roundInfo') }}
        </span>
        <div class="mt-4 flex w-full flex-col divide-y divide-blue-gray-700 rounded-xl bg-pv-blue-900 px-8 py-2">
          <div
            v-if="!inConfirmationStep && !lastAuctionOrder"
            class="flex flex-row items-center justify-between py-2"
          >
            <span class="text-base text-blue-gray-100">
              {{ $t('demoDay.virtualRound.auction.size') }}
            </span>
            <span class="text-base font-medium">
              $ {{ auction.targetAmount.toLocaleString('en-US') }}
            </span>
          </div>
          <div class="flex flex-row items-center justify-between py-2">
            <span class="text-base text-blue-gray-100">
              CAP
            </span>
            <a
              v-if="auction.cap === UNCAPPED_VALUE"
              class="text-base font-medium text-pv-light-blue underline"
              href="https://platanus.notion.site/Uncapped-SAFE-5cd6423520604aa5ac94fdf7b8e28a46"
              target="_blank"
            >
              Uncapped
            </a>
            <span
              v-else
              class="text-base font-medium"
            >
              $ {{ auction.cap.toLocaleString('en-US') }}
            </span>
          </div>
          <div
            v-if="inConfirmationStep || lastAuctionOrder"
            class="flex flex-row items-center justify-between py-2"
          >
            <span class="text-base text-blue-gray-100">
              {{ $t('demoDay.virtualRound.yourOffer') }}
            </span>
            <span class="text-base font-medium">
              $ {{ inConfirmationStep
                ? Number(amount).toLocaleString('en-US')
                : lastAuctionOrder.amount.toLocaleString('en-US') }}
            </span>
          </div>
        </div>
        <vee-form
          v-if="!inConfirmationStep"
          tag="div"
          class="mt-8 flex w-full flex-col items-center space-y-4 md:space-y-8"
          @submit="inConfirmationStep = true"
        >
          <kalio-short-text-input
            v-model="amount"
            name="order_amount"
            :rules="amountRules"
            :label="lastAuctionOrder
              ? $t('demoDay.virtualRound.improveAuctionOfferInput')
              : $t('demoDay.virtualRound.auctionOfferInput')"
            :description="$t(
              'demoDay.virtualRound.auction.minOrderAmount',
              { amount: $filters.toCurrency(minOrderAmount) }
            )"
          />

          <kalio-button
            :label="$t('demoDay.virtualRound.invest')"
            class="w-full self-center md:w-auto md:px-32"
          />
        </vee-form>
        <div
          v-else
          class="flex w-full flex-col self-center"
        >
          <div
            v-if="inConfirmationStep"
            class="my-4"
          >
            <span class="text-sm">
              {{ $t('demoDay.virtualRound.everythingCorrect') }}
            </span>
          </div>
          <div class="mt-2 flex w-full flex-col-reverse space-y-2 space-y-reverse md:w-auto md:flex-row md:justify-center md:space-x-4 md:space-y-0">
            <kalio-button
              :label="$t('form.cancel')"
              variant="tertiary"
              class="border-blue-gray-500 md:w-56"
              @click="inConfirmationStep = false"
            />
            <kalio-button
              :label="$t('demoDay.virtualRound.auction.confirmOffer')"
              class="md:w-56 md:px-16"
              :loading="isAuctionOrderMutationLoading"
              @click="submitOrder"
            />
          </div>
        </div>
      </div>
    </div>
  </kalio-modal>
</template>
