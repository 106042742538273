import { ref, computed } from 'vue';
import { get } from 'lodash';

export default function useFormAnswers(paths, object,
  { key = 'formAnswers', onlyAnswered = true, injected = [] } = {}) {
  const objectRef = ref(object);
  const jsonb = computed(() => get(objectRef.value, key, {}));

  function getLabel(path) {
    return get(jsonb.value, `${path}.label`);
  }

  function getAnswer(path) {
    return get(jsonb.value, `${path}.value`);
  }

  const formAnswers = computed(() => {
    let allFormAnswers = paths
      .filter(path => !!get(jsonb.value, path))
      .map(path => ({ label: getLabel(path), answer: getAnswer(path) }));

    if (injected.length > 0) {
      injected.forEach((injectedAnswer) => {
        allFormAnswers = [
          ...allFormAnswers.slice(0, injectedAnswer.index),
          injectedAnswer,
          ...allFormAnswers.slice(injectedAnswer.index),
        ];
      });
    }

    if (onlyAnswered) {
      allFormAnswers = allFormAnswers.filter(answer => !!answer.answer);
    }

    return allFormAnswers;
  });

  return {
    formAnswers,
    getLabel,
    getAnswer,
  };
}
