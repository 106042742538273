import { useMutation } from '@tanstack/vue-query';
import officeHourRequestsApi from '@/api/office_hour_requests';

export function useCreateOfficeHourRequestMutation(officeHourOfferingId, options = {}) {
  return useMutation({
    mutationFn: (officeHourRequest) => officeHourRequestsApi.create(officeHourOfferingId, officeHourRequest),
    ...options,
  });
}

export function useUpdateOfficeHourRequestMutation(options = {}) {
  return useMutation({
    mutationFn: (officeHourRequest) => officeHourRequestsApi.update(
      officeHourRequest.id,
      officeHourRequest,
    ),
    ...options,
  });
}
