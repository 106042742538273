import humps from 'humps';
import api from './api';

const path = '/api/v1/application_founders';

export default {
  create(applicationFounder) {
    return api({
      method: 'post',
      data: humps.decamelizeKeys({ applicationFounder }),
      url: path,
    });
  },
  delete(applicationFounderId) {
    return api({
      method: 'delete',
      url: `${path}/${applicationFounderId}`,
    });
  },
};
