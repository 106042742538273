import api from './api';

export default {
  getAll(groupMeetingId) {
    const path = `/api/v1/group_meetings/${groupMeetingId}/group_meeting_company_comments`;

    return api({
      url: path,
    });
  },
  create(groupMeetingId, companyId, body) {
    const path = `/api/v1/group_meetings/${groupMeetingId}/companies/${companyId}/comments`;

    return api({
      method: 'post',
      url: path,
      data: { groupMeetingCompanyComment: { body } },
    });
  },
};
