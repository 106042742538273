<script>
import { defineComponent, ref, computed, watch, toRef } from 'vue';
import { get } from 'lodash';
import KalioSearchBar from '../shared/kalio-search-bar.vue';
import KalioButton from '../shared/kalio-button.vue';
import KalioSpinner from '../shared/kalio-spinner.vue';

export default defineComponent({
  name: 'kalio-list-with-filters-and-searchbar',
  components: {
    KalioSearchBar,
    KalioButton,
    KalioSpinner,
  },
  props: {
    items: { type: Array, required: true },
    query: { type: String, required: true },
    searchPath: { type: String, required: true },
    filters: { type: Object, default: () => ({}) },
    filtersFromBackend: { type: Boolean, default: false },
    gridDisplay: { type: Boolean, default: false },
    getDataFromApi: { type: Function, default: null },
    loading: { type: Boolean, default: false },
  },
  setup(props) {
    function createEmptyFilters() {
      return Object.keys(props.filters).reduce(
        (filtersHash, filterKey) => {
          if (props.filters[filterKey].type === 'numberRange') filtersHash[filterKey] = {};
          else filtersHash[filterKey] = [];

          return filtersHash;
        },
        {},
      );
    }

    const initialFilters = Object.keys(props.filters).reduce(
      (filterHash, filterKey) => {
        const initialValue = get(props.filters, `${filterKey}.initialValue`, null);
        if (initialValue) filterHash[filterKey] = initialValue;

        return filterHash;
      },
      createEmptyFilters(),
    );

    const selectedFilters = ref(initialFilters);
    const filteredItems = ref([]);

    function removeAllFilters() {
      const initialFiltersValue = createEmptyFilters();
      selectedFilters.value = initialFiltersValue;
      if (props.filtersFromBackend) props.getDataFromApi(initialFiltersValue);
    }

    async function applyFilters() {
      props.getDataFromApi(selectedFilters.value);
    }

    const anyFilter = computed(() => Object.values(selectedFilters.value)
      .reduce((acc, filterValue) => acc || Boolean(filterValue), false));

    function defaultCompareFunction(filterValue, itemValue) {
      return filterValue === itemValue;
    }

    function handleSelectFilters(value, filterKey) {
      this.selectedFilters[filterKey] = value;
    }

    const items = toRef(props, 'items');
    watch([selectedFilters, items], () => {
      if (props.filtersFromBackend) {
        filteredItems.value = items.value;

        return;
      }

      let itemsCopy = [...items.value];

      Object.entries(selectedFilters.value).forEach(([filterKey, filterArray]) => {
        if (filterArray && filterArray.length > 0) {
          const itemKey = props.filters[filterKey].itemKey;
          const compareFunction = props.filters[filterKey].compareFunction || defaultCompareFunction;
          itemsCopy = itemsCopy.filter(item =>
            filterArray
              .map((selectedFilter) => selectedFilter.value)
              .some(filterValue => compareFunction(filterValue, get(item, itemKey))),
          );
        }
      });

      filteredItems.value = itemsCopy;
    }, { deep: true, immediate: true });

    const isSidebarOpen = ref(false);

    return {
      selectedFilters,
      filteredItems,
      removeAllFilters,
      applyFilters,
      anyFilter,
      defaultCompareFunction,
      isSidebarOpen,
      handleSelectFilters,
    };
  },
});
</script>

<template>
  <div class="flex divide-x-2 divide-pv-blue-700 overflow-hidden rounded-lg border border-blue-gray-700 bg-pv-blue-900">
    <div
      class="fixed left-0 top-0 z-40 flex h-full w-80 shrink-0 flex-col bg-pv-blue-900 p-2 pt-20 transition-transform ease-in-out lg:static lg:z-20 lg:translate-x-0 lg:p-5 xl:w-96"
      :class="{'-translate-x-full' : !isSidebarOpen}"
    >
      <div class="mb-8 flex justify-between text-blue-gray-100 lg:hidden">
        <span class="text-xl font-medium">
          {{ $t('filters') }}
        </span>
        <button
          class="flex items-center"
          @click="isSidebarOpen = false"
        >
          <inline-svg
            :src="require('assets/images/icons/close.svg')"
            class="size-6 fill-current"
          />
        </button>
      </div>
      <kalio-button
        :label="$t('list.cleanAllFilters')"
        variant="blue"
        :disabled="!anyFilter && !filtersFromBackend"
        class="mt-4"
        @click="removeAllFilters()"
      />
      <kalio-button
        v-if="filtersFromBackend"
        :label="$t('list.applyFilters')"
        variant="primary"
        :disabled="!anyFilter"
        class="mt-4"
        @click="applyFilters()"
      />
      <span class="my-6 h-px w-full bg-blue-gray-600" />
      <div class="flex h-full flex-col space-y-6 overflow-y-auto lg:overflow-y-visible">
        <vee-form
          v-for="(filter, filterKey) in filters"
          :key="filterKey"
        >
          <div v-if="filter.type === 'checkbox'">
            <div class="mb-1 block text-sm text-blue-gray-200 sm:text-lg">
              {{ filter.label }}
            </div>
            <div class="space-y-0.5">
              <kalio-checkbox
                v-for="(option, optionIndex) in filter.options"
                :key="`${filterKey}-option-${optionIndex}`"
                v-model="selectedFilters[filterKey]"
                :name="`${filterKey}-option`"
                :value="option"
                :label="option.label"
              />
            </div>
          </div>
          <kalio-select-input
            v-else-if="filter.type === 'multiselect'"
            v-model="selectedFilters[filterKey]"
            :multiple="true"
            :name="`${filterKey}-multiselect`"
            :label="filter.label"
            :options="filter.options"
            variant="semi-dark"
          />
          <kalio-number-range-input
            v-else-if="filter.type === 'numberRange'"
            v-model="selectedFilters[filterKey]"
            :label="filter.label"
          />
        </vee-form>
      </div>
    </div>
    <div class="flex grow flex-col space-y-3 p-3 md:space-y-6 md:p-5">
      <div class="flex w-full flex-col md:flex-row md:items-end md:space-x-6 md:space-y-0">
        <div class="flex flex-1">
          <form
            class="flex-1"
            :action="searchPath"
          >
            <kalio-search-bar
              name="query"
              :placeholder="$t('list.search')"
              :value="query"
              icon-name="search.svg"
              icon-size="w-7 h-auto"
            />
          </form>
          <button
            class="block items-center px-1 lg:hidden"
            @click="isSidebarOpen = !isSidebarOpen"
          >
            <inline-svg
              :src="require('assets/images/icons/tune.svg')"
              class="ml-2 w-5 rotate-180 fill-current"
            />
          </button>
        </div>
        <slot name="actions" />
      </div>
      <div
        :class="{ 'grid grid-cols-1 gap-y-2 md:grid-cols-2 md:gap-x-2': gridDisplay,
                  'flex flex-col space-x-0 space-y-4': !gridDisplay }"
        class="w-full"
      >
        <kalio-spinner
          v-if="loading"
          class="size-12 self-center"
        />
        <div
          v-for="(item, index) in filteredItems"
          v-else
          :key="index"
        >
          <slot
            name="item"
            :item="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
