<script>
import { inject, toRaw } from 'vue';
import KalioFormSection from '@/components/form/kalio-form-section.vue';
import countriesApi from '@/api/countries';
import prefillFormData from '@/utils/prefill_form.js';
import { currentUserKey } from '@/utils/keys.js';
import { formatCountriesApiResponse } from '@/utils/countries.js';
import demoDayInvestorApplicationsApi from '../api/demo_day_investor_applications';

export default {
  name: 'demo-day-application-form',
  components: { KalioFormSection },
  props: {
    formSections: { type: Object, required: true },
    companies: { type: Array, required: true },
    readOnly: { type: Boolean, default: false },
    investorApplication: { type: Object, default: () => ({}) },
    demoDay: { type: Object, default: () => ({}) },
    industries: { type: Array, required: true },
    fundKinds: { type: Array, required: true },
    investmentStages: { type: Array, required: true },
    invitationId: { type: String, required: false, default: null },
  },
  setup() {
    return {
      currentUser: inject(currentUserKey),
    };
  },
  data() {
    return {
      countries: [],
      sending: false,
      sections: { ...this.formSections },
      user: {
        socialMedia: {},
        ...toRaw(this.currentUser),
      },
    };
  },
  computed: {
    demoDayCompanies() {
      return this.companies.map(company => company.name);
    },
    isFund() {
      return this.sections.basicInfo.isFund.value === 'yes';
    },
    isDemoDayInvestor() {
      return this.sections.basicInfo.isDemoDayInvestor.value === 'yes';
    },
    demoDayIdParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const demoDayId = parseInt(urlParams.get('demo_day_id'), 10);

      return demoDayId;
    },
    isFundLpApplication() {
      return window.location.pathname.includes('/lps/');
    },
    formAnswers() {
      const formAnswers = { basicInfo: this.sections.basicInfo };
      if (this.isFund) {
        formAnswers.fund = this.sections.fund;
      } else {
        formAnswers.others = this.sections.others;
      }

      return formAnswers;
    },
    sortedIndustries() {
      const orderBy = this.$i18n.locale === 'es' ? 'name' : 'nameEn';

      return [...this.industries]
        .sort((a, b) => (b[orderBy] < a[orderBy] ? 1 : -1))
        .map((industry) => industry[orderBy]);
    },
    regionsOptions() {
      const regions = toRaw(this.$tm('demoDay.application.regions'));

      return Object.keys(regions).map(key => ({ label: regions[key], value: key }));
    },
  },
  watch: {
    investorApplication: {
      handler(application) {
        prefillFormData(this.sections, application?.formAnswers);
      },
      immediate: true,
    },
  },
  mounted() {
    countriesApi.getCountries().then(response => {
      this.countries = formatCountriesApiResponse(response);
    });
  },
  methods: {
    updateApplication() {
      return demoDayInvestorApplicationsApi.update(this.investorApplication.id, {
        user: this.user,
        demoDayInvestorApplication: {
          formAnswers: this.formAnswers,
          automaticallyCreated: false,
        },
      });
    },
    createApplication() {
      const demoDayInvestorApplication = { formAnswers: this.formAnswers };
      if (this.isFundLpApplication) {
        demoDayInvestorApplication.fundLpApplication = true;
      }

      if (this.invitationId) {
        demoDayInvestorApplication.invitationId = this.invitationId;
      }

      return demoDayInvestorApplicationsApi.create({
        user: this.user,
        demoDayInvestorApplication,
      }, this.demoDayIdParam);
    },
    async submit() {
      if (this.readOnly) return;

      this.sending = true;

      try {
        let route = this.demoDayIdParam ? `/demo_day?demo_day_id=${this.demoDayIdParam}&` : '/demo_day?';
        if (this.investorApplication && this.investorApplication.demoDayId === this.demoDay.id) {
          await this.updateApplication();
          route += 'updated_application=true';
        } else {
          await this.createApplication();
          route += 'successful_application=true';
        }
        window.location.href = route;
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>

<template>
  <vee-form
    class="flex flex-col space-y-8"
    @submit="submit"
  >
    <kalio-form-section :title="$t('demoDay.application.basicInfo')">
      <div class="flex flex-col gap-4 sm:flex-row">
        <kalio-short-text-input
          v-model="user.firstName"
          name="first_name"
          rules="required"
          :label="$t('user.form.firstName')"
        />
        <kalio-short-text-input
          v-model="user.lastName"
          name="last_name"
          rules="required"
          :label="$t('user.form.lastName')"
        />
      </div>
      <div class="flex flex-col gap-4 sm:flex-row">
        <kalio-select-input
          v-model="user.country"
          name="country"
          rules="required"
          :label="$t('user.form.country')"
          :options="countries"
          :get-option-label="(option) => option.name"
        />
        <kalio-tel-input
          v-model="sections.basicInfo.phone.value"
          name="phone"
          :required="sections.basicInfo.phone.rules.includes('required')"
          :country-hint="user.country"
          :label="sections.basicInfo.phone.label"
        />
      </div>
      <kalio-short-text-input
        v-model="sections.basicInfo.email.value"
        name="email"
        rules="required|email"
        :label="sections.basicInfo.email.label"
      />
      <kalio-short-text-input
        v-model="user.socialMedia.linkedin"
        name="linkedin"
        rules="required|url"
        :label="$t('user.form.linkedin')"
      />
      <kalio-long-text-input
        v-model="sections.basicInfo.acquisition.value"
        name="acquisition"
        :rules="sections.basicInfo.acquisition.rules"
        :label="sections.basicInfo.acquisition.label"
      />
      <kalio-long-text-input
        v-model="sections.basicInfo.recommendation.value"
        name="recommendation"
        :label="sections.basicInfo.recommendation.label"
      />
      <kalio-select-input
        v-model="sections.basicInfo.experience.value"
        name="experience"
        :multiple="true"
        :label="sections.basicInfo.experience.label"
        :rules="sections.basicInfo.experience.rules"
        :options="sections.basicInfo.experience.options"
      />
      <kalio-select-input
        v-model="sections.basicInfo.canDoItFast.value"
        name="can_do_it_fast"
        :label="sections.basicInfo.canDoItFast.label"
        :rules="sections.basicInfo.canDoItFast.rules"
        :options="sections.basicInfo.canDoItFast.options"
      />
      <kalio-select-input
        v-model="sections.basicInfo.reasonAttendance.value"
        name="reason_attendance"
        :label="sections.basicInfo.reasonAttendance.label"
        :rules="sections.basicInfo.reasonAttendance.rules"
        :options="sections.basicInfo.reasonAttendance.options"
      />
      <kalio-radio-inputs
        v-model="sections.basicInfo.isDemoDayInvestor.value"
        name="is_demo_day_investor"
        :label="sections.basicInfo.isDemoDayInvestor.label"
        :values="['yes', 'no']"
        :radio-labels="[$t('form.yes'), $t('form.no')]"
        :rules="sections.basicInfo.isDemoDayInvestor.rules"
      />
      <kalio-select-input
        v-if="isDemoDayInvestor"
        v-model="sections.basicInfo.previousDemoDayInvestments.value"
        name="previous_demo_day_investments"
        :multiple="true"
        :label="sections.basicInfo.previousDemoDayInvestments.label"
        :description="sections.basicInfo.previousDemoDayInvestments.description"
        :options="demoDayCompanies"
      />
      <kalio-radio-inputs
        v-model="sections.basicInfo.isFund.value"
        name="is_fund"
        :label="sections.basicInfo.isFund.label"
        :values="['yes', 'no']"
        :radio-labels="[$t('form.yes'), $t('form.no')]"
        :rules="sections.basicInfo.isFund.rules"
      />
    </kalio-form-section>

    <kalio-form-section
      v-if="isFund"
      :title="$t('demoDay.application.fund.title')"
    >
      <div class="flex flex-col gap-4 sm:flex-row">
        <kalio-short-text-input
          v-model="sections.fund.name.value"
          name="fund_name"
          :rules="sections.fund.name.rules"
          :label="sections.fund.name.label"
        />
        <kalio-select-input
          v-model="sections.fund.country.value"
          name="fund_country"
          :rules="sections.fund.country.rules"
          :label="sections.fund.country.label"
          :options="countries"
          :get-option-label="(option) => option.name"
        />
      </div>
      <kalio-select-input
        v-model="sections.fund.kind.value"
        name="fund_kind"
        :rules="sections.fund.kind.rules"
        :label="sections.fund.kind.label"
        :options="fundKinds"
        :get-option-label="(option) => $t(`investors.kind.${option}`)"
      />
      <kalio-long-text-input
        v-model="sections.fund.description.value"
        name="fund_description"
        :rules="sections.fund.description.rules"
        :label="sections.fund.description.label"
      />
      <kalio-long-text-input
        v-model="sections.fund.investmentThesis.value"
        name="fund_investment_thesis"
        :rules="sections.fund.investmentThesis.rules"
        :label="sections.fund.investmentThesis.label"
      />
      <kalio-select-input
        v-model="sections.fund.industries.value"
        multiple
        name="fund_industries"
        :rules="sections.fund.industries.rules"
        :label="sections.fund.industries.label"
        :options="sortedIndustries"
      />
      <kalio-select-input
        v-model="sections.fund.regionsOfInvestment.value"
        multiple
        name="fund_regions_of_investment"
        :rules="sections.fund.regionsOfInvestment.rules"
        :label="sections.fund.regionsOfInvestment.label"
        :options="regionsOptions"
        :reduce="option => option.value"
      />
      <kalio-select-input
        v-model="sections.fund.investmentStages.value"
        multiple
        name="fund_investment_stages"
        :rules="sections.fund.investmentStages.rules"
        :label="sections.fund.investmentStages.label"
        :options="investmentStages"
        :get-option-label="(option) => $t(`investors.stages.${option}`)"
      />
      <kalio-radio-inputs
        v-model="sections.fund.leadsRounds.value"
        name="fund_leads_rounds"
        :rules="sections.fund.leadsRounds.rules"
        :label="sections.fund.leadsRounds.label"
        :values="['yes', 'no']"
        :radio-labels="[$t('form.yes'), $t('form.no')]"
      />
      <kalio-short-text-input
        v-model="sections.fund.averageTicket.value"
        name="fund_average_ticket"
        :rules="sections.fund.averageTicket.rules"
        :label="sections.fund.averageTicket.label"
        type="number"
      />
      <kalio-number-range-input
        v-model="sections.fund.ticketRange.value"
        name="fund_ticket_range"
        :rules="sections.fund.ticketRange.rules"
        :label="sections.fund.ticketRange.label"
      />
      <kalio-short-text-input
        v-model="sections.fund.url.value"
        name="fund_url"
        :rules="sections.fund.url.rules"
        :label="sections.fund.url.label"
      />
      <kalio-short-text-input
        v-model="sections.fund.earlyStageFunds.value"
        name="early_stage_funds"
        :rules="sections.fund.earlyStageFunds.rules"
        :label="sections.fund.earlyStageFunds.label"
      />
      <kalio-short-text-input
        v-model="sections.fund.userPosition.value"
        name="fund_user_position"
        :rules="sections.fund.userPosition.rules"
        :label="sections.fund.userPosition.label"
      />
      <kalio-radio-inputs
        v-model="sections.fund.canWriteChecks.value"
        name="can_write_checks"
        :label="sections.fund.canWriteChecks.label"
        :values="['yes', 'no']"
        :radio-labels="[$t('form.yes'), $t('form.no')]"
        :rules="sections.fund.canWriteChecks.rules"
      />
    </kalio-form-section>

    <kalio-form-section
      v-else
      :title="$t('demoDay.application.others.title')"
    >
      <kalio-radio-inputs
        v-model="sections.others.canInvestMinimum.value"
        name="can_invest_minimum"
        :label="sections.others.canInvestMinimum.label"
        :values="['yes', 'no']"
        :radio-labels="[$t('form.yes'), $t('form.no')]"
        :rules="sections.others.canInvestMinimum.rules"
      />
      <kalio-short-text-input
        v-model="sections.others.otherInvestments.value"
        name="other_investments"
        :label="sections.others.otherInvestments.label"
      />
    </kalio-form-section>
    <kalio-button
      :label="$t('form.submit')"
      class="w-full sm:w-auto sm:self-center sm:px-24"
      type="submit"
      :loading="sending"
      :disabled="readOnly"
    />
  </vee-form>
</template>
