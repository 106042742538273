<script>
import { useEventBus } from '@vueuse/core';
import { rewatchDemoDayPitchKey } from '../utils/keys.js';

export default {
  name: 'demo-day-presentations-finished-banner-content',
  setup() {
    const bus = useEventBus(rewatchDemoDayPitchKey);

    function rewatch() {
      bus.emit();
    }

    return {
      rewatch,
    };
  },
};
</script>

<template>
  <div class="text-center font-medium text-black">
    <p>
      {{ $t('demoDay.presentationsFinished.message') }}
      <button
        class="underline focus:outline-none"
        @click="rewatch"
      >
        {{ $t('demoDay.presentationsFinished.goToRewatch') }}
      </button>
    </p>
  </div>
</template>
