<script setup>
import { onMounted } from 'vue';

const ADDEVENT_BATCH_CALENDAR_KEY = window.env.ADDEVENT_BATCH_CALENDAR_KEY;

onMounted(() => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.src = 'https://cdn.addevent.com/libs/cal/js/cal.events.embed.t3.init.js';
  document.body.appendChild(script);
});
</script>

<template>
  <div class="flex h-96 flex-col space-y-4 rounded-lg border border-blue-gray-700 bg-pv-blue-900 p-4 lg:h-full">
    <div
      style="width:100%;height:500px;"
      class="ae-emd-cal-events size-full rounded-lg"
      :data-calendar="ADDEVENT_BATCH_CALENDAR_KEY"
      :data-lbl-upcoming="$t('calendar.upcomingEvents')"
      :data-lbl-subscribe="$t('calendar.subscribe')"
      :data-lbl-no-events="$t('calendar.noEvents')"
      :data-lbl-show-more="$t('calendar.showMore')"
      data-include-atc="true"
      data-include-stc="true"
      data-include-moupcpicker="true"
      data-include-location="true"
      data-include-timezone="false"
      data-include-organizer="false"
      data-include-countdown="true"
      data-include-description="false"
      data-include-timezone-select="true"
      data-default-view="upcoming"
      data-stayonpage="false"
      data-datetime-format="1"
      data-datetime-language="es_CL"
      data-events-max="20"
      data-description-length="brief"
    />
  </div>
</template>
