<script>
import { computed } from 'vue';
import { formatCap, UNCAPPED_VALUE } from '../utils/virtual_round.js';

const UNCAPPED_SAFE_NOTION_URL = 'https://platanus.notion.site/Uncapped-SAFE-5cd6423520604aa5ac94fdf7b8e28a46';

export default {
  name: 'demo-day-auction-information',
  props: {
    auction: { type: Object, required: true },
    blurred: { type: Boolean, default: false },
  },
  setup(props) {
    const formattedCap = computed(() => formatCap(props.auction.cap));

    return {
      UNCAPPED_VALUE,
      UNCAPPED_SAFE_NOTION_URL,
      formattedCap,
    };
  },
};
</script>

<template>
  <div class="mt-3 flex flex-col rounded-xl border border-blue-gray-700 bg-pv-blue-700 p-6 text-base text-blue-gray-100">
    <div class="flex flex-col space-y-2">
      <div class="flex flex-row justify-between">
        <div class="flex flex-row items-center space-x-2">
          <span>
            {{ $t('demoDay.virtualRound.auction.size') }}
          </span>
          <VTooltip
            v-if="false"
            placement="top"
          >
            <div>
              <inline-svg
                :src="require('assets/images/icons/outline/info.svg')"
                class="size-3 stroke-current text-pv-gray"
              />
            </div>
            <template #popper>
              <p class="w-36 whitespace-pre-line">
                {{ $t('demoDay.virtualRound.auction.sizeTooltip') }}
              </p>
            </template>
          </VTooltip>
        </div>
        <span
          v-if="auction.targetAmount"
          class="font-medium"
        >
          {{ $filters.toCurrency(Number(auction.targetAmount) + Number(auction.amountRaisedPreviously)) }}
        </span>
      </div>
      <div
        v-if="false"
        class="ml-2 flex flex-col space-y-1 text-pv-gray"
      >
        <div class="flex flex-row items-center justify-between gap-x-2">
          <span class=" text-xs ">
            {{ $t('demoDay.virtualRound.auction.previouslyRaised') }}
          </span>
          <span class="text-sm font-medium">
            {{ $filters.toCurrency(auction.amountRaisedPreviously) }}
          </span>
        </div>
        <div class="flex flex-row items-center justify-between gap-x-2">
          <span class="text-xs">
            {{ $t('demoDay.virtualRound.auction.virtualRoundAmount') }}
          </span>
          <span class="text-sm font-medium">
            {{ $filters.toCurrency(auction.targetAmount) }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="false"
      class="flex flex-row justify-between"
      :class="auction.amountRaisedPreviously ? 'mt-4' : 'mt-3'"
    >
      <span>
        CAP
      </span>
      <a
        v-if="auction.cap === UNCAPPED_VALUE"
        class="font-medium text-pv-light-blue underline"
        :href="UNCAPPED_SAFE_NOTION_URL"
        target="_blank"
      >
        {{ formattedCap }}
      </a>
      <span
        v-else
        class="font-medium"
      >
        {{ formattedCap }}
      </span>
    </div>
    <div
      v-if="false"
      class="mt-3 flex flex-row justify-between"
    >
      <span>
        {{ $t('demoDay.virtualRound.auction.minAmount') }}
      </span>
      <span class="font-medium">
        {{ $filters.toCurrency(auction.minAmount) }}
      </span>
    </div>
    <slot name="timer" />
  </div>
</template>
