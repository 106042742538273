<script setup>
import { ref, computed, inject, toRef } from 'vue';
import { storeToRefs } from 'pinia';
import useActionCable from '@/hooks/actioncable.js';
import {
  useGroupMeetingCommitmentsQuery,
  useCreateGroupMeetingCommitmentMutation,
  useUpdateGroupMeetingCommitmentMutation,
} from '@/queries/group_meeting_commitments';
import { useRolesStore, useBatchStore, useGroupMeetingStore } from '@/stores';
import { currentUserKey } from '@/utils/keys.js';
import BaseGroupMeetingCommitmentsCard from '../shared/base-group-meeting-commitments-card.vue';
import BaseTrixModal from '../shared/base-trix-modal.vue';
import KalioFeedbackModal from '../shared/kalio-feedback-modal.vue';
import KalioSpinner from '../shared/kalio-spinner.vue';

const emit = defineEmits(['fetch-success']);
const props = defineProps({
  company: { type: Object, required: true },
  editAllowed: { type: Boolean, default: false },
});

const company = toRef(props, 'company');
const groupMeetingStore = useGroupMeetingStore();
const { groupMeeting } = storeToRefs(groupMeetingStore);

const {
  isFetching,
  isError,
  refetch,
  data: groupMeetingCommitments,
} = useGroupMeetingCommitmentsQuery(
  company,
  { onSuccess: () => { emit('fetch-success'); } },
);

useActionCable({ channel: 'GroupMeetingChannel', id: groupMeeting.value.id }, {
  received(event) {
    if (event.kind === 'group_meeting_commitment_update' &&
          event.company_id === props.company.id) {
      refetch();
    }
  },
});

const nextMeetingCreated = computed(() => !!groupMeeting.value.nextMeeting);
const nextMeetingCommitmentId = computed(() => {
  if (!nextMeetingCreated.value) return null;

  return (groupMeetingCommitments.value || []).find(
    commitment => commitment.groupMeetingId === groupMeeting.value.nextMeeting.id,
  )?.id;
});

const rolesStore = useRolesStore();
const batchStore = useBatchStore();
const currentUser = inject(currentUserKey);
const isAllowedToAddCommitments = computed(() => {
  if (!props.editAllowed) return false;

  return rolesStore.isMentor || rolesStore.isPartner ||
        batchStore.batchGroups.map(group => group.leaderId).includes(currentUser.value.id) ||
        rolesStore.isCompanyFounder(company.value);
});

const commitmentBody = ref('');
const commitmentModalOpen = ref(false);
const {
  mutate: createCommitment,
  isLoading: isCreatingCommitment,
  isError: isCreatingCommitmentError,
  reset: resetCreateCommitment,
} = useCreateGroupMeetingCommitmentMutation({
  onSuccess: () => {
    commitmentBody.value = '';
  },
  onSettled: () => {
    commitmentModalOpen.value = false;
  },
});

const {
  mutate: updateCommitment,
  isLoading: isUpdatingCommitment,
  isError: isUpdatingCommitmentError,
  reset: resetUpdateCommitment,
} = useUpdateGroupMeetingCommitmentMutation(
  nextMeetingCommitmentId,
  {
    onSuccess: () => {
      commitmentBody.value = '';
    },
    onSettled: () => {
      commitmentModalOpen.value = false;
    },
  },
);

const isCommitmentMutationLoading = computed(
  () => isCreatingCommitment.value || isUpdatingCommitment.value,
);

const isCommitmentMutationError = computed(
  () => isCreatingCommitmentError.value || isUpdatingCommitmentError.value,
);

function resetCommitmentMutation() {
  resetCreateCommitment();
  resetUpdateCommitment();
}

function handleSubmitCommitment() {
  if (nextMeetingCommitmentId.value) {
    updateCommitment({
      body: commitmentBody.value,
    });
  } else {
    createCommitment({
      companyId: company.value.id,
      groupMeetingId: groupMeeting.value.nextMeeting.id,
      body: commitmentBody.value,
    });
  }
}
</script>

<template>
  <div class="flex flex-col rounded-lg bg-pv-blue-900 px-2 py-3">
    <span class="text-sm text-blue-gray-400">
      Compromisos
    </span>
    <kalio-spinner
      v-if="isFetching"
      class="size-10 self-center"
    />
    <div
      v-else-if="isError"
      class="self-center"
    >
      <span>
        ❌
      </span><span class="ml-2">
        Hubo un error
      </span>
    </div>
    <base-group-meeting-commitments-card
      v-else
      class="mt-2"
      :group-meeting="groupMeeting"
      :group-meeting-commitments="groupMeetingCommitments || []"
    />
    <kalio-button
      v-if="isAllowedToAddCommitments"
      v-tooltip="nextMeetingCreated ? '' : 'No hay próxima reunión creada'"
      :label="nextMeetingCommitmentId ? 'Editar compromiso' : 'Crear compromiso'"
      class="mt-5 w-full"
      :disabled="!nextMeetingCreated"
      :variant="nextMeetingCommitmentId ? 'blue' : 'primary'"
      @click="commitmentModalOpen = true"
    />
  </div>
  <base-trix-modal
    v-model="commitmentBody"
    :show="commitmentModalOpen"
    title="Compromisos para el siguiente coliseo"
    header-icon="task.svg"
    name="commitment"
    rules="required"
    placeholder="- Tarea 1
                 - Tarea 2"
    :button-label="nextMeetingCommitmentId ? 'Actualizar compromiso' : 'Crear compromiso'"
    :loading="isCommitmentMutationLoading"
    @submit="handleSubmitCommitment"
    @close="commitmentModalOpen = false"
  />
  <kalio-feedback-modal
    v-if="isCommitmentMutationError"
    status="error"
    title="Oops. Hubo un error al crear el compromiso."
    accept="Cerrar"
    @accept="resetCommitmentMutation"
  />
</template>
