import api from './api';

export default {
  getAll() {
    return api({
      method: 'get',
      url: '/api/v1/industries',
    });
  },
};
