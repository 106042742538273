<script>
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { mergeRevenues, addMissingMonthsRevenues } from '@/utils/startup_revenues';
import startupApplicationUpdateApi from '@/api/startup_application_updates';
import KalioFormSection from '../form/kalio-form-section.vue';
import KalioModal from '../shared/kalio-modal.vue';

export default {
  name: 'kalio-startup-application-in-process-update-form',
  components: {
    KalioFormSection,
    KalioModal,
  },
  props: {
    startupApplication: { type: Object, required: true },
    updates: { type: Array, default: () => [] },
    show: { type: Boolean, required: true },
  },
  emits: ['on-save', 'close'],
  data() {
    return {
      es,
      loading: false,
      formSuccess: false,
      errorMessage: false,
      formAnswers: {
        revenues: [],
        totalUsers: 0,
        achievements: '',
        other: '',
        kind: 'in_process',
      },
    };
  },
  computed: {
    tripleTweetLength() {
      const TWEET_LENGTH = 280;
      const MAX_TWEETS_IN_LENGTH = 3;

      return `max:${TWEET_LENGTH * MAX_TWEETS_IN_LENGTH}`;
    },
    lastUpdate() {
      return this.updates[this.updates.length - 1];
    },
    lastUpdateDate() {
      return this.lastUpdate ? this.lastUpdate.createdAt : this.startupApplication.createdAt;
    },
    formattedLastUpdateDate() {
      return format(new Date(this.lastUpdateDate), "d 'de' MMMM", { locale: es });
    },
    revenues() {
      const applicationRevenues = this.startupApplication.formAnswers.progress.revenues.value || [];
      const allReportedRevenues = mergeRevenues(applicationRevenues, this.updates.map((update) => update.revenues));
      const revenuesWithMissingMonths = addMissingMonthsRevenues(
        allReportedRevenues,
        this.startupApplication.submittedAt,
      );

      return revenuesWithMissingMonths.map((revenue) => ({
        ...revenue,
        amount: parseInt(revenue.amount, 10),
        editable: revenue.wasMissing,
      }));
    },
    nonEditableRevenues() {
      return this.revenues.filter((revenue) => !revenue.editable);
    },
  },
  mounted() {
    this.formAnswers.revenues = this.revenues.filter((revenue) => revenue.editable);
  },
  methods: {
    format,
    parseISO,
    async submitUpdateApplication() {
      this.loading = true;
      const update = await startupApplicationUpdateApi.create(
        this.startupApplication.id,
        {
          ...this.formAnswers,
          revenues: this.formAnswers.revenues
            .filter((revenue) => revenue.amount !== '')
            .map(revenue => ({ amount: revenue.amount, date: revenue.date })),
        },
      );
      this.$emit('on-save', update);
      this.formSuccess = true;
      this.loading = false;
    },
  },
};
</script>

<template>
  <kalio-modal
    :show="show"
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <vee-form
      class="w-full rounded-lg bg-pv-blue-700 px-6 py-10"
      @submit="submitUpdateApplication"
    >
      <div class="flex flex-col space-y-12 px-4">
        <div class="flex w-full flex-col space-y-8">
          <kalio-form-section title="¿Qué información te gustaría actualizar?">
            <div class="flex flex-col items-start">
              <span class="text-lg">
                ¿Ha habido algún cambio en sus ingresos?
              </span>
              <p class="text-pv-gray">
                Recuerda que los valores deben estar en dólares
              </p>
              <div
                v-for="(revenue, index) in nonEditableRevenues"
                :key="`non-editable-revenue-${index}`"
                class="mt-2 flex w-full flex-row items-end space-x-2"
              >
                <kalio-text-answer
                  :label="index === 0 ? 'Mes' : ''"
                  :answer="format(parseISO(revenue.date), 'MMMM, yyyy', { locale: es })"
                />
                <kalio-text-answer
                  :label="index === 0 ? 'Revenue' : ''"
                  :answer="revenue.amount.toString()"
                />
              </div>
              <div
                v-for="(revenue, index) in formAnswers.revenues"
                :key="`revenue-${index}`"
                class="mt-2 flex w-full flex-row items-end space-x-2"
              >
                <kalio-text-answer
                  :label="index === 0 && nonEditableRevenues.length === 0 ? 'Mes' : ''"
                  :answer="format(parseISO(revenue.date), 'MMMM, yyyy', { locale: es })"
                />
                <kalio-short-text-input
                  v-model="formAnswers.revenues[index].amount"
                  rules="numeric"
                  :label="index === 0 && nonEditableRevenues.length === 0 ? 'Revenue' : ''"
                  :name="`revenue_amount_${index}`"
                />
              </div>
            </div>
            <kalio-short-text-input
              v-model="formAnswers.totalUsers"
              name="total_users"
              rules="numeric"
              label="Cantidad total de usuarios activos"
            />
            <kalio-long-text-input
              v-model="formAnswers.achievements"
              name="achievements"
              :rules="`${tripleTweetLength}|required`"
              :label="`¿Qué lograron desde el ${formattedLastUpdateDate} hasta ahora?`"
            />
            <kalio-long-text-input
              v-model="formAnswers.other"
              name="other"
              :rules="tripleTweetLength"
              label="¿Tienes alguna otra actualización relevante de tu postulación?"
            />
          </kalio-form-section>
        </div>
        <kalio-button
          label="Guardar información"
          class="w-full sm:w-auto sm:self-center sm:px-24"
          type="submit"
          :loading="loading"
        />
      </div>
    </vee-form>
  </kalio-modal>
</template>
