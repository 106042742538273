<script setup>
import FrankWriter from '@/components/shared/frank-writer.vue';
import { frankWriterPresets } from '@/utils/frank_writer_presets.js';

const PRESETS = [
  {
    name: 'Mailer assistant',
    title: 'Mailer',
    preset: frankWriterPresets.discardEmailPreset,
  },
];

</script>

<template>
  <frank-writer :presets="PRESETS" />
</template>
