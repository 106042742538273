import { serialize } from 'object-to-formdata';
import { decamelizeKeys } from 'humps';
import api from '../api';

export default {
  create(user, invite) {
    const path = '/internal/api/v1/users';

    return api({
      method: 'post',
      data: serialize(decamelizeKeys({
        user,
        ...(invite && { invite }),
      })),
      url: path,
      decamelizeKeys: false,
    });
  },
  getAll(filters) {
    const path = '/internal/api/v1/users';

    return api({
      method: 'get',
      url: path,
      params: filters,
    });
  },
};
