<script setup>
import { ref } from 'vue';
import ReviewStartupApplicationHeader from './startup-application-header.vue';
import BaseReviewApplicationTabs from './base-application-tabs.vue';
import ReviewApplicationComments from './comments.vue';

defineProps({
  qualifications: { type: Array, required: true },
});

const commentsExpanded = ref(false);
function toggleCommentsExpansion() {
  commentsExpanded.value = !commentsExpanded.value;
}
</script>

<template>
  <div class="flex flex-col">
    <review-startup-application-header :qualifications="qualifications" />
    <div class="my-6 h-px w-full bg-blue-gray-700" />
    <div class="flex flex-col gap-8 xl:flex-row">
      <base-review-application-tabs v-if="!commentsExpanded" />
      <review-application-comments
        :expanded="commentsExpanded"
        :class="commentsExpanded ? 'grow' : 'w-full xl:w-64'"
        @expand="toggleCommentsExpansion"
      />
    </div>
  </div>
</template>
