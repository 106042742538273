<script setup>
</script>

<template>
  <div>
    <iframe
      src="https://metabase-kalio.herokuapp.com/public/dashboard/dfe34d89-6264-47af-b395-bd41857667fa"
      frameborder="0"
      class="h-screen w-full"
      allowtransparency
    />
  </div>
</template>
