<script setup>
import { ref, inject, watch, computed, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useMouse } from '@vueuse/core';
import { screenKey } from '@/utils/keys';
import { useUIStore } from '@/stores';

const props = defineProps({
  keepHintOpen: { type: Boolean, default: false },
});

const SLIDE_OVER_OPEN_AREA_WIDTH = 30;
const MIN_OPEN_WIDTH = 280;
const { x } = useMouse();
const screen = inject(screenKey);
const hintOpen = ref(props.keepHintOpen);

const { isSlideOverOpen, slideOverOpenWidth } = storeToRefs(useUIStore());
const isDragging = ref(false);
const startX = ref(0);

watch(x, () => {
  if (!props.keepHintOpen) {
    hintOpen.value = x.value > screen.width - SLIDE_OVER_OPEN_AREA_WIDTH;
  }

  if (isDragging.value) {
    const newWidth = screen.width - x.value;
    if (newWidth >= MIN_OPEN_WIDTH) slideOverOpenWidth.value = newWidth;
  }
});

const contentWidth = computed(() => {
  if (!isSlideOverOpen.value) return 0;

  return slideOverOpenWidth.value;
});

function preventDefault(event) {
  event.preventDefault();
}

const stopDrag = (event) => {
  event.preventDefault();
  isDragging.value = false;
  document.removeEventListener('mousedown', preventDefault);
};

const startDrag = (event) => {
  if (isDragging.value) return;
  event.preventDefault();
  isDragging.value = true;
  startX.value = event.clientX;
  document.addEventListener('mousedown', preventDefault);
};

onMounted(() => document.addEventListener('mouseup', stopDrag));
onUnmounted(() => document.removeEventListener('mouseup', stopDrag));

</script>

<template>
  <button
    class="fixed inset-y-0 right-0 z-30 hidden items-center justify-center overflow-hidden bg-pv-blue-900 transition-width sm:flex"
    :class="hintOpen && !isSlideOverOpen ? 'w-8' : 'w-0'"
    @click="isSlideOverOpen = !isSlideOverOpen"
  >
    <div>
      <inline-svg
        :src="require('assets/images/icons/chevron-double-down.svg')"
        class="size-6 rotate-90 stroke-current"
      />
    </div>
  </button>
  <div
    class="absolute inset-y-0 right-0 z-30 overflow-x-hidden border-l border-l-blue-gray-700 bg-pv-blue-900 shadow-2xl sm:fixed sm:flex"
    :style="{ width: contentWidth + 'px' }"
    :class="{'transition-width duration-150 ease-linear': !isDragging}"
  >
    <div
      class="h-full w-1 cursor-ew-resize"
      @mousedown="startDrag"
    />
    <div class="flex h-screen w-full flex-col px-4 py-6">
      <div class="ml-3 flex shrink-0 items-center">
        <button
          type="button"
          class="rounded-md bg-pv-blue-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click="isSlideOverOpen = !isSlideOverOpen"
        >
          <inline-svg
            :src="require('assets/images/icons/close.svg')"
            class="size-6 fill-current text-blue-gray-50"
          />
        </button>
      </div>
      <slot />
    </div>
  </div>
</template>
