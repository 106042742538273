<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  title: { type: String, default: null },
  size: { type: String, default: 'base' },
});

const titleSize = computed(() => {
  const classes = {
    base: 'text-base',
    xl: 'text-xl',
    '2xl': 'text-2xl',
  };

  return classes[props.size];
});

const iconClasses = computed(() => {
  const sizeClasses = {
    base: 'h-6',
    xl: 'h-7',
    '2xl': 'h-8',
  };

  return `${sizeClasses[props.size]}`;
});

const open = ref(false);
</script>

<template>
  <div class="flex flex-col">
    <button
      class="flex flex-row items-center gap-x-2"
      type="button"
      @click="open = !open"
    >
      <inline-svg
        :src="require('assets/images/icons/triangle.svg')"
        class="w-auto cursor-pointer fill-current p-2 text-blue-gray-50 transition"
        :class="[
          open ? 'rotate-180' : 'rotate-90',
          iconClasses,
        ]"
      />
      <slot name="title">
        <span :class="[titleSize, 'text-blue-gray-50']">
          {{ title }}
        </span>
      </slot>
    </button>
    <div
      v-if="open"
      class="ml-4"
    >
      <slot />
    </div>
  </div>
</template>
