<script>
import { ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import KalioConfirmationModal from '@/components/shared/kalio-confirmation-modal.vue';
import useActionCable from '@/hooks/actioncable.js';
import { demoDayStreamingChannelKey } from '../../utils/keys.js';
import liveDemoDayCompanyApi from '../../api/live_demo_day_company.js';
import demoDaysApi from '../../api/demo_days.js';

export default {
  name: 'internal-demo-day-dashboard',
  components: { KalioConfirmationModal },
  props: {
    demoDayCompanies: { type: Array, required: true },
    demoDay: { type: Object, required: true },
  },
  setup(props) {
    const spanishStreamingLiveDemoDayCompanyId = ref();
    const englishStreamingLiveDemoDayCompanyId = ref();

    useActionCable({ channel: demoDayStreamingChannelKey, room: 'es' }, {
      received(event) {
        if (event.kind === 'live_demo_day_company_updated') {
          spanishStreamingLiveDemoDayCompanyId.value = event.demo_day_company_id;
        }
      },
    });

    useActionCable({ channel: demoDayStreamingChannelKey, room: 'en' }, {
      received(event) {
        if (event.kind === 'live_demo_day_company_updated') {
          englishStreamingLiveDemoDayCompanyId.value = event.demo_day_company_id;
        }
      },
    });

    liveDemoDayCompanyApi.get('es').then((id) => {
      spanishStreamingLiveDemoDayCompanyId.value = id;
    });

    liveDemoDayCompanyApi.get('en').then((id) => {
      englishStreamingLiveDemoDayCompanyId.value = id;
    });

    function updateLiveDemoDayCompanyId(id, locale) {
      liveDemoDayCompanyApi.update(id, locale);
    }

    const demoDayUpdateConfirmationModalOpen = ref(false);
    const { mutate: updateDemoDayEndDate } = useMutation({
      mutationFn: () => demoDaysApi.update(props.demoDay.id, { endDate: new Date() }),
      onSuccess() {
        demoDayUpdateConfirmationModalOpen.value = false;
      },
    });

    return {
      spanishStreamingLiveDemoDayCompanyId,
      englishStreamingLiveDemoDayCompanyId,
      updateLiveDemoDayCompanyId,
      demoDayUpdateConfirmationModalOpen,
      updateDemoDayEndDate,
    };
  },
};
</script>

<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-2">
      <span>
        Streaming in English
      </span>
      <div class="flex flex-row space-x-2">
        <button
          v-for="demoDayCompany in demoDayCompanies"
          :key="demoDayCompany.id"
          v-tooltip="demoDayCompany.company.name"
          class="relative size-14"
          @click="updateLiveDemoDayCompanyId(demoDayCompany.id, 'en')"
        >
          <img
            :src="demoDayCompany.company.isotypeUrl"
            class="size-full rounded-xl object-cover"
          >
          <span
            v-if="englishStreamingLiveDemoDayCompanyId === demoDayCompany.id"
            class="absolute -right-1 -top-1 flex size-2.5"
          >
            <span class="absolute inline-flex size-full animate-ping rounded-full bg-red-400 opacity-75" />
            <span class="relative inline-flex size-2.5 rounded-full bg-red-500" />
          </span>
        </button>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <span>
        Streaming en Español
      </span>
      <div class="flex flex-row space-x-2">
        <button
          v-for="demoDayCompany in demoDayCompanies"
          :key="demoDayCompany.id"
          v-tooltip="demoDayCompany.company.name"
          class="relative size-14"
          @click="updateLiveDemoDayCompanyId(demoDayCompany.id, 'es')"
        >
          <img
            :src="demoDayCompany.company.isotypeUrl"
            class="size-full rounded-xl object-cover"
          >
          <span
            v-if="spanishStreamingLiveDemoDayCompanyId === demoDayCompany.id"
            class="absolute -right-1 -top-1 flex size-2.5"
          >
            <span class="absolute inline-flex size-full animate-ping rounded-full bg-red-400 opacity-75" />
            <span class="relative inline-flex size-2.5 rounded-full bg-red-500" />
          </span>
        </button>
      </div>
    </div>

    <kalio-button
      label="Terminaron ambos streamings"
      class="w-auto self-start px-12"
      @click="demoDayUpdateConfirmationModalOpen = true"
    />
  </div>
  <kalio-confirmation-modal
    v-if="demoDayUpdateConfirmationModalOpen"
    title="¿Estás seguro/a?"
    @close="demoDayUpdateConfirmationModalOpen = false"
    @accept="updateDemoDayEndDate"
  />
</template>
