<script>
import KalioModal from '../shared/kalio-modal.vue';

export default {
  components: { KalioModal },
  props: {
    perk: { type: Object, required: true },
  },
  emits: ['close'],
};
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <div class="flex flex-col rounded-lg bg-pv-blue-700 px-6 py-5">
      <div class="mb-9 flex justify-between">
        <div class="flex items-center">
          <div class="mr-4 flex size-7 items-center justify-center rounded-md bg-pv-blue-900 p-1">
            <inline-svg
              class="h-4 w-auto fill-current text-pv-yellow"
              :src="require('assets/images/icons/copy.svg')"
            />
          </div>
          <p class="text-2xl text-blue-gray-50">
            {{ $t('perks.redeem', { companyName: perk.companyName }) }}
          </p>
        </div>
      </div>
      <p
        v-if="perk.fileUrl"
        class="mb-2 text-left text-base text-cool-gray-200"
      >
        {{ $t('perks.fileUrl') }}
      </p>
      <a
        v-if="perk.fileUrl"
        :href="perk.fileUrl"
        class="mb-4 pl-4 text-left text-sm underline"
        noopener
        target="_blank"
      >
        {{ $t('perks.download') }}
      </a>
      <p class="mb-2 text-left text-base text-cool-gray-200">
        {{ $t('perks.redeemInstructions') }}
      </p>
      <p class="mb-8 whitespace-pre-line pl-4 text-left text-sm text-cool-gray-200">
        {{ perk.redeemInstructions }}
      </p>
    </div>
  </kalio-modal>
</template>
