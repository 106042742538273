<script>
import KalioSocialMediaIcons from '@/components/shared/kalio-social-media-icons';

export default {
  name: 'demo-day-founder-card',
  components: { KalioSocialMediaIcons },
  props: {
    founder: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="flex flex-row items-center space-x-4">
    <div class="size-14 shrink-0 overflow-hidden rounded-lg">
      <img
        :src="founder.avatarUrl"
        alt="founder photo"
        class="size-full object-cover"
      >
    </div>
    <div class="flex flex-col">
      <span class="text-base font-medium">
        {{ founder.fullName }}
      </span>
      <span class="text-xs">
        {{ founder.email }}
      </span>
      <kalio-social-media-icons
        :social-media="founder.socialMedia"
        class="mt-1 space-x-2"
        icon-classes="w-4 h-4"
      />
    </div>
  </div>
</template>

