<script setup>
import { computed } from 'vue';
import useFormAnswers from '@/hooks/form_answers.js';

const props = defineProps({
  startupReferral: { type: Object, required: true },
});

const STARTUP_FORM_ANSWER_PATHS = [
  'startup.name',
  'startup.founderName',
  'startup.contactMethod',
  'startup.referrerComments',
];

const { formAnswers: referralFormAnswers } = useFormAnswers(
  STARTUP_FORM_ANSWER_PATHS,
  props.startupReferral,
);

const userPath = computed(() => `/people/${props.startupReferral.user.id}`);
</script>

<template>
  <div class="flex flex-col items-start space-y-4">
    <span class="text-left text-xl">
      Referral para {{ startupReferral.formAnswers.startup.name.value }} (de
      <a
        class="underline"
        :href="userPath"
      >
        {{ startupReferral.user.fullName }}
      </a>)
    </span>
    <kalio-text-answer
      v-for="(item, index) in referralFormAnswers"
      :key="index"
      :label="item.label"
      :answer="item.answer"
      size="small"
    />
    <kalio-text-answer
      :label="startupReferral.formAnswers.startup.referrerRecommendationLevel.label"
      :answer="startupReferral.formAnswers.startup.referrerRecommendationLevel.value.label"
      size="small"
    />
    <kalio-text-answer
      :label="startupReferral.formAnswers.startup.referrerConnectionWithStartup.label"
      :answer="startupReferral.formAnswers.startup.referrerConnectionWithStartup.value.label"
      size="small"
    />
  </div>
</template>
