<script>
import { ref } from 'vue';

export default {
  name: 'kalio-program-goal-form',
  props: {
    baseProgramGoal: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const programGoal = ref(props.baseProgramGoal);

    return { programGoal };
  },
};
</script>

<template>
  <div class="flex w-full max-w-lg flex-col space-y-4">
    <h1 class="text-center text-2xl sm:text-3xl">
      Meta del programa
    </h1>
    <p class="text-base text-blue-gray-200">
      Esta es la meta de tu startup para el programa.
      Te la recordaremos, y te animaremos a cumplirla.
      Piensa muy bien en cuál es tu métrica más importante.
      Si tienes dudas, pregúntale a los mentores!
    </p>

    <kalio-form-wrapper
      v-slot="{ loading }"
      :action="programGoal.id ? `/program/program_goals/${programGoal.id}` : '/program/program_goals'"
      :method="programGoal.id ? 'patch' : 'post'"
    >
      <div class="flex flex-col space-y-12">
        <div class="flex flex-col space-y-8">
          <kalio-short-text-input
            v-model="programGoal.goal"
            rules="required"
            name="program_goal[goal]"
            label="¿Cuál será tu meta para antes del Demo Day? ¿Qué quieres lograr?"
            placeholder="Ej: 20,000 MRR, 300 clientes, u otro"
          />
          <kalio-short-text-input
            v-model="programGoal.kpiName"
            rules="required"
            name="program_goal[kpi_name]"
            label="¿Cuál será tu North Star Metric?"
            placeholder="Ej: MRR, ARR, Usuarios Activos, u otro"
          />
          <kalio-short-text-input
            v-model="programGoal.periodGoal"
            rules="required"
            name="program_goal[period_goal]"
            label="¿Cuánto tienes que crecer semanal o mensualmente para lograr tu meta?"
            placeholder="Ej: 10% semanalmente"
          />
        </div>
        <kalio-button
          :loading="loading"
          type="submit"
          label="Guardar"
          class="w-full sm:w-auto sm:self-center sm:px-24"
        />
      </div>
    </kalio-form-wrapper>
  </div>
</template>
