// eslint-disable-next-line import/prefer-default-export
export function getWhatsappLink(number, message) {
  if (!number) return '';

  let whatsappFormattedNumber = number;
  const plusSignPosition = number.indexOf('+');
  if (plusSignPosition > 0) return '';
  if (plusSignPosition === 0) {
    whatsappFormattedNumber = number.substring(plusSignPosition + 1, number.length);
  }

  if (message) {
    return `https://web.whatsapp.com/send/?phone=${whatsappFormattedNumber}&text=${message}`;
  }

  return `https://web.whatsapp.com/send/?phone=${whatsappFormattedNumber}`;
}
