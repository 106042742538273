import { differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';
import i18n from '@/plugins/i18n';
import { toCurrency } from '@/filters';

const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const UNCAPPED_VALUE = -1;

function formatDDHH(now, date) {
  const days = differenceInDays(date, now);
  const hours = differenceInHours(date, now) % HOURS_IN_DAY;
  const daysText = `${days} ${i18n.global.t('demoDay.virtualRound.auction.days')}`;
  const hoursText = `${hours} ${i18n.global.t('demoDay.virtualRound.auction.hours')}`;

  return `${daysText}, ${hoursText}`;
}

function formatHHMMSS(now, date) {
  const hours = (differenceInHours(date, now) % HOURS_IN_DAY).toString().padStart(2, 0);
  const minutes = (differenceInMinutes(date, now) % MINUTES_IN_HOUR).toString().padStart(2, 0);
  const seconds = (differenceInSeconds(date, now) % MINUTES_IN_HOUR).toString().padStart(2, 0);

  return `${hours}:${minutes}:${seconds}`;
}

function formatCap(cap) {
  if (cap === UNCAPPED_VALUE) {
    return i18n.global.t('demoDay.virtualRound.auction.uncapped');
  }

  return toCurrency(cap);
}

export { UNCAPPED_VALUE, formatDDHH, formatHHMMSS, formatCap };
