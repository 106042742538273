<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import BaseDailyVideoControls from './base-daily-video-controls.vue';

defineEmits(['toggle-video', 'toggle-audio', 'leave-meeting']);
const props = defineProps({
  participant: { type: Object, required: true },
});

const username = computed(
  () => props.participant?.user_name || 'Invitado',
);

const videoSrc = ref(null);
const audioSrc = ref(null);
const audio = ref(null);

function updateSource(stream, newTrack) {
  const existingTracks = stream?.getTracks();
  if (!existingTracks || existingTracks.length === 0) {
    return new MediaStream([newTrack]);
  }

  const existingTrack = existingTracks[0];
  if (newTrack.id !== existingTrack.id) {
    stream.removeTrack(existingTrack);
    stream.addTrack(newTrack);
  }

  return null;
}

function handleVideo() {
  if (!props.participant?.video) return;

  const videoTrack = props.participant.tracks.video.persistentTrack;
  const videoStream = updateSource(videoSrc.value, videoTrack);
  if (videoStream) {
    videoSrc.value = videoStream;
  }
}

function handleAudio() {
  if (props.participant?.local || !props.participant?.audio) return;

  const audioTrack = props.participant.tracks.audio.persistentTrack;
  const audioStream = updateSource(audioSrc.value, audioTrack);
  if (audioStream) {
    audioSrc.value = audioStream;
  }

  audio.value.srcObject = audioSrc.value;
  // this is not on the docs, but with autoPlay it doesn't works, needs to be played
  // manually after updating srcObject
  audio.value.play();
}

function updateTracks() {
  handleAudio();
  handleVideo();
}

onMounted(updateTracks);
watch(() => props.participant, updateTracks);
</script>

<template>
  <div
    class="group relative flex shrink-0 items-center justify-center overflow-hidden"
    :class="{ 'cursor-pointer': participant.local }"
  >
    <audio ref="audio" />
    <template v-if="participant.video">
      <video
        v-if="participant.video"
        :srcObject="videoSrc"
        autoPlay
        playsInline
        class="size-full object-cover"
      />
      <span class="absolute right-2 top-2 text-blue-gray-400 group-hover:text-blue-gray-700">
        {{ username }}
      </span>
    </template>
    <span v-else>
      {{ username }}
    </span>

    <base-daily-video-controls
      v-if="participant.local"
      :participant="participant"
      @video-click="$emit('toggle-video')"
      @audio-click="$emit('toggle-audio')"
      @leave-meeting="$emit('leave-meeting')"
    />
  </div>
</template>
