exports.DAILY_STATUS = {
  outside: 'outside',
  joining: 'joining',
  joined: 'joined',
  error: 'error',
};

exports.INTERVIEW_STEP = {
  room: 'room',
  interview: 'interview',
  finished: 'finished',
};

exports.RECORDING_STEP = {
  notStarted: 'not_started',
  recording: 'recording',
  finished: 'finished',
};
