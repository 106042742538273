<script setup>
import { ref, inject } from 'vue';
import KalioMeeting from '@/components/shared/kalio-meeting.vue';
import ReviewStartupApplicationHeader from '@/components/internal/apply/review/startup-application-header.vue';
import ReviewApplicationComments from '@/components/internal/apply/review/comments.vue';
import ApplicationInterviewEvaluations from '@/components/internal/apply/review/application-interview-evaluations.vue';
import ApplyReviewFrankWriter from '@/components/internal/apply/review/frank-writer.vue';
import { useStartupApplicationStore } from '@/stores';
import { currentUserKey } from '@/utils/keys.js';
import interviewEvaluationsApi from '@/api/internal/interview_evaluations.js';
import applicationInterviewsApi from '@/api/internal/application_interviews.js';
import TabLegal from '@/components/internal/apply/review/tabs/legal.vue';

const currentUser = inject(currentUserKey);

const props = defineProps({
  startupApplication: { type: Object, required: true },
  applicationProcesses: { type: Array, required: true },
  aasmStates: { type: Array, required: true },
  countries: { type: Array, required: true },
  qualifications: { type: Array, required: true },
  evaluations: { type: Array, default: () => [] },
  interview: { type: Object, required: true },
  showVotes: { type: Boolean, default: false },
});

const {
  setFullStartupApplication,
  setStartupApplications,
} = useStartupApplicationStore();

setStartupApplications([props.startupApplication]);
setFullStartupApplication(props.startupApplication);

const scoreOptions = [1, 2, 3, 4, 5]; // eslint-disable-line no-magic-numbers
const decisionOptions = ['passed', 'failed', 'wait'];
const voteOptions = ['strong_no', 'no', 'neutral', 'yes', 'strong_yes'];
const sending = ref(false);
const sent = ref(props.evaluations.some((evaluation) => evaluation.user.id === currentUser.value.id));
const decisionTaken = ref(props.interview.decision !== null);
const errorMessage = ref(null);
const evaluations = ref(props.evaluations);
const interview = ref(props.interview);
const startupApplication = ref(props.startupApplication);
const isLegalOpen = ref(false);

const formAnswers = ref({
  ceoScore: null,
  ctoScore: null,
  foundingTeamScore: null,
  ideaScore: null,
  marketScore: null,
  progressScore: null,
  capTableScore: null,
  financesScore: null,
  notes: null,
  vote: null,
});

const decisionForm = ref({
  decision: null,
  comment: null,
});

async function fetchEvaluations() {
  const response = await interviewEvaluationsApi.getAll({ applicationInterviewId: interview.value.id });
  evaluations.value.splice(0); // eslint-disable-line no-magic-numbers
  evaluations.value.push(...(response.interviewEvaluations || response));
}

function createEvaluation() {
  interviewEvaluationsApi
    .create({ ...formAnswers.value, applicationInterviewId: interview.value.id })
    .then((response) => {
      sent.value = true;
      evaluations.value = [...evaluations.value, response.interviewEvaluation || response];
    })
    .catch((error) => {
      errorMessage.value = Object.keys(error.response.data.errors)
        .map((key) => `${key}: ${error.response.data.errors[key].join(', ')}`)
        .join(', ');
    });
}

function saveDecision() {
  const data = {
    decision: decisionForm.value.decision,
    decisionComment: decisionForm.value.comment,
  };

  applicationInterviewsApi
    .update(interview.value.id, data)
    .then((response) => {
      decisionTaken.value = true;
      interview.value.decision = response.applicationInterview.decision;
      interview.value.decisionComment = response.applicationInterview.decisionComment;
      if (interview.value.kind === 'first_interview') {
        startupApplication.value.firstInterview = interview.value;
      } else if (interview.value.kind === 'second_interview') {
        startupApplication.value.secondInterview = interview.value;
      }
      setStartupApplications([startupApplication.value]);
      setFullStartupApplication(startupApplication.value);
    });
}

function getVoteLabel(option) {
  const labels = {
    'strong_no': 'No fuerte',
    no: 'No',
    neutral: 'Neutral',
    yes: 'Sí',
    'strong_yes': 'Si fuerte',
  };

  return labels[option];
}

function getDecisionLabel(option) {
  const labels = {
    passed: 'Pasa a siguiente entrevista ✅',
    failed: 'No pasa a siguiente entrevista ❌',
    wait: 'Esperar',
  };

  return labels[option];
}

</script>

<template>
  <div class="flex w-full flex-row space-x-2">
    <div class="flex w-2/5 shrink-0 flex-col space-y-4">
      <div class="min-h-[600px]">
        <kalio-meeting :daily-url="interview.preparationMeetingUrl" />
      </div>
      <div class="w-full rounded-lg bg-pv-blue-900 p-3">
        <review-application-comments :expandable="false" />
      </div>
    </div>
    <div class="flex w-full flex-col self-start rounded-lg border border-blue-gray-600 bg-pv-blue-900 p-4">
      <review-startup-application-header :qualifications="qualifications">
        <template #buttons>
          <kalio-button
            label="Ir a Previa"
            :href="`/internal/apply/interviews/${interview.id}`"
            variant="tertiary"
          />
        </template>
      </review-startup-application-header>
      <div class="my-6 h-px w-full bg-blue-gray-700" />
      <div
        v-if="sent || showVotes"
        class="flex flex-col gap-y-2"
      >
        <div class="flex flex-col gap-y-2">
          <div class="flex justify-between">
            <h1 class="text-2xl font-bold">
              Votos
            </h1>
            <kalio-button @click="fetchEvaluations">
              Recargar
            </kalio-button>
          </div>
          <application-interview-evaluations :evaluations="evaluations" />
        </div>
        <div class="my-6 h-px w-full bg-blue-gray-700" />
        <div class="flex flex-col gap-y-2">
          <h2 class="text-2xl font-bold">
            Decisión de entrevista
          </h2>
          <div
            v-if="!decisionTaken"
            class="flex flex-col gap-y-4"
          >
            <kalio-select-input
              v-model="decisionForm.decision"
              name="decision"
              label="Decisión"
              :options="decisionOptions"
              :get-option-label="(option) => getDecisionLabel(option)"
              variant="semi-dark"
            />
            <kalio-trix-editor
              v-model="decisionForm.comment"
              name="decision_comment"
              label="Por qué se tomó esta decisión"
              variant="semi-dark"
            />
            <kalio-button
              label="Guardar decisión"
              :loading="sending"
              class="w-full sm:w-auto sm:self-center sm:px-24"
              @click="saveDecision"
            />
            <p
              v-if="errorMessage"
              class="text-sm text-red-500"
            >
              {{ errorMessage }}
            </p>
          </div>
          <div
            v-else
            class="flex flex-col gap-y-4"
          >
            <div class="text-base">
              {{ getDecisionLabel(interview.decision) }}
            </div>
            <div class="flex flex-col gap-y-1 text-base">
              <div class="font-bold">
                Comentario
              </div>
              <span
                class="trix-content"
                v-html="interview.decisionComment"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-y-4"
      >
        <div>
          <h1 class="text-2xl font-bold">
            Tu evaluación de la entrevista
          </h1>
          <p class="text-base">
            Evalúa cada item de 1 a 5, donde 5 es la mejor evaluación.
          </p>
        </div>
        <kalio-select-input
          v-model="formAnswers.ceoScore"
          name="ceo_score"
          label="Puntaje CEO"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <kalio-select-input
          v-model="formAnswers.ctoScore"
          name="cto_score"
          label="Puntaje CTO"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <kalio-select-input
          v-model="formAnswers.foundingTeamScore"
          name="founding_team_score"
          label="Puntaje team fundador completo"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <kalio-select-input
          v-model="formAnswers.ideaScore"
          name="idea_score"
          label="Puntaje de idea"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <kalio-select-input
          v-model="formAnswers.marketScore"
          name="market_score"
          label="Puntaje de mercado o espacio de la idea"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <kalio-select-input
          v-model="formAnswers.progressScore"
          name="progress_score"
          label="Puntaje de progreso"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <div class="mt-2 h-8">
          <button
            class="flex items-center border-none text-base text-pv-light-blue"
            @click="isLegalOpen = !isLegalOpen"
          >
            <span>
              {{ isLegalOpen ? 'Cerrar información legal' : 'Ver información legal' }}
            </span>
            <inline-svg
              :src="require('assets/images/icons/toggle-arrow.svg')"
              class="h-6 w-auto cursor-pointer fill-current p-2"
              :class="isLegalOpen ? 'rotate-0' : 'rotate-180'"
            />
          </button>
        </div>

        <div v-if="isLegalOpen">
          <tab-legal />
        </div>
        <kalio-select-input
          v-model="formAnswers.capTableScore"
          name="cap_table_score"
          label="Puntaje de cap table"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <kalio-select-input
          v-model="formAnswers.financesScore"
          name="finances_score"
          label="Puntaje de situación financiera"
          :options="scoreOptions"
          variant="semi-dark"
        />
        <kalio-trix-editor
          v-model="formAnswers.notes"
          name="notes"
          label="Comentarios y notas sobre la entrevista"
          variant="semi-dark"
        />
        <kalio-select-input
          v-model="formAnswers.vote"
          name="vote"
          label="Tu voto de la entrevista"
          :options="voteOptions"
          :get-option-label="(option) => getVoteLabel(option)"
          variant="semi-dark"
        />
        <kalio-button
          label="Enviar evaluación"
          :loading="sending"
          class="w-full sm:w-auto sm:self-center sm:px-24"
          @click="createEvaluation"
        />
        <p
          v-if="errorMessage"
          class="text-sm text-red-500"
        >
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </div>
  <apply-review-frank-writer />
</template>
