<script setup>
import { ref, watch } from 'vue';
import { useScroll, useThrottleFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { format } from 'date-fns';
import { useStartupApplicationStore } from '@/stores';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';

const emit = defineEmits([
  'set-application', 'change-sort', 'scrolled-to-first', 'scrolled-to-last',
]);
defineProps({
  selectedSort: { type: String, required: true },
  loadingBottom: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
});

const startupApplicationStore = useStartupApplicationStore();
const {
  startupApplications,
  currentStartupApplicationId,
} = storeToRefs(startupApplicationStore);

function formatDate(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

const scrollableElement = ref(null);
const { arrivedState } = useScroll(scrollableElement);

const THROTTLE_SCROLL_EVENT_TIME = 500;
const throttledScrollToLast = useThrottleFn(
  (state) => emit('scrolled-to-last', state),
  THROTTLE_SCROLL_EVENT_TIME,
);

watch(arrivedState, ({ bottom }) => {
  if (bottom) {
    throttledScrollToLast('bottom');
  }
});
</script>

<template>
  <div class="flex flex-col overflow-y-hidden rounded-md border border-blue-gray-700 bg-pv-blue-900 p-4">
    <div class="flex flex-col space-y-3">
      <span class="text-xs font-medium text-blue-gray-400">
        Postulaciones
      </span>
      <button
        class="flex flex-row items-center space-x-2 text-xs font-medium text-pv-gray"
        @click="$emit('change-sort')"
      >
        <span v-if="selectedSort === 'submitted_at'">
          Ordenado por fecha
        </span>
        <span v-else-if="selectedSort === 'score'">
          Ordenado por puntaje
        </span>
        <span v-else-if="selectedSort === 'updated_at'">
          Ordenado por cambios recientes
        </span>
        <inline-svg
          :src="require('assets/images/icons/switch.svg')"
          class="size-3 fill-current"
        />
      </button>
    </div>
    <div
      v-if="loading"
      class="mt-8 flex w-full flex-col items-center"
    >
      <kalio-spinner :size="12" />
    </div>
    <div
      v-else
      ref="scrollableElement"
      class="mt-4 flex size-full max-h-screen flex-col overflow-y-scroll"
    >
      <button
        v-for="startupApplication in startupApplications"
        :key="startupApplication.id"
        class="flex w-full flex-col rounded-md p-4 "
        :class="startupApplication.id === currentStartupApplicationId
          ? 'bg-pv-blue-700 pointer-events-none'
          : 'hover:bg-pv-blue-800'
        "
        @click="$emit('set-application', startupApplication.id)"
      >
        <span class="text-left font-bold">
          {{ startupApplication.startupName }}
        </span>
        <div class="flex flex-row items-center justify-between gap-x-4 text-pv-gray">
          <div class="flex flex-row items-center space-x-1">
            <span>
              {{ startupApplication.startupApplicationInsight.score }}
            </span>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <inline-svg
              :src="require('assets/images/icons/calendar.svg')"
              class="size-3 fill-current"
            />
            <span>
              {{ formatDate(startupApplication.submittedAt) }}
            </span>
          </div>
        </div>
      </button>
      <div
        v-if="loadingBottom"
        class="mt-2 flex flex-col items-center"
      >
        <kalio-spinner :size="8" />
      </div>
    </div>
  </div>
</template>
