<script setup>
import { ref, onMounted, watch } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps({
  widthClass: { type: String, default: 'w-full max-w-lg' },
  show: { type: Boolean, default: null },
  customCloseButton: { type: Boolean, default: false },
});

defineEmits(['close']);

/*
  backwards compatibility hack to allow using v-if instead of show with existing modals.
  Modals should be refactored to use show instead of v-if.
*/
const open = ref(false);
watch(props, (newProps) => {
  open.value = newProps.show;
});
onMounted(() => {
  if (props.show === null) {
    open.value = true;
  } else {
    open.value = props.show;
  }
});
</script>

<template>
  <TransitionRoot
    :show="open"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-30"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative z-50 rounded-lg bg-white shadow-xl transition-all"
              :class="widthClass"
            >
              <button
                v-if="!customCloseButton"
                class="absolute right-4 top-4 self-start focus:outline-none"
                @click="$emit('close')"
              >
                <inline-svg
                  :src="require('assets/images/icons/close.svg')"
                  class="h-auto w-6 fill-current text-white"
                />
              </button>
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
