<script>
import { computed } from 'vue';
import DemoDayAuctionOrdersTable from '../components/demo-day-auction-orders-table.vue';

export default {
  components: { DemoDayAuctionOrdersTable },
  props: {
    orders: { type: Array, default: () => ([]) },
    modifiedLastOrderInAmount: { type: String, default: '' },
    ordersTotalSum: { type: String, default: '' },
    targetAmount: { type: String, default: '' },
    companyName: { type: String, default: '' },
  },
  setup(props) {
    function getAmount(order) {
      if (order.partiallyIn) return `${props.modifiedLastOrderInAmount} de ${order.textAmount}`;

      return order.amount;
    }

    function getStatus(order) {
      if (order.completelyIn) return 'Adentro';
      if (order.partiallyIn) return 'Parcial';
      if (order.out) return 'Afuera';

      return '';
    }

    function getInvestorType(order) {
      if (order.investmentLegalInformation?.investmentType === 'natural_person') return 'Persona';
      if (order.investmentLegalInformation?.investmentType === 'legal_person') return 'Empresa';

      return '';
    }

    function getNaturalPersonName(order) {
      if (order.investmentLegalInformation?.investmentType === 'natural_person') return order.user.fullName;

      return '';
    }

    const csvContent = computed(() => {
      const headersArray = [
        '"ID"',
        '"Monto"',
        '"CAP"',
        '"Estado"',
        '"Email usuario"',
        '"Nombre completo usuario"',
        '"Invierte como Persona o Empresa"',
        '"DNI Inversión Persona"',
        '"Nombre Inversión Persona"',
        '"Dirección Inversión Persona"',
        '"Nombre Inversión Empresa"',
        '"DNI Empresa"',
        '"Dirección Inversión Empresa"',
        '"Nombre Representante Legal"',
        '"DNI Representante legal inversión"',
      ];
      const headers = `${headersArray.join(';')}\n`;

      const ordersContentArray = props.orders.map((order) => [
        order.id,
        getAmount(order),
        order.cap,
        getStatus(order),
        order.user.email,
        order.user.fullName,
        getInvestorType(order),
        order.investmentLegalInformation?.investmentData?.naturalPersonRut || '',
        getNaturalPersonName(order),
        order.investmentLegalInformation?.investmentData?.naturalPersonAddress || '',
        order.investmentLegalInformation?.investmentData?.businessName || '',
        order.investmentLegalInformation?.investmentData?.legalPersonRut || '',
        order.investmentLegalInformation?.investmentData?.legalPersonAddress || '',
        order.investmentLegalInformation?.investmentData?.legalRepresentativeName || '',
        order.investmentLegalInformation?.investmentData?.legalRepresentativeRut || '',
      ]);

      const ordersContent = ordersContentArray.map((order) => order.join(';')).join('\n');

      return `data:text/csv;charset=utf-8,${headers}${ordersContent}`;
    });

    function downloadCSV() {
      const encodedUri = encodeURI(csvContent.value);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'answers.csv');
      document.body.appendChild(link);

      link.click();
    }

    return {
      downloadCSV,
    };
  },
};
</script>

<template>
  <div class="container flex flex-col space-y-3">
    <h1 class="pt-6 text-3xl font-bold text-white">
      Resumen Ronda {{ companyName }}
    </h1>
    <div class="flex flex-col space-y-3 rounded-md bg-pv-blue-900 p-3">
      <div class="flex items-end justify-between">
        <div class="flex space-x-3">
          <div class="flex">
            <div class="rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4">
              <span class="mb-2 block text-sm text-pv-gray">
                Suma total órdenes
              </span>
              <span class="text-2xl">
                US {{ ordersTotalSum }}
              </span>
            </div>
          </div>

          <div class="flex">
            <div class="rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4">
              <span class="mb-2 block text-sm text-pv-gray">
                Monto objetivo
              </span>
              <span class="text-2xl">
                US {{ targetAmount }}
              </span>
            </div>
          </div>
        </div>
        <kalio-button
          label="Descargar datos"
          size="small"
          @click="downloadCSV"
        />
      </div>

      <div class="flex">
        <div class="w-full">
          <demo-day-auction-orders-table
            :orders="orders"
            :modified-last-order-in-amount="modifiedLastOrderInAmount"
          />
        </div>
      </div>
    </div>
  </div>
</template>
