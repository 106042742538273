import api from './api';

export default {
  create() {
    const path = '/api/v1/founder_profiles';

    return api({
      method: 'post',
      url: path,
    });
  },
  update(id, body) {
    const path = `/api/v1/founder_profiles/${id}`;

    return api({
      method: 'put',
      data: body,
      url: path,
    });
  },
};
