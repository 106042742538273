import api from './api';

export default {
  get() {
    const path = '/api/v1/users/phone';

    return api({
      method: 'GET',
      url: path,
    });
  },
  update(phoneNumber) {
    const path = '/api/v1/users/phone';

    return api({
      url: path,
      method: 'PATCH',
      data: { user: { phoneNumber } },
    });
  },
};
