<script setup>
import { computed } from 'vue';
import { Bar } from 'vue-chartjs';
import colors from 'tailwindcss/colors';
import { Chart as ChartJS, Tooltip, Legend, BarElement, LinearScale } from 'chart.js';

ChartJS.register(Tooltip, Legend, BarElement, LinearScale);

const props = defineProps({
  labels: { type: Array, required: true },
  data: { type: Array, required: true },
  suggestedMax: { type: Number, default: null },
});

const DEFAULT_SUGGESTED_MAX = 120;
const chartData = computed(() => ({
  labels: props.labels,
  datasets: [{
    color: colors.red['400'],
    backgroundColor: ['red', 'blue', 'orange', 'emerald', 'sky'].map(color => colors[color]['400']),
    data: props.data,
  }],
}));

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: colors.gray['400'],
      },
    },
    y: {
      display: true,
      suggestedMin: 0,
      suggestedMax: props.suggestedMax || DEFAULT_SUGGESTED_MAX,
      beginAtZero: true,
    },
  },
};
</script>

<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</template>
