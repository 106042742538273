import api from '../api';

export default {
  update(id, applicationInterview) {
    const path = `/internal/api/v1/application_interviews/${id}`;

    return api({
      method: 'patch',
      data: { applicationInterview },
      url: path,
    });
  },
};
