import humps from 'humps';
import api from './api';

export default {
  getAll(resourceType, resourceId = null) {
    const path = '/internal/api/v1/comments';

    return api({
      params: { resourceId, resourceType },
      url: path,
    });
  },
  create(comment) {
    const path = '/internal/api/v1/comments';

    return api({
      method: 'post',
      data: humps.decamelizeKeys({ ...comment }),
      url: path,
    });
  },
};
