<script setup>
import KalioButton from './kalio-button.vue';
import KalioModal from './kalio-modal.vue';

const props = defineProps({
  title: { type: String, default: '' },
  body: { type: String, default: '' },
  accept: { type: String, default: 'Sí, estoy seguro/a' },
  close: { type: String, default: 'No' },
  loading: { type: Boolean, default: false },
  widthClass: { type: String, default: 'w-full max-w-lg' },
  acceptBtnVariant: { type: String, default: 'primary' },
  closeBtnVariant: { type: String, default: 'red' },
});

const emit = defineEmits(['accept', 'close']);
</script>

<template>
  <kalio-modal
    :width-class="props.widthClass"
    @close="emit('close')"
  >
    <div class="flex flex-col items-center space-y-6 rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 p-6">
      <slot name="content">
        <span class="text-xl font-medium text-cool-gray-50">
          {{ props.title }}
        </span>
        <p class="text-base text-pv-gray">
          {{ props.body }}
        </p>
      </slot>
      <div
        class="flex w-full flex-col items-center space-y-2 sm:flex-row-reverse sm:space-y-0 sm:px-16"
        :class="!!props.close ? 'justify-between' : 'justify-center'"
      >
        <kalio-button
          :label="props.accept"
          :variant="props.acceptBtnVariant"
          :class="!!props.close ? 'w-full sm:w-auto' : 'px-16'"
          :loading="props.loading"
          @click="emit('accept')"
        />
        <kalio-button
          v-if="!!props.close"
          :variant="props.closeBtnVariant"
          :label="props.close"
          class="w-full sm:w-auto"
          @click="emit('close')"
        />
      </div>
    </div>
  </kalio-modal>
</template>
