<script>
import ProgressCheckpoint from '../shared/progress-checkpoint.vue';

export default {
  name: 'kalio-startup-application-status-state',
  components: { ProgressCheckpoint },
  props: {
    withArrow: { type: Boolean, default: true },
    position: { type: String, default: 'mid' },
    state: { type: String, required: true },
    selected: { type: Boolean, required: true },
  },
  emits: ['select-state'],
};
</script>

<template>
  <a
    class="relative flex flex-row space-x-4"
    :class="{ 'cursor-pointer': state !== 'next' }"
    @click="$emit('select-state')"
  >
    <progress-checkpoint
      :position="position"
      :state="state"
      bg-class="bg-pv-blue-700"
    />
    <div
      class="z-20 flex w-full flex-col space-y-2"
      :class="position === 'start' ? 'pb-6' : 'py-6'"
    >
      <div class="flex flex-row items-center justify-between">
        <slot name="title" />
        <inline-svg
          v-if="withArrow"
          :src="require('assets/images/icons/arrow.svg')"
          class="size-3 shrink-0 rotate-180 fill-current lg:hidden"
        />
      </div>
      <slot name="description" />
    </div>
    <div
      class="absolute -right-10 z-10 hidden size-10 origin-center rotate-45 rounded-lg bg-pv-blue-700 lg:block"
      :class="[
        selected ? 'visible' : 'invisible',
        position === 'start' ? 'top-6' : 'top-12'
      ]"
    />
  </a>
</template>

