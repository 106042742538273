import { countries as countriesList } from 'countries-list';

const COUNTRIES = Object.entries(countriesList).map(
  ([key, { name, phone, emoji, languages }]) => ({ name, code: phone, emoji, key, lang: languages[0] }),
);

function formatCountriesApiResponse(response) {
  return Object.entries(response.countries).map(entry => ({
    code: entry[0],
    name: entry[1],
  }));
}

function formatStatesApiResponse(response) {
  return Object.entries(response.states).map(entry => ({
    code: entry[0],
    name: entry[1],
  }));
}

function formatCitiesApiResponse(response) {
  return response.cities;
}

function getCountryName(key) {
  const country = COUNTRIES.find(_country => _country.key === key);

  return country ? country.name : '';
}

export {
  COUNTRIES,
  formatCountriesApiResponse,
  formatStatesApiResponse,
  formatCitiesApiResponse,
  getCountryName,
};
