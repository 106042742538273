<script setup>
import { ref, inject } from 'vue';
import { useUIStore } from '@/stores';
import { currentUserKey } from '@/utils/keys.js';
import { useSendInviteQuery } from '@/queries/slack_channel_invites';

const props = defineProps({
  private: { type: Boolean, default: false },
  name: { type: String, required: true },
  description: { type: String, required: true },
});

const currentUser = inject(currentUserKey);
const open = ref(false);
const uiStore = useUIStore();
const email = ref(currentUser.value.email);

function successToast() {
  uiStore.toast({
    message: `Invitación #${props.name} enviada`,
    position: 'top',
    type: 'success',
  });
}

function errorToast() {
  uiStore.toast({
    message: `No se pudo enviar la invitación a #${props.name}`,
    position: 'top',
    type: 'error',
  });
}

function onMutateSuccess(data) {
  open.value = false;
  const [channelRes] = data;
  if (channelRes.success) successToast();
  else errorToast();
}

const { isLoading, mutate } = useSendInviteQuery(
  email, props.name, { retry: false, onSuccess: onMutateSuccess },
);

function sendInvite() {
  if (isLoading.value) return;
  mutate();
}

</script>

<template>
  <button
    class="flex w-full flex-col overflow-hidden rounded-lg border border-blue-gray-700  bg-pv-blue-900 transition-max-height ease-in-out hover:bg-pv-blue-800"
    :class="open ? 'max-h-64' : 'max-h-24'"
    @click="open = !open"
    @keydown.space.prevent
  >
    <div class="flex size-full p-3">
      <div class="flex h-full grow flex-col gap-1">
        <div class="flex items-center gap-1 text-xl text-blue-gray-50">
          <inline-svg
            :src="require(`assets/images/icons/${props.private ? 'outline/lock.svg' : 'slack-public-channel.svg'}`)"
            class="size-5"
            :class="props.private ? 'stroke-current' : 'fill-current'"
          />
          <p>
            {{ props.name }}
          </p>
        </div>
        <p
          class="text-left text-sm"
          :class="{ 'line-clamp-2': !open }"
        >
          {{ props.description }}
        </p>
      </div>
      <div class="mt-1 flex h-16 items-center justify-center px-1">
        <inline-svg
          :src="require('assets/images/icons/caret-up.svg')"
          class="size-4 transition"
          :class="{ 'rotate-180': !open }"
          @click="open = !open"
        />
      </div>
    </div>
    <div class="w-full px-3 pb-2 pt-1">
      <vee-form
        class="flex w-full flex-col gap-2"
        @submit="sendInvite"
      >
        <kalio-short-text-input
          v-model="email"
          class="w-full"
          rules="email"
          name="email-invite"
          placeholder="correo@example.com"
          icon-name="social-media/email.svg"
          icon-classes="w-5 h-5 fill-current text-blue-gray-400"
          @click.stop
        />

        <kalio-button
          label="Enviar invitación"
          :loading="isLoading"
          @click.stop
        />
      </vee-form>
    </div>
  </button>
</template>
