<script setup>
import BaseInformationCard from '@/components/shared/base-information-card.vue';
</script>

<template>
  <base-information-card title="Recomendar una startup">
    <p>
      Recomiéndanos una startup. Ayúdanos a mejorar la comunidad.
    </p>
    <kalio-button
      href="/apply/referral/new"
      label="Recomendar una startup"
      class="mt-4 w-auto self-start px-6"
      variant="blue"
    />
  </base-information-card>
</template>
