<script setup>
import KalioOfficeHourModal from '@/components/office-hours/kalio-office-hour-modal.vue';

const props = defineProps({
  offering: { type: Object, required: true },
});

const emit = defineEmits(['office-hour-requested', 'error']);
</script>

<template>
  <kalio-office-hour-modal
    :offering="props.offering"
    @close="emit('close')"
    @error="emit('error')"
    @submit="emit('office-hour-requested')"
  />
</template>
