<script>
import { ref, computed } from 'vue';

export default {
  name: 'demo-day-auction-orders-table',
  props: {
    orders: { type: Array, default: () => ([]) },
    modifiedLastOrderInAmount: { type: String, default: '' },
  },
  setup() {
    const selectedOrder = ref(null);
    function investorPath(order) {
      return `/investors/${order.user.id}`;
    }

    function toggleLegalInformation(order) {
      if (selectedOrder.value?.id === order.id) selectedOrder.value = null;
      else selectedOrder.value = order;
    }

    const investmentData = computed(() => selectedOrder?.value.investmentLegalInformation.investmentData);

    return {
      investorPath,
      toggleLegalInformation,
      selectedOrder,
      investmentData,
    };
  },
};
</script>

<template>
  <div class="flex flex-col">
    <table class="min-w-full divide-y divide-gray-400 rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4">
      <thead>
        <tr>
          <th
            scope="col"
            class="p-3 text-left text-xs font-medium uppercase tracking-wider text-pv-gray"
          >
            Inversionista
          </th>
          <th
            scope="col"
            class="p-3 text-left text-xs font-medium uppercase tracking-wider text-pv-gray"
          >
            Monto
          </th>
          <th
            scope="col"
            class="p-3 text-left text-xs font-medium uppercase tracking-wider text-pv-gray"
          >
            ¿Siendo considerada?
          </th>
          <th
            scope="col"
            class="p-3 text-left text-xs font-medium uppercase tracking-wider text-pv-gray"
          >
            Fecha de creación
          </th>
          <th
            scope="col"
            class="p-3 text-right text-xs font-medium uppercase tracking-wider text-pv-gray"
          >
            Acciones
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-800">
        <tr v-if="orders.length === 0">
          <td class="p-4 text-sm">
            Aún no hay ofertas
          </td>
        </tr>
        <template
          v-for="(order, i) in orders"
          v-else
          :key="i"
        >
          <tr>
            <td class="whitespace-nowrap px-3 py-4">
              <div class="flex items-center">
                <div class="size-10 shrink-0">
                  <img
                    class="size-10 rounded-full object-cover"
                    :src="order.user.avatarUrl"
                    alt=""
                  >
                </div>
                <div class="ml-4">
                  <div class="text-sm font-medium text-white">
                    {{ order.user.fullName }}
                  </div>
                  <div class="text-sm text-pv-gray">
                    {{ order.user.email }}
                  </div>
                </div>
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <span
                v-if="order.completelyIn"
                class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
              >
                US {{ order.textAmount }}
              </span>
              <span
                v-if="order.partiallyIn"
                class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800"
              >
                <span>
                  US {{ modifiedLastOrderInAmount }}
                </span>
                <span class="ml-2 line-through">
                  US {{ order.textAmount }}
                </span>
              </span>
              <span
                v-if="order.out"
                class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800"
              >
                US {{ order.textAmount }}
              </span>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <span
                v-if="order.completelyIn"
                class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
              >
                Adentro
              </span>
              <span
                v-if="order.partiallyIn"
                class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800"
              >
                Parcial
              </span>
              <span
                v-if="order.out"
                class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800"
              >
                Afuera
              </span>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <span class="text-sm text-pv-gray">
                {{ order.createdAtFormatted }}
              </span>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-right text-sm font-medium">
              <div class="flex flex-col items-end">
                <a
                  target="_blank"
                  :href="investorPath(order)"
                  class="text-blue-600 hover:text-blue-900"
                >
                  Más info
                </a>
                <button
                  v-if="order.investmentLegalInformation"
                  class="text-blue-600 hover:text-blue-900"
                  @click="toggleLegalInformation(order)"
                >
                  Información legal
                </button>
              </div>
            </td>
          </tr>
          <tr
            v-if="selectedOrder?.id === order.id"
            class="border-none transition-max-height ease-in-out"
            :class="selectedOrder?.id === order.id ? 'max-h-0' : 'max-h-screen'"
          >
            <td colspan="5">
              <div class="flex items-start gap-4 px-3 pb-8 pt-2">
                <div
                  v-if="order.investmentLegalInformation.investmentType === 'natural_person'"
                  class="flex gap-4"
                >
                  <div class="flex items-center gap-2">
                    <inline-svg
                      :src="require('assets/images/icons/id-card.svg')"
                      class="h-auto w-4 fill-current text-white"
                    />
                    <span class="text-sm">
                      RUT: {{ investmentData.naturalPersonRut }}
                    </span>
                  </div>
                  <div class="flex items-center gap-1">
                    <inline-svg
                      :src="require('assets/images/icons/home.svg')"
                      class="h-auto w-4 fill-current text-white"
                    />
                    <span class="text-sm">
                      Dirección: {{ investmentData.naturalPersonAddress }}
                    </span>
                  </div>
                </div>
                <div
                  v-else
                  class="flex gap-8 text-left"
                >
                  <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-2">
                      <inline-svg
                        :src="require('assets/images/icons/business.svg')"
                        class="h-auto w-6 fill-current text-white"
                      />
                      <span class="font-medium">
                        Persona Jurídica
                      </span>
                    </div>

                    <div class="text-left">
                      <div class="text-sm font-medium text-white">
                        Razón Social: {{ investmentData.businessName }}
                      </div>
                      <div class="text-sm text-pv-gray">
                        RUT: {{ investmentData.legalPersonRut }}
                      </div>
                      <div class="text-sm text-pv-gray">
                        Dirección: {{ investmentData.legalPersonAddress }}
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-2">
                      <inline-svg
                        :src="require('assets/images/icons/id-card.svg')"
                        class="h-auto w-6 fill-current text-white"
                      />
                      <span class="font-medium">
                        Representante Legal
                      </span>
                    </div>
                    <div class="text-left">
                      <div class="text-sm font-medium text-white">
                        Nombre: {{ investmentData.legalRepresentativeName }}
                      </div>
                      <div class="text-xs text-pv-gray">
                        RUT: {{ investmentData.legalRepresentativeRut }}
                      </div>
                    </div>
                  </div>
                </div>
                <span class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                  CAP US {{ order.textCap }}
                </span>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
