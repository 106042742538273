<script>
export default {
  name: 'kalio-founder-profile-card',
  props: {
    founder: { type: Object, required: true },
  },
};
</script>

<template>
  <div class="flex shrink-0 flex-col items-center rounded-lg border border-blue-gray-700">
    <img
      class="mb-2 size-16 rounded-lg object-cover"
      :src="founder.avatarUrl"
      :alt="`${founder.fullName} avatar`"
    >
    <span class="mb-4 w-full break-words text-center text-xs font-medium text-blue-gray-50">
      {{ founder.fullName }}
    </span>
    <kalio-social-media-icons
      :social-media="founder.socialMedia"
      :email="founder.email"
      class="mb-4 mt-auto space-x-2.5 text-pv-gray"
      icon-classes="h-4 w-auto"
    />
    <div class="rounded-full border border-pv-light-blue bg-pv-light-blue/20 px-2 text-sm text-blue-400">
      {{ (founder.founderProfile && founder.founderProfile.companyPosition) || '---' }}
    </div>
  </div>
</template>
