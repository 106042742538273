<script setup>
import { ref, onMounted } from 'vue';
import BaseApplicationProcessStagesModal from '@/components/apply/base-application-process-stages-modal.vue';
import BaseApplicationProcessStages from '@/components/apply/base-application-process-stages.vue';
import BaseApplicationProcessTasks from '@/components/apply/base-application-process-tasks.vue';
import KalioConfirmationModal from '@/components/shared/kalio-confirmation-modal.vue';
import KalioSubscriptionModal from '@/components/shared/kalio-subscription-modal.vue';
import KalioAlert from '@/components/shared/kalio-alert.vue';
import CheckProgramBanner from './check-program-banner.vue';

const props = defineProps({
  startupApplication: { type: Object, default: null },
  applicationFounder: { type: Object, default: null },
  applicationProcess: { type: Object, required: true },
});

const datesModalOpen = ref(false);

onMounted(() => {
  if (window.location.search.includes('application_saved=true')) {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
});

const form = ref(null);
const askConfirmationModalOpen = ref(false);
function submitForm() {
  form.value.submit();
}
const subscribeModalOpen = ref(!props.applicationProcess.applicationsOpen);
</script>

<template>
  <div class="flex w-full flex-col md:max-w-xl xl:max-w-screen-xl">
    <rails-flash-alert />
    <div class="flex flex-col">
      <h1 class="text-2xl font-medium text-blue-gray-50 md:text-3xl xl:text-5xl">
        Postulación a Platanus
      </h1>

      <kalio-subscription-modal
        v-if="subscribeModalOpen"
        :email="applicationFounder?.email"
        locale="es"
        @close="subscribeModalOpen = false"
      >
        <kalio-alert
          status="notice"
          title="Las postulaciones aún no están abiertas."
          description="Déjanos tu email y te avisaremos cuando empiecen las postulaciones."
          :deletable="false"
        />
      </kalio-subscription-modal>

      <span class="mt-4 text-sm text-blue-gray-400 md:font-medium xl:text-xl">
        Generación {{ applicationProcess.batchSeason }}
      </span>
      <div class="mt-6 flex flex-col items-center space-y-8 xl:hidden">
        <kalio-button
          variant="tertiary"
          label="Información del proceso"
          class="w-auto px-12"
          @click="datesModalOpen = true"
        />
        <check-program-banner />
        <kalio-form-wrapper
          v-if="applicationProcess.applicationsOpen"
          ref="form"
          method="PUT"
          action="/apply/submit"
          class="w-full"
        >
          <base-application-process-tasks
            :startup-application="startupApplication"
            :application-founder="applicationFounder"
            class="overflow-hidden rounded-xl"
          />
          <kalio-button
            v-if="!applicationFounder?.secondaryApplicant"
            class="mt-6 w-full"
            label="Enviar postulación"
            type="button"
            :disabled="!applicationFounder || !startupApplication.readyToSubmit"
            @click="askConfirmationModalOpen = true"
          />
        </kalio-form-wrapper>
      </div>

      <div class="mt-4 hidden flex-row xl:flex">
        <div class="flex shrink-0 flex-col rounded-l-xl bg-pv-blue-900 p-6 xl:w-1/2">
          <span class="mb-8 text-2xl font-medium">
            Información del proceso
          </span>
          <base-application-process-stages
            v-if="applicationProcess.applicationsOpen"
            :application-process="applicationProcess"
          />
          <div
            v-else
            class="mt-4 flex items-center space-x-3 rounded-lg bg-pv-blue-900 p-2"
          >
            <span>
              No hay proceso de postulación activo.
            </span>
          </div>
        </div>
        <div class="flex grow flex-col space-y-5 rounded-r-xl border border-blue-gray-700 px-12 py-8">
          <span class="text-2xl font-bold text-blue-gray-50">
            Tu postulación
          </span>

          <check-program-banner />

          <kalio-form-wrapper
            v-if="applicationProcess.applicationsOpen"
            ref="form"
            method="PUT"
            class="flex flex-col items-center gap-8"
            action="/apply/submit"
          >
            <base-application-process-tasks
              :startup-application="startupApplication"
              :application-founder="applicationFounder"
              class="w-full space-y-4"
              rounded
            />
            <kalio-alert
              v-if="applicationFounder && startupApplication.readyToSubmit"
              status="notice"
              title="No has enviado tu postulación."
              :deletable="false"
            >
              <div>
                <p class="text-sm text-cool-gray-700">
                  Debes apretar el botón de enviar postulación para que podamos
                  recibir el formulario con tus respuestas.
                </p>
              </div>
            </kalio-alert>
            <kalio-button
              v-if="!applicationFounder?.secondaryApplicant"
              label="Enviar postulación"
              class="px-24"
              type="button"
              :disabled="!applicationFounder || !startupApplication.readyToSubmit"
              @click="askConfirmationModalOpen = true"
            />
          </kalio-form-wrapper>
          <div
            v-else
            class="mt-4 flex items-center justify-center space-x-3 rounded-lg bg-pv-blue-900 p-6"
          >
            <inline-svg
              :src="require('assets/images/icons/clock.svg')"
              class="size-5 fill-current"
            />
            <span>
              Pronto abriremos las postulaciones nuevamente.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kalio-confirmation-modal
    :show="askConfirmationModalOpen"
    title="Enviar postulación"
    body="¿Estás seguro/a de que quieres enviar tu postulación? Una vez que la envíes,
            no podrás editarla de nuevo. Sin embargo, podrás subir actualizaciones
            contándonos sobre el progreso de tu startup."
    @accept="submitForm"
    @close="askConfirmationModalOpen = false"
  />
  <base-application-process-stages-modal
    :show="datesModalOpen"
    :application-process="applicationProcess"
    @close="datesModalOpen = false"
  />
</template>
