import api from '../api';

export default {
  getAll(params) {
    const path = '/internal/api/v1/interview_evaluations';

    return api({
      params,
      url: path,
    });
  },
  create(interviewEvaluation) {
    const path = '/internal/api/v1/interview_evaluations';

    return api({
      method: 'post',
      data: { interviewEvaluation },
      url: path,
    });
  },
};
