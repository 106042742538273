<script setup>
import { computed } from 'vue';
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import useReactiveStorage from '@/hooks/useReactiveStorage';
import useKeyListener from '@/hooks/key_listener';
import { openFrankWriterEventBusKey } from '@/utils/keys';
import { componentPresetsMap } from '@/utils/frank_writer_presets';
import { useStartupApplicationStore, useUIStore } from '@/stores';
import BaseSlideOver from '@/components/shared/base-slide-over.vue';
import BaseEditableTabs from '@/components/shared/base-editable-tabs.vue';

function id() {
  const ID_RADIX = 36;

  return Math.random().toString(ID_RADIX).substr(2, 9); // eslint-disable-line no-magic-numbers
}

const props = defineProps({
  presets: { type: Array, required: true },
});

const startupApplicationStore = useStartupApplicationStore();
const currentApplicationId = computed(
  () => startupApplicationStore.currentStartupApplicationId?.toString(),
);

const { value: tabs, set: setTabs } = useReactiveStorage(
  currentApplicationId, 'review-tabs', () => [{ title: 'tab', id: id() }],
);
const { value: activeTabIndex, set: setActiveTabIndex } = useReactiveStorage(
  currentApplicationId, 'review-tab-index', () => 0,
);

function setCurrentTab(index) {
  setTabs(tabs.value.map((tab, i) => ({ ...tab, active: i === index })));
  setActiveTabIndex(index);
}

function createTab() {
  const tabId = id();
  setTabs([...tabs.value, { title: 'Tab', id: tabId }]);
  setCurrentTab(tabs.value.length - 1);
}

function closeTab(index) {
  setTabs(tabs.value.filter((_, i) => i !== index));

  setCurrentTab(Math.min(activeTabIndex.value, tabs.value.length - 1));
}

function setTabPreset(preset) {
  setTabs(tabs.value.map((tab, index) => (
    index === activeTabIndex.value ? { ...tab, ...preset } : tab
  )));
}

const { isSlideOverOpen } = storeToRefs(useUIStore());
/* eslint-disable max-statements-per-line */
// useKeyListener({ keys: ['f'], onKeyDown: () => { isSlideOverOpen.value = true; } });
useKeyListener({ keys: ['escape'], triggerOnInput: true, onKeyDown: () => { isSlideOverOpen.value = false; } });
/* eslint-enable max-statements-per-line */

const openFrankWriterEventBus = useEventBus(openFrankWriterEventBusKey);
openFrankWriterEventBus.on((presetName) => {
  isSlideOverOpen.value = true;
  createTab();
  setTabPreset(props.presets.find((p) => p.preset === presetName));
});

const component = computed(() => componentPresetsMap[tabs.value[activeTabIndex.value]?.preset]);
</script>

<template>
  <base-slide-over
    :tabs="tabs"
    :active-tab-index="activeTabIndex"
    keep-hint-open
  >
    <base-editable-tabs
      :tabs="tabs"
      :active-tab-index="activeTabIndex"
      @create-tab="createTab"
      @close-tab="closeTab"
      @set-active-tab="setCurrentTab"
    />
    <div
      v-for="(tab, index) in tabs"
      :key="`tab-${index}-${currentApplicationId}`"
      class="grow"
      :class="{ 'hidden': activeTabIndex !== index }"
    >
      <component
        :is="component"
        v-if="component"
        :id="tab.id"
        :key="tab.id"
        v-bind="tab.props"
        class="h-full rounded-lg bg-pv-blue-800 p-4"
      />
      <div
        v-else
        class="mt-24 flex flex-col px-6"
      >
        <span class="text-center">
          Selecciona una opción
        </span>
        <kalio-button
          v-for="preset in presets"
          :key="preset.name"
          :label="preset.name"
          class="mt-4"
          @click="setTabPreset(preset)"
        />
      </div>
    </div>
  </base-slide-over>
</template>

