import { v4 as uuidv4 } from 'uuid';
import { useQueryClient, useQuery, useMutation } from '@tanstack/vue-query';
import likesApi from '../api/likes.js';

function useLikesQuery(options = {}) {
  return useQuery(
    ['likes'],
    () => likesApi.getAll().then(response => response.likes || response),
    options,
  );
}

function useAddLikeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addLike'],
    mutationFn: (companyId) => likesApi.create(companyId),
    onMutate: async (companyId) => {
      await queryClient.cancelQueries(['likes']);

      const optimisticLike = { id: uuidv4(), companyId };
      queryClient.setQueryData(['likes'], (old) => [...old, optimisticLike]);

      return { optimisticLike };
    },
    onSuccess: (result, context) => queryClient.setQueryData(
      ['likes'],
      (old) => old.map((like) => (like.id === context.optimisticLike?.id ? result : like)),
    ),
    onError: (_error, _variables, context) => queryClient.setQueryData(
      ['likes'],
      (old) => old.filter((like) => like.id !== context.optimisticLike.id),
    ),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['likes'] }),
  });
}

function useDeleteLikeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteLike'],
    mutationFn: (id) => likesApi.delete(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries(['likes']);

      const previousLikes = queryClient.getQueryData(['likes']);

      queryClient.setQueryData(
        ['likes'],
        (old) => old?.filter((like) => like.id !== id),
      );

      return { previousLikes };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(['likes'], context.previousLikes);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['likes'] }),
  });
}

export { useLikesQuery, useAddLikeMutation, useDeleteLikeMutation };
