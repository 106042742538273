<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import { getWhatsappLink } from '@/utils/whatsapp.js';

const props = defineProps({
  reference: { type: Object, required: true },
});

const DEFAULT_GRAVATAR_URL = 'https://www.gravatar.com/avatar/?d=mp';

const QUALIFICTION_EMOJI = {
  'very_low': '💀',
  'low': '📉',
  'medium': '😐',
  'high': '👍',
  'very_high': '⭐',
};

const nameTooltip = computed(() => props.reference.fullName.length > 15 && props.reference.fullName);
</script>

<template>
  <kalio-accordion-row
    :toggle-enabled="!!reference.qualification"
    class="h-fit rounded-md border border-blue-gray-700 bg-blue-gray-900 p-2"
  >
    <template #title>
      <div class="flex h-fit w-full min-w-0 hover:opacity-100">
        <div class="flex w-full items-center gap-2">
          <div class="size-fit shrink-0 overflow-hidden rounded-full">
            <img
              class="h-[4.7rem] w-auto object-cover"
              :src="reference.referencedUser?.avatarUrl || DEFAULT_GRAVATAR_URL"
            >
          </div>
          <div class="flex min-w-0 shrink flex-col gap-1">
            <div>
              <a
                v-if="!!reference.referencedUser?.fromCommunity"
                v-tooltip="nameTooltip"
                class="truncate text-sm text-pv-yellow hover:opacity-75"
                target="_blank"
                :href="`/people/${reference.referencedUser.id}`"
              >
                <p
                  :v-tooltip="nameTooltip"
                  class="truncate text-sm"
                >
                  {{ reference.fullName }}
                </p>
              </a>
              <p
                v-else
                :v-tooltip="nameTooltip"
                class="truncate text-sm"
              >
                {{ reference.fullName }}
              </p>
            </div>
            <div class="flex gap-1">
              <kalio-copy-text-button
                :title="reference.email"
                :text="reference.email"
                icon-path="social-media/email.svg"
                icon-classes="h-4 w-4 fill-current"
              />
              <kalio-copy-text-button
                :title="reference.phone"
                :text="reference.phone"
                icon-path="solid/phone.svg"
                icon-classes="h-4 w-4 fill-current"
              />
              <a
                :href="`${getWhatsappLink(reference.phone, `Hola ${reference.fullName}`)}`"
                target="_blank"
              >
                <inline-svg
                  :src="require('assets/images/icons/social-media/whatsapp.svg')"
                  class="block h-4 w-auto fill-current"
                />
              </a>
            </div>
            <span class="text-xs italic">
              {{ reference.relationshipWithFounder }}
            </span>
            <span
              v-if="reference.qualification && reference.secondQualification"
              class="text-xs"
            >
              {{ QUALIFICTION_EMOJI[reference.qualification] }} /
              {{ QUALIFICTION_EMOJI[reference.secondQualification] }}
            </span>
            <div
              v-else
              class="flex"
            >
              <inline-svg
                :src="require('assets/images/icons/sand-clock.svg')"
                class="size-4 fill-current"
              />
              <span class="text-xs">
                Sin Respuesta
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="mt-2 flex flex-col gap-2">
      <p class="text-xs">
        <span class="font-bold">
          Se asociaría:
        </span>

        {{ QUALIFICTION_EMOJI[reference.qualification] }}
        {{ reference.qualification }}
      </p>
      <p class="text-xs">
        <span class="font-bold">
          Invertiría:
        </span>

        {{ QUALIFICTION_EMOJI[reference.secondQualification] }}
        {{ reference.secondQualification }}
      </p>
      <p class="text-xs italic">
        "{{ reference.observation }}"
      </p>
      <p class="text-right text-xs text-blue-gray-400">
        {{ format(new Date(reference.updatedAt), 'd/M/yy H:mm') }}
      </p>
    </div>
  </kalio-accordion-row>
</template>
