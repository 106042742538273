<script setup>
</script>

<template>
  <div>
    <iframe
      src="https://metabase-kalio.herokuapp.com/public/dashboard/953d6c77-bac3-4b4b-bf26-476231ea2d42"
      frameborder="0"
      class="h-screen w-full"
      allowtransparency
    />
  </div>
</template>
