<script>
import kalioListWithFiltersAndSearchbar from '../shared/kalio-list-with-filters-and-searchbar.vue';
import KalioFeedbackModal from '../shared/kalio-feedback-modal.vue';
import KalioPerkCard from './kalio-perk-card.vue';
import KalioPerkSuggestionModal from './kalio-perk-suggestion-modal.vue';

export default {
  name: 'kalio-perk-list',
  components: {
    KalioFeedbackModal,
    kalioListWithFiltersAndSearchbar,
    KalioPerkCard,
    KalioPerkSuggestionModal,
  },
  props: {
    perks: { type: Array, required: true },
    categories: { type: Array, required: true },
  },
  data() {
    return {
      showPerkSuggestionModal: false,
      showPerkSuggestionSuccessfullMessage: false,
      query: '',
    };
  },
  computed: {
    filters() {
      const filters = {};
      filters.categories = {
        label: this.$t('list.filters.category'),
        itemKey: 'categoryIds',
        options: this.categories.map(category => ({ value: category.id, label: category.name })),
        type: 'checkbox',
        compareFunction: (filterValue, itemValue) => itemValue.includes(filterValue),
      };

      return filters;
    },
  },
};
</script>

<template>
  <div class="w-full">
    <kalio-list-with-filters-and-searchbar
      :items="perks"
      :query="query"
      search-path="/perks"
      :filters="filters"
      :grid-display="true"
    >
      <template #item="{ item }">
        <kalio-perk-card :perk="item" />
      </template>
      <template #actions>
        <div class="flex flex-col">
          <p class="my-2 text-sm text-blue-gray-200 sm:mt-0">
            {{ $t('perks.suggestions.notFoundQuestion') }}
          </p>
          <kalio-button
            :label="$t('perks.suggestions.do')"
            variant="blue"
            @click="showPerkSuggestionModal = true"
          />
        </div>
      </template>
    </kalio-list-with-filters-and-searchbar>
    <kalio-perk-suggestion-modal
      v-if="showPerkSuggestionModal"
      @close="showPerkSuggestionModal = false"
      @perk-suggestion-successfully-created="showPerkSuggestionSuccessfullMessage = true"
    />
    <kalio-feedback-modal
      v-if="showPerkSuggestionSuccessfullMessage"
      :title="$t('perks.suggestions.successfullySent')"
      accept="Ok"
      @accept="showPerkSuggestionSuccessfullMessage = false"
    />
  </div>
</template>
