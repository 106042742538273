<script>
import KalioModal from '../shared/kalio-modal.vue';

export default {
  name: 'KalioJobApplicationModal',
  components: { KalioModal },
  props: {
    jobApplication: { type: Object, required: true },
  },
  emits: ['close'],
};
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <div class="flex flex-col rounded-lg bg-pv-blue-700 px-6 py-8">
      <p class="mb-4 text-xl">
        {{ $t('jobApplications.titles.withPosition', { position: jobApplication.position }) }}
      </p>
      <div class="mb-16 grid grid-cols-3 rounded-md bg-blue-gray-700 text-sm md:grid-cols-4 md:text-base">
        <p class="col-span-1 py-6">
          {{ $t('jobApplications.name') }}
        </p>
        <p class="col-span-2 py-6 text-left md:col-span-3">
          {{ jobApplication.firstName }} {{ jobApplication.lastName }}
        </p>
        <p class="col-span-1 bg-blue-gray-600 py-6">
          {{ $t('jobApplications.email') }}
        </p>
        <p class="col-span-2 bg-blue-gray-600 py-6 text-left md:col-span-3">
          {{ jobApplication.email }}
        </p>
        <p class="col-span-1 py-6">
          {{ $t('jobApplications.purpose') }}
        </p>
        <p class="col-span-2 py-6 text-left md:col-span-3">
          {{ jobApplication.purpose }}
        </p>
      </div>
      <button
        class="w-full self-center rounded-md bg-pv-yellow px-3 py-2 text-blue-gray-700 md:w-1/2"
        @click="window.open(jobApplication.resumePath, '_blank')"
      >
        {{ $t('jobApplications.actions.downloadCV') }}
      </button>
    </div>
  </kalio-modal>
</template>
