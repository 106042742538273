<script>
const COPY_TIMEOUT_IN_MS = 1500;

export default {
  name: 'KalioCopyTextButton',
  props: {
    text: { type: String, required: true },
    title: { type: String, default: 'Copiar' },
    buttonText: { type: String, default: null },
    iconClasses: { type: String, default: 'h-full w-full fill-current' },
    iconPath: { type: String, default: 'copy.svg' },
    copiedIconPath: { type: String, default: 'check.svg' },
  },
  emits: ['copied', 'clear'],
  data() {
    return {
      copied: false,
      currentTimeout: null,
    };
  },
  methods: {
    clearCopyTimeout() {
      if (this.currentTimeout) {
        clearTimeout(this.currentTimeout);
      }
    },
    setCopyTimeout() {
      this.copied = true;
      this.currentTimeout = setTimeout(() => {
        this.copied = false;
        this.$emit('clear');
      }, COPY_TIMEOUT_IN_MS);
    },
    copy() {
      this.clearCopyTimeout();
      this.setCopyTimeout();
      navigator.clipboard.writeText(this.text);
      this.$emit('copied', this.text);
    },
  },
};
</script>

<template>
  <button
    v-tooltip="title || 'Copiar'"
    class="flex flex-row items-center focus:outline-none"
    @click.stop.prevent="copy"
  >
    <span
      v-if="buttonText"
      class="mr-2 text-sm"
    >
      {{ buttonText }}
    </span>
    <inline-svg
      :src="require(`assets/images/icons/${copied ? copiedIconPath : iconPath}`)"
      :class="iconClasses"
    />
  </button>
</template>
