<script setup>
import { inject, ref, onMounted } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import eventRegistrationsApi from '@/api/event_registrations';
import { currentUserKey } from '@/utils/keys.js';
import KalioFormSection from '@/components/form/kalio-form-section.vue';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import KalioConfirmationModal from '@/components/shared/kalio-confirmation-modal.vue';

const props = defineProps({
  event: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);
const formAnswers = ref({
  basicInfo: {
    firstName: {
      label: 'Nombre',
      dataPath: 'formAnswers.basicInfo.firstName',
      value: currentUser.value.firstName,
    },
    lastName: {
      label: 'Apellido',
      dataPath: 'formAnswers.basicInfo.lastName',
      value: currentUser.value.lastName,
    },
    github: {
      label: 'URL perfil de github',
      dataPath: 'formAnswers.basicInfo.github',
      value: null,
    },
    linkedin: {
      label: 'URL perfil de LinkedIn',
      dataPath: 'formAnswers.basicInfo.linkedin',
      value: null,
    },
    currentOccupation: {
      label: 'Cuéntanos en qué estás ahora (trabajando o estudiando y dónde)',
      dataPath: 'formAnswers.basicInfo.currentOccupation',
      value: null,
    },
  },
  others: {
    acquisition: {
      label: '¿Cómo te enteraste del Demodev?',
      dataPath: 'formAnswers.curious.acquisition',
      value: null,
    },
    invitationCode: {
      label: 'Si te enviaron un código de invitación, debes ingresarlo acá.',
      description: 'Los cupos sin código son limitados, por lo que si tienes un código y no lo usas, ' +
                      'estarás quitandole la oportunidad de asistir a alguien más.',
      dataPath: 'formAnswers.others.invitationCode',
      value: null,
    },
    preVisit: {
      label: '¿Te gustaría venir unas horas antes a las oficinas de Platanus? (Cupos limitados)',
      description: 'La idea es que vengas a codear en tus proyectos acompañado/a de la comunidad ' +
                   'Platanus. Te avisaremos por correo si fuiste seleccionado/a.',
      dataPath: 'formAnswers.others.preVisit',
      value: null,
    },
  },
});

const { mutate, isLoading, isError, error, reset } = useMutation(
  () => eventRegistrationsApi.create(props.event.id, formAnswers.value),
  {
    onSuccess: () => {
      window.location = '/demodev/registration';
    },
  },
);

const askInvitationCodeModalOpen = ref(false);

function submit() {
  askInvitationCodeModalOpen.value = false;
  mutate();
}

function checkInvitationCode() {
  if (formAnswers.value.others.invitationCode.value) {
    mutate();
  } else {
    askInvitationCodeModalOpen.value = true;
  }
}

onMounted(() => {
  const invitationCode = new URLSearchParams(window.location.search).get('code');
  formAnswers.value.others.invitationCode.value = invitationCode;
});
</script>

<template>
  <vee-form
    class="flex w-full flex-col space-y-8"
    @submit="checkInvitationCode"
  >
    <kalio-form-section title="Información básica">
      <kalio-short-text-input
        v-model="formAnswers.basicInfo.firstName.value"
        name="first_name"
        rules="required"
        :label="formAnswers.basicInfo.firstName.label"
      />
      <kalio-short-text-input
        v-model="formAnswers.basicInfo.lastName.value"
        name="last_name"
        rules="required"
        :label="formAnswers.basicInfo.lastName.label"
      />
      <kalio-short-text-input
        v-model="formAnswers.basicInfo.github.value"
        name="github"
        rules="url"
        :label="formAnswers.basicInfo.github.label"
      />
      <kalio-short-text-input
        v-model="formAnswers.basicInfo.linkedin.value"
        name="linkedin"
        rules="url"
        :label="formAnswers.basicInfo.linkedin.label"
      />
      <kalio-short-text-input
        v-model="formAnswers.basicInfo.currentOccupation.value"
        name="current_occupation"
        rules="required"
        :label="formAnswers.basicInfo.currentOccupation.label"
      />
    </kalio-form-section>

    <kalio-form-section title="Otros">
      <kalio-short-text-input
        v-model="formAnswers.others.acquisition.value"
        name="acquisition"
        rules="required"
        :label="formAnswers.others.acquisition.label"
      />
      <div
        v-if="props.event.preVisitEnabled"
        class="flex flex-col gap-1"
      >
        <kalio-radio-inputs
          v-model="formAnswers.others.preVisit.value"
          name="pre_visit"
          :disabled="!props.event.preVisitAllowed"
          :rules="props.event.preVisitAllowed ? 'required' : ''"
          :values="['true', 'false']"
          :radio-labels="['Sí', 'No']"
          :label="formAnswers.others.preVisit.label"
          :description="formAnswers.others.preVisit.description"
        />
        <p
          v-if="!props.event.preVisitAllowed"
          class="text-sm text-red-600"
        >
          Ya no quedan cupos para la visita previa. Lo bueno es que aún puedes inscribirte al Demodev 👍
        </p>
      </div>
      <kalio-short-text-input
        v-model="formAnswers.others.invitationCode.value"
        name="invitation_code"
        :label="formAnswers.others.invitationCode.label"
        :description="formAnswers.others.invitationCode.description"
      />
    </kalio-form-section>

    <kalio-button
      label="Enviar información"
      class="w-full sm:w-auto sm:self-center sm:px-24"
      type="submit"
      :loading="isLoading"
    />
  </vee-form>
  <kalio-confirmation-modal
    v-if="askInvitationCodeModalOpen"
    title="¿Seguro/a que no tienes un código de invitación?"
    body="Los cupos sin código son limitados, por lo que si tienes un código y no lo usas,
            estarás quitandole la oportunidad de asistir a alguien más."
    close="No, tengo un código"
    width-class="w-full max-w-xl"
    @close="askInvitationCodeModalOpen = false"
    @accept="submit"
  />
  <kalio-feedback-modal
    v-if="isError && error.response?.data?.message === 'invalid_code'"
    title="Código inválido o ya en uso."
    body="Para asistir al evento debes recibir una invitación con un código válido."
    status="error"
    accept="Entendido"
    @accept="reset"
  />
  <kalio-feedback-modal
    v-else-if="isError"
    title="Hubo un error :( intentalo de nuevo."
    status="error"
    accept="Entendido"
    @accept="reset"
  />
</template>

