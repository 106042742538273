<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import { format } from 'date-fns';
import { currentUserKey } from '@/utils/keys.js';

const UNSEEN_TIMEOUT_MS = 5000;

const props = defineProps({ comment: { type: Object, required: true } });
const dynamicComment = ref(props.comment);

onMounted(() => {
  setTimeout(() => {
    dynamicComment.value.unseen = false;
  }, UNSEEN_TIMEOUT_MS);
});

const formattedDate = computed(() => format(dynamicComment.value.createdAt, 'dd/MM/yyyy HH:mm'));
const currentUser = inject(currentUserKey);
const isAuthor = computed(() => currentUser.value.id === dynamicComment.value.author.id);
</script>

<template>
  <div
    class="flex flex-col rounded-lg border border-pv-blue-700 bg-pv-blue-800 px-4 py-3 transition-colors"
    :class="{ 'border-pv-yellow': dynamicComment.unseen }"
  >
    <div class="flex flex-row items-center text-xs font-thin text-blue-gray-400">
      <span
        v-if="isAuthor"
        class="italic"
      >
        Tú
      </span>
      <span v-else-if="dynamicComment.author.fullName">
        {{ dynamicComment.author.fullName }}
      </span>
      <span v-else>
        {{ dynamicComment.author.email }}
      </span>
      <span class="ml-auto">
        {{ formattedDate }}
      </span>
    </div>
    <div class="mt-2 flex flex-row flex-wrap items-center gap-x-2 text-sm font-light text-cool-gray-400">
      <span class="text-xs text-pv-gray">
        Comentario sobre {{ dynamicComment.company.name }}:
      </span>
    </div>
    <div
      class="mt-3 overflow-scroll text-sm text-cool-gray-50"
      v-html="dynamicComment.body"
    />
  </div>
</template>
