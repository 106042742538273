<script>
import KalioCopyTextButton from '../shared/kalio-copy-text-button.vue';

export default {
  components: { KalioCopyTextButton },
  props: {
    libraryItem: { type: Object, required: true },
  },
  computed: {
    categories() {
      return this.libraryItem.categoryList;
    },
    hasCategories() {
      return this.categories.length > 0;
    },
  },
};
</script>

<template>
  <a
    :href="libraryItem.url"
    target="_blank"
    rel="noopener noreferrer"
    class="flex w-full flex-col rounded-xl border border-blue-gray-700 bg-pv-blue-800 px-8 py-4"
  >
    <div class="mb-0.5 flex items-center text-xl font-bold">
      <span class="mr-2 text-blue-gray-50">
        {{ libraryItem.name }}
      </span>
      <kalio-copy-text-button
        :text="libraryItem.url"
        :title="$t('libraryItems.copyLink')"
        class="size-4 shrink-0 text-pv-gray"
      />
    </div>
    <div class="mb-1 text-base text-blue-gray-50">
      {{ libraryItem.author }}
    </div>
    <span class="mb-6 self-start rounded border px-2 py-0.5 text-sm text-white">
      {{ libraryItem.kind }}
    </span>
    <div
      class="text-sm text-pv-gray"
      :class="{ 'mb-4': hasCategories }"
    >
      {{ libraryItem.description }}
    </div>
    <div
      v-if="hasCategories"
      class="flex flex-wrap gap-2.5"
    >
      <span
        v-for="(category, index) in categories"
        :key="index"
        class="self-start whitespace-nowrap rounded-full bg-pv-light-blue bg-opacity-20 px-2 py-0.5 text-sm text-blue-400"
      >
        {{ category }}
      </span>
    </div>
  </a>
</template>
