import api from '@/api/api';
export const connectionsApi = {
    getAll(demoDayId) {
        const path = `/api/v1/demo_day/demo_days/${demoDayId}/connections`;
        return api({
            method: 'get',
            url: path,
        });
    },
    create(connection) {
        const path = '/api/v1/demo_day/connections';
        return api({
            method: 'post',
            url: path,
            data: { connection },
        });
    },
};
