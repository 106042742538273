import { ref, reactive, computed, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { differenceInSeconds, differenceInHours } from 'date-fns';
import { useRolesStore } from '@/stores';
import { useVirtualRoundStore } from '../stores';
import { formatDDHH, formatHHMMSS } from '../utils/virtual_round.js';

export default function useVirtualRoundTimer(options = {}) {
  const { auction = ref(null) } = options;

  const virtualRoundStore = useVirtualRoundStore();
  const { demoDay } = storeToRefs(virtualRoundStore);

  const rolesStore = useRolesStore();
  const { isCommunityMember } = storeToRefs(rolesStore);

  const currentTime = ref(new Date());

  const virtualRoundTimer = reactive({
    auctionTimeLeft: null,
    auctionStartsIn: null,
    auctionStarted: computed(() => {
      if (isCommunityMember.value) {
        return new Date(demoDay.value.auctionsCommunityStartDate) < currentTime.value;
      }

      return new Date(demoDay.value.auctionsStartDate) < currentTime.value;
    }),
    auctionFinished: computed(() => currentTime.value > new Date(auction.value?.endDate)),
    demoDayFinished: computed(() => currentTime.value > new Date(demoDay.value.endDate)),
  });

  function setAuctionTimeLeft() {
    const endDate = new Date(auction.value?.endDate);
    const now = new Date();

    if (differenceInSeconds(endDate, now) < 0) {
      virtualRoundTimer.auctionTimeLeft = '00:00:00';
    } else if (differenceInHours(endDate, now) >= 24) {
      virtualRoundTimer.auctionTimeLeft = formatDDHH(now, endDate);
    } else {
      virtualRoundTimer.auctionTimeLeft = formatHHMMSS(now, endDate);
    }
  }
  function setAuctionStartsIn() {
    let startDate;
    if (isCommunityMember.value) {
      startDate = new Date(demoDay.value.auctionsCommunityStartDate);
    } else {
      startDate = new Date(demoDay.value.auctionsStartDate);
    }

    const now = new Date();
    if (differenceInSeconds(startDate, now) < 0) {
      virtualRoundTimer.auctionStartsIn = '00:00:00';
    } else if (differenceInHours(startDate, now) >= 24) {
      virtualRoundTimer.auctionStartsIn = formatDDHH(now, startDate);
    } else {
      virtualRoundTimer.auctionStartsIn = formatHHMMSS(now, startDate);
    }
  }
  function updateClocks() {
    setAuctionTimeLeft();
    setAuctionStartsIn();
    currentTime.value = new Date();
  }

  const timeLeftInterval = setInterval(() => {
    updateClocks();
  }, 1000);
  onBeforeUnmount(() => clearInterval(timeLeftInterval));

  return {
    virtualRoundTimer,
  };
}
