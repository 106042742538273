import getResultText from '@alheimsins/b5-result-text';
import calculateBig5Score from '@alheimsins/bigfive-calculate-score';

const TOTAL_QUESTIONS = 120;
const QUESTIONS_PER_PAGE = 40;
const NUMBER_OF_PAGES = TOTAL_QUESTIONS / QUESTIONS_PER_PAGE;

function getResultsWithText(answers) {
  const scores = calculateBig5Score({
    test: `${TOTAL_QUESTIONS}-IPIP-NEO-PI-R`,
    totalQuestions: TOTAL_QUESTIONS,
    answers,
  });

  const resultsWithText = Object.values(getResultText({
    scores,
    lang: 'es',
  }));

  return resultsWithText;
}

export {
  TOTAL_QUESTIONS,
  QUESTIONS_PER_PAGE,
  NUMBER_OF_PAGES,
  getResultsWithText,
};
