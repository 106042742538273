import api from '@/api/api.js';

export default {
  get({ id, demoDayId }) {
    let path;

    if (demoDayId) {
      path = `/api/v1/demo_day/demo_days/${demoDayId}/rule`;
    } else {
      path = `/api/v1/demo_day/rules/${id}`;
    }

    return api({
      method: 'get',
      url: path,
    });
  },
};
