<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioModal from '@/components/shared/kalio-modal.vue';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import {
  useResourceTagsQuery,
  useAddResourceTagMutation,
  useDeleteResourceTagMutation,
} from '@/queries/tags.js';
import { useIndustriesQuery } from '@/queries/industries.js';

defineEmits(['accept', 'close']);

const startupApplicationStore = useStartupApplicationStore();
const {
  currentStartupApplication: startupApplication,
  currentStartupApplicationId: startupApplicationId,
} = storeToRefs(startupApplicationStore);

const selectedIndustry = ref(null);

const { data: startupApplicationIndustries } = useResourceTagsQuery({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
  tagsOn: 'industries',
});

const {
  isLoading: isDeleteIndustryLoading,
  mutate: deleteIndustry,
} = useDeleteResourceTagMutation({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
  tagsOn: 'industries',
}, { onMutate: (industry) => (selectedIndustry.value = industry) });

const {
  isLoading: isAddIndustryLoading,
  mutate: addIndustry,
} = useAddResourceTagMutation({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
  tagsOn: 'industries',
});

const { data: industries } = useIndustriesQuery();
</script>

<template>
  <kalio-modal @close="$emit('close')">
    <div class="flex flex-col items-center space-y-6 rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 p-6">
      <kalio-header
        :title="`Agregar industria a ${startupApplication.startupName}`"
        icon="solid/building-office.svg"
        size="medium"
        class="mr-4 mt-3 self-start"
      />
      <kalio-select-input
        name="industry"
        placeholder="Selecciona o ingresa una industria"
        :options="(industries || []).map(industry => industry.name)"
        :loading="isAddIndustryLoading"
        taggable
        @option:selected="addIndustry"
      />
      <div class="flex flex-wrap gap-2">
        <kalio-badge
          v-for="(industry, index) in startupApplicationIndustries"
          :key="`${industry}-${index}`"
          :text="industry"
          with-border
        >
          <template #right>
            <kalio-spinner
              v-if="isDeleteIndustryLoading && selectedIndustry === industry"
              class="text-pv-light-blue"
              :size="3"
            />
            <button
              v-else
              :disabled="isDeleteIndustryLoading"
              @click="deleteIndustry(industry)"
            >
              <inline-svg
                :src="require('assets/images/icons/close.svg')"
                class="h-3 w-auto fill-current"
              />
            </button>
          </template>
        </kalio-badge>
      </div>
    </div>
  </kalio-modal>
</template>
