import { useQuery, useMutation } from '@tanstack/vue-query';
import groupMeetingCommitmentsApi from '@/api/group_meeting_commitments';

export function useGroupMeetingCommitmentsQuery(companyRef, options = {}) {
  return useQuery({
    queryKey: ['groupMeetingCommitments', companyRef],
    queryFn: () => groupMeetingCommitmentsApi.getAll({ companyId: companyRef.value.id })
      .then(response => response.groupMeetingCommitments),
    ...options,
  });
}

export function useCreateGroupMeetingCommitmentMutation(options = {}) {
  return useMutation({
    mutationFn: (commitment) => groupMeetingCommitmentsApi.create(commitment),
    ...options,
  });
}

export function useUpdateGroupMeetingCommitmentMutation(idRef, options = {}) {
  return useMutation({
    mutationFn: (commitment) => groupMeetingCommitmentsApi.update(idRef.value, commitment),
    ...options,
  });
}
