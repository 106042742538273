<script>
import { inject } from 'vue';
import { screenKey } from '../../utils/keys.js';

export default {
  props: {
    icon: { type: String, required: true },
    outlineSvg: Boolean,
    title: { type: String, required: true },
    description: { type: String, default: null },
    color: { type: String, default: 'yellow', validator: value => ['yellow', 'white'].includes(value) },
    size: { type: String, default: null },
  },
  setup() {
    return {
      screen: inject(screenKey),
    };
  },
  computed: {
    gridClasses() {
      const gapClasses = {
        small: 'gap-x-2',
        medium: 'gap-x-4',
        big: 'gap-x-5',
      };

      return gapClasses[this.computedSize];
    },
    containerClasses() {
      const paddingClasses = {
        small: 'py-1.5 px-2',
        medium: 'p-2',
        big: 'p-2.5',
      };

      return paddingClasses[this.computedSize];
    },
    titleClasses() {
      const textClasses = {
        small: 'text-lg font-medium',
        medium: 'text-2xl font-medium',
        big: 'text-3xl font-semibold',
      };

      return textClasses[this.computedSize];
    },
    descriptionClasses() {
      const textClasses = {
        small: 'text-sm font-light',
        medium: 'text-base font-normal',
        big: 'text-xl font-normal',
      };

      return textClasses[this.computedSize];
    },
    iconClasses() {
      const sizeClasses = {
        small: 'w-3 h-auto',
        medium: 'w-6 h-auto',
        big: 'w-7 h-auto',
      };

      const colorClasses = {
        yellow: 'text-pv-yellow',
        white: 'text-pv-gray',
      };

      return `${sizeClasses[this.computedSize]} ${colorClasses[this.color]}`;
    },
    computedSize() {
      return this.size || (this.screen.isMobile ? 'medium' : 'big');
    },
  },
};
</script>

<template>
  <div
    class="header grid grid-cols-2"
    :class="gridClasses"
  >
    <div
      class="self-start justify-self-start rounded-md bg-pv-blue-800"
      :class="containerClasses"
    >
      <inline-svg
        :class="[iconClasses, outlineSvg ? 'stroke-current' : 'fill-current']"
        :src="require(`assets/images/icons/${icon}`)"
      />
    </div>
    <span
      :class="titleClasses"
      class="flex items-center justify-self-start text-left text-blue-gray-50"
    >
      {{ title }}
    </span>
    <span
      v-if="!!description"
      class="col-start-2 justify-self-start"
      :class="descriptionClasses"
    >
      {{ description }}
    </span>
  </div>
</template>

<style scoped>
.header {
  grid-template-columns: min-content auto;
}
</style>
