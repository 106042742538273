<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import BaseReviewDiscardEmailTemplate from '@/components/apply/review/mails/base-template.vue';
import { useStartupApplicationStore, useStartupApplicationDiscardStore, useUIStore } from '@/stores';
import internalStartupApplicationsApi from '@/api/internal/startup_applications.js';

const emit = defineEmits(['close']);

const {
  currentStartupApplication: startupApplication,
  currentStartupApplicationId: startupApplicationId,
} = storeToRefs(useStartupApplicationStore());

const {
  discardFeedback,
  discardReason,
  discardReasonKeywords,
} = storeToRefs(useStartupApplicationDiscardStore());

const shouldUseDiscardReasonKeywords = computed(
  () => ['with_feedback', 'custom'].includes(discardReason.value),
);

function close() {
  emit('close');
  discardReason.value = null;
}

const uiStore = useUIStore();
const queryClient = useQueryClient();

const { isLoading, mutate: sendEmail } = useMutation(
  () => internalStartupApplicationsApi.sendDiscardEmail({
    id: startupApplicationId.value,
    discardReason: discardReason.value,
    discardReasonKeywords: shouldUseDiscardReasonKeywords.value ?
      discardReasonKeywords.value :
      null,
    discardFeedback: discardFeedback.value,
  }),
  {
    onSuccess: () => {
      close();
      queryClient.invalidateQueries({
        queryKey: ['startupApplication', startupApplicationId.value],
      });
      uiStore.toast({
        message: 'Correo programado',
        type: 'success',
        position: 'top',
      });
    },
  },
);

const { isSlideOverOpen } = storeToRefs(uiStore);
</script>

<template>
  <div class="fixed inset-0 z-30 h-screen w-full">
    <div class="relative size-full overflow-y-scroll">
      <div
        class="absolute"
        :class="isSlideOverOpen ?
          'sm:right-[500px] xl:right-auto xl:left-1/2 xl:-translate-x-1/2 w-full max-w-xs sm:max-w-xl'
          : 'left-1/2 -translate-x-1/2'"
      >
        <div class="relative mt-24 w-full rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 p-6">
          <button
            class="absolute right-4 top-4 self-start focus:outline-none"
            @click="close"
          >
            <inline-svg
              :src="require('assets/images/icons/close.svg')"
              class="h-auto w-6 fill-current text-white"
            />
          </button>
          <div class="flex flex-col items-center gap-y-6">
            <div class="w-full pt-6">
              <base-review-discard-email-template
                :discard-reason="discardReason"
                :application="startupApplication"
                :discard-reason-keywords="discardReasonKeywords"
                :model-value="discardFeedback"
                reviewing
                @update:model-value="discardFeedback = $event"
                @update:discard-reason-keywords="discardReasonKeywords = $event"
              />
            </div>
            <div class="flex w-full flex-col items-center gap-y-2 sm:flex-row-reverse sm:justify-between sm:space-y-0 sm:px-16">
              <kalio-button
                label="Programar envío"
                class="w-full sm:w-auto sm:px-16"
                :loading="isLoading"
                @click="sendEmail"
              />
              <kalio-button
                variant="red"
                label="Cancelar"
                class="w-full sm:w-auto"
                @click="close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
