<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useQueryClient } from '@tanstack/vue-query';
import { useUpdateStartupApplicationMutation } from '@/queries/internal/startup_applications.js';
import { useUIStore, useStartupApplicationStore } from '@/stores';
import { humanize } from '@/filters';
import KalioModal from '@/components/shared/kalio-modal.vue';
import StartupApplicationTagHandler from './startup-application-tag-handler.vue';

const emit = defineEmits(['close']);
const props = defineProps({
  selectedEvent: { type: String, default: null },
});

const startupApplicationStore = useStartupApplicationStore();
const {
  currentStartupApplication: startupApplication,
  currentStartupApplicationId: startupApplicationId,
} = storeToRefs(startupApplicationStore);

const {
  isLoading: isStartupApplicationMutationLoading,
  mutate,
} = useUpdateStartupApplicationMutation(startupApplicationId);

const queryClient = useQueryClient();
const sendDiscardEmailAfterDiscard = ref(false);
const uiStore = useUIStore();
function updateApplicationState() {
  const updateApplicationStateToastSymbol = Symbol();
  uiStore.toast({
    id: updateApplicationStateToastSymbol,
    message: `Ejecutando el evento ${humanize(props.selectedEvent)} sobre
                  ${startupApplication.value.startupName}`,
    type: 'loading',
    persist: true,
    position: 'top',
  });

  mutate(
    {
      id: startupApplicationId.value,
      body: { aasmStateEvent: props.selectedEvent },
    },
    {
      onSuccess: () => {
        uiStore.toast({
          id: updateApplicationStateToastSymbol,
          message: `Se ha ejecutado el evento ${humanize(props.selectedEvent)}
                    sobre ${startupApplication.value.startupName}`,
          type: 'success',
          duration: 3000,
          position: 'top',
        });

        if (sendDiscardEmailAfterDiscard.value) {
          window.open(`/internal/apply/review/startup_applications/${startupApplicationId.value}`);
        }

        queryClient.invalidateQueries({
          queryKey: ['startupApplication', startupApplicationId],
        });
      },
      onError: () => uiStore.toast({
        id: updateApplicationStateToastSymbol,
        message: `No se pudo ejecutar el evento ${humanize(props.selectedEvent)}
                      sobre ${startupApplication.value.startupName}`,
        type: 'error',
        duration: 6000,
        position: 'top',
      }),
      onSettled: () => emit('close'),
    },
  );
}

</script>

<template>
  <kalio-modal
    custom-close-button
    width-class="w-full max-w-xl"
  >
    <div class="w-full rounded-lg bg-blue-gray-800 px-8 py-6">
      <kalio-header
        icon="outline/pencil-square.svg"
        :title="`Cambiar de estado a ${startupApplication.startupName}`"
        size="medium"
        class="mb-8"
        outline-svg
      />
      <vee-form
        class="flex flex-col space-y-8 text-left"
        @submit="updateApplicationState"
      >
        <p>
          ¿Estás seguro de que deseas ejecutar el evento
          <b>
            {{ $filters.humanize(selectedEvent) }}
          </b> sobre
          {{ startupApplication.startupName }}?
        </p>
        <div
          v-if="selectedEvent == 'discard'"
          class="flex flex-col space-y-6"
        >
          <kalio-checkbox
            v-model="sendDiscardEmailAfterDiscard"
            name="send_discard_email"
            label="Quiero dejar programado el correo de descarte ahora"
          />
          <span>
            Puedes editar los tags de la postulación antes de descartarla
          </span>
          <startup-application-tag-handler :startup-application="startupApplication" />
        </div>
        <div class="flex w-full flex-row space-x-2 self-center">
          <kalio-button
            variant="tertiary"
            label="Cancelar"
            type="button"
            class="grow"
            @click="$emit('close')"
          />
          <kalio-button
            label="Aceptar"
            type="submit"
            :loading="isStartupApplicationMutationLoading"
            class="grow"
          />
        </div>
      </vee-form>
    </div>
  </kalio-modal>
</template>
