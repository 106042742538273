<script>
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import KalioTextListInput from '@/components/form/kalio-text-list-input.vue';
import buildSprintProjectsApi from '@/build_sprint_pack/api/build_sprint_projects.js';

export default {
  name: 'kalio-build-sprint-registration-form',
  components: { KalioFeedbackModal, KalioTextListInput },
  data() {
    return {
      loading: false,
      success: false,
      hasOtherMembers: null,
      buildSprintProject: {
        name: null,
        shortDescription: null,
        team: null,
        goal: null,
        usersEmails: [],
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;

      buildSprintProjectsApi.create(this.buildSprintProject)
        .then(() => {
          this.success = true;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    redirectToHome() {
      this.success = false;
      window.location = '/build_sprint';
    },
  },
};
</script>

<template>
  <div class="mt-4 flex flex-col space-y-8 sm:space-y-12">
    <kalio-header
      title="Inscripción al Build Sprint"
      icon="form.svg"
    />
    <vee-form
      class="flex flex-col space-y-4 sm:space-y-8"
      @submit="submit"
    >
      <div class="flex flex-col space-y-12">
        <kalio-form-section title="Detalles del proyecto">
          <kalio-short-text-input
            v-model="buildSprintProject.name"
            name="name"
            rules="required"
            label="Nombre del proyecto"
          />
          <kalio-short-text-input
            v-model="buildSprintProject.shortDescription"
            name="short_description"
            rules="required"
            label="Descripción corta (máx. 140 caracteres)"
          />
          <kalio-short-text-input
            v-model="buildSprintProject.goal"
            name="goal"
            rules="required"
            label="¿Cuál será su meta en las 4 semanas?"
            description="Recuerda que esta debe ser medible y ambiciosa.<br>
                              Ej: “Lanzar mi producto y tener 3 clientes”"
          />
          <kalio-radio-inputs
            v-model="hasOtherMembers"
            name="has_other_members"
            rules="required"
            label="¿Hay más integrantes ademas de tí?"
            :values="['yes', 'no']"
            :radio-labels="['Sí', 'No']"
          />
          <kalio-long-text-input
            v-if="hasOtherMembers !== null"
            v-model="buildSprintProject.team"
            name="team"
            rules="required"
            :label="hasOtherMembers === 'yes'
              ? 'Describe a cada uno de los integrantes'
              : 'Describete a tí mismo'"
            :description="hasOtherMembers === 'yes'
              ? 'Dinos cómo los conociste, cómo se complementan, qué habilidades tiene cada uno, etc.'
              : 'Cuéntanos por qué quieres participar del Build Sprint, cuáles son tus habilidades, ' +
                'qué experiencia tienes, etc.'"
          />
        </kalio-form-section>

        <kalio-form-section
          v-if="hasOtherMembers === 'yes'"
          title="Integrantes"
        >
          <kalio-text-list-input
            v-model="buildSprintProject.usersEmails"
            name="users_emails"
            rules="required|email"
            label="Ingresa los correos de los otros integrantes de tu equipo"
          />
        </kalio-form-section>

        <kalio-button
          label="Enviar"
          class="w-full sm:w-auto sm:self-center sm:px-32"
          :loading="loading"
        />
      </div>
    </vee-form>
  </div>
  <kalio-feedback-modal
    v-if="success"
    title="¡Listo!"
    body="Tu proyecto ha sido registrado con éxito. Recuerda que tu Build Sprint comenzará el lunes que viene."
    accept="Ok"
    @accept="redirectToHome"
  />
</template>
