<script>
import buildSprintProjectsApi from '@/build_sprint_pack/api/build_sprint_projects.js';

export default {
  name: 'kalio-build-sprint-weekly-updates-card',
  props: {
    buildSprintProject: { type: Object, required: true },
    buildSprintUpdates: { type: Array, required: true },
  },
  data() {
    return {
      dynamicProject: this.buildSprintProject,
      currentUpdateIndex: this.buildSprintUpdates.length - 1,
      goal: null,
      goalUpdateSuccess: false,
      goalUpdateLoading: false,
    };
  },
  computed: {
    currentUpdate() {
      return this.buildSprintUpdates[this.currentUpdateIndex];
    },
    hasNextUpdate() {
      return this.currentUpdateIndex + 1 < this.buildSprintUpdates.length;
    },
    hasPreviousUpdate() {
      return this.currentUpdateIndex > 0;
    },
    isLastUpdate() {
      return this.currentUpdate.isLastUpdate;
    },
  },
  watch: {
    goal() {
      this.goalUpdateSuccess = false;
    },
  },
  methods: {
    nextUpdate() {
      this.currentUpdateIndex++;
    },
    previousUpdate() {
      this.currentUpdateIndex--;
    },
    submitGoal() {
      if (!this.goal) {
        return;
      }

      this.goalUpdateLoading = true;

      buildSprintProjectsApi.update(this.dynamicProject.id, { goal: this.goal })
        .then(() => {
          this.dynamicProject.goal = this.goal;
          this.goal = '';
          this.$nextTick(() => {
            this.goalUpdateSuccess = true;
          });
        })
        .finally(() => {
          this.goalUpdateLoading = false;
        });
    },
  },
};
</script>

<template>
  <div class="flex flex-col space-y-4 rounded-lg bg-pv-blue-900 p-4 sm:space-y-6">
    <div class="flex flex-row items-center space-x-4">
      <span class="text-sm text-pv-gray">
        Reportes semanales
      </span>
      <div class="ml-4 flex flex-row items-center space-x-2">
        <button
          :disabled="!hasPreviousUpdate"
          @click="previousUpdate"
        >
          <inline-svg
            :src="require('assets/images/icons/arrow.svg')"
            class="size-3 fill-current text-pv-yellow"
            :class="{ 'opacity-60': !hasPreviousUpdate }"
          />
        </button>
        <span class="text-base">
          #{{ currentUpdateIndex + 1 }}
        </span>
        <button
          :disabled="!hasNextUpdate"
          @click="nextUpdate"
        >
          <inline-svg
            :src="require('assets/images/icons/arrow.svg')"
            class="size-3 rotate-180 fill-current text-pv-yellow"
            :class="{ 'opacity-60': !hasNextUpdate }"
          />
        </button>
      </div>
    </div>

    <div
      v-if="buildSprintUpdates.length > 0"
      class="flex flex-col space-y-4"
    >
      <kalio-text-answer
        label="Progreso de la semana anterior"
        :answer="currentUpdate.goalProgress"
        variant="semi-dark"
        size="small"
      />
      <kalio-text-answer
        v-if="!isLastUpdate"
        label="Objetivos para esta semana"
        :answer="currentUpdate.weeklyGoals"
        variant="semi-dark"
        size="small"
      />
      <a
        :href="`/build_sprint/updates/${currentUpdate.id}/edit`"
        class="flex flex-row items-center space-x-2 text-pv-yellow hover:opacity-75"
      >
        <span class="text-xs">
          Ver reporte completo
        </span>
        <inline-svg
          :src="require('assets/images/icons/arrow.svg')"
          class="size-3 rotate-180 fill-current text-pv-yellow"
        />
      </a>
    </div>

    <div class="flex flex-col items-start gap-y-4 sm:flex-row sm:flex-wrap sm:items-end sm:gap-x-4">
      <div class="relative flex w-full grow flex-col space-y-3 rounded-lg border border-blue-gray-600 bg-pv-blue-700 px-3 py-1 sm:w-auto">
        <VDropdown
          class="absolute -right-2.5 -top-2.5"
          distance="12"
          placement="top"
        >
          <button class="flex items-center justify-center overflow-hidden rounded-full border border-blue-gray-700 bg-pv-blue-900 p-1.5">
            <inline-svg
              :src="require('assets/images/icons/pencil.svg')"
              class="ml-0.5 size-3.5 fill-current text-blue-gray-200"
            />
          </button>
          <template #popper>
            <div class="flex flex-col space-y-2 bg-pv-blue-900 p-3">
              <kalio-short-text-input
                v-model="goal"
                name="goal"
                label="Ingresa tu nueva meta"
              />
              <kalio-button
                :label="goalUpdateSuccess ? 'Listo' : 'Guardar'"
                :loading="goalUpdateLoading"
                @click="submitGoal"
              />
            </div>
          </template>
        </VDropdown>
        <span class="text-xs text-blue-gray-300">
          Meta
        </span>
        <span class="text-base">
          {{ dynamicProject.goal }}
        </span>
      </div>
      <kalio-button
        v-if="dynamicProject.currentWeekUpdates.length === 0"
        label="Crear reporte semanal"
        href="/build_sprint/updates/new"
      />
    </div>
  </div>
</template>
