<script>

import { defineComponent } from 'vue';
import BaseUnderlineTabs from 'components/shared/base-underline-tabs.vue';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export default defineComponent({
  name: 'kalio-startup-application-updates-tabs',
  components: { BaseUnderlineTabs },
  props: {
    updates: { type: Array, required: true },
    variant: {
      type: String,
      default: 'semi-dark',
      validator: (value) => ['dark', 'semi-dark'].includes(value),
    },
  },
  data() {
    return {
      es,
      selectedUpdate: this.updates[0],
    };
  },
  computed: {
    dates() {
      return this.updates.map((update) =>
        format(new Date(update.createdAt), 'd MMMM yyyy', { locale: es }),
      );
    },
    showInfoClass() {
      const classes = {
        dark: 'bg-pv-blue-900',
        'semi-dark': 'bg-pv-blue-700',
      };

      return classes[this.variant];
    },
  },
  methods: {
    parseISO,
    format,
    selectUpdate(index) {
      this.selectedUpdate = this.updates[index];
    },
    formatDate(date) {
      return format(new Date(date), "d 'de' MMMM", { locale: es });
    },
  },
});
</script>

<template>
  <div class="flex flex-col items-start space-y-4">
    <div class="flex w-full">
      <base-underline-tabs
        :tabs="dates"
        size="small"
        button-classes="w-max"
        @select="selectUpdate"
      />
    </div>
    <div
      class="flex w-full flex-col space-y-4 rounded p-4"
      :class="showInfoClass"
    >
      <div
        v-if="selectedUpdate.revenues && selectedUpdate.revenues.length > 0"
        class="flex flex-col space-y-2"
      >
        <span class="text-sm font-medium">
          ¿Ha habido algún cambio en sus ingresos?
        </span>
        <div class="flex flex-col space-y-1">
          <span
            v-for="(revenue, index) in selectedUpdate.revenues"
            :key="index"
            class="text-sm text-blue-gray-300"
          >
            {{ format(parseISO(revenue.date), 'MMMM, yyyy', { locale: es }) }}:
            <span class="font-medium">
              {{ $filters.toCurrency(revenue.amount, { notation: 'compact' }) }}
            </span>
          </span>
        </div>
      </div>
      <div
        v-if="selectedUpdate.totalUsers"
        class="flex flex-col space-y-2"
      >
        <span class="text-sm font-medium">
          Cantidad total de usuarios activos
        </span>
        <span class="text-sm text-blue-gray-300">
          {{ selectedUpdate.totalUsers }} usuarios
        </span>
      </div>
      <div
        v-if="selectedUpdate.achievements"
        class="flex flex-col space-y-2"
      >
        <span class="text-sm font-medium">
          ¿Qué lograste desde el {{ formatDate(selectedUpdate.createdAt) }} hasta ahora?
        </span>
        <span class="text-sm text-blue-gray-300">
          {{ selectedUpdate.achievements }}
        </span>
      </div>
      <div
        v-if="selectedUpdate.other"
        class="flex flex-col space-y-2"
      >
        <span class="text-sm font-medium">
          ¿Tienes alguna otra actualización relevante de tu postulación?
        </span>
        <span class="text-sm text-blue-gray-300">
          {{ selectedUpdate.other }}
        </span>
      </div>
    </div>
  </div>
</template>
