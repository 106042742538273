<script setup>
import { inject } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { currentUserKey } from '@/utils/keys.js';
import { prefetchAssets } from '@/utils/prefetch_assets.js';

const currentUser = inject(currentUserKey);

prefetchAssets([
  require('assets/images/icons/solid/user.svg'),
  require('assets/images/icons/outline/logout.svg'),
]);
</script>

<template>
  <div>
    <Menu
      as="div"
      class="relative"
    >
      <div>
        <MenuButton class="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-gray-600 focus:ring-offset-2 focus:ring-offset-blue-gray-700">
          <img
            :src="currentUser.avatarUrl"
            class="size-9 rounded-full object-cover"
          >
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems class="absolute right-0 z-40 mt-2 flex origin-top-right flex-col divide-y divide-blue-gray-700 overflow-hidden rounded-md bg-pv-blue-900 shadow-lg ring-1 ring-blue-gray-700 focus:outline-none">
          <div class="flex flex-col px-4 py-2">
            <span class="mb-2 text-sm">
              {{ currentUser.firstName }}
            </span>
            <span class="text-sm text-pv-gray">
              {{ currentUser.email }}
            </span>
          </div>
          <div class="min-w-[150px] p-2">
            <MenuItem v-slot="{ active }">
              <a
                href="/profile/edit"
                class="flex flex-row items-center space-x-4 rounded-lg p-2 text-sm text-blue-gray-50"
                :class="{ 'bg-pv-blue-700': active }"
              >
                <inline-svg
                  :src="require('assets/images/icons/solid/user.svg')"
                  class="size-5 shrink-0 fill-current"
                />
                <span class="shrink-0 text-sm">
                  {{ $t('layout.navbar.editProfile') }}
                </span>
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                href="/accounts/users/sign_out"
                class="flex flex-row items-center space-x-4 rounded-lg p-2 text-sm text-blue-gray-50"
                :class="{ 'bg-pv-blue-700': active }"
              >
                <inline-svg
                  :src="require('assets/images/icons/outline/logout.svg')"
                  class="size-5 shrink-0 stroke-current"
                />
                <span class="shrink-0 text-sm">
                  {{ $t('layout.navbar.logOut') }}
                </span>
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
