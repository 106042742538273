import api from '../api';

export default {
  create(data) {
    const path = '/internal/api/v1/perk_suggestions';

    return api({
      method: 'post',
      data,
      url: path,
    });
  },
};
