<script setup>
import { ref, computed } from 'vue';
import Channel from './channel.vue';
import ChannelsInviteModal from './channels-invite-modal.vue';

const props = defineProps({
  channels: { type: Array, required: true },
});

const showModal = ref(false);

const channelCategories = computed(() => [{
  category: 'Esenciales',
  channelList: props.channels.filter(c => c.category === 'essential'),
}, {
  category: 'Podrían ser importantes',
  channelList: props.channels.filter(c => c.category === 'relevant'),
}, {
  category: 'Opcionales',
  channelList: props.channels.filter(c => c.category === 'optional'),
}]);
</script>

<template>
  <channels-invite-modal
    :show="showModal"
    @close="showModal = false"
  />

  <div class="flex flex-col gap-8">
    <div class="flex">
      <kalio-header
        title="Slack de Platanus"
        icon="social-media/slack.svg"
        class="grow"
      />
      <kalio-button
        label="Entrar a todos"
        @click="showModal = true"
      />
    </div>
    <div
      v-for="({category, channelList}, index) in channelCategories"
      :key="index"
      class="max-w-5xl"
    >
      <h2 class="mb-4 border-b-2 border-blue-gray-400 text-xl font-medium">
        {{ category }}
      </h2>
      <div class="grid gap-1 lg:grid-cols-3">
        <channel
          v-for="channel in channelList"
          :key="channel.id"
          :name="channel.name"
          :description="channel.description"
          :private="channel.private"
        />
      </div>
    </div>
  </div>
</template>
