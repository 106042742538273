<script>
import { ref, inject, toRaw } from 'vue';
import MarkdownIt from 'markdown-it';
import { storeToRefs } from 'pinia';
import prefillFormData from '@/utils/prefill_form';
import KalioModal from '@/components/shared/kalio-modal.vue';
import ProgressCheckpointBar from '@/components/shared/progress-checkpoint-bar.vue';
import { currentUserKey, screenKey } from '@/utils/keys.js';
import demoDayInvestorApplicationsApi from '../api/demo_day_investor_applications';
import rulesApi from '../api/rules.js';
import investorAttendanceApi from '../api/investor_attendance.js';
import { useVirtualRoundStore } from '../stores';
import DemoDayRules from './demo-day-rules.vue';

const md = new MarkdownIt({ html: true });

export default {
  name: 'demo-day-community-investor-application-modal',
  components: { KalioModal, DemoDayRules, ProgressCheckpointBar },
  props: {
    formSections: { type: Object, required: true },
    investorApplication: { type: Object, default: null },
  },
  emits: ['close'],
  setup() {
    const virtualRoundStore = useVirtualRoundStore();
    const { demoDay, investorAttendance } = storeToRefs(virtualRoundStore);
    const { setInvestorAttendance } = virtualRoundStore;

    const inputsContainer = ref(null);

    const currentUser = inject(currentUserKey);

    return {
      demoDay,
      investorAttendance,
      setInvestorAttendance,
      inputsContainer,
      currentUser,
      screen: inject(screenKey),
    };
  },
  data() {
    return {
      loading: false,
      rules: '',
      sections: {
        rulesAccepted: false,
        accreditedInvestor: false,
        qualifiedInvestor: false,
        ...this.formSections,
      },
      dynamicApplicationCompleted: this.investorApplication?.complete,
      user: {
        socialMedia: {},
        ...toRaw(this.currentUser),
      },
    };
  },
  computed: {
    markedRules() {
      return md.render(this.rules);
    },
    isFund() {
      return this.sections.basicInfo.isFund.value === 'yes';
    },
    currentCheckpoint() {
      const FIRST_STEP = 0;
      const SECOND_STEP = 1;
      const THIRD_STEP = 2;

      if (!this.dynamicApplicationCompleted) {
        return FIRST_STEP;
      } else if (!this.investorAttendance.tasksCompleted) {
        return SECOND_STEP;
      }

      return THIRD_STEP;
    },
    formAnswers() {
      const formAnswers = { basicInfo: this.sections.basicInfo };
      if (this.isFund) {
        formAnswers.fund = this.sections.fund;
      }

      return formAnswers;
    },
  },
  watch: {
    investorApplication: {
      handler(application) {
        prefillFormData(this.sections, application?.formAnswers);
      },
      immediate: true,
    },
  },
  mounted() {
    rulesApi.get({ demoDayId: this.demoDay.id }).then((response) => {
      if (this.$i18n.locale === 'en') {
        this.rules = response.contentEn;
      } else {
        this.rules = response.content;
      }
    });
  },
  methods: {
    handleInvalidSubmit() {
      this.inputsContainer.scrollIntoView({ behavior: 'smooth' });
    },
    acceptRules() {
      this.loading = true;

      investorAttendanceApi.update(this.investorAttendance.id, {
        rulesAccepted: this.sections.rulesAccepted,
        accreditedInvestor: this.sections.accreditedInvestor,
        qualifiedInvestor: this.sections.qualifiedInvestor,
        tutorialWatched: true,
      }).then(() => {
        this.setInvestorAttendance({
          ...this.investorAttendance,
          rulesAccepted: true,
          tasksCompleted: true,
        });
      })
        .finally(() => {
          this.loading = false;
        });
    },
    createApplication() {
      const demoDayInvestorApplication = { formAnswers: this.formAnswers };

      return demoDayInvestorApplicationsApi.create({
        demoDayInvestorApplication,
        user: this.user,
      });
    },
    updateApplication() {
      return demoDayInvestorApplicationsApi.update(this.investorApplication.id, {
        demoDayInvestorApplication: {
          formAnswers: this.formAnswers,
          automaticallyCreated: false,
        },
        user: this.user,
      });
    },
    async submitApplication() {
      this.loading = true;

      try {
        if (this.investorApplication) {
          await this.updateApplication();
        } else {
          await this.createApplication();
          this.dynamicApplicationCompleted = true;
        }
        this.dynamicApplicationCompleted = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <kalio-modal
    width-class="w-full max-w-2xl"
    data-testid="demo-day-community-investor-application-modal"
    @close="$emit('close')"
  >
    <div class="rounded-lg bg-pv-blue-700">
      <div class="flex flex-col items-center px-6 pb-6 pt-12">
        <kalio-header
          :title="$t('demoDay.application.communityApplication')"
          icon="form.svg"
          :size="screen.isMobile ? 'small' : 'medium'"
          class="mr-8 self-start"
        />
        <progress-checkpoint-bar
          :current-checkpoint="currentCheckpoint"
          :checkpoints="[
            $t('demoDay.application.applicationStep'),
            $t('demoDay.application.acceptRulesStep')
          ]"
          text-classes="text-sm w-28 sm:w-48 text-center sm:text-lg"
          class="mt-8"
        />
        <div
          v-if="!investorAttendance.tasksCompleted"
          class="mt-8 w-full"
        >
          <div v-if="currentCheckpoint === 0">
            <p class="text-left text-base text-blue-gray-200">
              {{ $t('demoDay.application.fastApplicationDescription') }}
            </p>
            <vee-form
              class="mt-8 flex flex-col space-y-8"
              @submit="submitApplication"
            >
              <div class="flex flex-col space-y-4">
                <kalio-short-text-input
                  v-model="user.socialMedia.linkedin"
                  name="linkedin"
                  rules="required|url"
                  :label="$t('user.form.linkedin')"
                />
                <kalio-tel-input
                  v-model="sections.basicInfo.phone.value"
                  :required="sections.basicInfo.phone.rules.includes('required')"
                  name="phone"
                  rules="required"
                  :label="sections.basicInfo.phone.label"
                />
                <kalio-radio-inputs
                  v-model="sections.basicInfo.isFund.value"
                  name="is_fund"
                  :rules="sections.basicInfo.isFund.rules"
                  :label="sections.basicInfo.isFund.label"
                  :values="['yes', 'no']"
                  :radio-labels="[$t('form.yes'), $t('form.no')]"
                />
                <kalio-short-text-input
                  v-if="isFund"
                  v-model="sections.fund.name.value"
                  name="fund_name"
                  :rules="sections.fund.name.rules"
                  :label="sections.fund.name.label"
                />
              </div>
              <kalio-button
                :label="$t('form.continue')"
                class="w-full sm:w-auto sm:self-center sm:px-24"
                type="submit"
                :loading="loading"
              />
            </vee-form>
          </div>
          <vee-form
            v-else-if="currentCheckpoint === 1"
            class="flex flex-col space-y-8"
            @submit="acceptRules"
            @invalid-submit="handleInvalidSubmit"
          >
            <div class="flex max-h-96 flex-col overflow-y-scroll rounded-xl bg-pv-blue-900 p-8">
              <demo-day-rules
                :md="markedRules"
                modal
              />
              <div
                ref="inputsContainer"
                class="flex flex-col items-start space-y-4"
              >
                <div class="flex flex-col items-start space-y-4">
                  <span class="font-medium">
                    {{ $t('form.required') }}
                  </span>
                  <kalio-checkbox
                    v-model="sections.rulesAccepted"
                    name="accept_rules"
                    :label="$t('demoDay.application.acceptRules')"
                    class="mt-2"
                    required
                  />
                </div>
                <div class="flex flex-col items-start space-y-4">
                  <span class="mt-6 font-medium">
                    {{ $t('form.optional') }}
                  </span>
                  <kalio-checkbox
                    v-model="sections.qualifiedInvestor"
                    name="qualified_investor"
                    :label="$t('demoDay.application.qualifiedInvestor')"
                    class="mt-2"
                  />
                  <kalio-checkbox
                    v-model="sections.accreditedInvestor"
                    name="accredited_investor"
                    :label="$t('demoDay.application.accreditedInvestor')"
                    class="mt-4"
                  />
                </div>
              </div>
            </div>
            <kalio-button
              :label="$t('form.submit')"
              class="mt-6 w-full md:mt-12 md:w-auto md:self-center md:px-32"
              type="submit"
              :loading="loading"
            />
          </vee-form>
        </div>
        <div
          v-else
          class="mt-6 flex flex-col"
        >
          <span class="mt-4 text-xl font-medium">
            {{ $t('demoDay.application.success.title') }}
          </span>
          <p class="mt-6 text-base text-pv-gray">
            {{ $t('demoDay.application.success.description') }}
          </p>
          <kalio-button
            :label="$t('form.accept')"
            class="mt-10 w-full md:w-auto md:self-center md:px-20"
            @click="$emit('close')"
          />
        </div>
      </div>
    </div>
  </kalio-modal>
</template>
