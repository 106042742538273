<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { toRefs, computed } from 'vue';
import { useField } from 'vee-validate';
import { parseISO } from 'date-fns';
import KalioBaseInput from './kalio-base-input';

const props = defineProps({
  rules: { type: String, default: '' },
  modelValue: { type: [Date, String], default: null },
  value: { type: [Date, String], default: null },
  name: { type: String, default: null },
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  format: { type: String, default: 'dd/MM/yyyy' },
  parseIso: { type: Boolean, default: false },
});

const initialValue = computed(() => {
  const value = props.modelValue || props.value;
  if (!value) return undefined;

  return props.parseIso ? parseISO(value) : new Date(value);
});

const { name, rules } = toRefs(props);
const { value: inputValue, errorMessage } = useField(name, rules, { initialValue });

const required = computed(() => props.rules.includes('required'));
</script>

<template>
  <kalio-base-input
    :label="label"
    :description="description"
    :required="required"
  >
    <template #input>
      <div class="flex flex-col">
        <div
          class="flex w-full appearance-none flex-row items-center overflow-hidden rounded-md border-2 bg-pv-blue-900 py-0.5"
          :class="errorMessage
            ? 'border-red-600'
            : 'border-blue-gray-700 focus-within:border-pv-light-blue'"
        >
          <input
            type="hidden"
            class="absolute hidden"
            :name="name"
            :value="inputValue"
          >
          <datepicker
            v-model="inputValue"
            auto-apply
            dark
            class="w-full"
            :format="format"
          />
        </div>
        <span
          v-if="errorMessage"
          class="mt-0.5 text-sm text-red-600"
        >
          {{ errorMessage }}
        </span>
      </div>
    </template>
  </kalio-base-input>
</template>

<style lang="scss">
.dp__theme_dark {
   --dp-background-color: #0F172A;
   --dp-text-color: #F8FAFC;
   --dp-hover-color: #484848;
   --dp-hover-text-color: #131E2F;
   --dp-hover-icon-color: #959595;
   --dp-primary-color: #005cb2;
   --dp-primary-text-color: #ffffff;
   --dp-secondary-color: #a9a9a9;
   --dp-border-color: transparent;
   --dp-menu-border-color: #2d2d2d;
   --dp-border-color-hover: transparent;
   --dp-disabled-color: #737373;
   --dp-scroll-bar-background: #212121;
   --dp-scroll-bar-color: #484848;
   --dp-success-color: #00701a;
   --dp-success-color-disabled: #428f59;
   --dp-icon-color: #959595;
   --dp-danger-color: #e53935;
   --dp-highlight-color: rgba(0, 92, 178, 0.2);
}
</style>
