<script>
import { ref, inject } from 'vue';
import KalioFeedbackModal from '../../shared/kalio-feedback-modal.vue';
import KalioFormSection from '../../form/kalio-form-section.vue';
import startupReferralsApi from '../../../api/startup_referrals';
import { currentUserKey } from '../../../utils/keys.js';

export default {
  name: 'kalio-startup-referral-form',
  components: { KalioFeedbackModal, KalioFormSection },
  setup() {
    const currentUser = inject(currentUserKey);

    const formAnswers = ref({
      referrer: {
        firstName: {
          component: 'kalio-short-text-input',
          label: 'Tu nombre',
          dataPath: 'formAnswers.referrer.firstName',
          value: currentUser.value.firstName,
        },
        lastName: {
          component: 'kalio-short-text-input',
          label: 'Tu apellido',
          dataPath: 'formAnswers.referrer.lastName',
          value: currentUser.value.lastName,
        },
        email: {
          component: 'kalio-short-text-input',
          label: 'Tu correo electrónico',
          dataPath: 'formAnswers.referrer.email',
          value: currentUser.value.email,
        },
      },
      startup: {
        name: {
          component: 'kalio-short-text-input',
          label: 'Nombre de la startup',
          name: 'startup_name',
          dataPath: 'formAnswers.startup.name',
        },
        founderName: {
          component: 'kalio-short-text-input',
          label: '¿Cómo se llama algún fundador o fundadora de la startup?',
          name: 'startup_founder_name',
          dataPath: 'formAnswers.startup.founderName',
        },
        contactMethod: {
          component: 'kalio-short-text-input',
          label: 'Correo de un/a fundador/a',
          description: 'Idealmente el correo con el que aplicó o con el que aplicará.',
          name: 'startup_contact_method',
          dataPath: 'formAnswers.startup.contactMethod',
        },
        referrerComments: {
          component: 'kalio-long-text-input',
          label: 'Comentarios',
          description: 'Dinos cómo los conociste, qué te gusta de ellos, etc.',
          name: 'referrer_comments',
          dataPath: 'formAnswers.startup.referrerComments',
        },
        referrerRecommendationLevel: {
          component: 'kalio-select-input',
          label: '¿Qué tanto recomiendas a esta startup?',
          name: 'startup_referrer_recommendation_level',
          dataPath: 'formAnswers.startup.referrerRecommendationLevel',
          options: [
            { label: '-1: Evítenlos', value: 'avoid_them' },
            { label: '0: Soy neutral sobre ellos', value: 'neutral' },
            { label: '1: Vale la pena que los miren', value: 'worth_a_look' },
            { label: '2: Definitivamente deberían entrevistarlos', value: 'interview_them' },
            { label: '3: Probablemente serán de la mitad superior del batch', value: 'top_half' },
            { label: '4: Probablemente serán de las 3 mejores del batch', value: 'top_three' },
          ],
        },
        referrerConnectionWithStartup: {
          component: 'kalio-select-input',
          label: '¿Cuál es tu relación con esta startup?',
          name: 'startup_referrer_connection_with_startup',
          dataPath: 'formAnswers.startup.referrerConnectionWithStartup',
          options: [
            { label: 'No conozco a los fundadores', value: 'dont_know_them' },
            { label: 'Conozco a alguno de los fundadores pero no muy bien', value: 'know_them_a_little' },
            { label: 'Soy amigo de alguno de los fundadores', value: 'friends' },
            { label: 'He trabajado con alguno de los fundadores', value: 'coworkers' },
            { label: 'Soy familiar de alguno de los fundadores', value: 'family' },
          ],
        },
      },

    });

    const sending = ref(false);
    const success = ref(false);

    function submit() {
      sending.value = true;

      startupReferralsApi.create(formAnswers.value).then(() => {
        sending.value = false;
        success.value = true;
      });
    }

    function redirectToHome() {
      success.value = false;
      window.location.replace('/');
    }

    return { formAnswers, sending, success, submit, redirectToHome };
  },
};
</script>

<template>
  <div class="flex w-full max-w-2xl flex-col">
    <h1 class="mb-16 mt-12 self-center text-3xl font-semibold">
      Recomendar una startup
    </h1>
    <vee-form
      class="flex flex-col space-y-12"
      @submit="submit"
    >
      <kalio-form-section title="Tu información">
        <kalio-short-text-input
          v-model="formAnswers.referrer.firstName.value"
          name="first_name"
          rules="required"
          :label="formAnswers.referrer.firstName.label"
        />
        <kalio-short-text-input
          v-model="formAnswers.referrer.lastName.value"
          name="last_name"
          rules="required"
          :label="formAnswers.referrer.lastName.label"
        />
        <kalio-short-text-input
          v-model="formAnswers.referrer.email.value"
          name="email"
          rules="required"
          :label="formAnswers.referrer.email.label"
        />
      </kalio-form-section>

      <kalio-form-section title="Información de la recomendación">
        <kalio-short-text-input
          v-model="formAnswers.startup.name.value"
          name="startup_name"
          rules="required"
          :label="formAnswers.startup.name.label"
        />
        <kalio-short-text-input
          v-model="formAnswers.startup.founderName.value"
          name="startup_founder_name"
          rules="required"
          :label="formAnswers.startup.founderName.label"
        />
        <kalio-short-text-input
          v-model="formAnswers.startup.contactMethod.value"
          name="startup_contact_method"
          rules="required|email"
          :label="formAnswers.startup.contactMethod.label"
          :description="formAnswers.startup.contactMethod.description"
        />
        <kalio-select-input
          v-model="formAnswers.startup.referrerRecommendationLevel.value"
          name="referrer_recommendation_level"
          rules="required"
          :label="formAnswers.startup.referrerRecommendationLevel.label"
          :options="formAnswers.startup.referrerRecommendationLevel.options"
        />
        <kalio-select-input
          v-model="formAnswers.startup.referrerConnectionWithStartup.value"
          name="referrer_connection_with_startup"
          rules="required"
          :label="formAnswers.startup.referrerConnectionWithStartup.label"
          :options="formAnswers.startup.referrerConnectionWithStartup.options"
        />
        <kalio-long-text-input
          v-model="formAnswers.startup.referrerComments.value"
          name="referrer_comments"
          rules="required"
          :label="formAnswers.startup.referrerComments.label"
          :description="formAnswers.startup.referrerComments.description"
        />
      </kalio-form-section>
      <kalio-button
        label="Enviar"
        :loading="sending"
        class="w-full sm:w-auto sm:self-center sm:px-24"
      />
    </vee-form>
  </div>
  <kalio-feedback-modal
    v-if="success"
    title="¡Referral enviado con éxito!"
    :body="`Hemos recibido tu recomendación de ${formAnswers.startup.name.value}.`"
    accept="Ok"
    @accept="redirectToHome"
  />
</template>

