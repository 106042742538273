import api from './api';

export default {
  getAll(params = {}) {
    return api({
      url: '/api/v1/weekly_updates',
      params,
    });
  },
};
