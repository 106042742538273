<script>
import { defineComponent, ref, toRefs, watch, inject } from 'vue';
import useFile from '../../hooks/file';
import { screenKey } from '../../utils/keys.js';

export default defineComponent({
  name: 'kalio-image-input',
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    rules: { type: String, default: 'ext:jpg,jpeg,png' },
    src: { type: String, default: null },
  },
  setup(props) {
    const inputRef = ref(null);
    const imageRef = ref(null);
    const { name, rules } = toRefs(props);
    const {
      inputValue,
      errorMessage,
      handleChange,
      handleDrop,
      reset,
      extensions,
    } = useFile({ inputRef, name, rules, src: props.src });

    function displayImage() {
      const reader = new FileReader();
      imageRef.value.title = inputValue.value.name;
      reader.onload = function (event) {
        imageRef.value.src = event.target.result;
      };
      reader.readAsDataURL(inputValue.value);
    }

    watch(inputValue, (newFile) => {
      if (newFile) {
        displayImage();
      } else {
        imageRef.value.src = null;
      }
    });

    const screen = inject(screenKey);

    return {
      inputValue,
      errorMessage,
      handleChange,
      handleDrop,
      reset,
      extensions,
      inputRef,
      imageRef,
      screen,
    };
  },
});
</script>

<template>
  <div
    class="flex flex-col"
    :class="$attrs.class"
  >
    <label
      class="cursor-pointer rounded-xl border-2 border-dashed border-blue-gray-500 bg-pv-blue-900 p-4"
      @dragenter.prevent
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <input
        v-bind="$attrs"
        ref="inputRef"
        type="file"
        :name="name"
        class="hidden"
        @change="handleChange"
      >
      <div class="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-x-10 sm:space-y-0">
        <div
          class="relative size-20 shrink-0 rounded-full border border-gray-700 sm:size-32"
          :class="{ 'hidden': !inputValue }"
        >
          <button
            class="absolute right-0 top-0 items-center justify-center rounded-full bg-pv-yellow focus:outline-none sm:right-2 sm:top-2"
            @click.prevent="reset"
          >
            <inline-svg
              :src="require('assets/images/icons/close.svg')"
              class="size-5 fill-current text-black sm:size-6"
            />
          </button>
          <div class="size-full overflow-hidden rounded-full">
            <img
              ref="imageRef"
              class="size-full object-cover"
            >
          </div>
        </div>
        <div class="flex flex-col items-center space-y-2">
          <div class="flex flex-row items-center space-x-2">
            <inline-svg
              :src="require('assets/images/icons/image.svg')"
              class="size-4 fill-current text-blue-gray-400 sm:size-6"
            />
            <span class="text-base text-pv-gray">
              {{ label }}
            </span>
          </div>
          <p class="text-base text-pv-yellow sm:text-lg">
            {{ $t('form.file.upload') }}
            <span
              v-if="!screen.isMobile"
              class="text-white"
            >
              {{ $t('form.file.orDrag') }}
            </span>
          </p>
          <span class="uppercase">
            {{ extensions }}
          </span>
        </div>
      </div>
    </label>
    <span
      v-if="errorMessage"
      class="mt-0.5 text-sm text-red-600"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>
