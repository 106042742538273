<script>
export default {
  emits: ['update:modelValue', 'search'],
};
</script>

<template>
  <div class="flex w-full flex-row items-center space-x-3 overflow-hidden rounded-md border border-blue-gray-700 bg-pv-blue-900 pr-2 ring-pv-light-blue focus-within:ring-1 md:pr-4">
    <input
      v-bind="$attrs"
      :value="$attrs.modelValue"
      class="w-full bg-pv-blue-900 p-3 text-sm text-blue-gray-200 outline-none"
      @keyup.enter="$emit('search')"
      @input="(event) => $emit('update:modelValue', event.target.value)"
    >
    <button @click="$emit('search')">
      <inline-svg
        :src="require('assets/images/icons/search.svg')"
        class="w-6 fill-current"
      />
    </button>
  </div>
</template>
