<script setup>
import { ref, onMounted, defineComponent } from 'vue';
import BaseToggle from '@/components/shared/base-toggle.vue';

defineProps({
  modal: { type: Boolean, default: false },
  md: { type: String, required: true },
});

const shadow = ref(null);
const nodes = ref([]);
const firstNodes = ref([]);
const lastNodes = ref([]);
const collapsableNodes = ref([]);

onMounted(() => {
  const allNodes = Array.from(shadow.value.children);
  const startIdx = allNodes.findIndex(node => node.id === 'collapsable-start');
  const endIdx = allNodes.findIndex(node => node.id === 'collapsable-end');
  if (startIdx !== -1 && endIdx !== -1) {
    collapsableNodes.value = allNodes.slice(startIdx + 1, endIdx);
    firstNodes.value = allNodes.slice(0, startIdx);
    lastNodes.value = allNodes.slice(endIdx + 1);
    nodes.value = [];
  } else {
    nodes.value = allNodes;
  }
});

const MarkdownNode = defineComponent({
  name: 'MarkdownNode',
  props: ['node'],
  template: `
    <div v-html="node.outerHTML" />
  `,
});

const isAccordionOpen = ref(false);
</script>

<template>
  <div class="-mt-4 whitespace-pre-line">
    <div
      ref="shadow"
      class="hidden"
      v-html="md"
    />
    <div :class="modal ? 'rules-modal' : 'rules'">
      <template v-if="nodes.length > 0">
        <markdown-node
          v-for="(node, index) in nodes"
          :key="index"
          :node="node"
        />
      </template>
      <template v-else>
        <markdown-node
          v-for="(node, index) in firstNodes"
          :key="index"
          :node="node"
        />
        <base-toggle
          class="my-4 -ml-4"
          size="2xl"
          @update:is-open="isAccordionOpen = $event"
        >
          <template #title>
            <div class="flex flex-col space-y-2">
              <span class="text-xl text-blue-gray-50 underline">
                {{ $t('demoDay.rules.toggle') }}
              </span>
            </div>
          </template>
          <markdown-node
            v-for="(node, index) in collapsableNodes"
            :key="index"
            :node="node"
          />
        </base-toggle>
        <markdown-node
          v-for="(node, index) in lastNodes"
          :key="index"
          :node="node"
        />
      </template>
    </div>
  </div>
</template>

<style>
.rules {
  @apply text-left;
}

.rules h2 {
  @apply font-semibold mb-4 mt-8 text-xl w-full border-b border-blue-gray-400 text-pv-yellow;
}

.rules h3 {
  @apply font-semibold text-xl mb-3 mt-6;
}

.rules ul {
  @apply list-disc ml-4 -my-2;
}

.rules a {
  @apply text-blue-400 underline;
}

.rules ul li {
  @apply text-blue-gray-200 -my-1;
}

.rules p {
  @apply text-blue-gray-200 my-3;
}

.rules-modal {
  @apply text-left;
}

.rules-modal h2 {
  @apply font-semibold text-xl w-full border-b border-blue-gray-400 text-pv-yellow my-4;
}

.rules-modal h3 {
  @apply font-semibold text-xl;
}

.rules-modal ul {
  @apply list-disc ml-4 -mt-4;
}

.rules-modal ul li {
  @apply -my-2;
}
</style>

