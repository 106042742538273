<script setup>
import BaseInformationCard from '@/components/shared/base-information-card.vue';

defineProps({
  company: { type: Object, required: true },
});
</script>

<template>
  <base-information-card
    edit-url="/company/edit"
    title="Información de tu startup"
  >
    <div class="flex flex-col items-center space-y-2 sm:flex-row sm:items-start sm:space-x-3 sm:space-y-0">
      <div class="size-20 shrink-0 overflow-hidden rounded-lg">
        <img
          :src="company.isotypeUrl"
          class="size-full object-cover"
        >
      </div>
      <div class="flex flex-col">
        <div class="flex flex-row items-center self-center sm:self-start">
          <span class="self-center text-xl font-bold text-blue-gray-50">
            {{ company.name }}
          </span>
          <country-flag :country="company.country" />
        </div>
        <p class="text-center sm:text-left">
          {{ company.slogan }}
        </p>
      </div>
    </div>
  </base-information-card>
</template>
