<script setup>

const props = defineProps({
  relevant: { type: Boolean, default: false },
  name: { type: String, required: true },
  institution: { type: String, required: true },
  relevantInstitution: { type: String, default: null },
});

</script>

<template>
  <div class="flex flex-col rounded-lg border border-blue-gray-700 p-2 text-pv-gray">
    <div class="flex gap-2">
      <p class="text-pv-gray">
        {{ props.relevantInstitution || props.institution }}
      </p>

      <kalio-badge
        v-if="props.relevant"
        text="Relevante"
      />
    </div>
    <p class="text-sm text-blue-gray-400">
      {{ props.name }}
    </p>
  </div>
</template>
