import api from './api';

const path = '/api/v1/countries';

export default {
  getCountries() {
    return api({
      method: 'get',
      url: path,
    });
  },
  getStates(country) {
    return api({
      method: 'get',
      url: `${path}/${country}/states`,
    });
  },
  getCities(country, state) {
    return api({
      method: 'get',
      url: `${path}/${country}/states/${state}/cities`,
    });
  },
};
