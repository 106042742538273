<script setup>
import { toRef } from 'vue';
import { useStartupApplicationStore } from '@/stores';
import ApplyReviewStartupApplicationPanel from '@/components/internal/apply/review/startup-application-panel.vue';
import ApplyReviewStartupApplicationQuestionBar from
  '@/components/internal/apply/review/startup-application-question-bar.vue';
import ApplyReviewFrankWriter from '@/components/internal/apply/review/frank-writer.vue';
import { useStartupApplicationQuery } from '@/queries/internal/startup_applications.js';

const props = defineProps({
  qualifications: { type: Array, required: true },
  startupApplicationId: { type: Number, required: true },
});

const {
  setFullStartupApplication,
  setStartupApplications,
} = useStartupApplicationStore();

setStartupApplications([{ id: props.startupApplicationId }]);

const startupApplicationId = toRef(props, 'startupApplicationId');
const { isSuccess } = useStartupApplicationQuery(
  startupApplicationId,
  {
    onSuccess: (data) => {
      setFullStartupApplication(data);
    },
  },
);

</script>

<template>
  <div class="flex w-full max-w-screen-xl flex-col">
    <kalio-back-button
      href="/internal/apply/review"
      class="mb-7 self-start sm:mb-10"
    />
    <div class="w-full rounded-lg bg-pv-blue-900 p-3">
      <apply-review-startup-application-panel
        v-if="isSuccess"
        :qualifications="qualifications"
      />
    </div>
  </div>
  <apply-review-startup-application-question-bar />
  <apply-review-frank-writer />
</template>
