<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed } from 'vue';

defineEmits(['update:modelValue', 'update:discardReasonKeywords']);
const props = defineProps({
  application: { type: Object, required: true },
  modelValue: { type: String, default: '' },
  discardReasonKeywords: { type: String, default: null },
  reviewing: { type: Boolean, default: false },
});

const isSoloFounder = computed(() => props.application.applicationFounders.length === 1);
const soloFounderName = computed(() => props.application.applicationFounders[0].user.firstName);
const startupName = computed(() => props.application.startupName);
</script>

<template>
  <div>
    <p v-if="isSoloFounder">
      Hola {{ soloFounderName }},
    </p>
    <p v-else>
      Hola fundadores de {{ startupName }},
    </p>

    <br>

    <p>
      Primero que todo, gracias por postular a Platanus. {{ isSoloFounder
        ? 'Lamento informarte' : 'Lamento informarles' }} que
      {{ startupName }} no fue seleccionada para pasar a la siguiente etapa.
    </p>

    <div
      v-if="reviewing"
      class="mt-4 flex flex-col space-y-2 rounded-lg bg-pv-blue-900 p-2"
    >
      <kalio-short-text-input
        :value="discardReasonKeywords"
        name="discard_reason_keywords"
        label="Keywords de descarte"
        description="Escribe las razones separadas por coma, por ej:
                        <br>no tiene CTO, no tiene tecnología, no tiene fulltime"
        @update:model-value="$emit('update:discardReasonKeywords', $event)"
      />
      <kalio-long-text-input
        v-bind="$attrs"
        :value="modelValue"
        placeholder="Escribe el feedback aquí..."
        variant="semi-dark"
        rows="8"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>

    <template v-else>
      <br>
      <p>
        {{ modelValue }}
      </p>
    </template>

    <br>

    <p v-if="isSoloFounder">
      Es prácticamente un hecho que varias startups que rechacemos serán empresas exitosas en el futuro.
      Si llega a ser tu caso, te agradeceríamos enormemente si nos envías un correo contándonos.
      Queremos aprender de nuestros errores.
    </p>
    <p v-else>
      Es prácticamente un hecho que varias startups que rechacemos serán empresas exitosas en el futuro.
      Si llega a ser su caso, les agradeceríamos enormemente si nos envían un correo contándonos.
      Queremos aprender de nuestros errores.
    </p>

    <br>

    <p v-if="isSoloFounder">
      Nos encantaría que postules de nuevo para el siguiente proceso. Si vemos un gran avance en el estado de
      la startup o de los fundadores, seguramente nos llamará la atención.
    </p>
    <p v-else>
      Nos encantaría que postulen de nuevo para el siguiente proceso. Si vemos un gran avance en el estado de
      la startup o de los fundadores, seguramente nos llamará la atención.
    </p>

    <br>

    <p v-if="isSoloFounder">
      Sabemos que dedicaste tiempo y esfuerzo en responder las preguntas del formulario y creemos que tener las
      respuestas a mano te puede servir en el futuro. Puedes descargarlas si entras a <a
        href="/apply/status?state=submitted"
        class="text-blue-400"
      >
        este link
      </a>.
    </p>
    <p v-else>
      Sabemos que dedicaron tiempo y esfuerzo en responder las preguntas del formulario y creemos que tener las
      respuestas a mano les puede servir en el futuro. Pueden descargarlas si entran a <a
        href="/apply/status?state=submitted"
        class="text-blue-400"
      >
        este link
      </a>.
    </p>

    <br>

    <p>
      Mucho éxito.
    </p>
  </div>
</template>
