<script setup>
import { ref } from 'vue';

const props = defineProps({
  isSubmitting: { type: Boolean, default: false },
  subject: { type: String, default: '' },
});

const message = ref(props.subject);
const emit = defineEmits(['office-hour-requested']);

function trackOfficeHour() {
  emit('office-hour-requested', { message: message.value });
}
</script>

<template>
  <vee-form
    v-slot="{ handleSubmit }"
    class="flex w-full flex-col space-y-6"
  >
    <kalio-long-text-input
      v-model="message"
      label="Mensaje"
      name="message"
      rules="required"
    />

    <kalio-button
      :loading="isSubmitting"
      label="Enviar mensaje"
      class="self-center sm:px-24"
      type="button"
      @click="handleSubmit(trackOfficeHour)"
    />
  </vee-form>
</template>
