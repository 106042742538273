import { ref, inject, computed } from 'vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import phonesApi from '@/api/phones';
import { currentUserKey } from '@/utils/keys.js';

export default function useValidatePhone(enabled) {
  const currentUser = inject(currentUserKey);
  const isPhoneValid = ref(false);
  function resetIsPhoneValid() {
    isPhoneValid.value = false;
  }

  const { isFetching } = useQuery(
    ['phone', currentUser],
    () => phonesApi.get(),
    {
      enabled,
      onSuccess: (data) => {
        isPhoneValid.value = data.valid;
      },
    },
  );

  const {
    mutate: validatePhone,
    isLoading: isMutating,
  } = useMutation(['phone'], (phone) => phonesApi.update(phone),
    { onSuccess: (data) => (isPhoneValid.value = data.valid) },
  );

  const isValidatingPhone = computed(() => isMutating.value || isFetching.value);

  return {
    validatePhone,
    isValidatingPhone,
    isPhoneValid,
    resetIsPhoneValid,
  };
}
