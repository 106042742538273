<script setup>
import { ref, computed } from 'vue';
import { parseISO, differenceInYears } from 'date-fns';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioTextAnswer from '@/components/form/kalio-text-answer.vue';
import BaseUnderlineTabs from '@/components/shared/base-underline-tabs.vue';
import BaseBig5ResultsChart from '@/components/shared/base-big5-results-chart.vue';
import useFormAnswers from '@/hooks/form_answers.js';
import { getWhatsappLink } from '@/utils/whatsapp.js';
import { getResultsWithText } from '@/utils/big5.js';
import ReferenceCard from './reference-card.vue';
import ExperienceCard from './experience-card.vue';

const FOUNDER_PROFILE_FORM_ANSWERS_PATHS = [
  'other.hackerOrCool',
  'experience.otherStartup',
  'experience.previousStartup',
  'experience.technical',
  'experience.hasCodingSkills',
  'experience.knowsHowToCode',
  'experience.howLearnedToCode',
  'experience.preferredLanguages',
  'experience.otherAccelerator',
  'commitment.currentlyFullTime',
  'commitment.fullTimeMonths',
  'commitment.fullTime',
  'commitment.otherJobs',
];

const APPLICATION_FORM_ANSWER_PATHS = [
  'founders.history',
  'founders.engineer',
];

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);

const { formAnswers: applicationFormAnswers } = useFormAnswers(
  APPLICATION_FORM_ANSWER_PATHS,
  startupApplication.value,
);

const applicationFounders = computed(() => startupApplication.value.applicationFounders);
const selectedApplicationFounder = ref(applicationFounders.value[0]);
function selectApplicationFounder(index) {
  selectedApplicationFounder.value = applicationFounders.value[index];
}

const selectedFounderProfile = computed(() => selectedApplicationFounder.value.founderProfile);

const previousExperiences = computed(() => selectedFounderProfile.value.previousExperiences);
const previousJobs = computed(() => previousExperiences.value.filter((exp) => exp.type === 'PreviousJob'));
const previousEducations = computed(() => previousExperiences.value.filter((exp) => exp.type === 'PreviousEducation'));

const { formAnswers: founderProfileFormAnswers } = useFormAnswers(
  FOUNDER_PROFILE_FORM_ANSWERS_PATHS,
  selectedFounderProfile,
  { key: 'formData' },
);

const selectedUser = computed(() => {
  const genderEmojis = {
    male: '👨',
    female: '👩',
    other: '🧑',
  };

  const gender = `${genderEmojis[selectedApplicationFounder.value.user.gender]}
                      ${selectedApplicationFounder.value.user.humanGender}`;
  const country = selectedApplicationFounder.value.user.country;
  const phoneNumber = selectedApplicationFounder.value.user.phoneNumber;

  const dateOfBirth = selectedApplicationFounder.value.user.dateOfBirth;
  const age = dateOfBirth ? differenceInYears(new Date(), parseISO(dateOfBirth)) : null;

  return {
    ...selectedApplicationFounder.value.user,
    gender,
    country,
    phoneNumber,
    age,
  };
});

const resultsWithText = computed(
  () => getResultsWithText(selectedFounderProfile.value.big5.answers),
);
</script>

<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-4">
      <kalio-text-answer
        v-for="(item, index) in applicationFormAnswers"
        :key="index"
        :label="item.label"
        :answer="item.answer"
        size="small"
      />
    </div>
    <base-underline-tabs
      wrap
      :tabs="applicationFounders.map(founder => founder.user.fullName || founder.user.email)"
      variant="yellow"
      class="self-start"
      tab-classes="mr-8"
      @select="selectApplicationFounder"
    />
    <div
      v-if="selectedApplicationFounder.founderProfileSubmitted"
      class="flex flex-col space-y-8 rounded-lg border border-gray-700 bg-pv-blue-800 p-4"
    >
      <div class="flex flex-col gap-3 lg:flex-row">
        <div class="flex basis-1/2 flex-col">
          <div class="mb-2 ml-1 flex flex-row items-center space-x-3 text-pv-gray">
            <inline-svg
              :src="require('assets/images/icons/outline/mortarboard.svg')"
              class="size-6 fill-current"
            />
            <span>
              Educación
            </span>
          </div>
          <div
            v-if="previousEducations.length > 0"
            class="flex w-full flex-col space-y-3"
          >
            <experience-card
              v-for="(job, index) in previousEducations"
              :key="index"
              v-bind="job"
            />
          </div>
          <p
            v-else
            class="text-sm text-blue-gray-400"
          >
            No se registró educación
          </p>
        </div>
        <div class="flex basis-1/2 flex-col">
          <div class="mb-2 ml-1 flex flex-row items-center space-x-3 text-pv-gray">
            <inline-svg
              :src="require('assets/images/icons/company.svg')"
              class="size-6 fill-current"
            />
            <span>
              Trabajos
            </span>
          </div>
          <div
            v-if="previousJobs.length > 0"
            class="flex w-full flex-col space-y-3"
          >
            <experience-card
              v-for="(job, index) in previousJobs"
              :key="index"
              v-bind="job"
            />
          </div>
          <p
            v-else
            class="text-sm text-blue-gray-400"
          >
            No se registró experiencia laboral
          </p>
        </div>
      </div>

      <div class="flex flex-row flex-wrap gap-x-4 gap-y-2">
        <div class="flex flex-row items-center space-x-1">
          <template v-if="selectedUser.country">
            <country-flag
              :country="selectedUser.country.code"
              size="small"
            />
            <span class="text-sm">
              {{ selectedUser.country.name }}
            </span>
          </template>
          <template v-else>
            <inline-svg
              :src="require('assets/images/icons/solid/flag.svg')"
              class="size-3 fill-current"
            />
            <span class="text-sm">
              ?
            </span>
          </template>
        </div>
        <span class="self-center text-sm">
          {{ selectedUser.gender }}
        </span>
        <div class="flex flex-row items-center space-x-1">
          <inline-svg
            :src="require('assets/images/icons/calendar.svg')"
            class="size-3 fill-current"
          />
          <span class="text-sm">
            {{ selectedUser.age || '?' }} años
          </span>
        </div>
        <div
          v-if="selectedUser.github || selectedUser.linkedin"
          class="flex flex-row items-center space-x-3"
        >
          <a
            v-if="selectedUser.github"
            :href="selectedUser.github"
            target="_blank"
          >
            <inline-svg
              :src="require('assets/images/icons/social-media/github.svg')"
              class="size-4 fill-current"
            />
          </a>
          <a
            v-if="selectedUser.linkedin"
            :href="selectedUser.linkedin"
            target="_blank"
          >
            <inline-svg
              :src="require('assets/images/icons/social-media/linkedin.svg')"
              class="size-4 fill-current"
            />
          </a>
        </div>
        <div
          v-if="selectedUser.phoneNumber"
          class="flex flex-row items-center space-x-1"
        >
          <inline-svg
            :src="require('assets/images/icons/outline/smartphone.svg')"
            class="size-3 stroke-current"
          />
          <a
            class="text-sm"
            target="_blank"
            :href="`${getWhatsappLink(
              selectedUser.phoneNumber,
              `Hola ${selectedUser.firstName}`
            )}`"
          >
            {{ selectedUser.phoneNumber }}
          </a>
        </div>
        <div
          v-if="selectedUser.email"
          class="flex flex-row items-center space-x-1"
        >
          <inline-svg
            :src="require('assets/images/icons/social-media/email.svg')"
            class="size-3 stroke-current"
          />
          <span class="text-sm">
            {{ selectedUser.email }}
          </span>
        </div>
      </div>
      <div
        v-if="selectedApplicationFounder.references.length > 0"
        class="flex flex-col space-y-4"
      >
        <span class="font-semibold">
          Referencias
        </span>

        <div class="grid gap-1 lg:grid-cols-3">
          <reference-card
            v-for="(reference, index) in selectedApplicationFounder.references"
            :key="index"
            :reference="reference"
          />
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <div
          v-if="selectedFounderProfile.big5"
          class="flex flex-col space-y-2"
        >
          <div class="w-full rounded-md border border-blue-gray-700 bg-pv-blue-900 px-6 py-4">
            <base-big5-results-chart
              :labels="resultsWithText.map(score => score.title)"
              :data="resultsWithText.map(score => score.score)"
              :width="300"
              :height="100"
            />
          </div>
          <a
            :href="`/apply/profile/big5/${selectedFounderProfile.big5.id}/results`"
            target="_blank"
            class="flex flex-row items-center space-x-2 text-pv-yellow hover:opacity-75"
          >
            <span class="text-xs">
              Ver resultados completos
            </span>
            <inline-svg
              :src="require('assets/images/icons/long-arrow.svg')"
              class="w-3 fill-current"
            />
          </a>
        </div>
        <span
          v-else
          class="text-blue-gray-400"
        >
          ❌
          <span class="ml-2">
            No realizó el test de personalidad
          </span>
        </span>
        <kalio-text-answer
          v-for="(item, index) in founderProfileFormAnswers"
          :key="index"
          :label="item.label"
          :answer="item.answer"
          size="small"
        />
      </div>
    </div>
    <kalio-alert
      v-else
      status="notice"
      title="No ha llenado su perfil de fundador."
      :deletable="false"
    />
  </div>
</template>
