<script>
import KalioListWithFiltersAndSearchbar from '../shared/kalio-list-with-filters-and-searchbar.vue';
import KalioJobApplicationCard from './kalio-job-application-card.vue';
import KalioJobOfferApplicationOptions from './kalio-job-offer-application-options.vue';

export default {
  name: 'KalioJobApplicationList',
  components: {
    KalioJobApplicationCard,
    KalioListWithFiltersAndSearchbar,
    KalioJobOfferApplicationOptions,
  },
  props: {
    jobApplications: { type: Array, required: true },
    jobOffers: { type: Array, required: true },
    query: { type: String, default: '' },
  },
  computed: {
    filters() {
      const filters = {};
      filters.jobOffers = {
        label: this.$t('jobOffers.listTitle.default'),
        itemKey: 'jobOfferId',
        options: this.jobOffers.map(jobOffer => ({ value: jobOffer.id, label: jobOffer.position })),
        type: 'checkbox',
      };

      return filters;
    },
  },
};
</script>

<template>
  <div class="w-full">
    <kalio-job-offer-application-options />
    <h1 class="mb-5 text-center text-2xl font-medium md:text-4xl">
      {{ $t('jobApplications.titles.owner') }}
    </h1>
    <div class="mb-14 text-center text-sm text-blue-gray-200 md:text-xl">
      {{ $t('jobApplications.subTitles.owner') }}
    </div>

    <kalio-list-with-filters-and-searchbar
      :items="jobApplications"
      :query="query"
      search-path="/my_jobs/applications"
      :filters="filters"
    >
      <template #item="{ item }">
        <kalio-job-application-card :job-application="item" />
      </template>
    </kalio-list-with-filters-and-searchbar>
  </div>
</template>
