<script>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Pagination,
  Navigation,
  Slide,
} from 'vue3-carousel';

export default {
  name: 'kalio-carousel',
  components: { Carousel, Slide, Pagination, Navigation },
  inheritAttrs: false,
  props: {
    navigation: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false },
    items: { type: Array, default: null },
  },
  computed: {
    carousel() {
      return this.$refs.carousel;
    },
  },
};
</script>

<template>
  <div class="w-full px-6">
    <carousel
      ref="carousel"
      v-bind="$attrs"
    >
      <slot v-if="!items" />
      <template v-else>
        <slide
          v-for="(item, index) in items"
          :key="index"
        >
          <slot
            name="item"
            :item="item"
          />
        </slide>
      </template>
      <template
        v-if="navigation || pagination"
        #addons
      >
        <navigation v-if="navigation" />
        <pagination v-if="pagination" />
      </template>
    </carousel>
  </div>
</template>

<style>
.carousel__prev, .carousel__next {
  @apply bg-pv-blue-900;
}

.carousel__prev {
  @apply -left-6;
}

.carousel__next {
  @apply -right-6;
}

.carousel__pagination-button--active {
  @apply bg-pv-blue-900 ring-1 ring-blue-gray-700;
}

.carousel__pagination-button:not(.carousel__pagination-button--active) {
  @apply bg-blue-gray-700;
}
</style>
