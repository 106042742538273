<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { format, isFuture, formatDistanceToNowStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import KalioConfirmationModal from '@/components/shared/kalio-confirmation-modal.vue';
import { useRolesStore, useGroupMeetingStore } from '@/stores';
import groupMeetingsApi from '@/api/group_meetings.js';
import useActionCable from '@/hooks/actioncable.js';
import GroupMeetingCall from '@/components/group-meetings/group-meeting-call.vue';

const props = defineProps({
  batchGroup: { type: Object, required: true },
  groupMeeting: { type: Object, required: true },
  groupMeetings: { type: Array, required: true },
  companies: { type: Array, required: true },
});

const isFinishPreparationOpen = ref(false);
const isGoToMeetingEnabled = ref(props.groupMeeting.preparationFinished);

const formattedDate = computed(() => {
  const date = new Date(props.groupMeeting.startDate);

  return format(date, "d 'de' MMMM yyyy", { locale: es });
});

const groupMeetingStore = useGroupMeetingStore();
const rolesStore = useRolesStore();

const { setGroupMeeting, setGroupMeetings, setCompanies } = groupMeetingStore;
setGroupMeeting(props.groupMeeting);
setGroupMeetings(props.groupMeetings);
setCompanies(props.companies);

const isMeetingInFuture = computed(() => isFuture(props.groupMeeting.startDate));
const isPartner = computed(() => rolesStore.isPartner);

const timeLeftMessage = ref('');
function updateTimeLeft() {
  const timeLeft = formatDistanceToNowStrict(props.groupMeeting.startDate, { locale: es });
  timeLeftMessage.value = `Quedan ${timeLeft} para empezar.`;
}

function finishPreparation() {
  groupMeetingsApi.update(props.groupMeeting.id, { preparationFinished: true });
  isFinishPreparationOpen.value = false;
}

useActionCable({ channel: 'GroupMeetingChannel', id: props.groupMeeting.id }, {
  received(event) {
    if (event.kind !== 'preparation_finished') return;

    isGoToMeetingEnabled.value = true;
  },
});

let intervalId;
onMounted(() => {
  if (!isMeetingInFuture.value) return;

  intervalId = setInterval(updateTimeLeft);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <group-meeting-call
    :batch-group="batchGroup"
    :group-meeting="groupMeeting"
    :group-meetings="groupMeetings"
    :companies="companies"
    :daily-url="batchGroup.mentorPreparationVideocallUrl"
    :call-owner-token="callOwnerToken"
  >
    <div class="flex flex-col gap-2">
      <div class="mb-3 flex w-full gap-2 text-xl">
        <div class="flex grow flex-row">
          <p class="text-3xl font-bold">
            Previa del {{ groupMeeting.name }}
            <span class="ml-2 text-base font-normal">
              {{ formattedDate }}
            </span>
          </p>
        </div>
        <kalio-button
          label="Ir al coliseo"
          :disabled="!isGoToMeetingEnabled"
          :href="`/group_meetings/${groupMeeting.id}`"
        />
        <kalio-button
          v-if="isPartner && !isGoToMeetingEnabled"
          label="Finalizar Previa"
          @click="isFinishPreparationOpen = true"
        />
        <kalio-confirmation-modal
          v-if="isFinishPreparationOpen"
          title="Terminar la previa"
          body="Esta acción activará al botón de 'Ir al coliseo' a todos los integrantes."
          accept="Finalizar la previa"
          close="Cerrar"
          close-btn-variant="tertiary"
          @accept="finishPreparation"
          @close="isFinishPreparationOpen = false"
        />
      </div>
      <div class="flex flex-col rounded-lg border border-blue-gray-600 bg-blue-gray-700 p-2">
        <p>
          En esta sala se reúnen los <strong>
            partners
          </strong> de Platanus y
          <strong>
            mentores
          </strong>
          del grupo para actualizarse unos minutos antes del coliseo.
        </p>
        <p v-if="isMeetingInFuture">
          {{ timeLeftMessage }}
        </p>
      </div>
    </div>
  </group-meeting-call>
</template>
