<script setup>
import { ref, inject, nextTick, computed, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import {
  useStartupApplicationStore,
  useStartupApplicationDiscardStore,
  useUIStore } from '@/stores';
import { useCreateStartupApplicationFeedbackQuery } from '@/queries/internal/startup_applications.js';
import useReactiveStorage from '@/hooks/useReactiveStorage';
import { currentUserKey } from '@/utils/keys';
import { StreamOrchestrator } from '@/utils/frank_writer';
import useActionCable from '@/hooks/actioncable';
import BaseChatInput from '@/components/shared/base-chat-input.vue';
import KalioCopyTextButton from '@/components/shared/kalio-copy-text-button.vue';

const props = defineProps({
  id: { type: String, required: true },
});

const {
  currentStartupApplicationId: startupApplicationId,
  currentStartupApplication: startupApplication,
} = storeToRefs(useStartupApplicationStore());

const {
  discardReasonKeywords,
  discardFeedback,
  discardReason,
} = storeToRefs(useStartupApplicationDiscardStore());

const { value: conversation, set: setConversation } = useReactiveStorage(
  startupApplicationId, `discard-email-preset-conversation-${props.id}`, () => [],
);

const { value: localDiscardReasonKeywords, set: setLocalDiscardReasonKeywords } = useReactiveStorage(
  startupApplicationId, `discard-email-preset-discard-reason-keywords-${props.id}`, () => '',
);

const {
  mutate,
  isLoading: isSendingMessage,
} = useCreateStartupApplicationFeedbackQuery(startupApplicationId);

const isReceivingResponse = ref(false);
const currentResponse = ref('');
const ongoingConversation = computed(() => {
  if (!isReceivingResponse.value) return conversation.value;

  return [
    ...conversation.value,
    { content: currentResponse.value, role: 'assistant' },
  ];
});

const uiStore = useUIStore();

const baseChatInputRef = ref(null);
function focusChatInput() {
  nextTick(() => {
    baseChatInputRef.value.chatInputRef.focus();
  });
}

function blurChatInput() {
  nextTick(() => {
    baseChatInputRef.value.chatInputRef.blur();
  });
}

function requestChatResponse() {
  isReceivingResponse.value = true;
  mutate({
    discardReasonKeywords: localDiscardReasonKeywords.value,
    discardReason: discardReason.value || 'custom',
    chatId: props.id,
    conversation: conversation.value,
  }, {
    onSuccess: focusChatInput,
    onError: () => {
      uiStore.toast({
        message: 'Error al generar feedback',
        type: 'error',
        position: 'top',
      });
    },
  });
}

function generateFeedback(form) {
  setLocalDiscardReasonKeywords(form.discard_reason_keywords);
  requestChatResponse();
}

function handleCopied(text) {
  discardFeedback.value = text;
  discardReasonKeywords.value = localDiscardReasonKeywords.value;
}

const messagesRef = ref(null);
function scrollToBottom() {
  nextTick(() => {
    if (!messagesRef.value) return;
    messagesRef.value.scrollTo({
      top: messagesRef.value.scrollHeight,
      behavior: 'smooth',
    });
  });
}

const messageContent = ref('');
function sendMessage() {
  if (isReceivingResponse.value) return;
  setConversation([...conversation.value, { content: messageContent.value, role: 'user' }]);
  messageContent.value = '';
  scrollToBottom();
  requestChatResponse();
}

function showCopyButton(message, index) {
  const messageInProgress = isReceivingResponse.value && index === ongoingConversation.value.length - 1;

  return message.role === 'assistant' && !messageInProgress;
}

let currentOrchestrator = new StreamOrchestrator();

function processOrderedChunch(chunk) {
  const response = chunk.choices[0];
  if (response.finish_reason) {
    isReceivingResponse.value = false;
    setConversation([...conversation.value, { content: currentResponse.value, role: 'assistant' }]);
    currentResponse.value = '';
    currentOrchestrator = new StreamOrchestrator();
    scrollToBottom();

    return;
  }

  const nextToken = response.delta.content;
  currentResponse.value += nextToken;
  scrollToBottom();
}

function processIncomingChunk(event) {
  const orderedChunks = currentOrchestrator.process(event);
  if (orderedChunks) orderedChunks.forEach(processOrderedChunch);
}

onMounted(scrollToBottom);

useActionCable({ channel: 'FrankWriterChannel', id: props.id }, { received: processIncomingChunk });

const { isSlideOverOpen } = storeToRefs(useUIStore());
watch(isSlideOverOpen, (isOpen) => {
  if (isOpen) focusChatInput();
  else blurChatInput();
});

const currentUser = inject(currentUserKey);
</script>

<template>
  <div class="flex flex-col">
    <div v-if="conversation.length === 0 && !isReceivingResponse">
      <p v-if="startupApplication.aasmState !== 'discarded'">
        Para poder enviar un mail de descarte primero se debe descartar la postulación.
      </p>
      <vee-form
        class="flex flex-col gap-y-4"
        @submit="generateFeedback"
      >
        <div class="flex flex-row items-end gap-x-2">
          <kalio-short-text-input
            size="small"
            name="discard_reason_keywords"
            label="Keywords de descarte"
            description="Escribe las razones separadas por coma, por ej:
                              <br>no tiene CTO, no tiene tecnología, no tiene fulltime"
          />
          <kalio-button
            label="Generar"
            size="small"
            type="submit"
            :loading="isSendingMessage"
          />
        </div>
      </vee-form>
    </div>
    <div
      v-else
      class="relative grow"
    >
      <div
        ref="messagesRef"
        class="absolute inset-0 flex flex-col overflow-y-scroll"
      >
        <div
          v-for="(message, index) in ongoingConversation"
          :key="index"
          class="mb-4 flex w-[95%] min-w-[100px] flex-col rounded-lg bg-pv-blue-900 p-2 pr-4"
          :class="message.role === 'user' ? 'self-end' : 'self-start'"
        >
          <div class="flex flex-row gap-x-2">
            <img
              :src="message.role === 'user'
                ? currentUser.avatarUrl
                : require('assets/images/frank.png')"
              class="size-10 shrink-0 rounded-full object-cover"
              :class="{ 'border border-pv-light-blue': message.role === 'user' }"
            >
            <p class="whitespace-pre-wrap text-sm">
              {{ message.content }}
            </p>
          </div>
          <kalio-copy-text-button
            v-if="showCopyButton(message, index)"
            :text="message.content"
            button-text="Copiar"
            class="mt-4 flex flex-row items-center self-center"
            icon-classes="w-4 h-4 fill-current"
            @copied="handleCopied"
          />
        </div>
      </div>
    </div>
    <div class="mt-auto pt-2">
      <base-chat-input
        ref="baseChatInputRef"
        v-model="messageContent"
        :loading="isReceivingResponse"
        :disabled="conversation.length === 0 && !isReceivingResponse"
        @submit="sendMessage"
      />
    </div>
  </div>
</template>
