<script>
import { inject } from 'vue';
import { screenKey } from '../../utils/keys.js';
import kalioJobApplicationModal from './kalio-job-application-modal.vue';
import KalioJobApplicationCardOptions from './kalio-job-application-card-options';

export default {
  components: { kalioJobApplicationModal, KalioJobApplicationCardOptions },
  props: {
    jobApplication: { type: Object, required: true },
    showCompany: { type: Boolean, default: false },
    showActions: { type: Boolean, default: false },
  },
  setup() {
    return {
      screen: inject(screenKey),
    };
  },
  data() {
    return {
      applicationModalOpen: false,
    };
  },
};
</script>

<template>
  <div class="flex divide-x divide-blue-gray-700 rounded-xl border border-blue-gray-700 bg-pv-blue-800">
    <div class="flex w-full flex-col px-4 py-3 text-blue-gray-100 md:px-7 md:py-4">
      <div class="mb-2 flex items-center">
        <inline-svg
          :src="require('assets/images/icons/user.svg')"
          class="mr-2 h-3 w-auto fill-current md:h-4"
        />
        <p class="text-base">
          {{ jobApplication.firstName }} {{ jobApplication.lastName }}
        </p>
      </div>
      <div class="mb-2 flex items-center">
        <inline-svg
          :src="require('assets/images/icons/briefcase.svg')"
          class="mr-2 h-3 w-auto fill-current text-blue-gray-300 md:h-4"
        />
        <p class="text-base">
          {{ jobApplication.position }}
        </p>
      </div>
      <div class="mb-2 flex items-center">
        <inline-svg
          :src="require('assets/images/icons/social-media/email.svg')"
          class="mr-2 h-2 w-auto fill-current md:h-3"
        />
        <p class="text-sm text-cool-gray-400">
          {{ jobApplication.email }}
        </p>
      </div>
    </div>
    <kalio-job-application-card-options
      v-if="!screen.isMobile"
      :job-application="jobApplication"
      @open-application-modal="applicationModalOpen = true"
    />
    <div
      v-else
      class="flex shrink-0 flex-col items-center justify-center px-2 py-4"
    >
      <VDropdown :distance="4">
        <button>
          <inline-svg
            :src="require('assets/images/icons/vertical-dots.svg')"
            class="h-5 w-4 fill-current"
          />
        </button>
        <template #popper="{ hide }">
          <kalio-job-application-card-options
            :job-application="jobApplication"
            @open-application-modal="
              applicationModalOpen = true;
              hide();
            "
          />
        </template>
      </VDropdown>
    </div>
    <kalio-job-application-modal
      v-if="applicationModalOpen"
      :job-application="jobApplication"
      @close="applicationModalOpen = false"
    />
  </div>
</template>

<style>
.v-popper--theme-dropdown .v-popper__inner {
  @apply bg-pv-blue-700 border-blue-gray-600 text-white;
}

.v-popper--theme-dropdown .v-popper__arrow-outer {
  @apply border-blue-gray-600;
}

.v-popper--theme-dropdown .v-popper__arrow-inner {
  @apply border-none;
}
</style>
