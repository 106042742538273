<script>
import ReviewApplicationDiscardEmailModalNoCto from './no-cto.vue';
import ReviewApplicationDiscardEmailModalNoTech from './no-tech.vue';
import ReviewApplicationDiscardEmailModalNoFulltime from './no-fulltime.vue';
import ReviewApplicationDiscardEmailModalCustom from './custom.vue';
import ReviewApplicationDiscardEmailModalWithFeedback from './with-feedback.vue';
import ReviewApplicationDiscardEmailModalWithoutFeedback from './without-feedback.vue';

const DISCARD_REASON_TO_COMPONENT = {
  'no_cto': ReviewApplicationDiscardEmailModalNoCto,
  'no_tech': ReviewApplicationDiscardEmailModalNoTech,
  'no_full_time': ReviewApplicationDiscardEmailModalNoFulltime,
  'custom': ReviewApplicationDiscardEmailModalCustom,
  'with_feedback': ReviewApplicationDiscardEmailModalWithFeedback,
  'without_feedback': ReviewApplicationDiscardEmailModalWithoutFeedback,
};

export default {
  name: 'base-review-discard-email-template',
  components: {
    ReviewApplicationDiscardEmailModalNoCto,
    ReviewApplicationDiscardEmailModalNoTech,
    ReviewApplicationDiscardEmailModalNoFulltime,
    ReviewApplicationDiscardEmailModalCustom,
    ReviewApplicationDiscardEmailModalWithFeedback,
    ReviewApplicationDiscardEmailModalWithoutFeedback,
  },
  inheritAttrs: false,
  props: {
    discardReason: { type: String, required: true },
    reviewing: { type: Boolean, default: false },
  },
  setup() {
    return {
      DISCARD_REASON_TO_COMPONENT,
    };
  },
};
</script>

<template>
  <div class="w-full rounded-lg bg-blue-gray-700 p-6 text-left">
    <component
      :is="DISCARD_REASON_TO_COMPONENT[discardReason]"
      :reviewing="reviewing"
      v-bind="$attrs"
    />
  </div>
</template>
