import api from './api';

export default {
  getAll(params = {}) {
    return api({
      url: '/api/v1/group_meeting_preparations',
      params,
    });
  },
};
