<script setup>
import { inject } from 'vue';
import { currentUserKey } from '@/utils/keys.js';
import RailsFlashAlert from '@/components/shared/rails-flash-alert.vue';

const currentUser = inject(currentUserKey);
</script>

<template>
  <div class="flex w-full max-w-md flex-col space-y-8 lg:max-w-6xl">
    <rails-flash-alert />
    <div class="mt-4 flex flex-col space-y-8">
      <div class="flex flex-row items-center space-x-5">
        <div class="size-12 overflow-hidden rounded-lg">
          <img
            class="size-full object-cover"
            :src="currentUser.avatarUrl"
          >
        </div>
        <h1 class="text-3xl font-medium text-blue-gray-50">
          Hola {{ currentUser.firstName }}!
        </h1>
      </div>
      <div class="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
        <slot />
      </div>
    </div>
  </div>
</template>
