<script setup>
import HomeLayout from '@/components/home/layout.vue';
import HomeStartupInfo from '@/components/home/startup-info.vue';
import HomeOfficeHours from '@/components/home/office-hours.vue';
import HomeLibraryItems from '@/components/home/library-items.vue';
import HomeWeeklyUpdate from '@/components/home/weekly-update.vue';
import HomeGroupMeetings from '@/components/home/group-meetings.vue';
import HomeCalendar from '@/components/home/calendar.vue';

defineProps({
  company: { type: Object, required: true },
  batch: { type: Object, required: true },
  officeHourOfferings: { type: Array, required: true },
  libraryItems: { type: Array, required: true },
  programGoal: { type: Object, default: null },
  weeklyUpdate: { type: Object, default: null },
  groupMeeting: { type: Object, default: null },
});
</script>

<template>
  <home-layout>
    <div class="flex w-full flex-col space-y-4">
      <home-startup-info :company="company" />
      <home-office-hours :office-hour-offerings="officeHourOfferings" />
      <home-library-items :library-items="libraryItems" />
    </div>
    <div class="flex w-full flex-col space-y-4">
      <div class="rounded-lg border border-blue-gray-700">
        <kalio-program-goal-panel
          is-founder
          :program-goal="programGoal"
        />
      </div>
      <home-weekly-update
        :company="company"
        :weekly-update="weeklyUpdate"
        :program-goal="programGoal"
      />
      <home-group-meetings
        :group-meeting="groupMeeting"
        :company="company"
        :batch="batch"
      />
      <home-calendar />
    </div>
  </home-layout>
</template>

