<script>
export default {
  name: 'kalio-back-button',
  props: {
    alternativeTag: { type: String, default: null },
    iconSizeClasses: { type: String, default: 'w-4 h-4' },
  },
  computed: {
    tag() {
      if (this.alternativeTag) return this.alternativeTag;

      return this.$attrs.href ? 'a' : 'button';
    },
  },
};
</script>

<template>
  <component
    :is="tag"
    class="flex flex-row items-center self-start rounded-full bg-blue-gray-700 px-4 py-1.5 hover:opacity-75 focus:outline-none"
  >
    <inline-svg
      :src="require('assets/images/icons/arrow.svg')"
      class="h-4 fill-current text-pv-gray"
      :class="iconSizeClasses"
    />
    <slot />
  </component>
</template>
