<script setup>
import { watch } from 'vue';
import { useField } from 'vee-validate';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  values: { type: Array, required: true },
  name: { type: String, required: true },
  rules: { type: String, default: null },
});

const initialValue = (props.values.includes(props.modelValue) && props.modelValue) || null;
const {
  value: inputValue,
  handleReset,
} = useField(props.name, props.rules, { type: 'radio', initialValue });

watch(inputValue, (newVal) => {
  if (!props.values.includes(inputValue.value)) {
    handleReset();
  }

  emit('update:modelValue', newVal);
});
</script>

<template>
  <div class="flex flex-row">
    <kalio-radio-input
      v-for="value in values"
      :key="`${name}-${value}`"
      v-model="inputValue"
      :name="name"
      :value="value"
    />
  </div>
</template>
