<script setup>
import { inject } from 'vue';
import BaseInformationCard from '@/components/shared/base-information-card.vue';
import KalioCarousel from '@/components/shared/kalio-carousel.vue';
import { screenKey } from '@/utils/keys.js';

defineProps({
  officeHourOfferings: { type: Array, required: true },
});

const screen = inject(screenKey);
</script>

<template>
  <base-information-card title="Office Hours">
    <p class="text-base">
      La red de Platanus disponibiliza Office Hours para ayudarte cuando lo necesites.
      Este es uno de los grandes valores de Platanus. Agenda todas las OH que quieras.
    </p>
    <div class="mt-4 flex max-w-xs flex-col space-y-4 self-center sm:max-w-xl lg:max-w-md xl:max-w-lg">
      <kalio-carousel
        :navigation="!screen.isMobile"
        :pagination="screen.isMobile"
        :autoplay="4000"
        wrap-around
        :items="officeHourOfferings"
      >
        <template #item="{ item: offering }">
          <a
            :href="offering.url"
            class="flex size-full cursor-pointer flex-row space-x-3 rounded-md border border-blue-gray-700 bg-pv-blue-700 p-2 text-left hover:opacity-75"
          >
            <div class="size-16 shrink-0 overflow-hidden rounded-md">
              <img
                :src="offering.user.avatarUrl"
                class="size-full object-cover"
              >
            </div>
            <div class="flex flex-col space-y-1">
              <span class="text-lg font-medium">
                {{ offering.user.fullName }}
              </span>
              <p class="line-clamp-3 text-sm text-pv-gray">
                {{ offering.description }}
              </p>
            </div>
          </a>
        </template>
      </kalio-carousel>
      <a
        href="/office_hour_offerings"
        class="mt-3 flex flex-row items-center space-x-2 self-center text-pv-yellow hover:opacity-75"
      >
        <span class="text-sm">
          Ver todos los office hours disponibles
        </span>
        <inline-svg
          :src="require('assets/images/icons/arrow.svg')"
          class="size-3 rotate-180 fill-current"
        />
      </a>
    </div>
  </base-information-card>
</template>
