import humps from 'humps';
import api from './api';

export default {
  create({ description, userId, active }) {
    const path = '/api/v1/office_hour_offerings';

    return api({
      method: 'post',
      data: humps.decamelizeKeys({
        officeHourOffering: {
          description,
          userId: userId || undefined,
          active: active ?? true,
        },
      }),
      url: path,
    });
  },
  update(description) {
    const path = '/api/v1/office_hour_offerings';

    return api({
      method: 'patch',
      data: humps.decamelizeKeys({
        officeHourOffering: { description },
      }),
      url: path,
    });
  },
};
