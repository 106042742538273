<script>
import getFormatOfOperationIconName from '../../utils/get_format_of_operation_icon_name.js';

export default {
  props: {
    jobOffer: { type: Object, required: true },
    showCompany: { type: Boolean, default: false },
    showActions: { type: Boolean, default: false },
  },
  computed: {
    formatOfOperationIcon() {
      return getFormatOfOperationIconName(this.jobOffer.formatOfOperation);
    },
  },
};
</script>

<template>
  <div class="flex divide-x divide-blue-gray-700 rounded-xl border border-blue-gray-700 bg-pv-blue-800">
    <a
      :href="jobOffer.showUrl"
      class="flex w-full items-center p-3 md:px-6 md:py-4"
    >
      <div class="flex-1">
        <div class="mb-1 text-sm md:text-xl">
          {{ jobOffer.position }}
        </div>
        <div class="flex flex-wrap gap-x-2.5 gap-y-1 md:gap-x-6">
          <div
            v-if="showCompany"
            class="flex space-x-1"
          >
            <inline-svg
              :src="require('assets/images/icons/business.svg')"
              class="size-4 fill-current text-blue-gray-400 md:size-5"
            />
            <span class="text-xs text-pv-gray md:text-sm">
              {{ jobOffer.companyName }}
            </span>
          </div>
          <div
            v-if="jobOffer.formatOfOperation"
            class="flex items-center space-x-1"
          >
            <inline-svg
              :src="require(`assets/images/icons/${formatOfOperationIcon}.svg`)"
              class="h-3 w-auto fill-current text-blue-gray-400 md:h-4"
            />
            <span class="text-xs text-pv-gray md:text-sm">
              {{ $t(`jobOffers.formatOfOperations.${jobOffer.formatOfOperation}`) }}
            </span>
          </div>
          <div
            v-if="jobOffer.countryInformation"
            class="flex items-center"
          >
            <country-flag
              :country="jobOffer.countryInformation.code.toLowerCase()"
              size="small"
            />
            <span class="ml-3 text-sm">
              {{ jobOffer.countryInformation.name }}
            </span>
          </div>
          <div class="flex items-center space-x-1">
            <inline-svg
              :src="require('assets/images/icons/salary.svg')"
              class="size-4 shrink-0 fill-current text-blue-gray-400 md:size-5"
            />
            <span class="text-xs text-pv-gray md:text-sm">
              {{ jobOffer.wageRange }}
            </span>
          </div>
        </div>
      </div>
      <inline-svg
        v-if="!showActions"
        :src="require('assets/images/icons/toggle-arrow.svg')"
        class="size-4 shrink-0 rotate-90 fill-current text-pv-gray"
      />
    </a>
    <div
      v-if="showActions"
      class="flex shrink-0 flex-col divide-y divide-blue-gray-700"
    >
      <a
        :href="jobOffer.showUrl"
        class="flex flex-1 items-center space-x-2 px-4 py-2"
      >
        <inline-svg
          :src="require('assets/images/icons/visibility.svg')"
          class="size-4 fill-current"
        />
        <span class="text-xs">
          {{ $t('jobOffers.actions.show') }}
        </span>
      </a>
      <a
        :href="jobOffer.editUrl"
        class="flex flex-1 items-center space-x-2 px-4 py-2"
      >
        <inline-svg
          :src="require('assets/images/icons/edit.svg')"
          class="size-4 fill-current"
        />
        <span class="text-xs">
          {{ $t('jobOffers.actions.edit') }}
        </span>
      </a>
      <a
        :href="jobOffer.applicationsUrl"
        class="flex flex-1 items-center space-x-2 px-4 py-2"
      >
        <inline-svg
          :src="require('assets/images/icons/assignment_ind.svg')"
          class="size-4 fill-current"
        />
        <span class="text-xs">
          {{ $t('jobOffers.actions.applications') }}
        </span>
      </a>
    </div>
  </div>
</template>
