import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { captureEvent } from '@/utils/analytics';
import { useUserStore } from '@/stores';
import { useLikesQuery, useAddLikeMutation, useDeleteLikeMutation } from '../queries/likes.js';

export default function useLikes(company = null) {
  const companyRef = ref(company);

  const userStore = useUserStore();
  const { currentUser } = storeToRefs(userStore);

  const { data: likes } = useLikesQuery();

  const companyHaslikeComputed = computed(
    () => likes.value?.some((like) => like.companyId === companyRef.value?.id),
  );
  function companyHasLikeMethod(companyId) {
    return likes.value?.some((like) => like.companyId === companyId);
  }

  const addLikeMutation = useAddLikeMutation();
  const deleteLikeMutation = useDeleteLikeMutation();

  function handleLike() {
    const like = likes.value.find((l) => l.companyId === companyRef.value.id);

    if (like) {
      deleteLikeMutation.mutate(like.id, {
        onSuccess: () => {
          captureEvent('company unliked', {
            company: companyRef.value.name,
            user: currentUser.value.fullName,
          });
        },
      });
    } else {
      addLikeMutation.mutate(companyRef.value.id, {
        onSuccess: () => {
          captureEvent('company liked', {
            company: companyRef.value.name,
          });
        },
      });
    }
  }

  return {
    likes,
    handleLike,
    companyHaslike: company ? companyHaslikeComputed : companyHasLikeMethod,
  };
}
