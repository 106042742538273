<script setup>
import { ref, onBeforeUnmount, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import useLikes from '@/hooks/likes.js';
import useVirtualRoundTimer from '../composables/virtual_round_timer.js';
import { useDemoDayStreamigStore, useVirtualRoundStore } from '../stores';

const props = defineProps({
  demoDayCompanies: { type: Array, required: true },
});

const { companyHaslike } = useLikes();
const demoDayStreamingStore = useDemoDayStreamigStore();
const virtualRoundStore = useVirtualRoundStore();
const { demoDay } = storeToRefs(virtualRoundStore);

const selectedDemoDayCompany = ref(props.demoDayCompanies[0]);
function selectDemoDayCompany(value) {
  selectedDemoDayCompany.value = value;
}
function handleSelectDemoDayCompany(value) {
  selectDemoDayCompany(value);
  demoDayStreamingStore.resetTimeSinceLastUserAction();
}

const liveDemoDayCompany = computed(() => props.demoDayCompanies.find(
  demoDayCompany => demoDayCompany.id === demoDayStreamingStore.liveDemoDayCompanyId,
));

const { virtualRoundTimer } = useVirtualRoundTimer();

watch(liveDemoDayCompany, () => {
  if (
    demoDayStreamingStore.canAutomaticallyChangeSelectedDemoDayCompany &&
        !virtualRoundTimer.demoDayFinished
  ) {
    selectDemoDayCompany(liveDemoDayCompany.value);
  }
});

const increaseSecondsSinceLastUserActionInterval = setInterval(
  demoDayStreamingStore.increaseSecondsSinceLastUserAction,
  1000,
);
onBeforeUnmount(() => clearInterval(increaseSecondsSinceLastUserActionInterval));

function selectDemoDayCompanyFromQueryParams() {
  const demoDayCompanyId = parseInt(
    new URLSearchParams(window.location.search).get('demo_day_company_id'),
    10,
  );
  window.history.replaceState({}, document.title, window.location.pathname);

  const demoDayCompany = props.demoDayCompanies.find(
    ddc => ddc.id === demoDayCompanyId,
  );

  if (demoDayCompany) {
    selectDemoDayCompany(demoDayCompany);

    return true;
  }

  return false;
}

onMounted(() => {
  if (!selectDemoDayCompanyFromQueryParams() && (
    new Date() < new Date(demoDay.value.startDate) || virtualRoundTimer.demoDayFinished
  )) {
    const randomDemoDayCompany = props.demoDayCompanies[
      Math.floor(Math.random() * props.demoDayCompanies.length)
    ];
    selectDemoDayCompany(randomDemoDayCompany);
  }
});

</script>

<template>
  <div class="flex flex-row overflow-hidden rounded-xl border border-blue-gray-700 bg-pv-blue-900">
    <div class="flex w-64 flex-col border-r border-blue-gray-700">
      <div
        v-for="demoDayCompany in demoDayCompanies"
        :key="demoDayCompany.id"
        class="border-b border-pv-blue-800 last:border-b-0"
      >
        <button
          class="flex w-full flex-row items-center border-l-6 p-5 hover:bg-blue-gray-800 focus:outline-none"
          :class="selectedDemoDayCompany.id === demoDayCompany.id
            ? 'border-pv-yellow'
            : 'hover:border-blue-gray-800 border-pv-blue-900'"
          @click="handleSelectDemoDayCompany(demoDayCompany)"
        >
          <span
            class="text-base font-medium"
            :class="{ 'text-blue-gray-300': selectedDemoDayCompany !== demoDayCompany }"
          >
            {{ demoDayCompany.company.name }}
          </span>
          <inline-svg
            v-if="companyHaslike(demoDayCompany.company.id)"
            :src="require('assets/images/icons/solid/heart.svg')"
            class="ml-auto size-4 fill-current text-red-400"
          />
        </button>
      </div>
    </div>
    <slot :demo-day-company="selectedDemoDayCompany" />
  </div>
</template>
