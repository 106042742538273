import { onMounted, onUnmounted } from 'vue';

export default function useKeyListener({ keys, triggerOnInput = false, onKeyDown, onKeyUp }) {
  function shouldTrigger(event) {
    const pressedKeys = [];
    if (event.ctrlKey) pressedKeys.push('ctrl');
    if (event.shiftKey) pressedKeys.push('shift');
    if (event.altKey) pressedKeys.push('alt');
    pressedKeys.push(event.key.toLowerCase());

    const pressedKeysString = pressedKeys.sort().join('+');
    const keysString = keys.sort().join('+');

    if (keysString !== pressedKeysString) return false;

    if (triggerOnInput) return true;

    return !['INPUT', 'TEXTAREA', 'TRIX-EDITOR'].includes(event.target.tagName);
  }

  function keyDownHandler(event) {
    if (!onKeyDown) return;
    if (!shouldTrigger(event)) return;
    event.preventDefault();
    onKeyDown(event);
  }

  function keyUpHandler(event) {
    if (!onKeyUp) return;
    if (!shouldTrigger(event)) return;
    event.preventDefault();
    onKeyUp(event);
  }

  onMounted(() => {
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', keyDownHandler);
    document.removeEventListener('keyup', keyDownHandler);
  });
}
