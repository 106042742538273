<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import {
  useResourcesTagsQuery,
  useResourceTagsQuery,
  useAddResourceTagMutation,
  useDeleteResourceTagMutation,
} from '@/queries/tags.js';

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplicationId: startupApplicationId } = storeToRefs(startupApplicationStore);
const selectedTag = ref(null);

const { data: tags } = useResourceTagsQuery({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
});

const {
  isLoading: isDeleteTagLoading,
  mutate: deleteTag,
} = useDeleteResourceTagMutation({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
}, { onMutate: (tag) => (selectedTag.value = tag) });

const {
  isLoading: isAddTagLoading,
  mutate: addTag,
} = useAddResourceTagMutation({
  resourceType: 'StartupApplication',
  resourceIdRef: startupApplicationId,
});

const { data: startupApplicationsTags } = useResourcesTagsQuery({ resourceType: 'StartupApplication' });
</script>

<template>
  <div class="flex flex-col space-y-2">
    <kalio-select-input
      name="tag"
      placeholder="Selecciona o ingresa un tag"
      :options="startupApplicationsTags"
      :loading="isAddTagLoading"
      taggable
      @option:selected="addTag"
    />
    <div class="flex flex-wrap gap-2">
      <kalio-badge
        v-for="(tag, index) in tags"
        :key="index"
        :text="tag"
        with-border
      >
        <template #right>
          <kalio-spinner
            v-if="isDeleteTagLoading && selectedTag === tag"
            class="text-pv-light-blue"
            :size="3"
          />
          <button
            v-else
            :disabled="isDeleteTagLoading"
            @click="deleteTag(tag)"
          >
            <inline-svg
              :src="require('assets/images/icons/close.svg')"
              class="h-3 w-auto fill-current"
            />
          </button>
        </template>
      </kalio-badge>
    </div>
  </div>
</template>
