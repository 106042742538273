import { useMutation } from '@tanstack/vue-query';
import slackInvitesApi from '@/api/slack_channel_invites.js';

export function useSendInviteQuery(email, name, options = {}) {
  return useMutation({
    mutationFn: () => slackInvitesApi.create({ email: email.value, name }),
    ...options,
  });
}

export function useSendInviteAllQuery(email, options = {}) {
  return useMutation({
    mutationFn: () => slackInvitesApi.create({ email: email.value, allChannels: true }),
    ...options,
  });
}
