<script setup>
import { computed, ref } from 'vue';
import CutOutCornerCard from '@/components/shared/cut-out-corner-card.vue';
import BaseCopyElement from '@/components/shared/base-copy-element.vue';

const props = defineProps({
  user: { type: Object, required: true },
});
const emailIcon = ref('social-media/email.svg');
const emailIconPath = computed(() => require(`assets/images/icons/${emailIcon.value}`));
const isCompanyInactive = computed(() => props.user.company !== null && !!props.user.company.inactiveSince);
const userPath = computed(() => `/people/${props.user.id}`);
const companyPath = computed(() => props.user.company?.profilePath);
const companyName = computed(() => props.user.company?.name);
const batchSeason = computed(() => props.user.company?.batch?.batchSeason);
const position = computed(() => props.user.founderProfile?.companyPosition);

function setEmailIcon() {
  emailIcon.value = 'social-media/email.svg';
}

function setCheckIcon() {
  emailIcon.value = 'check.svg';
}
</script>

<template>
  <a
    :href="userPath"
    target="_blank"
    class="flex w-full flex-row gap-4 rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4 transition-colors hover:border-blue-gray-600"
  >
    <cut-out-corner-card
      class="size-24 shrink-0"
      cut-size="28px"
      top-right-color="pv-blue-800"
      bottom-left-color="pv-blue-800"
    >
      <img
        class="size-full object-cover"
        :src="user.avatarUrl"
      >
    </cut-out-corner-card>
    <div class="mr-2 flex h-full grow flex-col justify-between">
      <div class="flex items-center gap-x-2">
        <p class="w-[90%] truncate text-lg font-medium">
          {{ user.fullName }}
        </p>
      </div>
      <div class="flex flex-row flex-wrap items-center gap-x-2.5">
        <a
          v-if="companyName"
          :href="companyPath"
          target="_blank"
        >
          <span class="text-center text-pv-gray hover:opacity-75 md:text-left">
            {{ companyName }}
          </span>
        </a>
      </div>

      <base-copy-element
        :text="user.email"
        :show-tooltip="false"
        @copied="setCheckIcon"
        @clear="setEmailIcon"
      >
        <div class="mb-1 flex items-center text-pv-gray transition-colors hover:text-pv-yellow">
          <inline-svg
            :src="emailIconPath"
            class="size-4 fill-current"
          />
          <span class="ml-1 text-sm">
            {{ user.email }}
          </span>
        </div>
      </base-copy-element>

      <div class="flex gap-1">
        <span
          v-if="isCompanyInactive"
          class="rounded-full bg-pv-gray/20 px-2 py-0.5 text-xs text-pv-gray"
        >
          {{ $t('people.inactive') }}
        </span>
        <span
          v-else-if="position"
          class="rounded-full bg-pv-gray/30 px-2 py-0.5 text-xs text-pv-gray"
        >
          {{ position }}
        </span>
        <span
          v-if="batchSeason"
          class="rounded-full bg-pv-yellow px-2 py-0.5 text-xs text-black"
        >
          {{ batchSeason }}
        </span>
      </div>
    </div>
  </a>
</template>
