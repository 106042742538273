<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioTextAnswer from '@/components/form/kalio-text-answer.vue';
import useFormAnswers from '@/hooks/form_answers.js';

const FORM_ANSWERS_PATHS = [
  'legal.entity',
  'legal.structure',
  'legal.otherInvestments',
  'legal.otherInvestmentsDetails',
  'legal.funding',
  'legal.spending',
  'legal.savings',
  'legal.yieldEquity',
  'legal.equity',
  'legal.runway',
  'legal.currentInvestmentRound',
  'legal.currentInvestmentRoundDetails',
  'legal.other',
];

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);

const injected = [];
if (startupApplication.value.startupApplicationStatistic?.burnRate !== null) {
  injected.push({
    index: 9,
    label: 'Burn rate calculado usando ingresos del último mes y gastos mensuales declarados',
    answer: startupApplication.value.startupApplicationStatistic?.burnRate.toString(),
  });
}

const { formAnswers, getAnswer } = useFormAnswers(
  FORM_ANSWERS_PATHS,
  startupApplication.value,
  { key: 'formAnswers', injected },
);

const applicationFounders = computed(() => startupApplication.value.applicationFounders);
const users = computed(() => applicationFounders.value.map(founder => founder.user));

function getUserEquity(email) {
  const userEquity = getAnswer('legal.equityByFounder').find((item) => item.name === email);

  return userEquity?.equity;
}

</script>

<template>
  <div class="flex flex-col space-y-12">
    <div class="flex flex-col space-y-4">
      <div class="flex flex-row items-center space-x-2">
        <inline-svg
          :src="require('assets/images/icons/outline/pie-chart.svg')"
          class="size-7 stroke-current"
        />
        <span class="text-xl font-semibold">
          Cap Table:
        </span>
      </div>
      <div class="flex flex-col space-y-2 whitespace-pre-line">
        <div v-if="getAnswer('legal.equityByFounder')">
          <h3 class="font-bold">
            Porcentaje fundadores
          </h3>
          <p
            v-for="(user, index) in users"
            :key="index"
          >
            {{ user.fullName }}: {{ getUserEquity(user.email) }}%
          </p>
        </div>
        <div>
          <h3 class="font-bold">
            Todos los detalles
          </h3>
          <div>
            {{ getAnswer('legal.equity') }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col space-y-4">
      <kalio-text-answer
        v-for="(item, index) in formAnswers"
        :key="index"
        :label="item.label"
        :answer="item.answer"
      />
    </div>
  </div>
</template>
