<script>
import KalioListWithFiltersAndSearchbar from '../shared/kalio-list-with-filters-and-searchbar.vue';
import KalioLibraryItemCard from './kalio-library-item-card.vue';

export default {
  name: 'KalioLibraryItemList',
  components: {
    KalioLibraryItemCard,
    KalioListWithFiltersAndSearchbar,
  },
  props: {
    libraryItems: { type: Array, required: true },
    kinds: { type: Array, required: true },
    categories: { type: Array, required: true },
    query: { type: String, default: '' },
  },
  computed: {
    filters() {
      return {
        kind: {
          label: this.$t('list.filters.kind'),
          itemKey: 'kind',
          options: this.kinds.map(kind => ({ value: kind, label: kind })),
          type: 'checkbox',
        },
        category: {
          label: this.$t('list.filters.category'),
          itemKey: 'categoryList',
          options: this.categories.map(category => ({ value: category, label: category })),
          compareFunction: (filterValue, itemValue) => itemValue.includes(filterValue),
          type: 'checkbox',
        },
      };
    },
  },
};
</script>

<template>
  <div class="w-full max-w-6xl">
    <h1 class="mb-5 text-center text-4xl font-medium text-blue-gray-50">
      {{ $t('libraryItems.listTitle') }}
    </h1>
    <div class="mb-14 text-center text-xl text-blue-gray-200">
      {{ $t('libraryItems.listSubtitle') }}
    </div>

    <kalio-list-with-filters-and-searchbar
      :items="libraryItems"
      :query="query"
      search-path="/library_items"
      :filters="filters"
    >
      <template #item="{ item }">
        <kalio-library-item-card :library-item="item" />
      </template>
    </kalio-list-with-filters-and-searchbar>
  </div>
</template>
