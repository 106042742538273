const FORMAT_OF_OPERATION_TO_ICON = {
  presential: 'briefcase',
  remote: 'wifi',
  hybrid: 'hybrid',
};

function getFormatOfOperationIconName(formatOfOperation) {
  return FORMAT_OF_OPERATION_TO_ICON[formatOfOperation];
}

export default getFormatOfOperationIconName;
