import humps from 'humps';
import api from './api';

export default {
  update(id, startupApplicationUpdates) {
    const path = `/startup_application_updates/${id}`;

    return api({
      method: 'put',
      data: humps.decamelizeKeys(startupApplicationUpdates),
      url: path,
    });
  },
  create(startupApplicationId, body) {
    const path = `/api/v1/startup_applications/${startupApplicationId}/startup_application_updates`;

    return api({
      method: 'post',
      data: body,
      url: path,
    });
  },
};
