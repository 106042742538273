<script setup>
import { ref, computed, inject } from 'vue';
import { defineRule } from 'vee-validate';
import { currentUserKey } from '@/utils/keys.js';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import KalioConfirmationModal from '@/components/shared/kalio-confirmation-modal.vue';
import KalioButton from '@/components/shared/kalio-button.vue';
import KalioCopyTextButton from '@/components/shared/kalio-copy-text-button.vue';
import applicationFoundersApi from '@/api/application_founders';
import startupApplicationsApi from '@/api/startup_application';

const currentUser = inject(currentUserKey);

const props = defineProps({
  applicationFounders: { type: Array, default: () => [] },
  startupApplication: { type: Object, required: true },
});

const newEmail = ref('');
const inviteFounderErrorMessage = ref(null);
const inviteFounderLoading = ref(false);
const inviteFounderSuccess = ref(false);
const removeFounderErrorMessage = ref(null);
const removeFounderLoading = ref(false);
const removeFounderSuccess = ref(false);
const removeFounderModalOpen = ref(false);
const cofoundersInvitedModalLoading = ref(false);
const cofoundersInvitedLoading = ref(false);
const currentApplicationFounders = ref(props.applicationFounders);
const founderToRemove = ref(null);

defineRule('deny-self-email', (value) => {
  if (value === currentUser.value.email) {
    return 'No puedes invitarte a ti mismo.';
  }

  return true;
});

const submitButtonLabel = computed(() => {
  if (newEmail.value) {
    return 'Agregar';
  } else if (currentApplicationFounders.value.length > 0) {
    return 'Terminé de invitar';
  }

  return 'No tengo co-fundadores';
});

const submitButtonVariant = computed(() => (
  currentApplicationFounders.value.length > 0 ||
  newEmail.value ? 'primary' : 'secondary'),
);

async function cofoundersInvited() {
  cofoundersInvitedLoading.value = true;
  await startupApplicationsApi.cofoundersInvited();
  cofoundersInvitedLoading.value = false;
  window.location = '/apply';
}

async function cofoundersInvitedFromModal() {
  cofoundersInvitedModalLoading.value = true;
  await startupApplicationsApi.cofoundersInvited();
  cofoundersInvitedModalLoading.value = false;
  window.location = '/apply';
}

async function addFounder() {
  inviteFounderErrorMessage.value = null;
  inviteFounderLoading.value = true;

  try {
    const response = await applicationFoundersApi.create({
      startupApplicationId: props.startupApplication.id,
      email: newEmail.value,
    });

    currentApplicationFounders.value.push(response.applicationFounder);
    inviteFounderSuccess.value = true;
  } catch (error) {
    inviteFounderErrorMessage.value = error.response.data.detail;
  } finally {
    newEmail.value = '';
    inviteFounderLoading.value = false;
  }
}

function askRemoveConfirmation(applicationFounder) {
  founderToRemove.value = applicationFounder;
  removeFounderModalOpen.value = true;
}

async function removeFounder() {
  removeFounderLoading.value = true;

  try {
    await applicationFoundersApi.delete(founderToRemove.value.id);
    currentApplicationFounders.value = currentApplicationFounders.value
      .filter(founder => founder.id !== founderToRemove.value.id);
    removeFounderSuccess.value = true;
  } catch (error) {
    removeFounderErrorMessage.value = error.response.data.detail;
  } finally {
    removeFounderModalOpen.value = false;
    founderToRemove.value = null;
    removeFounderLoading.value = false;
  }
}

defineExpose({
  cofoundersInvited,
  addFounder,
  askRemoveConfirmation,
  removeFounder,
  submitButtonLabel,
  submitButtonVariant,
});
</script>

<template>
  <div class="flex flex-col space-y-6 text-base">
    <div class="flex flex-col rounded-lg bg-pv-blue-900 p-4">
      <span class="font-medium">
        Invitaciones en curso
      </span>
      <span class="h-px w-full bg-pv-gray" />
      <span
        v-if="currentApplicationFounders.length === 0"
        class="mt-4 text-pv-gray"
      >
        No has invitado co-founders aún.
      </span>
      <div
        v-else
        class="mt-4 flex flex-col space-y-4"
      >
        <div
          v-for="applicationFounder in currentApplicationFounders"
          :key="applicationFounder.id"
          class="flex flex-row items-start"
        >
          <inline-svg
            v-if="applicationFounder.invitationUrl"
            :src="require('assets/images/icons/outline/paper-airplane.svg')"
            class="size-5 -rotate-45 stroke-current"
          />
          <inline-svg
            v-else-if="!applicationFounder.founderProfileSubmitted"
            :src="require('assets/images/icons/sand-clock.svg')"
            class="mt-0.5 size-5 fill-current text-pv-gray"
          />
          <div
            v-else
            class="mt-0.5 flex size-5 items-center justify-center rounded-full border border-blue-gray-50"
          >
            <inline-svg
              :src="require('assets/images/icons/check.svg')"
              class="size-2.5 fill-current text-pv-gray"
            />
          </div>
          <div class="ml-4 flex flex-col gap-y-1">
            <span>
              {{ applicationFounder.email }}
            </span>
            <div class="flex flex-row gap-x-2">
              <span
                v-if="!applicationFounder.founderProfileSubmitted"
                class="text-xs font-extralight text-blue-gray-400 md:text-sm"
              >
                ({{ applicationFounder.invitationUrl
                  ? 'No ha aceptado la invitación'
                  : 'No ha completado su perfil de fundador'
                }})
              </span>
              <kalio-copy-text-button
                v-if="applicationFounder.invitationUrl"
                class="text-blue-400"
                :text="applicationFounder.invitationUrl"
                button-text="Copiar link de invitación"
                icon-classes="w-3 h-3 fill-current"
              />
            </div>
          </div>
          <button
            class="ml-auto mt-1 hover:scale-110 hover:opacity-75 focus:outline-none"
            @click="askRemoveConfirmation(applicationFounder)"
          >
            <inline-svg
              :src="require('assets/images/icons/thrash.svg')"
              class="size-3.5 fill-current text-pv-gray"
            />
          </button>
        </div>
      </div>
    </div>

    <kalio-form-wrapper @submit="newEmail ? addFounder() : cofoundersInvited()">
      <div class="flex flex-col space-y-2 rounded-lg bg-pv-blue-900 p-4 md:space-y-6 md:px-8">
        <kalio-alert
          v-if="inviteFounderErrorMessage"
          status="alert"
          :title="inviteFounderErrorMessage"
          @close="inviteFounderErrorMessage = null"
        />
        <kalio-short-text-input
          v-model="newEmail"
          name="email"
          rules="deny-self-email|email"
          placeholder="correo@example.com"
          label="Email"
          variant="semi-dark"
          icon-name="social-media/email.svg"
          icon-classes="w-5 h-5 fill-current text-blue-gray-400"
        />
        <kalio-button
          :label="submitButtonLabel"
          :variant="submitButtonVariant"
          class="md:self-center md:px-24"
          type="submit"
          :loading="newEmail ? inviteFounderLoading : cofoundersInvitedLoading"
        />
      </div>
      <kalio-feedback-modal
        v-if="inviteFounderSuccess"
        title="¡Co-founder invitado!"
        :show-accept="false"
      >
        <div class="flex gap-4">
          <kalio-button
            label="Terminé de invitar"
            variant="tertiary"
            :loading="cofoundersInvitedModalLoading"
            @click="cofoundersInvitedFromModal"
          />
          <kalio-button
            label="Seguir invitando"
            @click="inviteFounderSuccess = false"
          />
        </div>
      </kalio-feedback-modal>

      <kalio-feedback-modal
        v-if="removeFounderSuccess"
        title="Co-founder eliminado."
        accept="Entendido"
        @accept="removeFounderSuccess = false"
      />
      <kalio-confirmation-modal
        v-if="removeFounderModalOpen"
        :loading="removeFounderLoading"
        @accept="removeFounder"
        @close="removeFounderModalOpen = false"
      >
        <template #content>
          <span class="text-xl font-medium">
            ¿Estas seguro de querer eliminar a {{ founderToRemove.email }}?
          </span>
          <span class="text-base">
            Ya no podrá acceder a la postulación ni será considerado en el proceso.
          </span>
        </template>
      </kalio-confirmation-modal>
    </kalio-form-wrapper>
  </div>
</template>
