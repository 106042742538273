<script>

export default {
  name: 'KalioCalendlyOfficeHour',
  props: { calendlyUrl: { type: String, required: true } },
  emits: ['office-hour-requested'],
  data() {
    return { message: '' };
  },
  mounted() {
    const calendlyScript = document.createElement('script');
    calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    calendlyScript.setAttribute('async', true);
    document.head.appendChild(calendlyScript);
    window.addEventListener('message', this.handleCalendlyScheduledEvent);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleCalendlyScheduledEvent);
  },
  methods: {
    handleCalendlyScheduledEvent(e) {
      if (e.data.event?.startsWith('calendly')) {
        const eventType = e.data.event.split('.')[1];
        if (eventType === 'event_scheduled') {
          this.$emit('office-hour-requested');
        }
      }
    },
  },
};
</script>

<template>
  <div
    class="calendly-inline-widget h-96 w-full overflow-scroll sm:h-144"
    :data-url="calendlyUrl"
  />
</template>
