<script setup>
import { ref, computed, watch, nextTick, onActivated } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { useScroll } from '@vueuse/core';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import groupMeetingCompanyCommentsApi from '@/api/group_meeting_company_comments';
import useActionCable from '@/hooks/actioncable.js';
import { useGroupMeetingStore } from '@/stores';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import KalioGroupMeetingsCompanyComment from './company-comment.vue';

function useCommentMutation(groupMeetingId) {
  return useMutation(({ companyId, body }) =>
    groupMeetingCompanyCommentsApi.create(groupMeetingId, companyId, body),
  );
}

function sortByCreatedAt(a, b) {
  return new Date(a.createdAt) - new Date(b.createdAt);
}

const groupMeetingStore = useGroupMeetingStore();
const { groupMeeting, groupMeetings, companies } = storeToRefs(groupMeetingStore);

const currentMeetingIndex = ref(groupMeetings.value.findIndex(
  (meeting) => meeting.id === groupMeeting.value.id),
);

if (currentMeetingIndex.value === -1) {
  currentMeetingIndex.value = 0;
}

const currentMeeting = computed(() => groupMeetings.value[currentMeetingIndex.value]);
const hasPreviousMeeting = computed(() => currentMeetingIndex.value < groupMeetings.value.length - 1);
const hasNextMeeting = computed(() => currentMeetingIndex.value > 0);
function previousMeeting() {
  currentMeetingIndex.value++;
}
function nextMeeting() {
  if (currentMeetingIndex.value > 0) currentMeetingIndex.value--;
}
const formattedDate = computed(() => format(new Date(currentMeeting.value.startDate), 'dd/MM'));

const selectedCompany = ref(null);
const commentBody = ref('');
const lastCommentId = ref(null);

const { data, refetch, isError: isFetchError, isLoading: isQueryLoading } = useQuery(
  ['groupMeetingCompanyComments', currentMeeting],
  () => groupMeetingCompanyCommentsApi.getAll(currentMeeting.value.id),
);
const comments = computed(
  () => [...data.value?.groupMeetingCompanyComments.map(
    (comment) => ({ ...comment, unseen: lastCommentId.value === comment.id }),
  )].sort((a, b) => sortByCreatedAt(a, b)),
);

const { isLoading: isMutationLoading, mutate } = useCommentMutation(groupMeeting.value.id);
function submit(_, { resetForm }) {
  mutate({
    companyId: selectedCompany.value.id,
    body: commentBody.value,
  }, {
    onSuccess: () => resetForm(),
  });
}

const meetingsSavedScrollHeight = ref({});
const scrollbar = ref(null);
const { y: savedScrollHeight } = useScroll(scrollbar);
watch(savedScrollHeight, () => {
  meetingsSavedScrollHeight.value[currentMeetingIndex.value] = savedScrollHeight.value;
});

function scrollToSavedHeight() {
  nextTick().then(() => scrollbar.value.scrollTo({
    top: meetingsSavedScrollHeight.value[currentMeetingIndex.value],
    behavior: 'instant',
  }));
}

watch(currentMeetingIndex, (newIndex, prevIndex) => {
  scrollToSavedHeight();
  if (prevIndex === groupMeetings.value.length - 1) lastCommentId.value = null;
});
onActivated(scrollToSavedHeight);

function scrollToLast() {
  nextTick().then(() => scrollbar.value.scrollTo({
    top: scrollbar.value.scrollHeight,
    behavior: 'smooth',
  }));
}

useActionCable({ channel: 'GroupMeetingChannel', id: groupMeeting.value.id }, {
  received(event) {
    if (event.kind === 'comment_created') {
      lastCommentId.value = event.comment_id;
      refetch().then(scrollToLast);
    }
  },
});

watch(isQueryLoading, () => {
  if (!isQueryLoading.value && !isFetchError.value) {
    scrollToLast();
  }
});

const inputsVisible = ref(true);
</script>

<template>
  <div class="flex size-full flex-col gap-4 px-2">
    <div class="flex flex-row items-center space-x-2 self-center">
      <div v-tooltip="hasPreviousMeeting ? '' : 'no hay coliseos previos'">
        <button
          :disabled="!hasPreviousMeeting"
          class="flex items-center justify-center rounded-full border border-blue-gray-700 bg-pv-blue-700 p-1"
          @click="previousMeeting"
        >
          <inline-svg
            :src="require('assets/images/icons/arrow.svg')"
            class="size-2 fill-current text-pv-yellow"
            :class="{ 'opacity-40': !hasPreviousMeeting }"
          />
        </button>
      </div>
      <span class="text-xs text-blue-gray-200">
        Comentarios coliseo {{ formattedDate }}
      </span>
      <div
        v-tooltip="hasNextMeeting ? '' : 'este es el coliseo más reciente'"
        class="ml-2"
      >
        <button
          :disabled="!hasNextMeeting"
          class="flex items-center justify-center rounded-full border border-blue-gray-700 bg-pv-blue-700 p-1"
          @click="nextMeeting"
        >
          <inline-svg
            :src="require('assets/images/icons/arrow.svg')"
            class="size-2 rotate-180 fill-current text-pv-yellow"
            :class="{ 'opacity-40': !hasNextMeeting }"
          />
        </button>
      </div>
    </div>
    <div
      ref="scrollbar"
      class="flex grow flex-col overflow-y-scroll"
    >
      <div
        v-if="isQueryLoading"
        class="flex grow items-center justify-center"
      >
        <kalio-spinner class="size-10 self-center" />
      </div>
      <kalio-alert
        v-else-if="isFetchError"
        status="alert"
        title="Hubo un error cargando los comentarios."
        size="small"
        :deletable="false"
      />
      <div
        v-else-if="comments.length > 0"
        class="flex flex-col space-y-2"
      >
        <kalio-group-meetings-company-comment
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
        />
      </div>
      <span
        v-else
        class="self-center text-sm text-pv-gray"
      >
        No hay comentarios.
      </span>
    </div>
    <div class="relative">
      <button
        type="button"
        class="absolute -top-2 left-1/2 hidden -translate-x-1/2 items-center justify-center rounded-full border border-blue-gray-600 bg-pv-blue-700 p-1 hover:scale-105 hover:bg-blue-gray-600 md:flex"
        @click="inputsVisible = !inputsVisible"
      >
        <inline-svg
          :src="require('assets/images/icons/double-toggle.svg')"
          class="size-4 fill-current"
          :class="{ 'rotate-180': !inputsVisible }"
        />
      </button>

      <vee-form
        v-if="inputsVisible"
        class="flex flex-col space-y-4 p-2"
        @submit="submit"
      >
        <kalio-select-input
          v-model="selectedCompany"
          name="company"
          label="Startup"
          rules="required"
          variant="semi-dark"
          size="small"
          :options="companies"
          :get-option-label="(option) => option.name"
        />
        <kalio-trix-editor
          v-model="commentBody"
          name="comment"
          label="Comentario"
          description="Solo los partners y otros mentores del grupo podran ver los comentarios."
          rules="required"
          variant="semi-dark"
          class="w-80"
          size="small"
        />
        <div
          v-tooltip="currentMeeting.id === groupMeeting.id ?
            '' : 'Debes seleccionar el coliseo actual para poder comentar.'"
          class="flex w-full flex-col"
        >
          <kalio-button
            class="w-full sm:w-auto sm:self-center sm:px-24 md:w-full"
            label="Enviar"
            :loading="isMutationLoading"
            :disabled="currentMeeting.id !== groupMeeting.id"
          />
        </div>
      </vee-form>
    </div>
  </div>
</template>
