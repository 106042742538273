import api from './api';

export default {
  create({ email = null, name = null, allChannels = false } = {}) {
    return api({
      url: '/api/v1/slack_channel_invites',
      method: 'post',
      data: { email, name, allChannels },
    });
  },
};
