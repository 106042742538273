<script>

export default {
  name: 'application-qualification-badge',
  props: {
    qualification: { type: String, required: true },
  },
};
</script>

<template>
  <kalio-badge
    v-if="qualification"
    :text="$t(`startupApplication.qualifications.${qualification}`)"
    color="gray"
    with-border
  />
</template>
