<script>
import { ref } from 'vue';
import { useEventBus } from '@vueuse/core';
import { rewatchDemoDayPitchKey } from '../utils/keys.js';
import useVirtualRoundTimer from '../composables/virtual_round_timer.js';
import DemoDayFounderCard from './demo-day-founder-card.vue';

export default {
  name: 'demo-day-company-dashboard',
  components: { DemoDayFounderCard },
  props: {
    demoDayCompany: { type: Object, required: true },
    showSlide: { type: Boolean, default: true },
  },
  setup() {
    const video = ref(null);

    const eventBus = useEventBus(rewatchDemoDayPitchKey);
    eventBus.on(() => {
      video.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });

    const { virtualRoundTimer } = useVirtualRoundTimer();

    return {
      video,
      virtualRoundTimer,
    };
  },
};
</script>

<template>
  <div class="flex flex-col divide-y divide-blue-gray-600">
    <div class="pb-6">
      <slot name="card" />
    </div>
    <div class="flex flex-col pt-4">
      <span class="text-xl font-medium">
        Founders
      </span>
      <div class="mt-4 grid grid-cols-1 gap-x-12 gap-y-6 xl:grid-cols-2">
        <demo-day-founder-card
          v-for="founder in demoDayCompany.company.users"
          :key="founder.id"
          :founder="founder"
        />
      </div>
      <div
        v-if="virtualRoundTimer.demoDayFinished && demoDayCompany.pitchVideoUrl"
        ref="video"
        class="mt-14 flex flex-col space-y-4"
      >
        <span class="text-xl font-medium">
          {{ $t('demoDay.company.pitch') }}
        </span>
        <div class="relative mt-6 w-full overflow-hidden rounded-lg border border-blue-gray-700 bg-pv-blue-800 pt-[55.25%]">
          <iframe
            class="absolute inset-0 size-full"
            :src="demoDayCompany.pitchVideoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
      <div
        v-if="showSlide"
        class="mt-8 flex flex-col space-y-4"
      >
        <span class="text-xl font-medium">
          {{ $t('demoDay.company.keyPoints') }}
        </span>
        <div class="w-full overflow-hidden rounded-lg">
          <img
            :src="demoDayCompany.slideUrl"
            alt="key points"
            class="size-full object-cover"
          >
        </div>
      </div>
    </div>
  </div>
</template>

