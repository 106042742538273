<script>
import CutOutCornerCard from '../shared/cut-out-corner-card.vue';

export default {
  name: 'kalio-user-card',
  components: { CutOutCornerCard },
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    isCompanyInactive() {
      return this.user.company && !!this.user.company.inactiveSince;
    },
    userPath() {
      return `/people/${this.user.id}`;
    },
    companyPath() {
      return this.user.company?.profilePath;
    },
    companyName() {
      return this.user.company?.name;
    },
    batchSeason() {
      return this.user.company?.batch?.batchSeason;
    },
    position() {
      return this.user.founderProfile?.companyPosition;
    },
  },
};
</script>

<template>
  <a
    :href="userPath"
    class="flex w-full flex-row gap-4 rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4"
  >
    <cut-out-corner-card
      class="size-20 shrink-0"
      cut-size="24px"
      top-right-color="pv-blue-800"
      bottom-left-color="pv-blue-800"
    >
      <img
        class="size-full object-cover"
        :src="user.avatarUrl"
      >
    </cut-out-corner-card>
    <div class="mr-2 flex flex-col justify-around">
      <div class="flex flex-row flex-wrap items-center gap-x-2">
        <span class="text-xl font-medium md:text-2xl">
          {{ user.fullName }}
        </span>
        <span
          v-if="position"
          class="my-1 text-base text-blue-gray-200 md:text-xl"
        >
          ({{ position }})
        </span>
      </div>
      <div class="flex flex-row flex-wrap items-center gap-x-2.5">
        <a
          v-if="companyName"
          :href="companyPath"
          target="_blank"
        >
          <span class="text-center text-lg text-pv-gray hover:opacity-75 md:text-left">
            {{ companyName }}
          </span>
        </a>
        <span
          v-if="batchSeason"
          class="rounded-full bg-pv-yellow px-2 py-0.5 text-xs text-black"
        >
          {{ batchSeason }}
        </span>
        <span
          v-if="isCompanyInactive"
          class="rounded-full bg-pv-gray bg-opacity-20 px-2 py-0.5 text-xs text-pv-gray"
        >
          {{ $t('people.inactive') }}
        </span>
      </div>
    </div>
    <div class="-mr-2 ml-auto shrink-0 items-center self-center text-base text-pv-yellow hover:opacity-75 md:mr-0 md:flex md:self-start">
      <span class="hidden md:inline">
        {{ $t('people.goToProfile') }}
      </span>
      <inline-svg
        :src="require('assets/images/icons/arrow.svg')"
        class="ml-1 hidden w-5 rotate-180 fill-current md:inline"
      />
      <inline-svg
        :src="require('assets/images/icons/toggle-arrow.svg')"
        class="size-3 rotate-90 fill-current md:hidden"
      />
    </div>
  </a>
</template>
