<script setup>
import useVirtualRoundTimer from '../composables/virtual_round_timer.js';
import DemoDayBlurredAuctionInformation from './demo-day-blurred-auction-information.vue';

const props = defineProps({
  demoDayCompany: { type: Object, required: true },
});

const { virtualRoundTimer } = useVirtualRoundTimer();
</script>

<template>
  <div
    class="flex grow flex-col lg:w-72"
    v-bind="$attrs"
  >
    <span class="hidden text-base font-medium md:block">
      {{ $t('demoDay.virtualRound.roundInfo') }}
    </span>
    <demo-day-blurred-auction-information>
      <template #timer>
        <div class="mt-3 flex flex-row justify-between gap-x-2">
          <span class="shrink-0 ">
            {{ $t('demoDay.virtualRound.auction.startsIn') }}
          </span>
          <div class="flex flex-row items-center space-x-2">
            <span class="text-right font-medium">
              {{ virtualRoundTimer.auctionStartsIn }}
            </span>
            <inline-svg
              :src="require('assets/images/icons/simple-clock.svg')"
              class="size-4 stroke-current"
            />
          </div>
        </div>
      </template>
    </demo-day-blurred-auction-information>
    <span
      v-if="!virtualRoundTimer.auctionStarted"
      class="mt-2 self-center text-sm text-pv-gray"
    >
      {{ $t('demoDay.virtualRound.auction.hasNotStarted') }}
    </span>
    <div class="mt-6 flex flex-col space-y-3">
      <kalio-button
        :label="$t('demoDay.virtualRound.invest')"
        :disabled="true"
      />
      <kalio-button
        :label="$t('demoDay.virtualRound.scheduleMeeting')"
        variant="tertiary"
        :disabled="true"
      />
    </div>
  </div>
</template>
