<script setup>
import { ref, computed, onUpdated } from 'vue';
import { useEventBus } from '@vueuse/core';
import useLikes from '@/hooks/likes.js';
import { getCountryName } from '@/utils/countries.js';
import { rewatchDemoDayPitchKey } from '../utils/keys.js';

const props = defineProps({
  demoDayCompany: { type: Object, required: true },
  shouldOpenAccordionOnRewatchEvent: { type: Boolean, default: false },
});

const isAccordionOpen = ref(false);

const eventBus = useEventBus(rewatchDemoDayPitchKey);
eventBus.on(() => {
  if (props.shouldOpenAccordionOnRewatchEvent) {
    isAccordionOpen.value = true;
  }
});

const company = computed(() => props.demoDayCompany.company);
const { companyHaslike } = useLikes(company);

const industries = computed(() => company.value.industries.join(', '));

const blurb = ref(null);

onUpdated(() => {
  blurb.value.innerHTML = blurb.value.innerHTML.replace(
    /\[redacted\]/g,
    '<span class="blur-[6px]">[redacted]</span>',
  );
});

</script>

<template>
  <div class="flex flex-col">
    <button
      class="flex flex-row bg-pv-blue-900 p-4 focus:outline-none"
      @click="isAccordionOpen = !isAccordionOpen"
    >
      <div class="mx-4 flex flex-col items-start gap-y-3 text-left">
        <div class="flex flex-row gap-x-2">
          <div class="relative size-[72px] shrink-0">
            <img
              :src="company.isotypeUrl"
              :alt="`${company.name} isotype`"
              class="size-full rounded-lg object-cover"
            >
            <inline-svg
              v-if="companyHaslike"
              :src="require('assets/images/icons/solid/heart.svg')"
              class="absolute -right-1 -top-1 size-4 fill-current text-red-400"
            />
          </div>
          <div class="flex flex-col gap-y-1">
            <span class="text-lg font-medium">
              {{ company.name }}
            </span>
            <div class="flex flex-row items-center gap-x-2">
              <country-flag
                :country="company.country.toLowerCase()"
                size="small"
              />
              <span class="text-xs">
                {{ getCountryName(company.country) }}
              </span>
            </div>
            <div class="flex flex-row items-center gap-x-2">
              <inline-svg
                :src="require('assets/images/icons/briefcase.svg')"
                class="size-3.5 shrink-0 fill-current"
              />
              <p class="mt-0.5 text-xs">
                {{ industries }}
              </p>
            </div>
          </div>
        </div>
        <p
          ref="blurb"
          class="text-xs leading-6"
        >
          {{ demoDayCompany.blurb }}
        </p>
      </div>
      <span class="ml-auto self-center">
        <inline-svg
          :src="require('assets/images/icons/toggle-arrow.svg')"
          class="size-4 shrink-0 fill-current text-pv-gray transition-transform duration-200 ease-in-out"
          :class="isAccordionOpen ? 'rotate-0' : 'rotate-180'"
        />
      </span>
    </button>
    <div
      class="w-full overflow-hidden"
      :class="isAccordionOpen ? 'max-h-max' : 'max-h-0'"
    >
      <slot />
    </div>
  </div>
</template>
