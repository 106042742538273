<script>
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import demoDayInvestorApplicationsApi from '../api/demo_day_investor_applications';
import DemoDayInternalInvestorApplicationsList from '../components/internal/demo-day-investor-applications-list.vue';
import DemoDayInternalInvestorApplicationReview from '../components/internal/demo-day-investor-application-review.vue';

export default {
  name: 'demo-day-internal-investor-applications-review',
  components: {
    DemoDayInternalInvestorApplicationsList,
    DemoDayInternalInvestorApplicationReview,
    KalioSpinner,
  },
  props: {
    currentBatchName: { type: String, required: false, default: null },
    currentDemoDay: { type: Object, required: true, default: null },
  },
  data() {
    return {
      selectedFilteredIndex: 0,
      allApplications: [],
      filteredApplications: [],
      sending: false,
      loading: true,
    };
  },
  computed: {
    selectedApplication() {
      if (this.filteredApplications.length === 0) return null;

      return this.filteredApplications[this.selectedFilteredIndex];
    },
    selectedApplicationIndex() {
      return this.allApplications.findIndex(
        (application) => application.id === this.selectedApplication.id,
      );
    },
  },
  mounted() {
    this.getInvestorApplications();
  },
  methods: {
    getInvestorApplications() {
      this.loading = true;
      demoDayInvestorApplicationsApi.getAll(this.currentDemoDay.id)
        .then((response) => {
          this.allApplications = [...response.demoDayInvestorApplications];
          this.loading = false;
        });
    },
    prev() {
      this.selectedFilteredIndex = this.selectedFilteredIndex > 0 ?
        this.selectedFilteredIndex - 1 :
        this.filteredApplications.length - 1;
    },
    next() {
      this.selectedFilteredIndex = (this.selectedFilteredIndex + 1) % (this.filteredApplications.length);
    },
    selectApplication(selectedApplication) {
      this.selectedFilteredIndex = this.filteredApplications.findIndex(
        (application) => application.id === selectedApplication.id,
      );
    },
    filterApplications(newFilteredApplications) {
      this.filteredApplications = newFilteredApplications;
      this.selectedFilteredIndex = 0;
    },
    updateApplication(updatedApplication) {
      this.allApplications[this.selectedApplicationIndex] = updatedApplication;
      this.filteredApplications[this.selectedFilteredIndex] = updatedApplication;
    },
    commentCreated(comment) {
      this.allApplications[this.selectedApplicationIndex].comments = [
        ...this.allApplications[this.selectedApplicationIndex].comments,
        comment,
      ];
    },
  },
};

</script>

<template>
  <div
    v-if="loading"
    class="flex h-10 items-center justify-center"
  >
    <kalio-spinner />
  </div>
  <div
    v-else
    class="grid grid-cols-5 gap-4"
  >
    <demo-day-internal-investor-applications-list
      class="col-span-2"
      :all-applications="allApplications"
      :current-batch-name="currentBatchName"
      @select-application="selectApplication"
      @filter-applications="filterApplications"
    />
    <div class="col-span-3 flex flex-col items-center space-y-4 rounded-lg bg-pv-blue-900 p-6">
      <template v-if="Boolean(selectedApplication)">
        <div class="flex flex-row items-center space-x-10">
          <button
            class="rounded-xl bg-gray-700 p-1"
            @click="prev()"
          >
            <inline-svg
              :src="require('assets/images/arrow-left.svg')"
              class="size-3 fill-pv-white"
            />
          </button>
          <label class="text-sm font-medium text-blue-gray-300">
            ({{ selectedFilteredIndex + 1 }} / {{ filteredApplications.length }})
          </label>
          <button
            class="rounded-xl bg-gray-700 p-1"
            @click="next()"
          >
            <inline-svg
              :src="require('assets/images/arrow-right.svg')"
              class="size-3 fill-pv-white"
            />
          </button>
        </div>
        <demo-day-internal-investor-application-review
          v-if="Boolean(selectedApplication)"
          :key="selectedApplication.id"
          :application="selectedApplication"
          @on-change="updateApplication"
          @comment-created="commentCreated"
        />
      </template>
      <span v-else>
        Ninguna postulación cumple con los filtros
      </span>
    </div>
  </div>
</template>
