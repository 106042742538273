import api from '@/api/api';
export const qualificationsApi = {
    create(buildSprintQualification) {
        const path = '/build_sprint/internal/api/v1/build_sprint_qualifications';
        return api({
            method: 'post',
            data: { buildSprintQualification },
            url: path,
        });
    },
    update(id, buildSprintQualification) {
        const path = `/build_sprint/internal/api/v1/build_sprint_qualifications/${id}`;
        return api({
            method: 'patch',
            data: { buildSprintQualification },
            url: path,
        });
    },
};
