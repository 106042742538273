<script>
import { ref, toRefs, inject } from 'vue';
import useFormSection from '../../hooks/form-section';
import emitter from '../../utils/emitter.js';

export default {
  name: 'kalio-form-section',
  props: {
    title: { type: String, required: true },
    description: { type: String, default: null },
    sectionName: { type: String, default: null },
    scrollOptions: {
      type: Object,
      default: () => ({ behavior: 'smooth', block: 'center' }),
    },
    flexRowAllowed: { type: Boolean, default: false },
  },
  setup(props) {
    const section = ref(null);

    if (props.sectionName) {
      const { sectionName, scrollOptions } = toRefs(props);
      const { form, scrollSectionIntoView } = useFormSection({ section, sectionName, scrollOptions });

      emitter.on('scroll-to', (name) => scrollSectionIntoView(name));
      const registerForm = inject('registerForm');
      registerForm(form);
    }

    return {
      section,
    };
  },
};
</script>

<template>
  <div
    ref="section"
    class="flex flex-col divide-y-2 divide-blue-gray-400"
    :class="{
      'justify-between md:flex-row md:gap-x-8 md:space-y-0 md:divide-y-0': flexRowAllowed
    }"
  >
    <div class="flex flex-col pb-2">
      <span class="text-xl font-medium">
        {{ title }}
      </span>
      <p
        v-if="description"
        class="mt-3 text-pv-gray"
      >
        {{ description }}
      </p>
    </div>
    <div
      class="flex w-full flex-col space-y-4 pt-5 sm:pt-7"
      :class="{ 'md:py-0': flexRowAllowed }"
    >
      <slot />
    </div>
  </div>
</template>
