import api from '@/api/api';

export default {
  update(id, demoDayInvestorAttendance) {
    const path = `/api/v1/demo_day/demo_day_investor_attendances/${id}`;

    return api({
      method: 'patch',
      url: path,
      data: { demoDayInvestorAttendance },
    });
  },
};
