<script setup>
import { computed } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'yellow',
    validator: (value) => ['yellow', 'white'].includes(value),
  },
  transparent: { type: Boolean, default: false },
  size: { type: String, default: 'base' },
});

const circleClasses = computed(() => {
  const colorClasses = {
    yellow: 'bg-pv-yellow',
    white: 'bg-blue-gray-50',
  };

  const sizeClasses = {
    sm: 'w-5 h-5 p-1',
    md: 'w-8 h-8 p-1',
    base: 'w-14 h-14 p-3',
  };

  return `${props.transparent ? '' : colorClasses[props.variant]} ${sizeClasses[props.size]}`;
});

const checkClasses = computed(() => {
  const colorClasses = {
    yellow: 'text-blue-gray-50',
    white: 'text-pv-blue-700',
  };

  return `${colorClasses[props.variant]}`;
});
</script>

<template>
  <div
    class="flex shrink-0 items-center justify-center rounded-full"
    :class="circleClasses"
  >
    <inline-svg
      :src="require('assets/images/icons/check.svg')"
      class="size-full fill-current"
      :class="{ [checkClasses]: !transparent }"
    />
  </div>
</template>
