<script setup>
</script>

<template>
  <div>
    <iframe
      src="https://metabase-kalio.herokuapp.com/public/dashboard/91b6e4d8-39ee-4994-9167-b249568381ce"
      frameborder="0"
      class="h-screen w-full"
      allowtransparency
    />
  </div>
</template>
