<script>
import { inject } from 'vue';
import KalioUserCard from '../people/kalio-user-card.vue';
import KalioHeader from '../shared/kalio-header.vue';
import KalioListWithFiltersAndSearchbar from '../shared/kalio-list-with-filters-and-searchbar.vue';
import { screenKey } from '../../utils/keys.js';

export default {
  name: 'KalioUsers',
  components: {
    KalioUserCard,
    KalioHeader,
    KalioListWithFiltersAndSearchbar,
  },
  props: {
    users: { type: Array, required: true },
    query: { type: String, required: true },
    batches: { type: Array, required: true },
    companies: { type: Array, required: true },
    industries: { type: Array, required: true },
  },
  setup() {
    return {
      screen: inject(screenKey),
    };
  },
  computed: {
    filters() {
      return {
        batch: {
          label: this.$t('list.filters.batch'),
          itemKey: 'company.batch.id',
          options: this.batches.map(batch => ({ value: batch.id, label: batch.batchSeason })),
          type: 'multiselect',
        },
        company: {
          label: this.$t('list.filters.company'),
          itemKey: 'company.id',
          options: this.companies.map(company => ({ value: company.id, label: company.name })),
          type: 'multiselect',
        },
        industry: {
          label: this.$t('list.filters.industry'),
          itemKey: 'companiesIndustryIds',
          options: this.industries.map(industry => ({ value: industry.id, label: industry.name })),
          compareFunction: (filterValue, itemValue) => itemValue.includes(filterValue),
          type: 'multiselect',
        },
      };
    },
  },
};
</script>

<template>
  <div class="flex w-full max-w-lg flex-col justify-between space-y-6 md:max-w-6xl">
    <kalio-header
      icon="brand/platanus-isotype.svg"
      title="Comunidad Platanus"
      :size="screen.isMobile || screen.isTablet ? 'medium' : 'big'"
    />
    <kalio-list-with-filters-and-searchbar
      :items="users"
      :query="query"
      search-path="/people"
      :filters="filters"
    >
      <template #item="{ item }">
        <kalio-user-card :user="item" />
      </template>
    </kalio-list-with-filters-and-searchbar>
  </div>
</template>
