<script>
import { ref, computed, inject } from 'vue';
import moment from 'moment';
import { currentUserKey } from '../../utils/keys.js';

export default {
  name: 'kalio-group-meetings-company-comment',
  props: {
    comment: { type: Object, required: true },
  },
  setup(props) {
    const dynamicComment = ref(props.comment);

    const formattedDate = computed(() => moment(dynamicComment.value.createdAt).format('DD/MM/YYYY - HH:MM'));

    const currentUser = inject(currentUserKey);
    const isAuthor = computed(() => currentUser.value.id === dynamicComment.value.author.id);

    return {
      dynamicComment,
      formattedDate,
      isAuthor,
    };
  },
};
</script>

<template>
  <div class="flex flex-col rounded-lg border border-pv-blue-700 bg-pv-blue-800 px-4 py-3">
    <div class="flex flex-row items-center gap-x-2 text-xs font-thin text-blue-gray-400">
      <div class="size-8 overflow-hidden rounded-full">
        <img
          class="size-full object-cover"
          :src="dynamicComment.author.avatarUrl"
        >
      </div>
      <span class="italic">
        {{ isAuthor ? 'Tú' : dynamicComment.author.fullName }}
      </span>
      <span class="ml-auto">
        {{ formattedDate }}
      </span>
    </div>
    <div
      class="mt-4 overflow-scroll text-sm text-cool-gray-50"
      v-html="dynamicComment.body"
    />
  </div>
</template>
