<script>
import humps from 'humps';
import KalioSocialMediaIcons from '../shared/kalio-social-media-icons.vue';

const NUMBER_OF_ISOTYPES_TO_SHOW = 3;

export default {
  components: {
    KalioSocialMediaIcons,
  },
  props: {
    investmentFirm: { type: Object, required: true },
    link: { type: Boolean, default: true },
  },
  computed: {
    hasSocialMedia() {
      if (!this.investmentFirm.socialMedia) return false;

      return Object.values(this.investmentFirm.socialMedia).filter(socialMedia => socialMedia).length > 0;
    },
    investmentStages() {
      if (!this.investmentFirm.investmentStages) return [];

      return this.investmentFirm.investmentStages.map(
        (investmentStage) => ({ ...investmentStage, stage: humps.camelize(investmentStage.stage) }),
      );
    },
    socialMedia() {
      return this.investmentFirm.socialMedia;
    },
    isotypesToShow() {
      return this.investmentFirm.companiesInvestedIsotypes.slice(0, NUMBER_OF_ISOTYPES_TO_SHOW);
    },
    extraInvestedCompanies() {
      return this.isotypesToShow.length > NUMBER_OF_ISOTYPES_TO_SHOW ? (
        this.investmentFirm.companiesInvestedIsotypes.length - NUMBER_OF_ISOTYPES_TO_SHOW
      ) : 0;
    },
    totalReviews() {
      return this.investmentFirm.investorReviews?.length || 0;
    },
    totalInvestments() {
      return this.investmentFirm.companiesInvestedIsotypes?.length || 0;
    },
    investmentFirmPath() {
      return `investment_firms/${this.investmentFirm.id}`;
    },
  },
};
</script>

<template>
  <div class="flex flex-col space-y-6 rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4">
    <div class="flex flex-col justify-between space-y-2 md:flex-row md:space-y-0">
      <div class="flex space-x-3">
        <div class="flex flex-col">
          <span class="text-xl font-medium">
            {{ investmentFirm.fullName }}
          </span>
          <a
            v-if="investmentFirm.webpage"
            :href="investmentFirm.webpage"
            class="text-sm text-blue-300 hover:text-blue-200"
            target="_blank"
          >
            {{ investmentFirm.webpage }}
          </a>
          <kalio-social-media-icons
            v-if="hasSocialMedia"
            :social-media="socialMedia"
            icon-classes="h-5 w-5 mr-2 text-xs"
          />
          <div class="mt-1 flex flex-col space-y-1 text-xs text-blue-gray-200">
            <span v-if="investmentFirm.kind">
              {{ $t(`investors.kind.${investmentFirm.kind}`) }}
            </span>
            <span v-if="investmentFirm.leadRounds">
              💪 {{ $t("investors.leadRounds") }}
            </span>
            <span v-if="investmentFirm.leadRounds === false">
              {{ $t("investors.doNotLeadRounds") }}.
            </span>
            <span v-if="investmentFirm.ticketMinAmount !== null && investmentFirm.ticketMaxAmount!== null">
              💵 {{
                $t(
                  'investors.ticketsBetween',
                  {
                    min: $filters.toCurrency(investmentFirm.ticketMinAmount, { notation: 'compact' }),
                    max: $filters.toCurrency(investmentFirm.ticketMaxAmount, { notation: 'compact' })
                  }
                )
              }}.
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:text-right">
        <span
          v-if="totalReviews > 0"
          class="text-lg font-medium"
        >
          Calificación {{ investmentFirm.avgInvestorLetter }}
        </span>
        <span
          v-else
          class="text-lg font-medium"
        >
          Sin calificación
        </span>
        <span class="text-xs text-cool-gray-300">
          {{ totalReviews }} reviews
        </span>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <div class="flex flex-wrap gap-2.5">
        <div
          v-if="investmentFirm.country"
          class="flex items-center self-start whitespace-nowrap rounded-full bg-pv-light-blue/10 px-2 py-0.5 text-sm text-blue-400"
        >
          <country-flag
            :country="investmentFirm.country.code.toLowerCase()"
            size="small"
          />
          <span class="ml-1 text-sm">
            {{ investmentFirm.country.name }}
          </span>
        </div>
        <span
          v-for="(investmentStage, index) in investmentStages"
          :key="index"
          class="self-start whitespace-nowrap rounded-full bg-pv-light-blue/10 px-2 py-0.5 text-sm text-blue-400"
        >
          {{ $t(`investors.stages.${investmentStage.stage}`) }}
        </span>
      </div>
      <a
        v-if="link"
        :href="investmentFirmPath"
        target="_blank"
        class="flex shrink-0 cursor-pointer items-center text-base text-pv-yellow hover:opacity-75 md:mr-0"
      >
        <span class="text-base font-light">
          {{ $t('people.goToProfile') }}
        </span>
        <inline-svg
          :src="require('assets/images/icons/arrow.svg')"
          class="ml-1 hidden w-5 rotate-180 fill-current md:inline"
        />
        <inline-svg
          :src="require('assets/images/icons/toggle-arrow.svg')"
          class="ml-1 size-3 rotate-90 fill-current md:hidden"
        />
      </a>
    </div>
  </div>
</template>
