<script setup>
import { computed } from 'vue';
import KalioSpinner from './kalio-spinner';

const props = defineProps({
  index: { type: Number, required: true },
  message: { type: String, required: true },
  type: { type: String, required: true },
  component: { type: [Object, null], required: true },
  duration: { type: Number, required: true },
  icon: { type: [String, null], required: true },
});
const containerClasses = computed(() => {
  const colorClasses = {
    success: 'bg-blue-gray-50 border border-green-400',
    info: 'bg-blue-gray-50',
    'semi-dark': 'bg-pv-blue-800 border border-blue-gray-50',
    error: 'bg-red-50 border border-red-400',
    loading: 'bg-blue-gray-50 border border-blue-gray-400',
  };

  return colorClasses[props.type];
});

const messageClasses = computed(() => {
  const colorClasses = {
    success: 'text-blue-gray-800',
    info: 'text-black',
    'semi-dark': 'text-blue-gray-50',
    error: 'text-red-400',
    loading: 'text-black',
  };

  return colorClasses[props.type];
});

</script>

<template>
  <div
    class="flex rounded-lg px-3 py-2 shadow-[0_45px_60px_-15px_rgba(0,0,0,8)] sm:px-4 sm:py-3"
    :class="containerClasses"
  >
    <component
      :is="component"
      v-if="component"
    />
    <div
      v-else
      class="flex flex-row items-center"
    >
      <inline-svg
        v-if="icon"
        :src="require(`assets/images/icons/${icon}`)"
        class="mr-2 fill-current"
      />
      <kalio-spinner
        v-else-if="type === 'loading'"
        class="mr-3 size-3"
      />
      <span
        class="text-sm sm:text-base"
        :class="messageClasses"
      >
        {{ message }}
      </span>
    </div>
  </div>
</template>
