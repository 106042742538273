<script>
import emitter from '../../utils/emitter.js';

export default {
  data() {
    return {
      loading: false,
      open: true,
    };
  },
  computed: {
    sections() {
      return this.$store.state.form.sections;
    },
    currentSectionName() {
      return this.$store.getters.currentSection?.name;
    },
  },
  methods: {
    submit() {
      this.loading = true;
      emitter.emit('submit');
    },
    valid(section) {
      return this.$store.getters.validSection(section);
    },
    emitScroll(sectionName) {
      emitter.emit('scroll-to', sectionName);
    },
  },
};
</script>

<template>
  <div class="flex flex-col items-center space-y-8 rounded-l-lg bg-pv-blue-900 py-6">
    <div class="flex w-full flex-col">
      <button
        v-for="section in sections"
        :key="`${section.name}`"
        class="border-l focus:outline-none"
        :class="section.name === currentSectionName ?
          'bg-pv-blue-700 border-pv-yellow' : 'hover:bg-pv-blue-800 border-transparent'"
        @click="emitScroll(section.name)"
      >
        <span class="flex flex-row items-center pr-4">
          <span class="whitespace-nowrap px-8 py-2 text-base font-medium capitalize">
            {{ section.name }}
          </span>
          <span
            v-if="valid(section)"
            class="ml-auto flex size-4 items-center justify-center rounded-full border border-pv-yellow bg-pv-yellow bg-opacity-40"
          >
            <inline-svg
              class="size-2.5 fill-current text-pv-yellow"
              :src="require('assets/images/icons/check.svg')"
            />
          </span>

        </span>
      </button>
    </div>
    <kalio-button
      label="Guardar avance"
      :loading="loading"
      size="small"
      class="my-6"
      @click="submit"
    />
  </div>
</template>
