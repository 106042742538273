<script setup>
import { ref, computed } from 'vue';
import KalioContactModal from '@/components/shared/kalio-contact-modal.vue';
import libraryItemRecommendationsApi from '@/api/library_item_recommendations';
import { capitalize } from '@/filters';
import { useCreateOfficeHourRequestMutation, useUpdateOfficeHourRequestMutation } from '@/queries/office_hour_requests';
import { useUIStore } from '@/stores';
import KalioEmailOfficeHour from './kalio-email-office-hour.vue';
import KalioSlackOfficeHour from './kalio-slack-office-hour.vue';
import KalioCalendlyOfficeHour from './kalio-calendly-office-hour.vue';
import KalioPlatanusCalOfficeHour from './kalio-platanus-cal-office-hour.vue';

const props = defineProps({
  offering: { type: Object, required: true },
});

const emit = defineEmits(['submit', 'close', 'error']);
const uiStore = useUIStore();

const STEPS = [
  { step: 'subject', title: 'Asunto de tu Office Hour', icon: 'solid/pencil-square.svg' },
  { step: 'recommendations', title: 'Recursos Relacionados', icon: 'book.svg' },
  { step: 'select-method', title: 'Selecciona un método de contacto', icon: 'solid/dots-horizontal.svg' },
  { step: 'specific-method', title: '', icon: '' },
];

const METHODS = {
  slack: { icon: 'social-media/slack.svg', title: 'Comunícate por Slack' },
  email: { icon: 'social-media/email.svg', title: 'Enviar Email' },
  calendly: { icon: 'calendar.svg', title: 'Agendar una reunión' },
  'platanus_cal': { icon: 'calendar.svg', title: 'Agendar una reunión' },
};

function getRandomPlaceholder() {
  const placeholders = [
    'Necesito ayuda con growth hacking',
    'Necesito ayuda con la arquitectura de mi app',
    'Necesito ayuda para contratar a mi primer Dev',
    'Necesito ayuda para levantar capital',
  ];
  const randomIndex = Math.floor(Math.random() * placeholders.length);

  return placeholders[randomIndex];
}

const stepIndex = ref(0);
const currentRequestId = ref(null);
const recommendations = ref([]);
const isLoadingRecommendations = ref(false);
const placeholder = ref(getRandomPlaceholder());
const subject = ref('');
const selectedMethod = ref(null);
const stepInfo = computed(() => STEPS[stepIndex.value]);
const methodInfo = computed(() => selectedMethod.value && METHODS[selectedMethod.value.kind]);
const currentStep = computed(() => stepInfo.value.step);
const user = computed(() => props.offering.user);

const currentTitle = computed(() => {
  if (currentStep.value === 'specific-method') return methodInfo.value.title;

  return stepInfo.value.title;
});

const currentIcon = computed(() => {
  if (currentStep.value === 'specific-method') return methodInfo.value.icon;

  return stepInfo.value.icon;
});

const calendlyUrl = computed(() => {
  const calendlyMethod = props.offering.contactMethods.find(method => method.kind === 'calendly');

  return calendlyMethod ? calendlyMethod.url : null;
});

const platanusCalUrl = computed(() => {
  const platanusCalMethod = props.offering.contactMethods.find(method => method.kind === 'platanus_cal');

  return platanusCalMethod ? platanusCalMethod.url : null;
});

function nextStep() {
  stepIndex.value += 1;
}

function prevStep() {
  stepIndex.value -= 1;
}

async function loadRecommendations() {
  isLoadingRecommendations.value = true;
  const libraryItems = await libraryItemRecommendationsApi.getAll(currentRequestId.value);
  recommendations.value = libraryItems;
  isLoadingRecommendations.value = false;
}

const { mutate: createOfficeHourRequest, isLoading: isCreateLoading } =
  useCreateOfficeHourRequestMutation(props.offering.id, {
    onSuccess: async (response) => {
      if (currentStep.value === 'subject') {
        currentRequestId.value = response.id;
        await loadRecommendations();
        nextStep();
      }
    },
    onError: () => emit('error'),
  });

const { mutate: updateOfficeHourRequest, mutateAsync: updateOfficeHourRequestAsync, isLoading: isUpdateLoading } =
  useUpdateOfficeHourRequestMutation({
    onSuccess: async () => {
      if (currentStep.value === 'subject') {
        await loadRecommendations();
        nextStep();

        return;
      }
    },
  });

function onSubjectSubmit() {
  if (currentRequestId.value) {
    updateOfficeHourRequest({ id: currentRequestId.value, subject: subject.value });
  } else {
    createOfficeHourRequest({ subject: subject.value });
  }
}

function markAsSolved() {
  updateOfficeHourRequest({ id: currentRequestId.value, event: 'mark_as_solved' });
  emit('close');
}

function contactMethodText(method) {
  return method && method.split('_')
    .map(word => capitalize(word))
    .join(' ');
}

function selectMethod(method) {
  updateOfficeHourRequest({ id: currentRequestId.value, contactMethod: method.kind });
  if (method.kind === 'other_link') {
    window.open(method.url);
  } else {
    selectedMethod.value = method;
    nextStep();
  }
}

function showModalAndExit(message) {
  uiStore.toast({
    message,
    position: 'top',
    type: 'success',
  });
  emit('close');
}

async function submit(data) {
  await updateOfficeHourRequestAsync({
    id: currentRequestId.value,
    requestData: data,
    event: 'mark_as_scheduled',
  });
  emit('submit', data);
  showModalAndExit('¡Listo!');
}
</script>

<template>
  <kalio-contact-modal
    :icon="currentIcon"
    :title="currentTitle"
    @close="emit('close')"
  >
    <kalio-back-button
      v-if="stepIndex > 0"
      class="mb-2"
      @click="prevStep"
    />

    <div v-if="currentStep === 'subject'">
      <vee-form @submit="onSubjectSubmit">
        <div class="flex flex-col items-center gap-5">
          <kalio-long-text-input
            v-model="subject"
            name="subject"
            rules="required"
            :placeholder="placeholder"
            label="Escribe el motivo de tu Office Hour"
          />

          <kalio-button
            :loading="isCreateLoading || isUpdateLoading || isLoadingRecommendations"
            type="submit"
            label="Continuar"
          />
        </div>
      </vee-form>
    </div>
    <div v-if="currentStep === 'recommendations'">
      <div class="mt-2 flex flex-col items-center gap-4">
        <p>
          Estos recursos pueden serte útiles para resolver tu duda
        </p>
        <div class="flex w-full flex-col gap-2">
          <kalio-button
            v-for="(recommendation, index) in recommendations"
            :key="index"
            :href="recommendation.url"
            target="_blank"
            variant="tertiary"
          >
            <div class="flex h-16 w-full flex-col justify-between">
              <p class="w-full truncate text-pv-yellow">
                {{ recommendation.name }}
              </p>
              <p class="truncate text-sm">
                {{ recommendation.description }}
              </p>
              <p class="text-xs text-pv-gray">
                {{ recommendation.author }}
              </p>
            </div>
          </kalio-button>
        </div>

        <p class="mt-2">
          ¿Pudiste solucionar tu duda con los recursos?
        </p>

        <div class="flex w-64 flex-col gap-2">
          <kalio-button
            label="Sí, gracias"
            @click="markAsSolved"
          />

          <kalio-button
            label="No, continuar con la Office Hour"
            variant="secondary"
            @click="nextStep"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="currentStep === 'select-method'"
      class="flex flex-col items-center space-y-2 p-4"
    >
      <button
        v-for="(method, index) in offering.contactMethods"
        :key="index"
        class="flex w-64 items-center justify-center rounded-xl border border-blue-gray-700 py-2 text-lg capitalize hover:bg-pv-blue-800 focus:outline-none sm:w-80"
        @click="selectMethod(method)"
      >
        {{ contactMethodText(method.kind) }}
      </button>
    </div>
    <template v-else-if="currentStep === 'specific-method'">
      <kalio-email-office-hour
        v-if="selectedMethod.kind === 'email'"
        :email="user.email"
        :subject="subject"
      />
      <kalio-slack-office-hour
        v-else-if="selectedMethod.kind === 'slack'"
        :is-submitting="isUpdateLoading"
        :subject="subject"
        @office-hour-requested="submit"
      />
      <kalio-calendly-office-hour
        v-else-if="selectedMethod.kind === 'calendly'"
        :calendly-url="calendlyUrl"
        @office-hour-requested="submit"
      />
      <kalio-platanus-cal-office-hour
        v-else-if="selectedMethod.kind === 'platanus_cal'"
        :platanus-cal-url="platanusCalUrl"
        :subject="subject"
        :office-hour-request-id="currentRequestId"
      />
    </template>
  </kalio-contact-modal>
</template>
