import api from '../api';

export default {
  get({ resourceType, resourceId, tagsOn }) {
    const path = `/internal/api/v1/tags/${resourceType}/${resourceId}`;

    return api({
      method: 'get',
      params: { tagsOn },
      url: path,
    });
  },
  getAll({ resourceType, tagsOn }) {
    const path = `/internal/api/v1/tags/${resourceType}`;

    return api({
      method: 'get',
      params: { tagsOn },
      url: path,
    });
  },
  create({ resourceId, resourceType, tagsOn, tag }) {
    const path = `/internal/api/v1/tags/${resourceType}/${resourceId}`;

    return api({
      method: 'post',
      data: { tagsOn, tag },
      url: path,
    });
  },
  delete({ resourceId, resourceType, tagsOn, tag }) {
    const path = `/internal/api/v1/tags/${resourceType}/${resourceId}`;

    return api({
      method: 'delete',
      data: { tagsOn, tag },
      url: path,
    });
  },
};
