import api from '@/api/api';

export default {
  create(buildSprintUpdate) {
    const path = '/build_sprint/api/v1/updates';

    return api({
      method: 'post',
      data: { buildSprintUpdate },
      url: path,
    });
  },
  update(id, buildSprintUpdate) {
    const path = `/build_sprint/api/v1/updates/${id}`;

    return api({
      method: 'patch',
      data: { buildSprintUpdate },
      url: path,
    });
  },
};
