<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import BaseInformationCard from '@/components/shared/base-information-card.vue';

const props = defineProps({
  groupMeeting: { type: Object, default: null },
  company: { type: Object, required: true },
  batch: { type: Object, required: true },
});

const weeklyUpdateSubmitted = computed(() => props.company.weeklyUpdateSubmitted);
</script>

<template>
  <base-information-card title="Próximos coliseos">
    <div
      v-if="groupMeeting"
      class="flex flex-col space-y-5 lg:flex-row lg:items-center lg:space-x-6 lg:space-y-0"
    >
      <div class="mt-4 flex shrink-0 flex-row space-x-3 lg:mt-0">
        <div class="flex w-16 flex-col items-center justify-center rounded-lg border border-blue-gray-700 bg-blue-gray-800 sm:w-24">
          <span class="text-sm capitalize sm:text-base">
            {{ format(new Date(groupMeeting.startDate), 'LLL', { locale: es }) }}
          </span>
          <span class="text-xl font-medium sm:text-2xl">
            {{ format(new Date(groupMeeting.startDate), 'd') }}
          </span>
        </div>
        <div class="flex flex-col space-y-0.5 sm:space-y-2">
          <span class="font-medium text-blue-gray-50">
            {{ groupMeeting.name }}
          </span>
          <span class="text-sm text-pv-gray">
            {{ groupMeeting.meetingDuration }}
          </span>
          <a
            :href="`/group_meetings/${groupMeeting.id}`"
            class="flex flex-row items-center space-x-2 text-pv-yellow hover:opacity-75"
          >
            <span class="text-xs">
              Ir a la reunión
            </span>
            <inline-svg
              :src="require('assets/images/icons/arrow.svg')"
              class="h-auto w-3 rotate-180 fill-current"
            />
          </a>
        </div>
      </div>
      <div class="hidden h-20 w-px bg-blue-gray-700 lg:flex" />
      <div class="h-px w-full bg-blue-gray-700 lg:hidden" />
      <div class="flex flex-col space-y-2">
        <span class="text-blue-gray-200">
          Tareas por realizar
        </span>
        <div
          v-if="groupMeeting.tasksAvailable"
          class="flex flex-col"
        >
          <component
            :is="weeklyUpdateSubmitted ? 'div' : 'a'"
            v-if="batch.inWeeklyUpdatesPeriod"
            href="/weekly_updates/new"
            class="flex flex-row items-center"
          >
            <div
              class="rounded-full border"
              :class="weeklyUpdateSubmitted ? 'border-emerald-400' : 'border-red-400'"
            >
              <inline-svg
                class="size-4 fill-current p-0.5"
                :src="require(`assets/images/icons/${weeklyUpdateSubmitted ? 'check' : 'close'}.svg`)"
              />
            </div>
            <span class="ml-2 text-pv-gray">
              Reporte semanal
            </span>
            <inline-svg
              v-if="!weeklyUpdateSubmitted"
              class="ml-2 mt-0.5 size-2.5 shrink-0 rotate-90 fill-current text-pv-yellow"
              :src="require('assets/images/icons/toggle-arrow.svg')"
            />
          </component>
        </div>
        <span
          v-else
          class="text-sm italic"
        >
          Podrás realizar las tareas previas a la reunión a partir del
          {{ format(new Date(groupMeeting.tasksAvailableDate), "d 'de' LLLL", { locale: es }) }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="flex items-center justify-center rounded-lg bg-pv-blue-700 px-8 py-6"
    >
      <span class="text-xs italic text-pv-gray sm:text-base">
        No tienes reuniones agendadas todavía
      </span>
    </div>
  </base-information-card>
</template>
