<script>

import officeHourOfferingsApi from '../../../api/office_hour_offerings';

export default {
  name: 'AddOfficeHours',
  props: {
    officeHourOffering: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      availableForOfficeHours: 'yes',
      officeHourOfferingDescription: this.officeHourOffering ?
        this.officeHourOffering.description : '',
      sending: false,
    };
  },
  computed: {
    invalid() {
      return !this.availableForOfficeHours;
    },
  },
  methods: {
    createOfficeHourOffering() {
      officeHourOfferingsApi.create({ description: this.officeHourOfferingDescription, active: true })
        .then(() => {
          window.location = '/community/cal_connections/data';
        });
    },
    updateOfficeHourOffering() {
      officeHourOfferingsApi.update(this.officeHourOfferingDescription)
        .then(() => {
          window.location = '/community/cal_connections/data';
        });
    },
    submit() {
      if (this.sending) return;

      this.sending = true;
      if (this.availableForOfficeHours !== 'yes') {
        window.location = '/community/cal_connections/data';

        return;
      }

      if (this.officeHourOffering) {
        this.updateOfficeHourOffering();
      } else {
        this.createOfficeHourOffering();
      }
    },
  },
};

</script>

<template>
  <div>
    <span class="flex flex-row items-center space-x-3">
      <h1 class="inline text-2xl sm:text-3xl">
        ☕ ¿Office Hours?
      </h1>
    </span>

    <div class="mt-8 flex flex-col space-y-4">
      <kalio-radio-inputs
        v-model="availableForOfficeHours"
        label="Además de configurar tu calendario,
              ¿quieres disponibilizar algunos minutos a la semana para reunirte
              con otros fundadores?"
        name="available_for_office_hours"
        :values="['yes', 'no']"
        :radio-labels="['Sí', 'No']"
      />
      <kalio-long-text-input
        v-if="availableForOfficeHours === 'yes'"
        v-model="officeHourOfferingDescription"
        name="office_hour_offering_description"
        label="¿De qué temas te gusta hablar? ¿En qué crees que puedes aportar?"
      />
    </div>

    <kalio-button
      label="Siguiente"
      class="mt-8 w-full sm:w-auto sm:self-center sm:px-24"
      type="submit"
      :loading="sending"
      :disabled="invalid"
      @click="submit"
    />
  </div>
</template>
