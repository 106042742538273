<script>
import moment from 'moment';

export default {
  name: 'investor-application-list-item',
  props: {
    application: { type: Object, required: true },
  },
  emits: ['select-application'],
  computed: {
    fullName() {
      return this.application.user.fullName;
    },
    createdAt() {
      return moment(this.application.createdAt).format('DD-MM-YYYY HH:mm');
    },
  },
};
</script>

<template>
  <div
    class="flex cursor-pointer flex-col p-2"
    @click="$emit('select-application')"
  >
    <span class="mb-2 text-sm">
      {{ fullName }}
    </span>
    <div class="flex items-center">
      <inline-svg
        :src="require('assets/images/icons/calendar.svg')"
        class="mr-0.5 size-3 fill-current"
      />
      <span class="text-xs">
        {{ createdAt }}
      </span>
    </div>
  </div>
</template>
