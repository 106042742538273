<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'kalio-range-text-input',
  props: {
    label: { type: String, default: 'Rango' },
    rules: { type: String, default: '' },
    minValue: { type: [Number, String], default: 0 },
    maxValue: { type: [Number, String], default: 0 },
    modelValue: { type: Object, default: () => ({ min: '', max: '' }) },
    variant: {
      type: String,
      default: 'dark',
      validator: (value) => ['dark', 'semi-dark'].includes(value),
    },
    size: {
      type: String,
      default: 'big',
      validator: (value) => ['small', 'base'].includes(value),
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: {
        min: this.minValue,
        max: this.maxValue,
      },
    };
  },
  computed: {
    labelClasses() {
      const classes = {
        small: 'text-xs sm:text-base',
        big: 'text-sm sm:text-lg',
      };

      return classes[this.size];
    },
  },
  watch: {
    modelValue(newValue) {
      this.value = newValue;
    },
  },
  methods: {
    updateMinValue(value) {
      this.value.min = value;
      this.$emit('update:modelValue', this.value);
    },
    updateMaxValue(value) {
      this.value.max = value;
      this.$emit('update:modelValue', this.value);
    },
  },
});
</script>

<template>
  <div>
    <label
      class="block text-blue-gray-200"
      :class="labelClasses"
    >
      {{ label }}
    </label>
    <div class="flex gap-4">
      <kalio-short-text-input
        v-model="value.min"
        name="min_value"
        label="Min"
        :variant="variant"
        type="number"
        @update:model-value="updateMinValue"
      />
      <kalio-short-text-input
        v-model="value.max"
        name="max_value"
        label="Max"
        :variant="variant"
        type="number"
        @update:model-value="updateMaxValue"
      />
    </div>
  </div>
</template>
