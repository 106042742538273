<script setup>
import KalioModal from '@/components/shared/kalio-modal.vue';
import BaseApplicationProcessStages from './base-application-process-stages.vue';

defineEmits(['close']);
defineProps({
  applicationProcess: { type: Object, required: true },
});
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    custom-close-button
  >
    <div class="flex flex-col rounded-lg bg-pv-blue-700 px-3 py-8">
      <span class="text-lg font-semibold">
        Información del proceso
      </span>
      <base-application-process-stages :application-process="applicationProcess" />
      <button
        class="mt-6 self-center text-lg"
        @click="$emit('close')"
      >
        Cerrar
      </button>
    </div>
  </kalio-modal>
</template>
