<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);
const buildSprintProject = computed(() => startupApplication.value.buildSprintProject);

function updateTitle(update) {
  const date = format(new Date(update.createdAt), "d 'de' MMMM", { locale: es });
  const qualification = update.buildSprintQualification?.qualification ?
    update.buildSprintQualification.qualification :
    'sin calificación';

  return `${date}, ${qualification}`;
}
</script>

<template>
  <div
    v-if="buildSprintProject"
    class="flex flex-col space-y-8"
  >
    <div class="flex flex-col">
      <span class="text-xl font-bold">
        Meta
      </span>
      <span class="text-sm">
        {{ buildSprintProject.goal }}
      </span>
      <div class="mt-2 flex shrink-0 flex-col items-end justify-around">
        <a
          class="flex items-center text-sm text-pv-light-blue underline hover:opacity-75"
          :href="`/build_sprint/internal/projects/review?projectId=${buildSprintProject.id}`"
          target="_blank"
        >
          Ir al proyecto
          <inline-svg
            :src="require('assets/images/icons/arrow.svg')"
            class="ml-1 w-3 rotate-180 fill-current"
          />
        </a>
      </div>
    </div>
    <div class="flex flex-col space-y-4">
      <span class="text-xl font-bold">
        Actualizaciones
      </span>
      <kalio-accordion-row
        v-for="(update) in buildSprintProject.buildSprintUpdates"
        :key="update.id"
        :title="updateTitle(update)"
        max-height-class="max-h-full"
      >
        <div class="mt-2 flex flex-col space-y-6 px-1">
          <div
            v-if="update.buildSprintQualification"
            class="flex flex-col"
          >
            <div class="flex items-center justify-between">
              <span class="font-bold">
                📝 Calificación
              </span>
              <span>
                {{ update.buildSprintQualification.qualification }}
              </span>
            </div>
            <span class="mb-2 text-xs text-blue-gray-300">
              {{ update.buildSprintQualification.user.email }}
            </span>
            <div
              v-if="update.buildSprintQualification.message"
              class="text-base"
              v-html="update.buildSprintQualification.message"
            />
          </div>
          <div
            v-if="update.summary"
            class="flex flex-col"
          >
            <span class="font-bold">
              🤖 Resumen
            </span>
            <span class="mt-1">
              {{ update.summary }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-base font-medium">
              Progreso de la semana y cercanía a la meta
            </span>
            <span class="mt-1 text-sm">
              {{ update.goalProgress }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-base font-medium">
              Razón del avance
            </span>
            <span class="mt-1 text-sm">
              {{ update.kpiVariationReason }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-base font-medium">
              Metas semanales
            </span>
            <span class="mt-1 text-sm">
              {{ update.weeklyGoals }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-base font-medium">
              Mayor obstáculo
            </span>
            <span class="mt-1 text-sm">
              {{ update.biggestObstacle }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-base font-medium">
              Aprendizajes de los usuarios
            </span>
            <span class="mt-1 text-sm">
              {{ update.learningsFromUsers }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-base font-medium">
              Moral del equipo
            </span>
            <span class="mt-1 text-sm">
              {{ update.teamMorale }}
            </span>
          </div>
        </div>
      </kalio-accordion-row>
    </div>
  </div>
  <div v-else>
    <span>
      No hay ningún proyecto del Build Sprint asociado a esta postulación
    </span>
  </div>
</template>
