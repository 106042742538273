import api from './api';

export default {
  startRecording(id) {
    return api({
      method: 'POST',
      url: `/api/v1/apply/recorded_interviews/${id}/start_recording`,
    });
  },
  stopRecording(id) {
    return api({
      method: 'POST',
      url: `/api/v1/apply/recorded_interviews/${id}/stop_recording`,
    });
  },
  getRecording(id) {
    return api({
      method: 'GET',
      url: `/api/v1/apply/recorded_interviews/${id}/recording`,
    });
  },
  update(id, recordedInterview) {
    return api({
      method: 'PATCH',
      url: `/api/v1/apply/recorded_interviews/${id}`,
      data: { recordedInterview },
    });
  },
};
