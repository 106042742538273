<script>
import investmentLegalInformationApi from '../api/investment_legal_information.js';

export default {
  name: 'demo-day-investment-legal-information-form',
  props: {
    auctionOrderId: { type: Number, required: true },
  },
  emits: ['submitted', 'error'],
  data() {
    return {
      loading: false,
      personType: 'legal',
      legalPersonFormData: {
        businessName: null,
        legalPersonRut: null,
        legalRepresentativeRut: null,
        legalRepresentativeName: null,
        legalPersonAddress: null,
      },
      naturalPersonFormData: {
        naturalPersonName: null,
        naturalPersonRut: null,
        naturalPersonEmail: null,
        naturalPersonAddress: null,
      },
    };
  },
  computed: {
    isPersonLegal() {
      return this.personType === 'legal';
    },
    isPersonNatural() {
      return this.personType === 'natural';
    },
  },
  methods: {
    submit() {
      this.loading = true;

      const investmentData = this.isPersonNatural ? this.naturalPersonFormData : this.legalPersonFormData;
      const investmentType = this.isPersonNatural ? 'natural_person' : 'legal_person';
      investmentLegalInformationApi.create({
        investmentLegalInformation: {
          auctionOrderId: this.auctionOrderId,
          investmentData,
          investmentType,
        },
      })
        .then(() => {
          this.$emit('submitted');
        })
        .catch(() => {
          this.$emit('error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div class="flex w-full flex-col space-y-12">
    <div class="flex w-full flex-row">
      <button
        class="flex grow items-center justify-center space-x-4 rounded-l-lg border-y border-l py-2 focus:outline-none"
        :class="isPersonLegal
          ? 'border-pv-yellow bg-pv-yellow text-black'
          : 'border-blue-gray-400 text-blue-gray-400 hover:bg-pv-blue-700'"
        data-testid="legal-person"
        @click="personType = 'legal'"
      >
        <inline-svg
          :src="require('assets/images/icons/lawyer-hammer.svg')"
          class="size-4 fill-current"
        />
        <span class="text-base font-medium">
          {{ $t('demoDay.investmentLegalInformation.form.personType.legal') }}
        </span>
      </button>
      <button
        class="flex grow flex-row items-center justify-center space-x-4 rounded-r-lg border-y border-r py-2 focus:outline-none"
        :class="isPersonNatural
          ? 'border-pv-yellow bg-pv-yellow text-black'
          : 'border-blue-gray-400 text-blue-gray-400 hover:bg-pv-blue-700'"
        data-testid="natural-person"
        @click="personType = 'natural'"
      >
        <inline-svg
          :src="require('assets/images/icons/profile.svg')"
          class="size-4 fill-current"
        />
        <span class="mt-0.5 text-base font-medium">
          {{ $t('demoDay.investmentLegalInformation.form.personType.natural') }}
        </span>
      </button>
    </div>
    <vee-form
      class="flex flex-col space-y-8"
      @submit="submit"
    >
      <div
        v-if="isPersonLegal"
        class="flex w-full flex-col space-y-3"
      >
        <kalio-short-text-input
          v-model="legalPersonFormData.businessName"
          name="busness_name"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.legal.businessName')"
        />
        <kalio-short-text-input
          v-model="legalPersonFormData.legalPersonRut"
          name="legal_person_rut"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.legal.legalPersonRut')"
        />
        <kalio-short-text-input
          v-model="legalPersonFormData.legalRepresentativeRut"
          name="legal_representative_rut"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.legal.legalRepresentativeRut')"
        />
        <kalio-short-text-input
          v-model="legalPersonFormData.legalRepresentativeName"
          name="legal_representative_name"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.legal.legalRepresentativeName')"
        />
        <kalio-short-text-input
          v-model="legalPersonFormData.legalRepresentativeEmail"
          name="legal_representative_email"
          rules="required|email"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.legal.legalRepresentativeEmail')"
        />
        <kalio-short-text-input
          v-model="legalPersonFormData.legalPersonAddress"
          name="legal_person_address"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.legal.legalPersonAddress')"
        />
      </div>
      <div
        v-else
        class="flex w-full flex-col space-y-3"
      >
        <kalio-short-text-input
          v-model="naturalPersonFormData.naturalPersonName"
          name="natural_person_name"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.natural.naturalPersonName')"
        />
        <kalio-short-text-input
          v-model="naturalPersonFormData.naturalPersonRut"
          name="natural_person_rut"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.natural.naturalPersonRut')"
        />
        <kalio-short-text-input
          v-model="naturalPersonFormData.naturalPersonAddress"
          name="natural_person_address"
          rules="required"
          variant="semi-dark"
          :label="$t('demoDay.investmentLegalInformation.form.natural.naturalPersonAddress')"
        />
      </div>
      <kalio-button
        type="submit"
        :label="$t('form.submit')"
        class="w-full sm:w-auto sm:self-center sm:px-24"
        :loading="loading"
      />
    </vee-form>
  </div>
</template>
