<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'kalio-investment-firm-tags',
  props: {
    investmentFirm: { type: Object, required: true },
    investmentStages: { type: Array, required: true },
  },
});
</script>

<template>
  <div class="mb-4 flex flex-wrap gap-x-4 gap-y-2">
    <div
      v-if="investmentFirm.countryInformation"
      class="flex items-center"
    >
      <country-flag
        :country="investmentFirm.countryInformation.code"
        size="small"
      />
      <span class="ml-1 text-sm text-pv-gray">
        {{ investmentFirm.countryInformation.name }}
      </span>
    </div>
    <div class="flex items-center">
      <span
        v-if="investmentFirm.leadRounds"
        class="text-sm text-pv-gray"
      >
        💪 {{ $t("investors.leadRounds") }}
      </span>
      <span
        v-else
        class="text-sm text-pv-gray"
      >
        {{ $t("investors.doNotLeadRounds") }}
      </span>
    </div>
    <div
      v-if="investmentStages && investmentStages.length > 0"
      class="flex items-center"
    >
      <inline-svg
        :src="require('assets/images/icons/chart.svg')"
        class="size-4"
      />
      <span class="ml-1 text-sm text-pv-gray">
        {{ investmentStages.map((stage) => $t(`investors.stages.${stage}`)).join(", ") }}
      </span>
    </div>
    <div
      v-if="investmentFirm.earlyStageFunds"
      class="flex items-center"
    >
      <inline-svg
        :src="require('assets/images/icons/moneybag.svg')"
        class="size-4 fill-pv-yellow"
      />
      <span class="ml-1 text-sm text-pv-gray">
        {{ $filters.toCurrency(investmentFirm.earlyStageFunds, { notation: 'compact' }) }}
      </span>
    </div>
    <div
      v-if="investmentFirm.ticketMinAmount && investmentFirm.ticketMaxAmount"
      class="flex items-center"
    >
      <inline-svg
        :src="require('assets/images/icons/salary.svg')"
        class="size-4 fill-pv-yellow"
      />
      <span class="ml-1 text-sm text-pv-gray">
        {{
          $t(
            'investors.ticketsBetween',
            {
              min: $filters.toCurrency(investmentFirm.ticketMinAmount, { notation: 'compact' }),
              max: $filters.toCurrency(investmentFirm.ticketMaxAmount, { notation: 'compact' })
            }
          )
        }}
      </span>
    </div>
    <div
      v-if="investmentFirm.averageTicket"
      class="flex items-center"
    >
      <inline-svg
        :src="require('assets/images/icons/salary.svg')"
        class="size-4 fill-pv-yellow"
      />
      <span class="ml-1 text-sm text-pv-gray">
        {{
          $t('investors.averageTicket', {
            averageTicket: $filters.toCurrency(investmentFirm.averageTicket, { notation: 'compact' })
          })
        }}
      </span>
    </div>
    <div
      v-if="investmentFirm.industryList && investmentFirm.industryList.length > 0"
      class="flex items-center"
    >
      <inline-svg
        :src="require('assets/images/icons/business.svg')"
        class="size-4 fill-pv-yellow"
      />
      <span class="ml-1 text-sm text-pv-gray">
        {{ investmentFirm.industryList.join(", ") }}
      </span>
    </div>
    <div
      v-if="investmentFirm.regionsOfInvestmentList && investmentFirm.regionsOfInvestmentList.length > 0"
      class="flex items-center"
    >
      <inline-svg
        :src="require('assets/images/icons/world-americas.svg')"
        class="size-4 fill-pv-yellow stroke-pv-blue-700"
      />
      <span class="ml-1 text-sm text-pv-gray">
        {{ investmentFirm.regionsOfInvestmentList.join(', ') }}
      </span>
    </div>
  </div>
</template>
