import api from './api';

export default {
  update(groupMeetingId, groupMeeting) {
    const path = `/api/v1/group_meetings/${groupMeetingId}`;

    return api({
      method: 'patch',
      data: { groupMeeting },
      url: path,
    });
  },
};
