<script>
export default {
  data() {
    return {
      pathName: {
        '/my_jobs': this.$t('jobOffers.listTitle.default'),
        '/my_jobs/applications': this.$t('jobApplications.titles.default'),
      },
    };
  },
  computed: {
    isJobsPath() {
      return this.currentPath.includes('/my_jobs') && !this.isApplicationPath;
    },
    isApplicationPath() {
      return this.currentPath.includes('/my_jobs/applications');
    },
    currentPath() {
      return window.location.pathname;
    },
  },
};
</script>

<template>
  <div
    v-if="isJobsPath || isApplicationPath"
    class="mb-7 flex items-center justify-center md:mb-14"
  >
    <a
      href="/my_jobs"
      class="w-1/2 rounded-l-md p-2 text-center text-sm md:w-1/4 md:text-base"
      :class="{ 'bg-pv-yellow font-medium text-blue-gray-800': isJobsPath,
                'border border-blue-gray-500 text-blue-gray-400': isApplicationPath }"
    >
      {{ pathName['/my_jobs'] }}
    </a>
    <a
      href="/my_jobs/applications"
      class="w-1/2 rounded-r-md p-2 text-center text-sm md:w-1/4 md:text-base"
      :class="{ 'bg-pv-yellow font-medium text-blue-gray-800': isApplicationPath,
                'border border-blue-gray-500 text-blue-gray-400': isJobsPath }"
    >
      {{ pathName['/my_jobs/applications'] }}
    </a>
  </div>
</template>
