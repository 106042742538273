<script setup>
import { computed, ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import Hls from 'hls.js';

const props = defineProps({
  videoUrl: { type: String, required: true },
  markers: { type: Array, default: () => [] },
  pictureInPicture: { type: Boolean, default: false },
});

const videoRef = ref(null);
const videoDuration = ref(0);
const PIP_WAIT_MS = 100;

function seekToMarker(time) {
  videoRef.value.currentTime = time;
}

function markerPercentage(marker) {
  if (!videoRef.value) return 0;

  return (marker.time / videoDuration.value) * 100;
}

function createHlsVideo() {
  const hls = new Hls();
  hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
    videoDuration.value = data.levels[0].details.totalduration;
    window.hlsUrl = hls.url;
  });

  hls.loadSource(props.videoUrl);
  hls.attachMedia(videoRef.value);
}

function pauseAndUnload(video) {
  // hack to avoid zombie video after leaving PiP.
  setTimeout(() => {
    if (!video.paused) video.pause();
    video.removeAttribute('src');
    video.load();
  }, PIP_WAIT_MS);
}

function restoreInlineVideo() {
  const pictureInPictureElement = document.pictureInPictureElement;
  const { currentTime, playbackRate } = pictureInPictureElement;

  pictureInPictureElement.pause();
  document.exitPictureInPicture();

  nextTick(async () => {
    // quick hack that waits to avoid requesting play over dom-removed element
    await new Promise(resolve => setTimeout(resolve, PIP_WAIT_MS));
    const isInDom = document.body.contains(videoRef.value);
    const isCurrentVideo = props.videoUrl === window.lastPipSrc; // avoid autoplaying other video
    if (isInDom && isCurrentVideo) {
      await videoRef.value.play();
      videoRef.value.currentTime = currentTime;
      videoRef.value.playbackRate = playbackRate;
    }
  });
}

onMounted(() => {
  if (props.videoUrl) {
    createHlsVideo();
  }

  if (videoRef.value) {
    const video = videoRef.value;
    videoRef.value.onleavepictureinpicture = () => pauseAndUnload(video);
  }

  if (props.pictureInPicture && document.pictureInPictureElement) {
    restoreInlineVideo();
  }
});

const isPlaying = computed(() => videoRef.value.currentTime > 0 && !videoRef.value.paused);
const isUserActivated = computed(() => navigator.userActivation.isActive);

onBeforeUnmount(async () => {
  if (isPlaying.value && isUserActivated.value && props.pictureInPicture) { // verify user toggled action to avoid error
    window.lastPipSrc = window.hlsUrl;
    await videoRef.value.requestPictureInPicture();
    setTimeout(() => document.pictureInPictureElement.play(), PIP_WAIT_MS); // quick hack to make it work in Arc browser
  }
});
</script>

<template>
  <div class="flex flex-col items-center">
    <video
      ref="videoRef"
      controls
      class="w-full"
    />
    <kalio-accordion-row
      max-height-class="max-h-80"
      :with-divider="false"
      class="p-2"
    >
      <template #title>
        <div class="flex w-full flex-row items-center gap-x-2 pl-2 hover:opacity-75">
          <div class="relative w-full rounded-full bg-pv-blue-800 py-1">
            <button
              v-for="marker in markers"
              :key="marker.time"
              v-tooltip="marker.description"
              :style="{ left: (markerPercentage(marker) - 1) + '%' }"
              class="absolute top-1/2 h-2 w-1 -translate-y-1/2 rounded-full bg-blue-gray-200 transition duration-150 hover:scale-125 hover:opacity-75"
              @click.stop="seekToMarker(marker.time)"
            />
          </div>
        </div>
      </template>
      <div class="flex flex-col items-start gap-y-4 p-2">
        <a
          v-for="marker in markers"
          :key="marker.time"
          class="cursor-pointer text-xs text-pv-light-blue hover:opacity-75"
          @click="seekToMarker(marker.time)"
        >
          {{ $filters.secondsToMMSS(marker.time) }}: {{ marker.description }}
        </a>
      </div>
    </kalio-accordion-row>
  </div>
</template>
