<script>
export default {
  name: 'kalio-program-goal-panel',
  props: {
    programGoal: { type: [Object, null], default: null },
    size: { type: String, default: 'normal' },
    isFounder: { type: Boolean, default: false },
    allowEdit: { type: Boolean, default: true },
  },
  computed: {
    hasProgramGoal() {
      return this.programGoal && Object.keys(this.programGoal).length > 0;
    },
    isSmall() {
      return this.size === 'small';
    },
    growthText() {
      return `Tienen que crecer ${this.programGoal.kpiName} ${this.programGoal.periodGoal}.`;
    },
  },

};
</script>

<template>
  <div
    class="relative flex w-full flex-col rounded-lg bg-pv-blue-900"
    :class="isSmall ? 'p-2 space-y-2' : 'p-4 space-y-4'"
  >
    <div class="flex space-x-2">
      <inline-svg
        :src="require('assets/images/icons/goal.svg')"
        class="-ml-1 fill-current text-pv-yellow"
        :class="isSmall ? 'w-5 h-5' : 'w-6 h-6'"
      />
      <span
        class="text-xs"
        :class="isSmall ? 'sm:text-sm' : 'sm:text-base'"
      >
        Meta del programa
      </span>
    </div>
    <div class="flex flex-col gap-2">
      <span
        v-if="hasProgramGoal"
        :class="isSmall ? 'text-base' : 'text-lg'"
      >
        {{ programGoal.goal }}
      </span>
      <div class="flex flex-col divide-y divide-slate-700 px-2 py-1">
        <div />
        <div />
      </div>
      <span
        v-if="hasProgramGoal"
        :class="isSmall ? 'text-sm' : 'text-base'"
        class="text-pv-gray"
      >
        {{ growthText }}
      </span>
      <div
        v-else
        class="flex flex-col space-y-4"
      >
        <p class="text-base">
          {{ isFounder ? 'No tienes creada tu meta del programa.' : 'No tiene meta del programa creada.' }}
        </p>
        <kalio-button
          v-if="isFounder"
          label="Crear meta del programa"
          class="self-start sm:px-8"
          href="/program/program_goals/new"
        >
          Crear meta programa
        </kalio-button>
      </div>
    </div>
    <a
      v-if="isFounder && hasProgramGoal && allowEdit"
      :href="`/program/program_goals/${programGoal.id}/edit`"
      class="absolute -right-2.5 -top-6 flex cursor-pointer items-center justify-center overflow-hidden rounded-full border border-blue-gray-700 bg-pv-blue-900 p-1.5 hover:bg-pv-blue-700"
    >
      <inline-svg
        :src="require('assets/images/icons/pencil.svg')"
        class="ml-0.5 size-3.5 fill-current text-blue-gray-200"
      />
    </a>
  </div>
</template>
