import humps from 'humps';
import api from './api';

export default {
  index(page, filters = {}) {
    const companiesInvestedKey = 'auction_orders_auction_investable_of_Company_type_name_' +
      'or_auction_orders_auction_investable_of_InvestmentVehicle_type_companies_name_in';
    let path = '/api/v1/investors?';
    if (page) {
      path += `page=${page}&`;
    }

    if (filters.companiesInvestedIn && filters.companiesInvestedIn.length > 0) {
      filters.companiesInvestedIn.forEach((proxy) => {
        path += `q[${companiesInvestedKey}][]=${proxy.value}&`;
      });
      path += 'q[auction_orders_aasm_state_in][]=completely_in&';
      path += 'q[auction_orders_aasm_state_in][]=partially_in&';
    }
    if (filters.countryName && filters.countryName.length > 0) {
      filters.countryName.forEach((proxy) => {
        path += `q[country_name_in][]=${proxy.value}&`;
      });
    }
    if (filters.avgInvestorLetter && filters.avgInvestorLetter.length > 0) {
      filters.avgInvestorLetter.forEach((proxy) => {
        path += `q[avg_investor_letters_in][]=${encodeURIComponent(proxy.value)}&`;
      });
    }
    if (filters.investmentStages && filters.investmentStages.length > 0) {
      filters.investmentStages.forEach((proxy) => {
        path += `q[investment_stages_stage_in][]=${humps.decamelize(proxy.value)}&`;
      });
    }
    if (filters.kind && filters.kind.length > 0) {
      filters.kind.forEach((proxy) => {
        path += `q[kind_in][]=${humps.decamelize(proxy.value)}&`;
      });
    }
    if (filters.leadRounds && filters.leadRounds.length > 0) {
      filters.leadRounds.forEach((proxy) => {
        path += `q[lead_rounds_eq_any][]=${proxy.value}&`;
      });
    }
    if (filters.ticketMinMax) {
      if (Boolean(filters.ticketMinMax.min)) {
        path += `q[ticket_max_amount_gteq]=${filters.ticketMinMax.min}&`;
      }
      if (Boolean(filters.ticketMinMax.max)) {
        path += `q[ticket_max_amount_lteq]=${filters.ticketMinMax.max}&`;
      }
    }

    return api({
      method: 'get',
      url: path,
    });
  },
};
