import InternalApplyReviewFrankWriterDiscardEmailPreset from
  '@/components/internal/apply/review/frank-writer-discard-email-preset.vue';

export const frankWriterPresets = {
  discardEmailPreset: 'apply-review-discard-email-preset',
};

export const componentPresetsMap = {
  'apply-review-discard-email-preset': InternalApplyReviewFrankWriterDiscardEmailPreset,
};
