import api from '@/api/api';

export default {
  create(body, demoDayId = null) {
    const path = demoDayId ?
      `/api/v1/demo_day/demo_days/${demoDayId}/demo_day_investor_applications` :
      '/api/v1/demo_day/demo_day_investor_applications';

    return api({
      method: 'post',
      data: body,
      url: path,
    });
  },
  update(id, body) {
    const path = `/api/v1/demo_day/demo_day_investor_applications/${id}`;

    return api({
      method: 'patch',
      data: body,
      url: path,
    });
  },
  getAll(demoDayId = null) {
    let path = '/api/v1/demo_day/demo_day_investor_applications';
    if (demoDayId) {
      path += `?demo_day_id=${demoDayId}`;
    }

    return api({
      method: 'get',
      url: path,
    });
  },
  guessFirm(id) {
    const path = `/api/v1/demo_day/demo_day_investor_applications/${id}/guess_firm`;

    return api({
      method: 'get',
      url: path,
    });
  },
};
