import PriorityQueue from './data_structures/priority_queue';

/* eslint-disable import/prefer-default-export */
export class StreamOrchestrator {
  constructor() {
    this.currentIndex = 0;
    this.queue = new PriorityQueue((a, b) => a.index < b.index);
  }

  process(chunk) {
    this.queue.push(chunk);

    return this._popCompletedChunks();
  }

  _popCompletedChunks() {
    const completedChunks = [];

    while (!this.queue.isEmpty() && this.queue.peek().index === this.currentIndex) {
      completedChunks.push(this.queue.pop());
      this.currentIndex += 1;
    }

    return completedChunks;
  }
}
