<script setup>
import HomeLayout from '@/components/home/layout.vue';
</script>

<template>
  <home-layout>
    <div class="flex w-full flex-col space-y-6 rounded-lg border border-blue-gray-700 bg-pv-blue-900 p-4">
      <span class="text-xs text-pv-gray sm:text-base">
        Links importantes
      </span>
      <ul class="list-inside list-disc space-y-2 text-pv-light-blue">
        <li class="hover:opacity-75">
          <a href="/internal/apply/review">
            Revisar postulaciones
          </a>
        </li>
        <li class="hover:opacity-75">
          <a href="/program/handbook">
            Manual del programa
          </a>
        </li>
        <li class="hover:opacity-75">
          <a href="/demo_day/internal/demo_day_investor_applications">
            Postulaciones de inversionistas al Demo Day
          </a>
        </li>
        <li class="hover:opacity-75">
          <a href="/community/cal_connections/data">
            Platanus Cal
          </a>
        </li>
        <li class="hover:opacity-75">
          <a href="/internal/users/new">
            Invitar usuarios a Kalio
          </a>
        </li>
        <li class="hover:opacity-75">
          <a href="/internal/companies">
            Estado startups
          </a>
        </li>
        <li class="hover:opacity-75">
          <a href="/internal/batches/current">
            Dashboard del batch actual
          </a>
        </li>
      </ul>
    </div>
  </home-layout>
</template>
