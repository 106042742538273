import { computed } from 'vue';
import { useQueryClient, useQuery, useMutation } from '@tanstack/vue-query';
import ordersApi from '../api/orders.js';

function useAuctionOrderQuery(auction, options = {}) {
  const enabled = computed(() => !!auction.value);

  return useQuery(
    ['auctionOrder', auction],
    () => ordersApi.getAll(auction.value.id).then(response => response.orders.pop() || null),
    { ...options, enabled },
  );
}

function useAddAuctionOrderMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addAuctionOrder'],
    mutationFn: ({ auction, order }) => ordersApi.create(auction.value.id, order),
    onSuccess: (auctionOrder, { auction }) => queryClient.setQueryData(
      ['auctionOrder', auction],
      auctionOrder,
    ),
    onSettled: (_result, _error, { auction }) => queryClient.invalidateQueries({ queryKey: ['auctionOrder', auction] }),
  });
}

export { useAuctionOrderQuery, useAddAuctionOrderMutation };
