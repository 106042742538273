import humps from 'humps';
import api from './api';

export default {
  create(eventId, formAnswers) {
    const path = `/api/v1/events/${eventId}/registrations`;
    const data = { eventRegistration: { formAnswers } };

    return api({
      method: 'post',
      data: humps.decamelizeKeys(data),
      url: path,
    });
  },
};
