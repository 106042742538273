<script>
import KalioPerkRedeemInstructionsModal from './kalio-perk-redeem-instructions-modal.vue';

export default {
  components: { KalioPerkRedeemInstructionsModal },
  props: {
    perk: { type: Object, required: true },
  },
  data() {
    return {
      isBenefitsOpen: false,
      showRedeemInstructionsModal: false,
      showPerkSuggestionModal: false,
    };
  },
  computed: {
    companyPath() {
      if (!this.perk.url) {
        return null;
      } else if (this.perk.url.includes('http://') || this.perk.url.includes('https://')) {
        return this.perk.url;
      }

      return `https://${this.perk.url}`;
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-3 divide-y divide-blue-gray-500 rounded-md border border-blue-gray-700 bg-pv-blue-800 p-4">
    <div class="flex flex-col gap-4">
      <h3 class="pb-3 text-xl">
        <a
          :href="companyPath"
          target="_blank"
          class="border-b-2 border-pv-yellow pb-1"
        >{{ perk.companyName }}</a>
      </h3>
      <div class="hide-scrollbar flex gap-2 overflow-x-scroll">
        <kalio-badge
          v-for="category in perk.categoryList"
          :key="category.id"
          size="sm"
          :text="category"
        />
      </div>
      <p
        v-tooltip="perk.companyDescription.length > 380 && perk.companyDescription"
        class="line-clamp-5 h-20 text-xs"
      >
        {{ perk.companyDescription }}
      </p>
      <div class="flex h-8 items-center justify-between">
        <button
          class="flex items-center border-none text-xs text-blue-gray-200 focus:outline-none"
          @click="isBenefitsOpen = !isBenefitsOpen"
        >
          <span class="mr-1 font-bold">
            {{ isBenefitsOpen ? $t('perks.hideBenefits') : $t('perks.showBenefits') }}
          </span>
          <inline-svg
            :src="require('assets/images/icons/toggle-arrow.svg')"
            class="h-6 w-auto cursor-pointer fill-current p-2 text-blue-gray-50 transition focus:outline-none"
            :class="isBenefitsOpen ? 'rotate-0' : 'rotate-180'"
          />
        </button>
        <kalio-button
          v-if="!isBenefitsOpen"
          :label="$t('perks.use')"
          label-classes="text-blue-gray-700"
          size="small"
          @click="showRedeemInstructionsModal = true"
        />
      </div>
    </div>
    <div
      v-if="isBenefitsOpen"
      class="flex flex-col space-y-4"
    >
      <p class="pt-3 text-base font-medium">
        {{ $t('perks.benefits') }}
      </p>
      <p class="whitespace-pre-wrap text-xs">
        {{ perk.description }}
      </p>
      <kalio-button
        :label="$t('perks.use')"
        size="small"
        class="w-3/4 self-center"
        label-classes="text-blue-gray-700"
        @click="showRedeemInstructionsModal = true"
      />
    </div>
    <kalio-perk-redeem-instructions-modal
      v-if="showRedeemInstructionsModal"
      :perk="perk"
      @close="showRedeemInstructionsModal = false"
    />
  </div>
</template>
