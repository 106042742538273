import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import internalTagsApi from '../api/internal/tags';

export function useResourcesTagsQuery({ resourceType, tagsOn = 'tags', options }) {
  return useQuery(
    [tagsOn, resourceType],
    () => internalTagsApi
      .getAll({ resourceType, tagsOn })
      .then(tags => tags.map(tag => tag.name)),
    options,
  );
}

export function useResourceTagsQuery({ resourceType, resourceIdRef, tagsOn = 'tags', options }) {
  return useQuery(
    [tagsOn, resourceType, resourceIdRef],
    () => internalTagsApi
      .get({ resourceType, resourceId: resourceIdRef.value, tagsOn })
      .then(tags => tags.map(tag => tag.name)),
    options,
  );
}

export function useAddResourceTagMutation({ resourceType, resourceIdRef, tagsOn = 'tags', options }) {
  const queryClient = useQueryClient();

  return useMutation(
    (tag) => internalTagsApi.create({ resourceType, resourceId: resourceIdRef.value, tagsOn, tag }),
    {
      onSuccess: async (_result, tag) => {
        queryClient.setQueryData(
          [tagsOn, resourceType, resourceIdRef],
          (oldTags) => [...(oldTags || []), tag],
        );
      },
      ...options,
    },
  );
}

export function useDeleteResourceTagMutation({ resourceType, resourceIdRef, tagsOn = 'tags' }, options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (tag) => internalTagsApi.delete({ resourceType, resourceId: resourceIdRef.value, tagsOn, tag }),
    {
      onSuccess: async (_result, tag) => {
        queryClient.setQueryData(
          [tagsOn, resourceType, resourceIdRef],
          (oldTags) => (oldTags || []).filter(oldTag => oldTag !== tag),
        );
      },
      ...options,
    },
  );
}
