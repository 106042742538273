<script>
import { inject } from 'vue';
import getFormatOfOperationIconName from '../../utils/get_format_of_operation_icon_name.js';
import KalioConfirmationModal from '../shared/kalio-confirmation-modal.vue';
import jobOfferApi from '../../api/job_offers.js';
import { useRolesStore } from '../../stores';
import { currentUserKey, screenKey } from '../../utils/keys.js';
import KalioJobOfferApplyModal from './kalio-job-offer-apply-modal.vue';
import KalioJobOfferCard from './kalio-job-offer-card.vue';

export default {
  name: 'kalio-job-offer-show',
  components: { KalioJobOfferCard, KalioJobOfferApplyModal, KalioConfirmationModal },
  props: {
    jobOffer: { type: Object, required: true },
    otherJobOffers: { type: Array, default: () => [] },
    isCompanyFounder: { type: Boolean, required: true },
    existingApplication: { type: Boolean, required: true },
  },
  setup() {
    const rolesStore = useRolesStore();

    return {
      isFounder: rolesStore.isFounder,
      currentUser: inject(currentUserKey),
      screen: inject(screenKey),
    };
  },
  data() {
    return {
      deleteModalOpen: false,
      applyModalOpen: false,
      showJobApplicationSuccessfullMessage: false,
      errorPresent: false,
      loading: false,
      errorMessage: null,
      applied: this.existingApplication,
      open: this.jobOffer.active,
    };
  },
  computed: {
    formatOfOperationIcon() {
      return getFormatOfOperationIconName(this.jobOffer.formatOfOperation);
    },

    applyButtonIcon() {
      if (this.applied) return 'check.svg';
      if (!this.open) return 'close.svg';

      return '';
    },

    applyButtonLabel() {
      if (this.applied) return this.$t('jobOffers.applied');
      if (!this.open) return this.$t('jobOffers.closed');

      return this.$t('jobOffers.apply');
    },
  },
  mounted() {
    const url = new URL(window.location);
    if (url.searchParams.get('modal-open')) {
      this.applyModalOpen = true;
      url.searchParams.delete('modal-open');
      window.history.replaceState({}, '', url);
    }
  },
  methods: {
    async deleteJobOffer() {
      try {
        await jobOfferApi.delete(this.jobOffer.id);
        window.location.href = '/my_jobs';
      } catch (error) {
        this.errorMessage = error.response?.data?.detail || 'Ocurrió un error, inténtalo más tarde.';
      }
    },
    openModal() {
      if (this.currentUser) {
        this.applyModalOpen = true;
      } else {
        this.redirectToLoginPath();
      }
    },
    redirectToLoginPath() {
      window.location = `/accounts/users/sign_in?redirect_to=${this.jobOffer.showUrl}?modal-open=true`;
    },
    onJobApplicationCreationSucceeded() {
      this.showJobApplicationSuccessfullMessage = true;
      this.applied = true;
    },
  },
};
</script>

<template>
  <div class="flex w-full max-w-screen-md flex-col">
    <kalio-alert
      v-if="showJobApplicationSuccessfullMessage"
      status="success"
      :title="$t('jobOffers.application.successfullySent')"
      @close="showJobApplicationSuccessfullMessage = false"
    />
    <kalio-alert
      v-if="!!errorMessage"
      :title="errorMessage"
      class="w-full"
      status="alert"
      @close="errorMessage = null"
    />
    <kalio-back-button
      v-if="!screen.isMobile"
      :href="isFounder ? '/my_jobs' : '/jobs'"
      class="my-4"
    />
    <div class="mb-8 flex flex-col gap-y-8 md:mb-10 md:flex-row">
      <div class="flex-1">
        <div class="mb-2 flex space-x-5 text-blue-gray-300">
          <div class="flex items-end space-x-1">
            <inline-svg
              :src="require('assets/images/icons/business.svg')"
              class="h-5 w-auto fill-current md:h-6"
            />
            <span class="text-sm text-pv-gray md:text-sm">
              {{ jobOffer.companyName }}
            </span>
          </div>
          <div
            v-if="jobOffer.formatOfOperation"
            class="flex items-end"
          >
            <div class="mr-3 flex items-center space-x-1">
              <inline-svg
                :src="require(`assets/images/icons/${formatOfOperationIcon}.svg`)"
                class="h-4 w-auto fill-current md:h-4"
              />
              <span class="text-sm text-pv-gray">
                {{ $t(`jobOffers.formatOfOperations.${jobOffer.formatOfOperation}`) }}
              </span>
            </div>
            <div
              v-if="jobOffer.countryInformation"
              class="flex items-center"
            >
              <country-flag
                :country="jobOffer.countryInformation.code.toLowerCase()"
                size="small"
              />
              <span class="ml-3 text-sm">
                {{ jobOffer.countryInformation.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex justify-between">
          <h1 class="mb-2 text-2xl font-medium text-blue-gray-50">
            {{ jobOffer.position }}
          </h1>
          <VDropdown
            v-if="isCompanyFounder && screen.isMobile"
            :distance="4"
          >
            <button>
              <inline-svg
                :src="require('assets/images/icons/vertical-dots.svg')"
                class="h-5 w-4 fill-current"
              />
            </button>
            <template #popper>
              <div class="flex flex-col divide-y divide-blue-gray-700">
                <a
                  class="flex justify-start px-4 py-2"
                  :href="`/my_jobs/${jobOffer.id}/edit`"
                >
                  <inline-svg
                    :src="require('assets/images/icons/edit.svg')"
                    class="mr-2 size-6 fill-current"
                  />
                  <span class="text-sm">
                    {{ $t('jobOffers.actions.edit') }}
                  </span>
                </a>
                <div
                  class="flex cursor-pointer justify-start px-4 py-2"
                  @click="deleteModalOpen = true"
                >
                  <inline-svg
                    :src="require('assets/images/icons/delete.svg')"
                    class="mr-2 h-5 w-6 fill-current text-blue-gray-300"
                  />
                  <span class="text-sm">
                    {{ $t('jobOffers.actions.delete') }}
                  </span>
                </div>
                <a
                  class="flex justify-start px-4 py-2"
                  :href="'/my_jobs/applications'"
                >
                  <inline-svg
                    :src="require('assets/images/icons/profiles.svg')"
                    class="mr-2 h-5 w-6 fill-current"
                  />
                  <span class="text-sm">
                    {{ $t('jobApplications.titles.default') }}
                  </span>
                </a>
              </div>
            </template>
          </VDropdown>
        </div>
        <div class="flex items-end space-x-1 text-blue-400">
          <inline-svg
            :src="require('assets/images/icons/salary.svg')"
            class="h-5 w-auto fill-current md:h-6"
          />
          <span class="text-base md:text-sm">
            {{ jobOffer.wageRange }}
          </span>
        </div>
      </div>
      <div
        v-if="isCompanyFounder && !screen.isMobile"
        class="flex items-center"
      >
        <a
          class="mr-7"
          :href="`/my_jobs/${jobOffer.id}/edit`"
        >
          <inline-svg
            :src="require('assets/images/icons/edit.svg')"
            class="size-6 fill-current"
          />
        </a>
        <button @click="deleteModalOpen = true">
          <inline-svg
            :src="require('assets/images/icons/delete.svg')"
            class="h-5 w-4 fill-current"
          />
        </button>
      </div>
      <kalio-button
        v-if="!isCompanyFounder"
        :label="applyButtonLabel"
        class="w-full md:w-auto md:self-end md:justify-self-end"
        :left-icon="applyButtonIcon"
        :disabled="applied || !open"
        @click="openModal"
      />
    </div>
    <div class="mb-10 space-y-4">
      <div class="rounded-lg bg-pv-blue-900 p-4">
        <h2 class="mb-4 border-b-2 border-blue-gray-400 text-xl font-medium">
          {{ $t('jobOffers.aboutUs') }}
        </h2>
        <div
          class="text-base"
          v-html="jobOffer.aboutUs"
        />
      </div>
      <div class="rounded-lg bg-pv-blue-900 p-4">
        <h2 class="mb-4 border-b-2 border-blue-gray-400 text-xl font-medium">
          {{ $t('jobOffers.description') }}
        </h2>
        <div
          class="text-base"
          v-html="jobOffer.description"
        />
      </div>
      <div class="rounded-lg bg-pv-blue-900 p-4">
        <h2 class="mb-4 border-b-2 border-blue-gray-400 text-xl font-medium">
          {{ $t('jobOffers.requiredSkills') }}
        </h2>
        <ul class="space-y-2.5">
          <li
            v-for="(skill, index) in jobOffer.requiredSkills"
            :key="index"
            class="flex"
          >
            <inline-svg
              :src="require('assets/images/icons/toggle-arrow-right.svg')"
              class="mr-2.5 h-6 w-auto fill-current text-pv-light-blue"
            />
            <p class="text-base">
              {{ skill }}
            </p>
          </li>
        </ul>
      </div>
      <div class="rounded-lg bg-pv-blue-900 p-4">
        <h2 class="mb-4 border-b-2 border-blue-gray-400 text-xl font-medium">
          {{ $t('jobOffers.benefits') }}
        </h2>
        <ul class="space-y-2.5">
          <li
            v-for="(benefit, index) in jobOffer.benefits"
            :key="index"
            class="flex"
          >
            <inline-svg
              :src="require('assets/images/icons/toggle-arrow-right.svg')"
              class="mr-2.5 h-6 w-auto fill-current text-pv-light-blue"
            />
            <p class="text-base">
              {{ benefit }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="otherJobOffers.length"
      class="flex flex-col"
    >
      <h2 class="mb-4 text-2xl font-medium">
        {{ $t('jobOffers.otherOffers', { companyName: jobOffer.companyName }) }}
      </h2>
      <div class="space-y-2">
        <kalio-job-offer-card
          v-for="otherJobOffer in otherJobOffers"
          :key="otherJobOffer.id"
          :job-offer="otherJobOffer"
          :show-company="false"
          :show-actions="false"
        />
      </div>
    </div>
    <kalio-job-offer-apply-modal
      v-if="applyModalOpen"
      :position="jobOffer.position"
      :company-name="jobOffer.companyName"
      :job-offer-id="jobOffer.id"
      @close="applyModalOpen = false"
      @job-application-creation-succeeded="onJobApplicationCreationSucceeded"
    />
    <kalio-confirmation-modal
      v-if="deleteModalOpen"
      :title="$t('jobOffers.deleteModal.confirmationMessage')"
      :body="$t('jobOffers.deleteModal.explanationMessage')"
      @close="deleteModalOpen = false"
      @accept="deleteJobOffer"
    />
  </div>
</template>

<style>
.trix-content > ul {
  list-style: disc;
}

.trix-content > ol {
  list-style: decimal;
}

.trix-content > pre {
  color: black;
}
</style>
