<script setup>
import { computed } from 'vue';
import { get } from 'lodash';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioTextAnswer from '../../../../form/kalio-text-answer.vue';
import useFormAnswers from '../../../../../hooks/form_answers.js';
import { getVideoEmbedUrl } from '../../../../../utils/review.js';
import BaseTabIframe from './base-iframe.vue';

const FORM_ANSWERS_PATHS = [
  'progress.general',
  'progress.revenueOther',
  'progress.otherProgram',
  'progress.dedicationOther',
];

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);
const { formAnswers } = useFormAnswers(FORM_ANSWERS_PATHS, startupApplication.value);

const applicationFounders = computed(() =>
  startupApplication.value.applicationFounders.map(founder => founder.founderProfile)
    .filter((founder) => founder.formData),
);
const founderCount = computed(() => applicationFounders.value.length);

const productVideoUrl = computed(
  () => get(startupApplication.value.formAnswers, 'progress.productDemoVideo.value'),
);
const productVideoEmbedUrl = computed(
  () => getVideoEmbedUrl(productVideoUrl.value),
);
const progress = computed(() => startupApplication.value.formAnswers.progress);
function getYesNo(value) {
  return value === 'yes' ? 'Sí' : 'No';
}

const usersText = computed(() => {
  const users = progress.value.users?.value;
  if (!users) return null;
  if (users === 'no') return 'No tienen usuarios';

  const usersAmount = progress.value.usersAmount?.value;
  if (!usersAmount) return 'Sí tienen usuarios';

  return `Tienen ${usersAmount} usuarios`;
});

function foundersHaveAnswered(section, property) {
  return applicationFounders.value.some((founder) => {
    const answer = founder.formData[section]?.[property]?.value;

    return answer !== undefined && answer !== null;
  });
}

function countFoundersYesAnswer(section, property) {
  return applicationFounders.value.filter((founder) => {
    const answer = founder.formData[section]?.[property]?.value;

    return answer === 'yes';
  }).length;
}

const foundersCommitmentFullTimeText = computed(() => {
  const fullTimeCount = countFoundersYesAnswer('commitment', 'fullTime');

  if (fullTimeCount === founderCount.value) return 'Todos trabajarían full time';
  if (fullTimeCount === 0) return 'Ninguno trabajaría full time';

  return `${fullTimeCount} de ${founderCount.value} trabajarían full time`;
});

const foundersCurrentlyFullTimeText = computed(() => {
  const fullTimeCount = countFoundersYesAnswer('commitment', 'currentlyFullTime');

  if (fullTimeCount === founderCount.value) return 'Todos trabajan full time';
  if (fullTimeCount === 0) return 'Ninguno trabaja full time';

  return `${fullTimeCount} de ${founderCount.value} trabajan full time`;
});

const foundersFullTimeMonthsText = computed(() => {
  const foundersFullTimeMonths = applicationFounders.value.map((founder) => (
    founder.formData.commitment?.fullTimeMonths?.value || 0
  )).map(Number);

  const totalFullTimeMonths = Math.min(...foundersFullTimeMonths);

  return `${totalFullTimeMonths} meses todos trabajando full time`;
});
</script>

<template>
  <div class="flex flex-col space-y-4">
    <div
      v-if="productVideoEmbedUrl"
      class="flex flex-col items-center space-y-1"
    >
      <base-tab-iframe
        :src="productVideoEmbedUrl"
        class="mt-4"
      />
      <a
        :href="productVideoUrl"
        target="_blank"
        class="flex flex-row items-center space-x-2 text-pv-light-blue"
      >
        <span>
          Video del producto
        </span>
        <inline-svg
          :src="require('assets/images/icons/external.svg')"
          class="size-3 fill-current"
        />
      </a>
    </div>
    <div class="flex flex-col rounded-lg bg-pv-blue-900 p-4">
      <span v-if="progress.teamSize?.value">
        👫 {{ progress.teamSize.value }} personas en el equipo
      </span>
      <span v-if="progress.monthsWorkingInIdea?.value">
        🔨 {{ progress.monthsWorkingInIdea.value }} meses trabajando en la idea
      </span>
      <!-- legacy -->
      <span v-if="progress.monthsWorkingFullTime?.value">
        🛠 {{
          progress.monthsWorkingFullTime.value ?
            `${progress.monthsWorkingFullTime.value} meses trabajando full time` :
            'No trabajan full time'
        }}
      </span>
      <!-- legacy -->
      <span v-if="progress.wouldWorkFullTime?.value">
        🫡 {{ getYesNo(progress.wouldWorkFullTime.value) }} trabajarían full time
      </span>
      <span v-if="foundersHaveAnswered('commitment', 'currentlyFullTime')">
        🧑‍💻 {{ foundersCurrentlyFullTimeText }}
      </span>
      <span v-if="foundersHaveAnswered('commitment', 'fullTimeMonths')">
        🧑‍💻 {{ foundersFullTimeMonthsText }}
      </span>
      <span v-if="foundersHaveAnswered('commitment', 'fullTime')">
        🫡 {{ foundersCommitmentFullTimeText }}
      </span>
      <span v-if="progress.hasRevenue?.value">
        💰 {{ getYesNo(progress.hasRevenue.value) }} tienen ingresos
      </span>
      <span v-if="progress.monthsWithRevenue?.value">
        💰 {{ progress.monthsWithRevenue.value }} meses con ingresos
      </span>
      <span v-if="progress.users?.value">
        👤 {{ usersText }}
      </span>
      <span v-if="progress.monthsWithUsers?.value">
        👤 {{ progress.monthsWithUsers.value }} meses con usuarios
      </span>
    </div>
    <kalio-text-answer
      v-for="(item, index) in formAnswers"
      :key="index"
      :label="item.label"
      :answer="item.answer"
    />
  </div>
</template>
