<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { defineRule } from 'vee-validate';
import { format } from 'date-fns';

const emit = defineEmits(['update-timer', 'reset-timer']);
const props = defineProps({
  resetAllowed: { type: Boolean, required: true },
  muteAllowed: { type: Boolean, required: true },
  lastResetTimestamp: { type: Date, default: () => new Date() },
  timerDuration: { type: Number, required: true },
});

const isTimerDropdownOpen = ref(false);
const TIMER_MMSS_REGEX = /^([0-5]?[0-9]):([0-5]?[0-9])$/;
const TIMER_SS_REGEX = /^\d{1,2}$/;

defineRule('timer-format', (value) => {
  if (!TIMER_MMSS_REGEX.test(value) && !TIMER_SS_REGEX.test(value)) {
    return 'formato inválido';
  }

  return true;
});

const timerSecondsLeft = ref(0);
function updateTimerSecondsLeft() {
  const lastReset = props.lastResetTimestamp?.getTime() || 0;
  const timeSinceTimerReset = (new Date().getTime() - lastReset) / 1000;
  if (timeSinceTimerReset < props.timerDuration) {
    timerSecondsLeft.value = props.timerDuration - timeSinceTimerReset;
  } else {
    timerSecondsLeft.value = 0;
  }
}

function secondsToClockFormat(seconds) {
  const date = new Date(0);
  date.setSeconds(seconds);

  return format(date, 'mm:ss');
}

const newTimerDurationString = ref(secondsToClockFormat(props.timerDuration));

function getNewTimerDurationSeconds() {
  if (TIMER_MMSS_REGEX.test(newTimerDurationString.value)) {
    const [minutes, seconds] = newTimerDurationString.value.split(':');

    return (parseInt(minutes, 10) * 60) + parseInt(seconds, 10);
  }

  return parseInt(newTimerDurationString.value, 10);
}

function updateTimer() {
  isTimerDropdownOpen.value = false;
  emit('update-timer', getNewTimerDurationSeconds());
}

let interval;
onMounted(() => {
  interval = setInterval(updateTimerSecondsLeft, 1000);
});
onBeforeUnmount(() => clearInterval(interval));

const timerSecondsLeftClockFormat = computed(() => secondsToClockFormat(timerSecondsLeft.value));

const newTimerDuration = ref(props.timerDuration);
watch(() => props.timerDuration, (newDuration) => {
  newTimerDuration.value = newDuration;
});

</script>

<template>
  <div class="mb-2 flex flex-row items-center gap-1 self-end">
    <div class="flex flex-row items-center gap-1">
      <span>
        Tiempo restante del turno actual:
      </span>
      <span class="w-12 text-center text-pv-yellow">
        {{ timerSecondsLeftClockFormat }}
      </span>
    </div>
    <button
      v-if="resetAllowed"
      class="flex size-7 items-center justify-center rounded-full border border-blue-gray-700 bg-pv-blue-900 p-1 hover:opacity-75"
      @click="$emit('reset-timer')"
    >
      <inline-svg
        :src="require('assets/images/icons/reset.svg')"
        class="stroke-current text-pv-yellow"
      />
    </button>
    <VDropdown
      v-if="resetAllowed"
      :triggers="[]"
      :shown="isTimerDropdownOpen"
      :auto-hide="false"
      distance="12"
      placement="top"
    >
      <button
        class="flex size-7 items-center justify-center rounded-full border border-blue-gray-700 bg-pv-blue-900 p-1 hover:opacity-75"
        @click="isTimerDropdownOpen = !isTimerDropdownOpen"
      >
        <inline-svg
          :src="require('assets/images/icons/edit.svg')"
          class="stroke-current text-pv-yellow"
        />
      </button>
      <template #popper>
        <vee-form
          as="div"
          class="flex w-48 flex-col space-y-2 bg-pv-blue-900 p-3"
        >
          <div class="flex flex-col">
            <p>
              Ingresa el tiempo por turno
            </p>
            <p class="text-sm text-slate-500">
              formato MM:SS o SS
            </p>
          </div>
          <kalio-short-text-input
            v-model="newTimerDurationString"
            name="group_meeting_timer"
            rules="timer-format|required"
          />
          <kalio-button
            label="Guardar"
            @click="updateTimer"
          />
        </vee-form>
      </template>
    </VDropdown>
  </div>
</template>
