<script setup>
import BaseInformationCard from '@/components/shared/base-information-card.vue';

defineProps({
  company: { type: Object, required: true },
  weeklyUpdate: { type: Object, required: true },
  programGoal: { type: Object, required: true },
});
</script>

<template>
  <base-information-card
    class="relative"
    title="Actualizaciones semanales"
    :create-url="company.weeklyUpdateSubmitted ? null : '/weekly_updates/new'"
  >
    <base-weekly-update-card
      :weekly-update="weeklyUpdate"
      :program-goal="programGoal"
    />
  </base-information-card>
</template>
