import humps from 'humps';
import api from './api';

export default {
  create(calendlyUri, interviewKind) {
    const path = '/api/v1/apply/application_interviews';

    return api({
      method: 'post',
      data: humps.decamelizeKeys({ calendlyUri, kind: interviewKind }),
      url: path,
    });
  },
};
