<script>
import KalioSelectInput from '@/components/form/kalio-select-input';

export default {
  name: 'demo-day-investor-applications-filters',
  components: {
    KalioSelectInput,
  },
  props: {
    applicationsCountries: { type: Array, required: true },
    applicationsStates: { type: Array, required: true },
    applicationsBatches: { type: Array, required: true },
    currentBatchName: { type: String, required: false, default: null },
    allApplications: { type: Array, required: true },
  },
  emits: ['filter-applications'],
  data() {
    return {
      filters: {
        state: [],
        country: [],
        kind: [],
        batch: this.currentBatchName ? [this.currentBatchName] : [],
      },
    };
  },
  mounted() {
    this.$emit('filter-applications', this.filterApplications(this.allApplications));
  },
  methods: {
    stateSelected(value) {
      this.setFilter('state', value);
    },
    countrySelected(value) {
      this.setFilter('country', value);
    },
    kindSelected(value) {
      this.setFilter('kind', value);
    },
    batchSelected(value) {
      this.setFilter('batch', value);
    },
    setFilter(type, value) {
      this.filters = { ...this.filters, [type]: value };
      this.$emit('filter-applications', this.filterApplications(this.allApplications));
    },
    filterApplications(allApplications) {
      let newFilteredApplications = [...allApplications];
      if (
        this.filters.state.length !== 0 ||
          this.filters.country.length !== 0 ||
          this.filters.kind.length !== 0 ||
          this.filters.batch.length !== 0
      ) {
        newFilteredApplications = allApplications.filter((application) => {
          let matchesState = this.filters.state.length === 0;
          let matchesCountry = this.filters.country.length === 0;
          let matchesBatch = this.filters.batch.length === 0;
          let matchesKind = this.filters.kind.length === 0;
          if (this.filters.state.includes(application.aasmState)) {
            matchesState = true;
          }
          const country = application.formAnswers?.basicInfo?.country?.value.name;
          if (this.filters.country.includes(country)) {
            matchesCountry = true;
          }
          const batch = application.demoDay?.batchName;
          if (this.filters.batch.includes(batch)) {
            matchesBatch = true;
          }
          const isFund = application.formAnswers?.fund;
          if (this.filters.kind.includes('Fondo') && !!isFund) matchesKind = true;
          const isAngel = application.formAnswers?.angel;
          if (this.filters.kind.includes('Ángel') && !!isAngel) matchesKind = true;

          if (matchesState && matchesCountry && matchesBatch && matchesKind) return true;

          return false;
        });
      }
      this.filteredApplications = newFilteredApplications;

      return newFilteredApplications;
    },
  },
};
</script>

<template>
  <div class="flex flex-col space-y-2">
    <kalio-select-input
      v-model="filters.batch"
      size="small"
      label="Generación del demo day"
      name="batch"
      :options="applicationsBatches"
      :required="false"
      :multiple="true"
      @update:model-value="batchSelected"
    />
    <kalio-select-input
      size="small"
      label="Estado"
      name="status"
      :options="applicationsStates"
      :required="false"
      :multiple="true"
      @update:model-value="stateSelected"
    />
    <kalio-select-input
      size="small"
      label="Tipo"
      name="kind"
      :options="[
        'Ángel',
        'Fondo',
      ]"
      :required="false"
      :multiple="true"
      @update:model-value="kindSelected"
    />
    <kalio-select-input
      size="small"
      label="País"
      name="country"
      :options="applicationsCountries"
      :required="false"
      :multiple="true"
      @update:model-value="countrySelected"
    />
  </div>
</template>
