import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import internalStartupApplicationsApi from '@/api/internal/startup_applications.js';
import internalStartupApplicationDiscardFeedbacksApi from '@/api/internal/startup_application_discard_feedbacks.js';

export function useStartupApplicationsQuery(
  { filters, sort, firstPageToFetch = 1 },
  options = {},
) {
  return useInfiniteQuery({
    queryKey: ['startupApplications', filters, sort],
    queryFn: ({ pageParam = firstPageToFetch }) => internalStartupApplicationsApi
      .getAll(pageParam, filters.value, sort.value),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    ...options,
  });
}

export function useStartupApplicationQuery(startupApplicationIdRef, options = {}) {
  return useQuery({
    queryKey: ['startupApplication', startupApplicationIdRef],
    queryFn: () => internalStartupApplicationsApi
      .get(startupApplicationIdRef.value)
      .then(response => response.startupApplication),
    ...options,
  });
}

export function useUpdateStartupApplicationMutation(startupApplicationIdRef, options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, body }) => internalStartupApplicationsApi.update(id, body),
    ...options,
    onSuccess: (response) => {
      options.onSuccess?.(response);
      queryClient.setQueryData(
        ['startupApplication', startupApplicationIdRef],
        response.startupApplication,
      );
    },
  });
}

export function useCreateStartupApplicationFeedbackQuery(startupApplicationIdRef, options = {}) {
  return useMutation({
    mutationFn: (body) => internalStartupApplicationDiscardFeedbacksApi.create(startupApplicationIdRef.value, body),
    ...options,
  });
}
