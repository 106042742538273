<script>
import KalioListWithFiltersAndSearchbar from '../shared/kalio-list-with-filters-and-searchbar.vue';
import i18n from '../../plugins/i18n';
import KalioJobOfferCard from './kalio-job-offer-card.vue';
import KalioJobOfferApplicationOptions from './kalio-job-offer-application-options.vue';

export default {
  name: 'KalioJobOfferList',
  components: {
    KalioJobOfferCard,
    KalioListWithFiltersAndSearchbar,
    KalioJobOfferApplicationOptions,
  },
  props: {
    jobOffers: { type: Array, required: true },
    positions: { type: Array, default: undefined },
    formatOfOperations: { type: Array, required: true },
    companies: { type: Array, default: () => [] },
    query: { type: String, default: '' },
    showCompanyInCards: { type: Boolean, default: false },
    showActions: { type: Boolean, default: false },
    newJobOfferUrl: { type: String, default: '' },
    title: { type: String, default: i18n.global.t('jobOffers.listTitle.default') },
    subtitle: { type: String, default: i18n.global.t('jobOffers.listSubtitle.default') },
    searchPath: { type: String, default: '/jobs' },
  },
  data() {
    return {
      queryParamsCompanyOptionInitialValue: null,
    };
  },
  computed: {
    filters() {
      const filters = {};
      if (this.positions) {
        filters.positions = {
          label: this.$t('list.filters.position'),
          itemKey: 'position',
          options: this.positions.map(position => ({ value: position, label: position })),
          type: 'checkbox',
        };
      }
      filters.formatOfOperations = {
        label: this.$t('list.filters.formatOfOperation'),
        itemKey: 'formatOfOperation',
        options: this.formatOfOperations.map(formatOfOperation => ({
          value: formatOfOperation,
          label: this.$t(`jobOffers.formatOfOperations.${formatOfOperation}`),
        })),
        type: 'checkbox',
      };
      if (this.companies) {
        filters.companies = {
          label: this.$t('list.filters.company'),
          itemKey: 'companyId',
          options: this.companies.map(company => ({ value: company.id, label: company.name })),
          type: 'checkbox',
          initialValue: this.queryParamsCompanyOptionInitialValue,
        };
      }

      return filters;
    },
  },
  created() {
    const url = new URL(window.location.href);

    const companySlug = url.searchParams.get('startup');
    const company = this.companies.find((c) => c.slug === companySlug);

    if (company) {
      this.queryParamsCompanyOptionInitialValue = [{
        label: company.name,
        value: company.id,
      }];
    }

    url.searchParams.delete('startup');
    window.history.replaceState({}, '', url);
  },
};
</script>

<template>
  <div class="w-full">
    <kalio-job-offer-application-options />
    <h1 class="mb-5 text-center text-2xl font-medium md:text-4xl">
      {{ title }}
    </h1>
    <div class="mb-14 text-center text-sm text-blue-gray-200 md:text-xl">
      {{ subtitle }}
    </div>

    <kalio-list-with-filters-and-searchbar
      :items="jobOffers"
      :query="query"
      :search-path="searchPath"
      :filters="filters"
    >
      <template
        v-if="showActions"
        #actions
      >
        <kalio-button
          :label="$t('jobOffers.new')"
          :href="newJobOfferUrl"
          class="h-full"
          size="small"
        />
      </template>
      <template #item="{ item }">
        <kalio-job-offer-card
          :job-offer="item"
          :show-company="showCompanyInCards"
          :show-actions="showActions"
        />
      </template>
    </kalio-list-with-filters-and-searchbar>
  </div>
</template>
