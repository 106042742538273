import humps from 'humps';
import api from './api';

const path = '/api/v1/job_offers';

export default {
  create(jobOffer) {
    return api({
      method: 'post',
      data: humps.decamelizeKeys({ jobOffer }),
      url: path,
    });
  },
  update(jobOfferId, jobOffer) {
    return api({
      method: 'patch',
      data: humps.decamelizeKeys({ jobOffer }),
      url: `${path}/${jobOfferId}`,
    });
  },
  delete(jobOfferId) {
    return api({
      method: 'delete',
      url: `${path}/${jobOfferId}`,
    });
  },
};
