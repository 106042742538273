<script setup>
import { computed } from 'vue';
import KalioCopyTextButton from '../shared/kalio-copy-text-button.vue';

defineEmits(['schedule']);
const props = defineProps({
  offering: { type: Object, required: true },
});

const company = computed(() => props.offering.user.company);
const companyPosition = computed(() => props.offering.user.founderProfile?.companyPosition);
</script>

<template>
  <div class="flex size-full flex-col space-y-6 rounded-lg bg-pv-blue-900 p-4">
    <div class="flex w-full flex-row space-x-8">
      <div class="relative">
        <div class="relative size-24 shrink-0">
          <img
            v-if="company"
            :src="company.isotypeUrl"
            class="absolute -right-1 -top-1 size-8 shrink-0 rounded-full object-cover"
          >
          <img
            :src="offering.user.avatarUrl"
            class="size-full rounded-full object-cover"
          >
          <a
            v-if="company"
            :href="company.profilePath"
            class="absolute -bottom-2 left-1/2 z-20 mx-auto -translate-x-1/2 rounded-md bg-pv-blue-700 px-1"
          >
            <span class="whitespace-nowrap text-xs font-medium text-blue-gray-50">
              {{ company.name }}
            </span>
          </a>
        </div>
      </div>
      <div class="flex size-full flex-col justify-between gap-y-4">
        <div class="flex w-full flex-row items-center gap-x-2">
          <span class="mr-auto text-left font-bold text-blue-gray-50">
            {{ offering.user.fullName }}
          </span>
          <kalio-copy-text-button
            :text="`${window.location.host}/office_hour_offerings/${offering.id}`"
            :title="$t('officeHours.copyLink')"
            class="size-4 shrink-0 text-pv-gray"
          />
        </div>
        <div class="flex flex-wrap gap-2">
          <kalio-badge
            v-if="companyPosition"
            :text="companyPosition"
            round-class="rounded-md"
            color="gray"
          />
          <kalio-badge
            v-for="(tag, index) in offering.tags"
            :key="index"
            :text="tag.name"
            color="gray"
            round-class="rounded-md"
          />
        </div>
        <kalio-button
          label="Agendar"
          label-classes="text-xs"
          left-icon="calendar.svg"
          size="small"
          variant="blue"
          left-icon-classes="h-3 w-auto fill-current mr-1.5"
          class="self-start"
          @click="$emit('schedule')"
        />
      </div>
    </div>
    <p class="line-clamp-4 text-left text-sm">
      {{ offering.description }}
    </p>
  </div>
</template>
