<script setup>
import { ref } from 'vue';
import BaseTabs from '@/components/shared/base-tabs.vue';
import WeeklyUpdatesDashboard from './weekly-updates-dashboard.vue';
import ColiseumsDashboard from './coliseums-dashboard.vue';
import AttendeesAndFeedbackDashboard from './attendees-and-feedback-dashboard.vue';
import OfficeHoursDashboard from './office-hours-dashboard.vue';

defineProps({
  companies: { type: Array, default: () => ([]) },
});

const selectedTabIndex = ref(0);

</script>

<template>
  <div class="flex flex-col space-y-4">
    <base-tabs
      :tabs="['Reportes semanales', 'Coliseos', 'Asistencia y feedback', 'Office Hours']"
      size="small"
      @select="selectedTabIndex = $event"
    />
    <div v-if="selectedTabIndex === 0">
      <weekly-updates-dashboard :companies="companies" />
    </div>
    <div v-if="selectedTabIndex === 1">
      <coliseums-dashboard />
    </div>
    <div v-if="selectedTabIndex === 2">
      <attendees-and-feedback-dashboard />
    </div>
    <div v-if="selectedTabIndex === 3">
      <office-hours-dashboard />
    </div>
  </div>
</template>
