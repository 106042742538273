<script>
import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useRolesStore, useUIStore } from '../stores';
import {
  currentUserKey,
  currentUserCompanyKey,
  currentUserCompanyAuctionKey,
  currentUserMentoredBatchGroupKey,
  screenKey,
} from '../utils/keys.js';
import KalioToastsContainer from './shared/kalio-toasts-container.vue';
import TheKalioLayoutFooter from './the-kalio-layout-footer.vue';
import KalioLayoutAvatarDropdown from './kalio-layout-avatar-dropdown.vue';

export default {
  name: 'kalio-layout',
  components: {
    KalioToastsContainer,
    TheKalioLayoutFooter,
    KalioLayoutAvatarDropdown,
  },
  props: {
    withSidebar: {
      type: [Boolean, String],
      default: true,
      validator: (value) => {
        if (typeof value === 'boolean') return true;
        else if (typeof value === 'string') {
          return ['hidden'].includes(value);
        }

        return false;
      },
    },
  },
  setup() {
    const rolesStore = useRolesStore();
    const {
      isBuildSprintReviewer,
      isPartner,
      isMentor,
      isFounder,
      isBatchFounder,
      isCurrentBatchFounder,
      isPortfolioFounder,
      isPlatanusTeamMember,
      isOfficeHourProvider,
    } = storeToRefs(rolesStore);

    const { banner } = useUIStore();

    return {
      isBuildSprintReviewer,
      isPartner,
      isMentor,
      isFounder,
      isBatchFounder,
      isCurrentBatchFounder,
      isPortfolioFounder,
      isPlatanusTeamMember,
      isOfficeHourProvider,
      currentUser: inject(currentUserKey),
      currentUserCompany: inject(currentUserCompanyKey),
      currentUserCompanyAuction: inject(currentUserCompanyAuctionKey),
      currentUserMentoredBatchGroup: inject(currentUserMentoredBatchGroupKey),
      landingOrigin: inject('landingOrigin'),
      screen: inject(screenKey),
      banner,
    };
  },
  data() {
    return {
      isSidebarOpen: false,
      items: [],
    };
  },
  computed: {
    showSidebar() {
      return this.withSidebar && (this.isFounder || this.isPartner);
    },
    sidebarHidden() {
      return this.withSidebar === 'hidden';
    },
    rootPath() {
      if (window.location.pathname.startsWith('/demo_day')) {
        return '/demo_day';
      } else if (window.location.pathname.startsWith('/apply')) {
        return '/apply';
      }

      return (this.isFounder || this.isPartner || this.isOfficeHourProvider) ? '/' : '/apply';
    },
    userSignedIn() {
      return !!this.currentUser;
    },
    currentUserAvatarUrl() {
      return this.currentUser?.avatarUrl;
    },
    currentUrlItemIndex() {
      return this.items
        .map(item => item.subItems.map(subItem => subItem.url).includes(window.location.pathname))
        .indexOf(true);
    },
  },
  mounted() {
    if (this.showSidebar) {
      this.addCommunityItem();
      this.addProgramItem();
      this.addCompanyItem();
      this.addResourcesItem();
      this.addTrackingSection();
      this.addBuildSprintSection();
      this.addInternalSection();
    }
  },
  methods: {
    addCommunityItem() {
      const item = {
        title: 'Comunidad',
        icon: 'community.svg',
        open: false,
        subItems: [
          { title: 'Personas', url: '/people' },
          { title: 'Inversionistas', url: '/investors' },
          { title: 'Office hours', url: '/office_hour_offerings' },
          { title: 'Platanus Cal', url: '/community/cal_connections/new' },
          { title: 'Slack de Platanus', url: '/community/slack_channels' },
          { title: 'Lanzamientos', url: process.env.RAILS_ENV === 'production' ?
            `https://${process.env.LAUNCHES_DOMAIN}` : '/launch-pack' },
          { title: 'Recomendar startups', url: '/apply/referral/new' },
        ],
      };

      if (this.isFounder || this.isMentor || this.isPartner) {
        item.subItems.push({ title: 'Directorio de startups', url: '/community/startups' });
      }

      this.items.push(item);
    },
    addProgramItem() {
      if (this.isFounder || this.isPartner || this.isMentor) {
        const item = {
          title: 'Programa',
          icon: 'rocket.svg',
          open: false,
          subItems: [
            {
              title: 'Manual del programa',
              url: '/program/handbook',
              blank: true,
            },
          ],
        };

        if (this.isBatchFounder) {
          item.subItems.push({ title: 'Actualizaciones semanales', url: '/weekly_updates' });
        }

        if (this.isPartner || this.isPlatanusTeamMember) {
          item.subItems.push({ title: 'Coliseos', url: '/group_meetings' });
        } else if (this.isMentor || this.isCurrentBatchFounder) {
          item.subItems.push({ title: 'Ir al coliseo', url: '/group_meetings/go' });
        }

        if (this.isCurrentBatchFounder) {
          item.subItems.push({ title: 'Meta del programa', url: '/program/program_goals/new' });
          item.subItems.push({
            title: 'Configurar Ronda Virtual',
            url: '/demo_day/program/virtual_round_information/edit',
          });
        }

        if (this.currentUserCompanyAuction) {
          item.subItems.push({ title: 'Tu ronda virtual', url: '/demo_day/program/virtual_round/summary' });
        }

        this.items.push(item);
      }
    },
    addCompanyItem() {
      if (this.isFounder) {
        const item = {
          title: 'Tu empresa',
          icon: 'company.svg',
          open: false,
          subItems: [
            { title: 'Editar perfil', url: '/company/edit' },
            { title: 'Ofertas de trabajo', url: '/my_jobs' },
          ],
        };

        if (this.isBatchFounder && this.currentUserCompany?.slug) {
          item.subItems.push({
            title: 'Ver perfil público',
            url: `${this.landingOrigin}/startups/${this.currentUserCompany.slug}`,
            blank: true,
            external: true,
          });
        }

        this.items.push(item);
      }
    },
    addResourcesItem() {
      const item = {
        title: 'Recursos',
        icon: 'book.svg',
        open: false,
        subItems: [
          {
            title: 'Guía',
            url: '/community/knowledge',
          },
          {
            title: 'Material útil',
            url: '/library_items',
          },
          {
            title: 'Blog',
            url: 'https://blog.platan.us',
            blank: true,
            external: true,
          },
          {
            title: 'Directorio freelancers',
            url: 'https://platanus.notion.site/64074045e748463a9864b58326d68481?v=b3600cd0cf0d421eb4d60df9a8b2864d',
            blank: true,
            external: true,
          },
        ],
      };
      if (this.isPartner || this.isPortfolioFounder) {
        item.subItems.push({ title: 'Perks', url: '/perks' });
      }

      this.items.push(item);
    },
    addTrackingSection() {
      if (this.isPartner || this.isMentor || (this.isFounder && this.currentUserCompany?.isTracking)) {
        const item = { title: 'Seguimiento', icon: 'search.svg', open: false, subItems: [] };
        if (this.isPartner || this.isMentor) {
          item.subItems.push({ title: 'Estado startups', url: '/internal/companies' });
        }
        if (this.isPartner) {
          item.subItems.push({ title: 'Batch actual', url: '/internal/batches/current' });
        }
        if (this.isMentor) {
          item.subItems.push({
            title: 'Mi grupo',
            url: `/batch_groups/${this.currentUserMentoredBatchGroup.id}/startups`,
          });
        }
        if (this.isFounder) {
          item.subItems.push({ title: 'Actualizaciones mensuales', url: '/tracking/company_updates' });
        }
        this.items.push(item);
      }
    },
    addBuildSprintSection() {
      if (this.isBuildSprintReviewer) {
        const item = { title: 'Build Sprint', icon: 'goal.svg', open: false, subItems: [] };
        item.subItems.push({ title: 'Proyectos', url: '/build_sprint/internal/projects/review' });
        item.subItems.push({ title: 'Actualizaciones', url: '/build_sprint/internal/updates/review' });
        this.items.push(item);
      }
    },
    addInternalSection() {
      if (this.isPartner) {
        const item = { title: 'Partners', icon: 'lock.svg', open: false, subItems: [] };
        item.subItems.push({ title: 'Invitar usuario', url: '/internal/users/invite' });
        item.subItems.push({ title: 'Crear empresa', url: '/internal/companies/new' });
        item.subItems.push({ title: 'Revisar postulaciones', url: '/internal/apply/review' });
        this.items.push(item);
      }
    },
    openSidebar() {
      if (!this.screen.isMobile && !this.screen.isTablet) {
        this.isSidebarOpen = true;
      }
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    isItemOpen(index) {
      return this.items[index].open;
    },
    toggleItem(index) {
      this.items[index].open = !this.items[index].open;
    },
  },
};
</script>

<template>
  <div class="relative flex w-full flex-col items-center">
    <nav class="fixed z-30 flex w-full justify-end bg-pv-blue-900 p-4 md:absolute">
      <div class="flex w-1/2 flex-row items-center justify-between">
        <a :href="rootPath">
          <inline-svg
            :src="require('assets/images/icons/brand/platanus-isotype.svg')"
            class="-ml-3 h-6 w-auto"
          />
        </a>
        <kalio-layout-avatar-dropdown v-if="userSignedIn" />
        <a
          v-else
          href="/accounts/users/sign_in"
          class="flex flex-row items-center rounded-lg px-3 text-pv-gray hover:bg-pv-blue-700"
        >
          <inline-svg
            :src="require('assets/images/icons/outline/login.svg')"
            class="size-5 stroke-current sm:size-4"
          />
          <span class="ml-2 hidden sm:block">
            Login
          </span>
        </a>
      </div>
    </nav>

    <aside
      v-if="showSidebar"
      class="left-0 top-0 z-30 flex h-full flex-col"
      :class="[
        { 'pointer-events-none': !isSidebarOpen && (screen.isMobile || screen.isTablet) },
        sidebarHidden ? 'fixed md:absolute' : 'fixed'
      ]"
    >
      <div
        class="pointer-events-auto flex items-center overflow-hidden bg-pv-blue-900 px-4 py-5 transition-width ease-in-out"
        :class="isSidebarOpen ? 'w-56' : 'w-14'"
      >
        <button
          class="relative h-6 w-10 focus:outline-none"
          @click="toggleSidebar"
        >
          <div
            class="absolute h-0.5 w-5 rounded-sm bg-white transition ease-in-out"
            :class="isSidebarOpen ? 'top-2.5 rotate-45' : 'top-1'"
          />
          <div
            class="absolute top-2.5 h-0.5 w-5 rounded-sm bg-white transition ease-in-out"
            :class="{ '-rotate-45': isSidebarOpen }"
          />
          <div
            class="absolute top-4 h-0.5 w-3 rounded-sm bg-white transition ease-in-out"
            :class="{ 'invisible rotate-45': isSidebarOpen }"
          />
        </button>
      </div>
      <div
        class="sidebar hide-scrollbar flex h-full flex-col overflow-y-scroll bg-pv-blue-900 pb-6 pt-12 transition-width ease-in-out"
        :class="isSidebarOpen ? 'w-56' : sidebarHidden ? 'w-0' : 'w-0 md:w-14'"
        @mouseenter="openSidebar"
        @mouseleave="closeSidebar"
      >
        <div
          v-for="(item, index) in items"
          :key="`item-${index}`"
          class="flex flex-col border-t border-blue-gray-800 last:border-b"
        >
          <button
            class="flex w-56 shrink-0 flex-row items-center justify-between py-6 focus:outline-none"
            :class="currentUrlItemIndex === index ? 'border-l-4 border-pv-yellow px-3' : 'px-4'"
            @click="toggleItem(index)"
          >
            <span
              class="flex flex-row space-x-4 text-blue-gray-300"
              :href="item.url"
            >
              <inline-svg
                :src="require(`assets/images/icons/${item.icon}`)"
                class="h-auto w-6 fill-current"
              />
              <span class="text-base font-medium">
                {{ item.title }}
              </span>
            </span>
            <inline-svg
              :src="require('assets/images/icons/toggle-arrow.svg')"
              class="h-6 w-auto cursor-pointer fill-current p-2 text-blue-gray-300 transition"
              :class="isItemOpen(index) ? 'rotate-0' : 'rotate-180 '"
            />
          </button>
          <div
            class="flex flex-col overflow-hidden bg-pv-blue-800 transition-max-height ease-in-out"
            :class="isItemOpen(index) && isSidebarOpen ? 'max-h-80' : 'max-h-0'"
          >
            <a
              v-for="(subItem, subIndex) in item.subItems"
              :key="`sub-item-${subIndex}`"
              :href="subItem.url"
              :target="subItem.blank ? '_blank' : '_self'"
              class="py-2 pl-12 text-sm text-blue-gray-200 hover:bg-pv-blue-700"
            >
              <div
                v-if="subItem.external"
                class="flex flex-row items-center space-x-2"
              >
                <span>
                  {{ subItem.title }}
                </span>
                <inline-svg
                  :src="require('assets/images/icons/external.svg')"
                  class="size-3 fill-current"
                />
              </div>
              <span v-else>
                {{ subItem.title }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </aside>

    <main class="flex min-h-screen w-full flex-col items-center py-16 sm:pb-24">
      <kalio-toasts-container />
      <kalio-banner v-if="banner.isVisible" />
      <slot />
    </main>

    <the-kalio-layout-footer />
  </div>
</template>

<style>
/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
