<template>
  <div class="mt-3 flex flex-col rounded-xl border border-blue-gray-700 bg-pv-blue-700 p-6 text-base text-blue-gray-100">
    <div class="flex flex-col space-y-2">
      <div class="flex flex-row justify-between">
        <span>
          {{ $t('demoDay.virtualRound.auction.size') }}
        </span>
        <span class="font-medium blur">
          [REDACTED]
        </span>
      </div>
      <div class="ml-2 flex flex-col space-y-1 text-pv-gray">
        <div class="flex flex-row items-center justify-between gap-x-2">
          <span class=" text-xs ">
            {{ $t('demoDay.virtualRound.auction.previouslyRaised') }}
          </span>
          <span class="text-sm font-medium blur">
            [REDACTED]
          </span>
        </div>
        <div class="flex flex-row items-center justify-between gap-x-2">
          <span class="text-xs">
            {{ $t('demoDay.virtualRound.auction.virtualRoundAmount') }}
          </span>
          <span class="text-sm font-medium blur">
            [REDACTED]
          </span>
        </div>
      </div>
    </div>
    <div class="mt-3 flex flex-row justify-between">
      <span>
        CAP
      </span>
      <span class="font-medium blur">
        [REDACTED]
      </span>
    </div>
    <div class="mb-1 mt-3 flex flex-row justify-between">
      <span>
        {{ $t('demoDay.virtualRound.auction.minAmount') }}
      </span>
      <span class="font-medium blur">
        [REDACTED]
      </span>
    </div>
    <slot name="timer" />
  </div>
</template>
