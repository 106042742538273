<script setup>
import { useQueryClient } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import { useUpdateStartupApplicationMutation } from '@/queries/internal/startup_applications.js';
import { useUIStore, useStartupApplicationStore } from '@/stores';
import { humanize } from '@/filters';
import KalioConfirmationModal from '@/components/shared/kalio-confirmation-modal.vue';

const emit = defineEmits(['close']);
const props = defineProps({
  qualification: { type: String, required: true },
});

const startupApplicationStore = useStartupApplicationStore();
const {
  currentStartupApplication: startupApplication,
  currentStartupApplicationId: startupApplicationId,
} = storeToRefs(startupApplicationStore);
const queryClient = useQueryClient();

const {
  isLoading,
  mutate,
} = useUpdateStartupApplicationMutation(startupApplicationId);

const uiStore = useUIStore();

function updateQualification() {
  const updateQualificationToastSymbol = Symbol();
  uiStore.toast({
    id: updateQualificationToastSymbol,
    message: `Calificando a ${startupApplication.value.startupName} como
                  ${humanize(props.qualification)}`,
    type: 'loading',
    persist: true,
    position: 'top',
  });

  mutate(
    {
      id: startupApplication.value.id,
      body: { qualification: props.qualification },
    },
    {
      onSuccess: () => {
        uiStore.toast({
          id: updateQualificationToastSymbol,
          message: `${startupApplication.value.startupName} calificada
                    como ${humanize(props.qualification)}`,
          type: 'success',
          duration: 3000,
          position: 'top',
        });
        queryClient.invalidateQueries({
          queryKey: ['startupApplication', startupApplicationId],
        });
      },
      onError: () => uiStore.toast({
        id: updateQualificationToastSymbol,
        message: `No se pudo calificar como ${humanize(props.qualification)}
                      a ${startupApplication.value.startupName}`,
        type: 'error',
        duration: 6000,
        position: 'top',
      }),
      onSettled: () => {
        emit('close');
      },
    },
  );
}
</script>

<template>
  <kalio-confirmation-modal
    :title="`Calificar a ${startupApplication.startupName}`"
    :body="`¿Estás seguro de que deseas calificar a ${startupApplication.startupName}
                  como &quot;${$filters.humanize(qualification)}&quot;?`"
    :loading="isLoading"
    @close="$emit('close')"
    @accept="updateQualification"
  />
</template>
