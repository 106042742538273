<script setup>
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import BaseInformationCard from '@/components/shared/base-information-card.vue';

defineProps({
  groupMeeting: { type: Object, default: null },
});
</script>

<template>
  <base-information-card title="Próximo coliseo">
    <div class="flex shrink-0 flex-row space-x-3 self-start">
      <div class="flex w-16 flex-col items-center justify-center rounded-lg border border-blue-gray-700 bg-blue-gray-800 sm:w-24">
        <span class="text-sm capitalize sm:text-base">
          {{ format(new Date(groupMeeting.startDate), 'LLL', { locale: es }) }}
        </span>
        <span class="text-xl font-medium sm:text-2xl">
          {{ format(new Date(groupMeeting.startDate), 'd') }}
        </span>
      </div>
      <div class="flex flex-col space-y-0.5 sm:space-y-2">
        <span class="font-medium text-blue-gray-50">
          {{ groupMeeting.name }}
        </span>
        <span class="text-sm text-pv-gray">
          {{ groupMeeting.meetingDuration }}
        </span>
        <a
          :href="`/group_meetings/${groupMeeting.id}`"
          class="flex flex-row items-center space-x-2 text-pv-yellow hover:opacity-75"
        >
          <span class="text-xs">
            Ir a la reunión
          </span>
          <inline-svg
            :src="require('assets/images/icons/arrow.svg')"
            class="h-auto w-3 rotate-180 fill-current"
          />
        </a>
      </div>
    </div>
  </base-information-card>
</template>
