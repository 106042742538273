<script setup>
import { inject } from 'vue';
import { screenKey } from '@/utils/keys.js';
import KalioModal from '../shared/kalio-modal.vue';
import KalioHeader from '../shared/kalio-header.vue';
import KalioTrixEditor from '../form/kalio-trix-editor.vue';

defineEmits(['close', 'submit']);
defineProps({
  title: { type: String, required: true },
  headerIcon: { type: String, required: true },
  buttonLabel: { type: String, required: true },
  loading: { type: Boolean, default: false },
});

const screen = inject(screenKey);
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <div class="rounded-lg bg-pv-blue-700">
      <div class="flex flex-col p-3 sm:p-5">
        <div class="mr-12">
          <kalio-header
            :title="title"
            :icon="headerIcon"
            :size="screen.isMobile ? 'small' : 'medium'"
          />
        </div>
        <div class="mt-8 flex flex-col items-center p-4 sm:px-8">
          <kalio-trix-editor v-bind="$attrs" />
          <kalio-button
            class="mt-8 w-full sm:w-auto sm:px-24"
            :label="buttonLabel"
            :loading="loading"
            @click="$emit('submit')"
          />
        </div>
      </div>
    </div>
  </kalio-modal>
</template>
