<script>
import { computed } from 'vue';

export default {
  name: 'application-period-badge',
  props: {
    applicationPeriod: { type: String, required: true },
  },
  setup(props) {
    const applicationPeriodBadgeColor = computed(() => {
      switch (props.applicationPeriod) {
      case 'early':
        return 'green';
      case 'general':
        return 'gray';
      default:
        return 'red';
      }
    });

    return {
      applicationPeriodBadgeColor,
    };
  },
};
</script>

<template>
  <kalio-badge
    :text="$filters.capitalize(applicationPeriod)"
    :color="applicationPeriodBadgeColor"
    with-border
  />
</template>
