import api from './api';

export default {
  getAll() {
    const path = '/api/v1/likes';

    return api({
      url: path,
    });
  },
  create(companyId) {
    const path = '/api/v1/likes';

    return api({
      method: 'post',
      data: { like: { companyId } },
      url: path,
    });
  },
  delete(id) {
    const path = `/api/v1/likes/${id}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
