<script>
import { defineComponent } from 'vue';
import KalioComments from '@/components/shared/kalio-comments.vue';
import KalioButton from '@/components/shared/kalio-button.vue';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import KalioInvestmentFirmCard from '@/components/investors/kalio-investment-firm-card.vue';
import investmentFirmsApi from '@/api/investment_firms';
import demoDayInvestorApplicationsApi from '../../api/demo_day_investor_applications';

const AASM_STATE_TO_EVENT = {
  'to_review': 'mark_as_to_review',
  rejected: 'reject',
  accepted: 'accept',
};

export default defineComponent({
  name: 'demo-day-investor-application-review',
  components: {
    KalioButton,
    KalioComments,
    KalioSpinner,
    KalioInvestmentFirmCard,
  },
  props: {
    application: { type: Object, required: true },
  },
  emits: ['on-change', 'comment-created'],
  data() {
    return {
      sending: false,
      assigningFirm: false,
      creatingFirm: false,
      dynamicApplication: { ...this.application },
      loadingSuggestedFirms: true,
      suggestedInvestmentFirms: [],
      selectedInvestmentFirm: null,
    };
  },
  computed: {
    basicInfo() {
      console.log(this.dynamicApplication.formAnswers);

      return this.dynamicApplication.formAnswers.basicInfo;
    },
    others() {
      return this.dynamicApplication.formAnswers.others;
    },
    fund() {
      return this.dynamicApplication.formAnswers.fund;
    },
    angel() {
      return this.dynamicApplication.formAnswers.angel;
    },
    user() {
      return this.dynamicApplication.user;
    },
    investmentFirm() {
      return this.dynamicApplication.investmentFirm;
    },
    fullName() {
      return this.user?.fullName;
    },
    canDoItFastText() {
      const { canDoItFast } = this.basicInfo;
      if (!canDoItFast) return null;

      if (canDoItFast.value === 'Yes' || canDoItFast.value === 'Sí') {
        return '✅ Puede invertir con SAFE en 2 o 3 días';
      } else if (canDoItFast.value === 'No') {
        return '❌ No puede invertir con SAFE en 2 o 3 días';
      }

      return '❔ No sabe si puede invertir con SAFE en 2 o 3 días';
    },
    canInvestMinimumText() {
      if (!this.others?.canInvestMinimum && !this.angel?.canInvestMinimum) return null;

      if (
        this.others?.canInvestMinimum?.value === 'yes' ||
        this.angel?.canInvestMinimum?.value === 'yes'
      ) {
        return '✅ Puede invertir USD 6.000';
      }

      return '❌ No puede invertir USD 6.000';
    },
    hasCommentsListener() {
      return this.$attrs && this.$attrs.commentCreated;
    },
    fundLeadRounds() {
      if (!this.fund) return false;

      if (this.fund.leadsRounds !== 'yes' && this.fund.leadsRounds !== 'sí') return true;

      return true;
    },
    investmentFirmFromAnswers() {
      return {
        countryInformation: this.fund.country.value,
        leadRounds: this.fundLeadRounds,
        earlyStageFunds: this.fund.earlyStageFunds.value,
        ticketMinAmount: this.fund?.ticketRange?.value?.min,
        ticketMaxAmount: this.fund?.ticketRange?.value?.max,
        averageTicket: this.fund.averageTicket.value,
        industryList: this.fund.industries.value,
        regionsOfInvestmentList: this.fund.regionsOfInvestment.value,
      };
    },
  },
  watch: {
    application: {
      handler(newApplication) {
        this.dynamicApplication = { ...newApplication };
        if (newApplication.formAnswers.fund && !newApplication.investmentFirm) {
          this.getSuggestedInvestmentFirm();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async changeApplicationState(newState) {
      this.sending = true;
      try {
        await demoDayInvestorApplicationsApi.update(
          this.dynamicApplication.id,
          {
            demoDayInvestorApplication: {
              aasmStateEvent: AASM_STATE_TO_EVENT[newState],
            },
          },
        );
        this.dynamicApplication.aasmState = newState;
        this.$emit('on-change', this.dynamicApplication);
      } finally {
        this.sending = false;
      }
    },
    commentCreated(comment) {
      if (this.hasCommentsListener) {
        this.$emit('comment-created', comment);
      } else {
        this.dynamicApplication.comments = [
          ...this.dynamicApplication.comments,
          comment,
        ];
      }
    },
    async getSuggestedInvestmentFirm() {
      this.loadingSuggestedFirms = true;
      const response = await demoDayInvestorApplicationsApi.guessFirm(
        this.dynamicApplication.id,
      );
      this.suggestedInvestmentFirms = response.investmentFirms;
      this.loadingSuggestedFirms = false;
    },
    async assignInvestmentFirm() {
      this.assigningFirm = true;
      await demoDayInvestorApplicationsApi.update(
        this.dynamicApplication.id,
        {
          demoDayInvestorApplication: {
            investmentFirmId: this.selectedInvestmentFirm.id,
          },
        },
      );
      this.dynamicApplication.investmentFirm = this.selectedInvestmentFirm;
      this.$emit('on-change', this.dynamicApplication);
      this.assigningFirm = false;
    },
    async createInvestmentFirm() {
      this.creatingFirm = true;
      const response = await investmentFirmsApi.create({
        investmentFirm: {
          demoDayInvestorApplicationId: this.dynamicApplication.id,
        },
      });
      this.dynamicApplication.investmentFirm = response.investmentFirm;
      this.$emit('on-change', this.dynamicApplication);
      this.creatingFirm = false;
    },
  },
});
</script>

<template>
  <div class="grid grid-cols-4 gap-4">
    <div class="col-span-4 md:col-span-3">
      <div class="mb-4 flex w-full items-center justify-between">
        <span class="rounded-full border border-pv-light-blue px-2 py-0.5 text-sm text-pv-light-blue">
          {{ dynamicApplication.aasmState }}
        </span>
        <div class="flex gap-2">
          <kalio-button
            v-if="dynamicApplication.aasmState === 'submitted'"
            size="small"
            label="Por revisar"
            variant="tertiary"
            :loading="sending"
            @click="changeApplicationState('to_review')"
          />
          <kalio-button
            v-if="dynamicApplication.aasmState === 'submitted' || dynamicApplication.aasmState === 'to_review'"
            size="small"
            label="Rechazar"
            variant="secondary"
            :loading="sending"
            @click="changeApplicationState('rejected')"
          />
          <kalio-button
            v-if="dynamicApplication.aasmState === 'submitted' || dynamicApplication.aasmState === 'to_review'"
            size="small"
            label="Aceptar"
            :loading="sending"
            @click="changeApplicationState('accepted')"
          />
        </div>
      </div>
      <div class="flex w-full flex-col items-start text-blue-gray-50">
        <div class="mb-8 flex flex-col">
          <span class="mb-3 text-3xl">
            {{ fullName }}
          </span>
          <div class="flex flex-wrap items-center gap-x-4 gap-y-1">
            <div v-if="basicInfo.country?.value?.code">
              <country-flag
                :country="basicInfo.country.value.code.toLowerCase()"
                size="small"
              />
              <span class="ml-1 text-sm text-pv-gray">
                {{ basicInfo.country.value.name }}
              </span>
            </div>
            <div
              v-if="basicInfo.email?.value"
              class="flex items-center"
            >
              <inline-svg
                :src="require('assets/images/icons/social-media/email.svg')"
                class="size-4 fill-current"
              />
              <span class="ml-1 text-sm text-pv-gray">
                {{ basicInfo.email.value }}
              </span>
            </div>
            <div
              v-if="basicInfo.phone?.value"
              class="flex items-center"
            >
              <inline-svg
                :src="require('assets/images/icons/profile.svg')"
                class="size-4 fill-current"
              />
              <span class="ml-1 text-sm text-pv-gray">
                {{ basicInfo.phone.value }}
              </span>
            </div>
            <div
              v-if="user.socialMedia?.linkedin"
              class="flex items-center"
            >
              <inline-svg
                :src="require('assets/images/icons/social-media/linkedin.svg')"
                class="size-4 fill-current"
              />
              <a
                :href="user.socialMedia.linkedin"
                target="_blank"
                rel="noopener noreferrer"
                class="ml-1 text-sm text-pv-light-blue underline"
              >
                Linkedin
              </a>
            </div>
            <div
              v-if="others?.otherInvestments?.value"
              class="flex items-center"
            >
              <inline-svg
                :src="require('assets/images/icons/moneybag.svg')"
                class="size-4 fill-pv-yellow"
              />
              <span class="ml-1 text-sm text-pv-gray">
                {{ others.otherInvestments.value }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="fund"
          class="mb-8 flex flex-col rounded-lg border border-pv-blue-700 p-4"
        >
          <div class="mb-2 flex flex-col">
            <span
              v-if="fund.userPosition && fund.name"
              class="text-lg font-medium"
            >
              {{ `${fund.userPosition.value} en ${fund.name.value}` }}
            </span>
            <div
              v-if="fund.canWriteChecks?.value"
              class="flex items-center"
            >
              <span class="ml-1 text-sm text-pv-gray">
                {{
                  fund.canWriteChecks.value === "yes"
                    ? "✍️ Puede escribir cheques"
                    : "❌ ✍️ No puede escribir cheques"
                }}
              </span>
            </div>
            <a
              v-if="fund.url"
              class="mb-2 text-sm text-pv-light-blue underline"
              :href="fund.url.value"
              target="_blank"
            >
              {{ fund.url.value }}
            </a>
          </div>
          <kalio-investment-firm-tags
            :investment-firm="investmentFirmFromAnswers"
            :investment-stages="fund.investmentStages.value"
          />
          <div
            v-if="fund.description?.value"
            class="mb-4 flex flex-col"
          >
            <span class="text-sm font-medium">
              Descripción del fondo
            </span>
            <span class="text-sm text-pv-gray">
              {{ fund.description.value }}
            </span>
          </div>
          <div
            v-if="fund.investmentThesis?.value"
            class="flex flex-col"
          >
            <span class="text-sm font-medium">
              Tésis de inversión
            </span>
            <span class="text-sm text-pv-gray">{{
              fund.investmentThesis.value
            }}</span>
          </div>
        </div>
        <div
          v-if="dynamicApplication.user.allCompaniesInvested?.length > 0"
          class="mb-8 flex flex-col rounded-lg border border-pv-blue-700 p-4"
        >
          <span class="mb-4 text-xl font-medium">
            Startups del portafolio en las que ha invertido
          </span>
          <div class="grid w-full grid-cols-1 gap-4 rounded-md bg-pv-blue-900 md:grid-cols-3">
            <div
              v-for="(startup, i) in dynamicApplication.user.allCompaniesInvested"
              :key="startup.id"
              class="flex items-center rounded-lg border border-blue-gray-700 bg-pv-blue-700 py-2 pl-3 md:w-48"
            >
              <img
                :src="dynamicApplication.user.companiesInvestedIsotypes[i]"
                class="mr-4 size-9 rounded-md"
              >
              <span class="text-base">
                {{ startup.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="mb-8 flex w-full flex-col rounded-lg border border-pv-blue-700 p-4">
          <span class="mb-3 text-xl font-medium">
            Este Demo Day
          </span>
          <div class="flex flex-col gap-2">
            <div class="flex flex-wrap gap-4">
              <span
                v-if="canDoItFastText"
                class="ml-1 text-sm text-pv-gray"
              >
                {{ canDoItFastText }}
              </span>
              <span
                v-if="canInvestMinimumText"
                class="ml-1 text-sm text-pv-gray"
              >
                {{ canInvestMinimumText }}
              </span>
            </div>
            <div
              v-if="basicInfo.recommendation?.value"
              class="flex flex-col"
            >
              <span class="text-sm font-medium">
                ¿Alguien te recomendó venir?
              </span>
              <span class="text-sm">
                {{ basicInfo.recommendation.value }}
              </span>
            </div>
            <div
              v-if="basicInfo.acquisition?.value"
              class="flex flex-col"
            >
              <span class="text-sm font-medium">
                ¿Cómo te enteraste del Demo Day?
              </span>
              <span class="text-sm">
                {{ basicInfo.acquisition.value }}
              </span>
            </div>
            <div
              v-if="basicInfo.reasonAttendance?.value"
              class="flex flex-col"
            >
              <span class="text-sm font-medium">
                ¿Por qué quieres asistir?
              </span>
              <span class="text-sm">
                {{ basicInfo.reasonAttendance.value }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="fund">
          <div
            v-if="!investmentFirm"
            class="flex flex-col"
          >
            <span class="mb-3 text-xl font-medium">
              Configuración de fondo de inversión
            </span>
            <kalio-spinner v-if="loadingSuggestedFirms" />
            <div
              v-else
              class="flex flex-col"
            >
              <div class="mb-2 flex items-end space-x-4">
                <kalio-select-input
                  v-model="selectedInvestmentFirm"
                  name="investment_firm"
                  size="small"
                  label="Asignar a una firma de inversión existente"
                  :options="suggestedInvestmentFirms"
                  :get-option-label="(option) => option.fullName"
                />
                <kalio-button
                  size="small"
                  label="Guardar"
                  variant="tertiary"
                  :loading="assigningFirm"
                  @click="assignInvestmentFirm"
                />
              </div>
              <span class="mb-2 mt-1 flex justify-center">
                o
              </span>
              <kalio-button
                size="small"
                label="Crear nueva firma de inversión"
                variant="primary"
                :loading="creatingFirm"
                @click="createInvestmentFirm"
              />
            </div>
          </div>
          <div
            v-else
            class="flex flex-col"
          >
            <span class="mb-3 text-xl font-medium">
              Fondo de inversión vinculado
            </span>
            <kalio-investment-firm-card
              :key="investmentFirm.id"
              :link="false"
              :investment-firm="investmentFirm"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-4 rounded-lg bg-pv-blue-900 p-2 md:col-span-1">
      <kalio-comments
        :resource="application"
        resource-type="DemoDayInvestorApplication"
        @comment-created="commentCreated"
      />
    </div>
  </div>
</template>
