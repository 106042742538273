<script setup>
import { ref, onUpdated, computed } from 'vue';
import useLikes from '@/hooks/likes.js';
import { getCountryName } from '@/utils/countries.js';

const props = defineProps({
  demoDayCompany: { type: Object, required: true },
});

const company = computed(() => props.demoDayCompany.company);
const { handleLike, companyHaslike } = useLikes(company);

const industries = computed(() => company.value.industries.join(', '));

const blurb = ref(null);

onUpdated(() => {
  blurb.value.innerHTML = blurb.value.innerHTML.replace(
    /\[redacted\]/g,
    '<span class="blur-[6px]">[redacted]</span>',
  );
});
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div class="flex flex-row items-center gap-x-4">
      <div class="flex size-24 shrink-0 items-center justify-center overflow-hidden rounded-xl">
        <img
          :src="company.isotypeUrl"
          :alt="`${company.name} isotype`"
          class="size-full object-cover"
        >
      </div>
      <div class="flex flex-col items-start gap-y-1">
        <div class="flex flex-row items-center gap-x-2">
          <span class="text-2xl font-semibold">
            {{ company.name }}
          </span>
          <a
            :href="company.webpage"
            target="_blank"
          >
            <inline-svg
              :src="require('assets/images/icons/chain.svg')"
              class="size-5 stroke-current text-pv-light-blue"
            />
          </a>
          <button @click="handleLike">
            <inline-svg
              :src="require(`assets/images/icons/${companyHaslike ? 'solid' : 'outline'}/heart.svg`)"
              class="size-5"
              :class="companyHaslike ? 'fill-current text-red-400' : 'stroke-current text-blue-gray-50'"
            />
          </button>
        </div>
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1">
            <country-flag
              :country="company.country"
              size="small"
            />
            <span class="text-sm">
              {{ getCountryName(company.country) }}
            </span>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <inline-svg
              :src="require('assets/images/icons/briefcase.svg')"
              class="size-3 fill-current"
            />
            <span class="mt-0.5 text-sm">
              {{ industries }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <p
      ref="blurb"
      class="text-sm leading-6"
    >
      {{ demoDayCompany.blurb }}
    </p>
  </div>
</template>
