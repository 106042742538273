import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import auctionsApi from '../api/auctions.js';

// eslint-disable-next-line import/prefer-default-export
export function useAuctionsQuery(demoDay, options = {}) {
  const enabled = computed(() => !!demoDay.value);

  return useQuery(['auctions', demoDay],
    () => auctionsApi.getAll(demoDay.value.id).then(response => response.auctions),
    { enabled, ...options },
  );
}
