import api from './api';

const MAX_PERCENTAGE = 100;

export default {
  create(trixAttachment) {
    const data = new FormData();
    data.append('attachment[file]', trixAttachment.file);

    return api({
      method: 'post',
      url: '/api/v1/trix_attachments',
      data,
      onUploadProgress: (progress) => trixAttachment.setUploadProgress(
        progress.loaded / progress.total * MAX_PERCENTAGE,
      ),
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};
