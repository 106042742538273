<script setup>
import KalioModal from '@/components/shared/kalio-modal.vue';
import BaseCircleCheck from '@/components/shared/base-circle-check.vue';

const bulletPoints = [
  'Es opcional, pero si lo contestas le daremos más prioridad en la revisión a tu postulación.',
  'Son 120 preguntas de selección multiple.',
  'Te daremos el resultado del test por si te es útil.',
  'Te demorarás aprox. entre 5 a 9 minutos.',
];
</script>

<template>
  <kalio-modal
    width-class="max-w-xl w-full"
    custom-close-button
  >
    <div class="flex flex-col items-center space-y-8 rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 p-6 sm:space-y-11">
      <div class="flex flex-col items-center space-y-4 sm:space-y-6">
        <base-circle-check />
        <span class="text-xl font-medium text-blue-gray-50">
          Perfil de fundador guardado
        </span>
        <span class="text-blue-gray-300">
          Nos gustaria que realices un test de personalidad muy corto.
        </span>
      </div>
      <div class="flex w-full flex-col items-start">
        <span class="mb-3 text-lg font-medium text-blue-gray-50">
          ¿En qué consiste el test?
        </span>
        <div
          v-for="point in bulletPoints"
          :key="point"
          class="ml-2 mt-2 flex flex-row space-x-4 sm:mt-4"
        >
          <base-circle-check
            size="sm"
            variant="white"
          />
          <span class="text-left text-blue-gray-300">
            {{ point }}
          </span>
        </div>
      </div>
      <div class="flex w-full flex-col items-center gap-x-4 gap-y-3 px-4 sm:flex-row-reverse sm:justify-between">
        <kalio-button
          href="/apply/profile/big5/new"
          class="w-full sm:w-auto sm:px-12"
        >
          <span>
            Ir al test
          </span>
          <inline-svg
            :src="require('assets/images/icons/long-arrow.svg')"
            class="ml-3 fill-current"
          />
        </kalio-button>
        <kalio-button
          variant="secondary"
          label="Más tarde"
          href="/apply?founder_profile_submitted=true"
          class="w-full sm:w-auto sm:px-10"
        />
      </div>
    </div>
  </kalio-modal>
</template>
