<script setup>
import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import BaseUnderlineTabs from '../../../shared/base-underline-tabs.vue';
import TabSummary from './tabs/summary.vue';
import TabIdea from './tabs/idea.vue';
import TabFounders from './tabs/founders.vue';
import TabProgress from './tabs/progress.vue';
import TabLegal from './tabs/legal.vue';
import TabBuildSprint from './tabs/build-sprint.vue';
import TabUpdates from './tabs/updates.vue';
import TabOther from './tabs/other.vue';

const tabs = ref([
  'Resumen',
  'Idea',
  'Fundadores',
  'Progreso',
  'Legal',
  'Otros',
]);

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);

const inProcessUpdates = startupApplication.value.startupApplicationUpdates?.filter(
  (update) => update.kind === 'in_process',
);

if (inProcessUpdates?.length > 0) tabs.value.push('Actualizaciones');

const selectedTab = ref(0);
const changedTabAtLeastOnce = ref(false);
function selectTab(value) {
  changedTabAtLeastOnce.value = true;
  selectedTab.value = value;
}

const selectedTabComponent = computed(() => {
  switch (tabs.value[selectedTab.value]) {
  case 'Resumen':
    return TabSummary;
  case 'Idea':
    return TabIdea;
  case 'Fundadores':
    return TabFounders;
  case 'Progreso':
    return TabProgress;
  case 'Legal':
    return TabLegal;
  case 'Build Sprint':
    return TabBuildSprint;
  case 'Actualizaciones':
    return TabUpdates;
  case 'Otros':
    return TabOther;
  default:
    return TabSummary;
  }
});

onMounted(() => {
  if (startupApplication.value.hasBuildSprintParticipant) {
    tabs.value.push('Build Sprint');
  }
});
</script>

<template>
  <div class="flex grow flex-col">
    <base-underline-tabs
      :tabs="tabs"
      size="small"
      @select="selectTab"
    />
    <component
      :is="selectedTabComponent"
      class="mt-8 grow rounded-lg border border-blue-gray-700 bg-pv-blue-700 px-4 py-6"
    />
  </div>
</template>
