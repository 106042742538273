<script>
import { computed } from 'vue';
import { useUIStore } from '../../stores';

export default {
  name: 'kalio-banner',
  setup() {
    const { banner } = useUIStore();

    const containerClasses = computed(() => {
      const colorClasses = {
        white: 'bg-blue-gray-200',
      };

      return colorClasses[banner.color];
    });

    const messageClasses = computed(() => {
      const colorClasses = {
        white: 'text-black',
      };

      return colorClasses[banner.color];
    });

    return {
      banner,
      containerClasses,
      messageClasses,
    };
  },

};
</script>

<template>
  <div
    class="mt-1 flex w-full flex-col items-center px-12 py-4"
    :class="containerClasses"
  >
    <component
      :is="banner.component"
      v-if="banner.component"
    />
    <span
      v-else
      class="font-medium"
      :class="messageClasses"
    >
      {{ banner.message }}
    </span>
  </div>
</template>
