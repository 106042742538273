import api from '@/api/api';

export default {
  getAll(demoDayId) {
    const path = `/api/v1/demo_day/demo_days/${demoDayId}/connected_users`;

    return api({
      method: 'get',
      url: path,
    });
  },
};
