// https://www.youtube.com/watch?v=87rnMzENg3U
// eslint-disable-next-line import/prefer-default-export
export const legendMargin = {
  id: 'legendMargin',
  beforeInit(chart) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      this.height += 20;
    };
  },
};
