import { watch, onMounted, getCurrentInstance } from 'vue';
import { useWindowScroll } from '@vueuse/core';
import { useForm } from 'vee-validate';
import { useStore } from 'vuex';

export default function useFormSection({ section, sectionName, scrollOptions }) {
  const store = useStore();
  const form = useForm();
  const { emit } = getCurrentInstance();

  function setSectionState(valid) {
    store.dispatch('setSectionState', { name: sectionName.value, valid });
  }

  onMounted(() => {
    if (sectionName.value) {
      store.dispatch('addSection', sectionName.value).then(() => {
        form.validate({ mode: 'silent' }).then(
          ({ valid }) => setSectionState(valid),
        );
      });
    }
  });

  watch(form.values, () => {
    form.validate({ mode: 'silent' }).then(({ valid }) => setSectionState(valid));
  });

  function scrollSectionIntoView(_sectionName) {
    if (_sectionName === sectionName.value) {
      section.value.scrollIntoView(scrollOptions.value);
    }
  }

  function isElementInViewport() {
    const rect = section.value.getBoundingClientRect();

    return (
      rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  function elementCoversViewport() {
    const rect = section.value.getBoundingClientRect();

    return (
      rect.top <= 0 &&
        rect.bottom >= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  function updateVisibility() {
    if (isElementInViewport() || elementCoversViewport()) {
      emit('visible');
      store.dispatch('setVisibleSection', sectionName.value);
    }
  }

  onMounted(updateVisibility);

  const { y: windowY } = useWindowScroll();
  watch(windowY, updateVisibility);

  return {
    scrollSectionIntoView,
    form,
  };
}
