
<script setup>
import { ref, inject } from 'vue';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import KalioCarousel from '@/components/shared/kalio-carousel.vue';
import BaseOfficeHourOfferingCard from '@/components/shared/base-office-hour-offering-card.vue';
import { screenKey } from '@/utils/keys';
import OnboardingOfficeHourOfferingModal from './office-hour-offering-modal.vue';

const screen = inject(screenKey);
const error = ref(false);
const selectedOffering = ref({});
const contactModalOpen = ref(false);

const openContactModal = (offering) => {
  selectedOffering.value = offering;
  contactModalOpen.value = true;
};

const closeContactModal = () => {
  selectedOffering.value = {};
  contactModalOpen.value = false;
};

const props = defineProps({
  offerings: { type: Array, required: true },
});

const emit = defineEmits(['office-hour-requested']);
</script>

<template>
  <kalio-carousel
    :autoplay="4000"
    wrap-around
    :navigation="!screen.isMobile"
    :pagination="!screen.isMobile"
    :items="props.offerings"
    :items-to-show="screen.isMobile ? 1 : 2"
  >
    <template #item="{ item: offering }">
      <base-office-hour-offering-card
        :offering="offering"
        class="mx-2"
        @schedule="openContactModal(offering)"
      />
    </template>
  </kalio-carousel>
  <onboarding-office-hour-offering-modal
    v-if="contactModalOpen"
    :offering="selectedOffering"
    @close="closeContactModal"
    @office-hour-requested="emit('office-hour-requested')"
    @error="error = true; contactModalOpen = false"
  />
  <kalio-feedback-modal
    v-if="error"
    status="error"
    title="Hubo un error, intenta más tarde."
    accept="Ok"
    @accept="error = false"
  />
</template>
