/* eslint-disable import/prefer-default-export */
import { format, parseISO, startOfMonth, differenceInMonths, subMonths } from 'date-fns';
import { unionBy } from 'lodash';

export function mergeRevenues(applicationRevenues, updatesRevenues) {
  let revenues = [...applicationRevenues];
  updatesRevenues.forEach((update) => {
    revenues = unionBy(update, revenues, 'date');
  });

  return revenues.sort((a, b) => new Date(a.date) - new Date(b.date));
}

export function addMissingMonthsRevenues(revenues, submittedAt) {
  const lastReportedMonth = revenues.length > 0 ?
    parseISO(revenues[revenues.length - 1].date) :
    new Date(submittedAt);

  const missingMonths = differenceInMonths(
    startOfMonth(new Date()),
    startOfMonth(new Date(lastReportedMonth)),
  );

  const missingMonthsRevenues = [];
  for (let i = 0; i < missingMonths - 1; i++) {
    const month = subMonths(new Date(), i + 1);
    missingMonthsRevenues.push({ date: format(month, 'yyyy-MM'), amount: null, wasMissing: true });
  }

  return [...revenues, ...missingMonthsRevenues.reverse()];
}
