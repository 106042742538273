import api from '@/api/api';

export default {
  getAll(auctionId) {
    const path = `/api/v1/demo_day/auctions/${auctionId}/orders`;

    return api({
      method: 'get',
      url: path,
    });
  },
  create(auctionId, auctionOrder) {
    const path = `/api/v1/demo_day/auctions/${auctionId}/orders`;

    return api({
      method: 'post',
      data: { auctionOrder },
      url: path,
    });
  },
};
