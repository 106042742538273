<script setup>
import { ref } from 'vue';

const props = defineProps({
  evaluations: { type: Array, required: true },
});

const evaluations = ref(props.evaluations);

function getVoteLabel(option) {
  const labels = {
    'strong_no': 'No fuerte',
    no: 'No',
    neutral: 'Neutral',
    yes: 'Sí',
    'strong_yes': 'Si fuerte',
  };

  return labels[option];
}
</script>

<template>
  <div class="flex flex-col gap-y-3">
    <kalio-accordion-row
      v-for="evaluation in evaluations"
      :key="evaluation.id"
      :title="`Voto de ${evaluation.user.fullName}: ${getVoteLabel(evaluation.vote)}`"
      max-height-class="max-h-screen"
      :with-divider="false"
      :is-open="false"
    >
      <div class="flex gap-4 p-4">
        <div class="flex w-96 flex-col gap-y-2">
          <span class="text-sm font-bold">
            Notas
          </span>
          <div class="rounded-md bg-pv-blue-700 p-2">
            <span
              class="trix-content text-sm"
              v-html="evaluation.notes"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y-2 text-xs">
          <span class="text-sm font-bold">
            Puntajes
          </span>
          <div class="flex flex-col gap-y-1 rounded-md bg-pv-blue-700 p-2">
            <span>
              CEO score: {{ evaluation.ceoScore }}
            </span>
            <span>
              CTO score: {{ evaluation.ctoScore }}
            </span>
            <span>
              Founding team score: {{ evaluation.foundingTeamScore }}
            </span>
            <span>
              Idea score: {{ evaluation.ideaScore }}
            </span>
            <span>
              Market score: {{ evaluation.marketScore }}
            </span>
            <span>
              Progress score: {{ evaluation.progressScore }}
            </span>
            <span>
              Cap table score: {{ evaluation.capTableScore }}
            </span>
            <span>
              Finances score: {{ evaluation.financesScore }}
            </span>
          </div>
        </div>
      </div>
    </kalio-accordion-row>
  </div>
</template>
