<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import KalioCopyTextButton from '@/components/shared/kalio-copy-text-button.vue';
import BaseDailyVideo from '@/components/shared/base-daily-video.vue';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import { DAILY_STATUS } from '@/utils/recorded_interview.js';
import { useDailyStore } from '@/stores';

defineEmits(['toggle-video', 'toggle-audio', 'start-interview', 'leave-meeting', 'join-meeting']);
defineProps({
  dailyStatus: { type: String, required: true },
  recordedInterview: { type: Object, required: true },
});

const dailyStore = useDailyStore();
const { participants } = storeToRefs(dailyStore);

const textCopied = ref(false);
const copyTextRef = ref([]);
function handleCopy() {
  copyTextRef.value.copy();
}

const interviewUrl = computed(() => `${window.location.host}/apply/recorded_interview`);
</script>

<template>
  <div class="flex w-full flex-col rounded-lg bg-pv-blue-900 px-4 pb-6 pt-2 sm:px-8">
    <span class="text-blue-gray-400 sm:-ml-4">
      Usuarios en la sala
    </span>
    <div
      class="mt-8 flex flex-row items-center gap-4 overflow-x-scroll rounded-lg bg-pv-blue-700 p-2 sm:flex-wrap"
      :class="dailyStatus === DAILY_STATUS.joining ? 'justify-center' : 'sm:justify-center'"
    >
      <kalio-spinner
        v-if="dailyStatus === DAILY_STATUS.joining"
        :size="8"
        class="my-4 self-center"
      />
      <template v-else-if="dailyStatus === DAILY_STATUS.joined">
        <base-daily-video
          v-for="participant in participants"
          :key="participant.key"
          :participant="participant"
          class="h-28 w-48 rounded-lg border border-blue-gray-700"
          @toggle-video="$emit('toggle-video')"
          @toggle-audio="$emit('toggle-audio')"
          @leave-meeting="$emit('leave-meeting')"
        />
      </template>
    </div>
    <kalio-alert
      status="notice"
      title="Invita a tus cofundadores"
      class="mt-6"
      :deletable="false"
    >
      <div class="mb-4 mt-6 flex flex-col items-center">
        <p class="text-sm text-cool-gray-700">
          Deben estar todas los fundadores con 10% o más de participación en la empresa.
          <br>
          <br>
          Para invitarlos, puedes compartirles el siguiente link:
        </p>
        <div class="mt-8 flex flex-row items-center space-x-4">
          <div class="flex w-36 items-center space-x-2 rounded-lg bg-pv-light-blue/10 px-4 py-2 sm:w-full">
            <inline-svg
              :src="require('assets/images/icons/url.svg')"
              class="h-auto w-4 shrink-0 -rotate-45 stroke-current text-pv-light-blue sm:w-6"
            />
            <span class="truncate text-sm text-cool-gray-600">
              {{ interviewUrl }}
            </span>
          </div>
          <button
            class="flex h-full w-12 items-center justify-center rounded-lg border border-transparent bg-pv-light-blue/10 text-sm text-cool-gray-600 hover:border-pv-light-blue hover:bg-pv-light-blue/30"
            @click="handleCopy"
          >
            <kalio-copy-text-button
              v-if="!textCopied"
              ref="copyTextRef"
              :text="interviewUrl"
              class="w-4 text-pv-light-blue"
              @clear="textCopied = false"
            />
            <span v-else>
              Copy link
            </span>
          </button>
        </div>
      </div>
    </kalio-alert>
    <kalio-button
      label="Comenzar entrevista"
      class="mt-10 w-full self-center sm:w-auto sm:px-24"
      :disabled="dailyStatus !== DAILY_STATUS.joined"
      @click="$emit('start-interview')"
    />
  </div>
</template>
