import api from '@/api/api';

export default {
  create(officeHourOfferingId, officeHourRequest) {
    const path = '/api/v1/office_hour_requests';

    return api({
      method: 'post',
      data: {
        officeHourRequest: { officeHourOfferingId, ...officeHourRequest },
      },
      url: path,
    });
  },

  update(officeHourRequestId, officeHourRequest) {
    const path = `/api/v1/office_hour_requests/${officeHourRequestId}`;

    return api({
      method: 'patch',
      data: { officeHourRequest },
      url: path,
    });
  },
};
