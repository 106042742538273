<script>
import { inject } from 'vue';
import KalioTextListInput from '../form/kalio-text-list-input.vue';
import KalioFeedbackModal from '../shared/kalio-feedback-modal.vue';
import jobOffersApi from '../../api/job_offers.js';
import countriesApi from '../../api/countries';
import { formatCountriesApiResponse } from '../../utils/countries.js';
import { screenKey } from '../../utils/keys.js';

export default {
  name: 'kalio-job-offer-form',
  components: { KalioTextListInput, KalioFeedbackModal },
  props: {
    jobOfferId: { type: Number, default: undefined },
    initialActive: { type: Boolean, default: false },
    initialFormatOfOperation: {
      type: String,
      default: 'remote',
      validator: (value) => ['remote', 'presential', 'hybrid'].includes(value),
    },
    initialWageRange: { type: String, default: '' },
    initialPosition: { type: String, default: '' },
    initialAboutUs: { type: String, default: '' },
    initialBenefits: { type: Array, default: () => [] },
    initialDescription: { type: String, default: '' },
    initialRequiredSkills: { type: Array, default: () => [] },
    countryInformation: { type: Object, default: () => ({}) },
  },
  setup() {
    return {
      screen: inject(screenKey),
    };
  },
  data() {
    return {
      jobOffer: {
        active: this.initialActive,
        formatOfOperation: this.initialFormatOfOperation,
        wageRange: this.initialWageRange,
        countryInformation: this.countryInformation,
        position: this.initialPosition,
        aboutUs: this.initialAboutUs,
        benefits: [...this.initialBenefits],
        description: this.initialDescription,
        requiredSkills: [...this.initialRequiredSkills],
      },
      countries: [],
      loading: false,
      error: false,
    };
  },
  computed: {
    creating() {
      return !this.jobOfferId;
    },
  },
  mounted() {
    countriesApi.getCountries().then(response => {
      this.countries = formatCountriesApiResponse(response);
    });
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        const { jobOffer } = this.creating ?
          await jobOffersApi.create(this.jobOffer) :
          await jobOffersApi.update(this.jobOfferId, this.jobOffer);
        window.location = jobOffer.showUrl;
      } catch (_error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div class="w-full max-w-screen-md">
    <kalio-header
      :title="$t(`jobOffers.${creating ? 'newTitle' : 'editTitle' }`)"
      icon="form.svg"
      :size="screen.isMobile ? 'small' : 'big'"
      class="mb-12"
    />
    <vee-form
      class="flex flex-col space-y-12"
      @submit="submit"
    >
      <div class="flex flex-col space-y-8">
        <kalio-short-text-input
          v-model="jobOffer.position"
          name="job_offer[position]"
          :label="$t('jobOffers.position')"
          rules="required"
        />
        <kalio-checkbox
          v-model="jobOffer.active"
          name="job_offer[active]"
          :value="true"
          :label="$t('jobOffers.active')"
        />
        <kalio-radio-inputs
          v-model="jobOffer.formatOfOperation"
          rules="required"
          name="format_of_operation"
          :label="$t('jobOffers.formatOfOperation')"
          :values="['remote', 'presential', 'hybrid']"
          :radio-labels="[
            $t('jobOffers.formatOfOperations.remote'),
            $t('jobOffers.formatOfOperations.presential'),
            $t('jobOffers.formatOfOperations.hybrid')
          ]"
        />
        <kalio-short-text-input
          v-model="jobOffer.wageRange"
          name="job_offer[wage_range]"
          :label="$t('jobOffers.wageRange')"
          rules="required"
        />
        <kalio-select-input
          v-model="jobOffer.countryInformation"
          name="job_offer[country_information]"
          :label="$t('jobOffers.countryInformation')"
          :options="countries"
          :get-option-label="(option) => option.name"
        />
        <kalio-trix-editor
          v-model="jobOffer.aboutUs"
          name="job_offer[about_us]"
          :label="$t('jobOffers.aboutUs')"
          rules="required"
        />
        <kalio-text-list-input
          v-model="jobOffer.benefits"
          name="job_offer[benefits]"
          :label="$t('jobOffers.benefits')"
          rules="required"
        />
        <kalio-trix-editor
          v-model="jobOffer.description"
          name="job_offer[description]"
          :label="$t('jobOffers.description')"
          rules="required"
        />
        <kalio-text-list-input
          v-model="jobOffer.requiredSkills"
          name="job_offer[required_skills]"
          :label="$t('jobOffers.requiredSkills')"
          rules="required"
        />
      </div>
      <kalio-button
        class="w-full self-center px-24 md:w-auto"
        :label="$t(`jobOffers.${creating ? 'newSubmit' : 'editSubmit' }`)"
        type="submit"
        :loading="loading"
      />
    </vee-form>
    <kalio-feedback-modal
      v-if="error"
      status="error"
      :title="$t('genericError')"
      accept="Ok"
      @accept="error = null"
    />
  </div>
</template>
