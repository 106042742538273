<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from 'chart.js';
import resolveConfig from 'tailwindcss/resolveConfig';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { legendMargin } from '@/utils/charts';
import tailwindConfig from '../../../../../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

ChartJS.register(Tooltip, Legend, BarElement, LinearScale, CategoryScale, ChartDataLabels);

const PV_BLUE_GRAY = theme.colors['blue-gray'];
const FIRST_COLOR_POSITION = 4;

export default {
  name: 'startup-revenues-chart',
  components: { Bar },
  props: {
    startupApplication: { type: Object, required: true },
    applicationUpdates: { type: Array, required: true },
  },
  setup(props) {
    const inProcessUpdates = computed(
      () => props.applicationUpdates.filter((update) => update.kind === 'in_process'),
    );
    const dates = computed(() => {
      const datesSet = new Set();

      props.startupApplication.formAnswers.progress.revenues.value.forEach(
        (revenue) => datesSet.add(revenue.date),
      );
      inProcessUpdates.value.forEach(
        (update) => update.revenues.forEach(
          (revenue) => datesSet.add(revenue.date),
        ),
      );

      return [...datesSet].sort((a, b) => new Date(a) - new Date(b));
    });

    /**
    * Generates an array of revenue values for each date
    * ChartJS requires arrays of the same size for each data series
    * @return {array} - revenue value for each date. The size has to be equal to the amount of months
    * Example
    * Startup application revenues: [10, 20, 30, null, null]
    * First update revenues: [null, null, 35, 40, null]
    * Second update revenues: [null, null, null, null, 50]
    */
    function getAmountsForEachDate(revenues) {
      return dates.value.map((date) => {
        const dateRevenue = revenues.find(
          (revenue) => revenue.date === date,
        );

        return dateRevenue ? dateRevenue.amount : null;
      });
    }

    const applicationRevenuesInformedAt = computed(
      () => ({
        createdAt: format(new Date(props.startupApplication.submittedAt), 'dd MMM yyyy'),
        amounts: getAmountsForEachDate(props.startupApplication.formAnswers.progress.revenues.value),
      }),
    );

    const updatesRevenuesInformedAt = computed(() => {
      const updatesRevenues = inProcessUpdates.value.map((update) => ({
        createdAt: format(new Date(update.createdAt), 'dd MMM yyyy'),
        amounts: getAmountsForEachDate(update.revenues),
      }));

      return updatesRevenues.flat();
    });

    const revenuesInformedAt = computed(() => [
      applicationRevenuesInformedAt.value,
      ...updatesRevenuesInformedAt.value,
    ]);

    const COLOR_STEP = 100;
    const LAST_COLOR_INDEX = 900;
    const BASE = LAST_COLOR_INDEX + COLOR_STEP;

    return {
      chartData: {
        labels: dates.value.map(date => format(new Date(date), 'MMM yyyy', { locale: es })),
        datasets: revenuesInformedAt.value.map((revenue, i) => ({
          label: `update ${format(new Date(revenue.createdAt), 'MMM yyyy', { locale: es })}`,
          data: revenue.amounts,
          backgroundColor: PV_BLUE_GRAY[((i + FIRST_COLOR_POSITION) * COLOR_STEP) % BASE],
        })),
      },
      chartOptions: {
        barThickness: 'flex',
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              color: 'white',
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              color: 'white',
            },
          },
        },
        parsing: {
          yAxisKey: 'amount',
          xAxisKey: 'month',
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: 'white',
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            color: 'white',
          },
        },
      },
      plugins: [legendMargin],
    };
  },
};

</script>

<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row items-center space-x-2">
      <inline-svg
        :src="require('assets/images/icons/moneybag.svg')"
        class="size-5 fill-current"
      />
      <span class="font-semibold">
        Revenue (USD)
      </span>
    </div>
    <Bar
      id="startup-revenue-chart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :plugins="plugins"
      :height="170"
      :width="300"
    />
  </div>
</template>
