<script setup>
import { storeToRefs } from 'pinia';
import { useStartupApplicationStore } from '@/stores';
import KalioModal from '@/components/shared/kalio-modal.vue';
import StartupApplicationTagHandler from './startup-application-tag-handler.vue';

defineEmits(['close']);

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);
</script>

<template>
  <kalio-modal @close="$emit('close')">
    <div class="flex flex-col items-center space-y-6 rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 p-6">
      <kalio-header
        :title="`Agregar tags a ${startupApplication.startupName}`"
        icon="solid/tag.svg"
        size="medium"
        class="mr-4 mt-3 self-start"
      />
      <startup-application-tag-handler />
    </div>
  </kalio-modal>
</template>
