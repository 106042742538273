<script>
import { ref } from 'vue';
import humps from 'humps';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import investmentLegalInformationApi from '../api/investment_legal_information.js';
import DemoDayInvestmentLegalInformationForm from './demo-day-investment-legal-information-form.vue';

export default {
  name: 'demo-day-investment-legal-information',
  components: { DemoDayInvestmentLegalInformationForm, KalioFeedbackModal },
  props: {
    auctionOrder: { type: Object, required: true },
    lastInvestmentInformation: { type: Object, default: null },
    backTo: { type: String, default: null },
  },
  setup() {
    return {
      form: ref(null),
    };
  },
  data() {
    return {
      selectedInformation: 'previous',
      error: false,
      loading: false,
    };
  },
  computed: {
    isPreviousInformationSelected() {
      return this.selectedInformation === 'previous';
    },
    isNewInformationSelected() {
      return this.selectedInformation === 'new';
    },
    previousLegalInformationFormatted() {
      const data = this.lastInvestmentInformation.investmentData;
      const investmentType = humps.camelize(this.lastInvestmentInformation.investmentType);
      let keys;

      if (investmentType === 'legalPerson') {
        keys = [
          'businessName',
          'legalPersonRut',
          'legalRepresentativeRut',
          'legalRepresentativeName',
          'legalPersonAddress',
        ];
      } else {
        keys = [
          'naturalPersonName',
          'naturalPersonRut',
          'naturalPersonAddress',
        ];
      }

      const personType = this.$t(
        `demoDay.investmentLegalInformation.investmentType.${investmentType}`,
      );

      return `${personType}, ${keys.map(key => data[key]).join(', ')}`;
    },
  },
  methods: {
    submitPreviousLegalInformation() {
      this.loading = true;

      investmentLegalInformationApi.create({
        investmentLegalInformation: {
          ...this.lastInvestmentInformation,
          auctionOrderId: this.auctionOrder.id,
        },
      })
        .then(() => {
          this.handleSubmitted();
        })
        .catch(() => {
          this.handleError();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSubmitted() {
      const urlParams = new URLSearchParams(window.location.search);
      const backTo = urlParams.get('back_to') || '/demo_day/virtual_round';
      window.location = `${backTo}?investment_legal_information_saved=true`;
    },
    handleError() {
      this.error = true;
    },
    selectNewInformation() {
      if (this.isNewInformationSelected) return;

      this.selectedInformation = 'new';
      this.$nextTick(() => this.form.$el.scrollIntoView({ behavior: 'smooth' }));
    },
  },
};
</script>

<template>
  <div class="flex flex-col">
    <kalio-back-button :href="backTo || '/demo_day/virtual_round'" />
    <kalio-header
      :title="$t('demoDay.investmentLegalInformation.investmentData')"
      icon="id-card.svg"
      size="big"
      class="mt-10"
    />
    <span
      v-if="!!lastInvestmentInformation"
      class="mt-10"
    >
      {{ $t('demoDay.investmentLegalInformation.selectAnyTwoOptions') }}
    </span>

    <button
      v-if="!!lastInvestmentInformation"
      class="relative mt-10 flex flex-row justify-between rounded-xl border bg-pv-blue-900 px-6 py-3 pr-16 focus:outline-none"
      :class="isPreviousInformationSelected ? 'border-pv-light-blue' : 'border-blue-gray-500'"
      @click="selectedInformation = 'previous'"
    >
      <div
        class="absolute right-2.5 top-2.5 flex size-8 shrink-0 items-center justify-center rounded-full border-2 sm:right-3 sm:top-5"
        :class="isPreviousInformationSelected
          ? 'bg-blue-gray-100 border-blue-gray-100'
          : 'bg-pv-blue-800 border-blue-gray-400'"
      >
        <inline-svg
          :src="require('assets/images/icons/check.svg')"
          class="size-4 fill-current"
          :class="isPreviousInformationSelected ? 'text-pv-blue-900' : 'text-pv-blue-800'"
        />
      </div>
      <div class="flex flex-col items-start space-y-4">
        <span class="font-medium">
          {{ $t('demoDay.investmentLegalInformation.previousInformation') }}
        </span>
        <span class="text-left text-base text-blue-gray-300">
          {{ previousLegalInformationFormatted }}
        </span>
      </div>
    </button>

    <button
      class="relative flex flex-row justify-between rounded-xl border bg-pv-blue-900 p-6 focus:outline-none"
      :class="[
        isNewInformationSelected ? 'border-pv-light-blue' : 'border-blue-gray-500',
        lastInvestmentInformation ? 'mt-6' : 'mt-12 sm:mt-16'
      ]"
      data-testid="new-information"
      @click="selectNewInformation"
    >
      <div
        class="absolute right-2.5 top-6 flex size-8 shrink-0 items-center justify-center rounded-full border-2 sm:right-3"
        :class="isNewInformationSelected
          ? 'bg-blue-gray-100 border-blue-gray-100'
          : 'bg-pv-blue-800 border-blue-gray-400'"
      >
        <inline-svg
          v-if="isNewInformationSelected"
          :src="require('assets/images/icons/check.svg')"
          class="size-4 fill-current"
          :class="isPreviousInformationSelected ? 'text-pv-blue-900' : 'text-pv-blue-800'"
        />
      </div>
      <div class="flex w-full flex-col items-start">
        <span class="font-medium">
          {{ $t('demoDay.investmentLegalInformation.addNewInformation') }}
        </span>
        <div
          v-show="isNewInformationSelected"
          class="w-full"
        >
          <demo-day-investment-legal-information-form
            ref="form"
            :auction-order-id="auctionOrder.id"
            class="mt-8"
            @submitted="handleSubmitted"
            @error="handleError"
          />
        </div>
      </div>
    </button>

    <kalio-button
      v-if="isPreviousInformationSelected"
      :label="$t('form.submit')"
      class="mt-12 self-center px-24"
      :loading="loading"
      @click="submitPreviousLegalInformation"
    />

    <kalio-feedback-modal
      v-if="error"
      :title="$t('genericError')"
      status="error"
      :accept="$t('form.accept')"
      @accept="error = false"
    />
  </div>
</template>
