<script>
import { computed } from 'vue';

export default {
  name: 'application-aasm-state-badge',
  props: {
    aasmState: { type: String, required: true },
  },
  setup(props) {
    const aasmStateBadgeColor = computed(() => {
      switch (props.aasmState) {
      case 'discarded':
        return 'red';
      case 'selected':
        return 'green';
      default:
        return 'blue';
      }
    });

    return {
      aasmStateBadgeColor,
    };
  },
};
</script>

<template>
  <kalio-badge
    :text="$t(`startupApplication.aasmStates.${$filters.camelize(aasmState)}`)"
    :type="aasmStateBadgeColor"
    with-border
  />
</template>
