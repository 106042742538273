<script>
import { ref, watch } from 'vue';
import DemoDayAuctionInformation from './demo-day-auction-information.vue';

export default {
  name: 'virtual-round-information-form',
  components: { DemoDayAuctionInformation },
  props: {
    demoDayCompany: { type: Object, required: true },
    company: { type: Object, required: true },
    auction: { type: Object, required: true },
    action: { type: String, required: true },
    method: { type: String, required: true },
    enctype: { type: String, required: true },
  },
  setup(props) {
    const dynamicAuction = ref({
      ...props.auction,
      publicSlackMessageOrderNotificationString: props.auction.publicSlackMessageOrderNotification ? 'true' : 'false',
    });
    const dynamicDemoDayCompany = ref({ ...props.demoDayCompany });

    const totalAmount = ref(props.auction.amountRaisedPreviously + props.auction.targetAmount);

    watch([totalAmount, dynamicAuction], () => {
      dynamicAuction.value.targetAmount =
        totalAmount.value - dynamicAuction.value.amountRaisedPreviously;
    }, { immediate: true, deep: true });

    watch([dynamicAuction.value.publicSlackMessageOrderNotificationString], () => {
      dynamicAuction.value.publicSlackMessageOrderNotification =
        dynamicAuction.value.publicSlackMessageOrderNotificationString === 'true';
    }, { immediate: true });

    return {
      dynamicAuction,
      dynamicDemoDayCompany,
      totalAmount,
    };
  },
};
</script>

<template>
  <kalio-form-wrapper
    v-slot="{ loading }"
    class="mt-8 rounded-2xl bg-pv-blue-900 p-8"
    :action="action"
    :method="method"
    :enctype="enctype"
  >
    <div class="mb-6 flex flex-col space-y-4">
      <kalio-long-text-input
        rules="required"
        name="virtual_round_information[blurb_es]"
        label="Blurb en español"
        :value="dynamicDemoDayCompany.blurbEs"
        description="Este texto lo mostraremos a los inversionistas de habla hispana en Demo Day para que sepan
                      de qué trata y cómo va tu empresa."
      />
      <kalio-long-text-input
        rules="required"
        name="virtual_round_information[blurb_en]"
        label="Blurb en inglés"
        :value="dynamicDemoDayCompany.blurbEn"
        description="Este texto lo mostraremos a los inversionistas de habla inglesa en Demo Day para que sepan
                      de qué trata y cómo va tu empresa."
      />
      <kalio-long-text-input
        rules="required"
        name="virtual_round_information[blurb_redacted_es]"
        label="Blurb redacted en español"
        :value="dynamicDemoDayCompany.blurbRedactedEs"
        description="Este texto lo mostraremos a los inversionistas de habla hispana unos días antes. Los números
                      de tracción debes reemplazarlos por el texto [redacted]."
      />
      <kalio-long-text-input
        rules="required"
        name="virtual_round_information[blurb_redacted_en]"
        label="Blurb redacted en inglés"
        :value="dynamicDemoDayCompany.blurbRedactedEn"
        description="Este texto lo mostraremos a los inversionistas de habla inglesa unos días antes. Los números
                      de tracción debes reemplazarlos por el texto [redacted]."
      />
      <kalio-short-text-input
        :value="dynamicDemoDayCompany.investorsBookingLink"
        rules="required|url"
        name="virtual_round_information[investors_booking_link]"
        label="Link de Calendly para inversión"
        description="En este link te agendarán los inversionistas que quieran invertir."
      />
      <kalio-short-text-input
        :value="dynamicDemoDayCompany.bookingLink"
        rules="required|url"
        name="virtual_round_information[booking_link]"
        label="Link de Calendly para otros motivos"
        description="En este link te agendarán los inversionistas que quieran hablar contigo por otra razón."
      />
      <kalio-file-input
        id="avatar-input"
        rules="required|ext:png,jpg"
        name="virtual_round_information[slide]"
        :src="demoDayCompany.slideEsUrl"
        label="Slide con puntos principales"
        description="Debe ser la slide final de tu presentación, que tiene los
                      mensajes principales. Lo mostraremos a los inversionistas."
      />
      <kalio-file-input
        rules="required|ext:png,jpg"
        name="virtual_round_information[slide_en]"
        :src="demoDayCompany.slideEnUrl"
        label="Slide en inglés con puntos principales"
        description="Debe ser la slide final de tu presentación, que tiene los
                      mensajes principales. Lo mostraremos a los inversionistas de habla inglesa."
      />
      <kalio-short-text-input
        v-model="dynamicAuction.cap"
        rules="required|numeric"
        name="virtual_round_information[cap]"
        label="CAP"
      />
      <kalio-short-text-input
        v-model="totalAmount"
        rules="required|numeric"
        name="virtual_round_information[total_amount]"
        label="Monto total de su ronda"
        description="Lo que levantaron en las semanas previas del Demo Day + lo que les falta por levantar."
      />
      <kalio-short-text-input
        v-model="dynamicAuction.amountRaisedPreviously"
        rules="required|numeric"
        name="virtual_round_information[amount_raised_previously]"
        label="Monto que levantaron en las semanas previas al Demo Day"
      />
      <kalio-short-text-input
        v-model="dynamicAuction.minAmount"
        rules="required|numeric"
        name="virtual_round_information[min_amount]"
        label="Monto mínimo de inversión"
        description="Sugerimos un monto de US $6,000"
        placeholder="6000"
      />
      <kalio-radio-inputs
        v-model="dynamicAuction.publicSlackMessageOrderNotificationString"
        name="virtual_round_information[public_slack_message_order_notification]"
        rules="required"
        label="¿Dónde quieres que se publique en Slack si llega una oferta para tu startup?"
        description="En el canal compartido de Demo Day podemos celebrar tod@s junt@s,
                      pero si prefieres mantenerlo en privado, está perfecto también."
        :values="['true', 'false']"
        :radio-labels="['Canal de Demo Day', 'Canal privado entre Platanus y tu empresa']"
      />
    </div>

    <demo-day-auction-information :auction="dynamicAuction" />

    <kalio-button
      type="submit"
      :loading="loading"
      label="Guardar"
      class="mt-6 w-full px-12"
    />
  </kalio-form-wrapper>
</template>
