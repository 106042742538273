<script>
export default {
  name: 'ProgressCheckpointBar',
  props: {
    checkpoints: { type: Array, required: true },
    hideText: { type: Boolean, default: false },
    currentCheckpoint: { type: Number, required: true },
    variant: { type: String, default: 'yellow' },
    orientation: { type: String, default: 'horizontal' },
    textClasses: { type: String, default: 'px-4 text-base text-center' },
    size: {
      type: String,
      default: 'large',
      validator: (value) => ['small', 'large'].includes(value),
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isHorizontal() { return this.orientation === 'horizontal'; },
    checkIconColor() {
      const options = { 'yellow': 'text-white', white: 'text-pv-blue-900' };

      return options[this.variant];
    },
    color() {
      const options = { yellow: 'pv-yellow', white: 'white' };

      return options[this.variant];
    },
    edgeClass() {
      const horizontal = 'w-full h-px';
      const vertical = 'flex-grow w-px';

      return this.isHorizontal ? horizontal : vertical;
    },
  },
};
</script>

<template>
  <div
    class="flex"
    :class="isHorizontal ? 'flex-row' : 'flex-col items-start'"
  >
    <div
      v-for="(checkpoint, index) in checkpoints"
      :key="index"
      class="flex"
      :class="isHorizontal ? 'flex-col items-center space-y-2' : 'flex-row flex-shrink-0 space-x-4'"
    >
      <div
        class="flex shrink-0"
        :class="isHorizontal ? 'w-full flex-row items-center' : 'flex-col items-center'"
      >
        <div
          :class="[
            edgeClass,
            index <= currentCheckpoint ? `bg-${color}` : 'bg-pv-gray',
            { 'invisible': index === 0 },
            { 'hidden': !isHorizontal }
          ]"
        />
        <div
          class="flex shrink-0 items-center justify-center overflow-hidden rounded-full"
          :class="{
            'size-12': size === 'large',
            'size-6': size === 'small',
            'self-start': isHorizontal,
            'border-3': index >= currentCheckpoint,
            [`border-${color}`]: index === currentCheckpoint,
            'border-pv-gray-400': index > currentCheckpoint
          }"
        >
          <div
            v-if="index < currentCheckpoint"
            class="flex size-full items-center justify-center"
            :class="`bg-${color}`"
          >
            <inline-svg
              :src="require('assets/images/icons/check.svg')"
              class="size-6 fill-current"
              :class="checkIconColor"
            />
          </div>
          <div
            v-if="index === currentCheckpoint"
            class="rounded-full"
            :class="[
              'bg-${color}',
              { 'size-4': size === 'large', 'size-2': size === 'small' },
            ]"
          />
        </div>
        <div
          :class="[
            edgeClass,
            index < currentCheckpoint ? `bg-${color}` : 'bg-pv-gray',
            { 'invisible': index === checkpoints.length - 1 }
          ]"
        />
      </div>
      <span
        v-if="hideText"
        class="w-28 md:w-40"
      />
      <span
        v-else
        :class="[textClasses, index === currentCheckpoint ? `text-${color}` : 'text-gray-400']"
      >
        {{ checkpoint }}
      </span>
    </div>
  </div>
</template>
