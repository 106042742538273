
<script setup>
import { ref, computed } from 'vue';
import KalioSearchBar from '../../shared/kalio-search-bar.vue';

const props = defineProps({
  companies: { type: Array, default: () => ([]) },
});

const searchFilter = ref(null);

const filteredCompanies = computed(() => {
  if (!searchFilter.value) {
    return props.companies;
  }

  const filter = searchFilter.value.toLowerCase();

  return props.companies.filter(company => company.name.toLowerCase().includes(filter));
});

function programGoalText(company) {
  const periodTypeText = company.programGoal.periodType === 'weekly' ? 'semana a semana' : 'mes a mes';

  return `${company.programGoal.periodGoal} ${company.programGoal.kpiName} ${periodTypeText}`;
}
</script>

<template>
  <div class="flex flex-col space-y-4">
    <kalio-search-bar
      v-model="searchFilter"
      placeholder="Buscar..."
      rules="required"
    />
    <div class="flex w-full flex-col space-y-4">
      <div
        v-for="company in filteredCompanies"
        :key="company.id"
        class="flex flex-col space-y-2 rounded-lg border border-blue-gray-600 bg-pv-blue-800 p-4 md:space-y-8"
      >
        <div class="flex w-full flex-row items-start">
          <div class="size-20 shrink-0 overflow-hidden rounded-lg">
            <img
              :src="company.isotypeUrl"
              class="size-full object-cover"
            >
          </div>
          <div class="ml-3 flex flex-col">
            <span class="text-2xl font-medium">
              {{ company.name }}
            </span>
            <span class="text-base text-pv-gray">
              {{ company.slogan }}
            </span>
            <div class="mt-2 flex flex-row items-center space-x-1">
              <inline-svg
                :src="require('assets/images/icons/goal.svg')"
                class="size-5 fill-current text-pv-yellow"
              />
              <span class="text-sm text-pv-gray">
                {{ (company.programGoal && programGoalText(company)) || 'No tiene programa creado' }}
              </span>
            </div>
          </div>
          <a
            :href="company.statusPath"
            target="blank_"
            class="ml-auto hidden shrink-0 flex-row items-center space-x-2 text-pv-yellow md:flex"
          >
            <span class="text-base">
              Ir a ficha
            </span>
            <inline-svg
              :src="require('assets/images/icons/arrow.svg')"
              class="size-3 rotate-180 fill-current"
            />
          </a>
        </div>
        <a
          :href="company.statusPath"
          target="blank_"
          class="flex flex-row items-center space-x-2 self-start text-pv-yellow md:hidden"
        >
          <span class="text-base">
            Ir a ficha
          </span>
          <inline-svg
            :src="require('assets/images/icons/arrow.svg')"
            class="size-3 rotate-180 fill-current"
          />
        </a>
        <div class="grid grid-cols-1 gap-2 md:grid-cols-3 lg:gap-4 xl:grid-cols-4">
          <div class="flex flex-col space-y-1">
            <span class="text-base text-pv-gray">
              Reporte subido
            </span>
            <span
              :class="company.weeklyUpdateSubmitted ? 'text-emerald-400' : 'text-red-400'"
              class="text-2xl font-medium"
            >
              {{ company.weeklyUpdateSubmitted ? 'Sí' : 'No' }}
            </span>
          </div>
          <div class="flex flex-col space-y-1 overflow-x-scroll">
            <span class="whitespace-nowrap text-base text-pv-gray">
              {{ (company.programGoal && company.programGoal.kpiName) || 'kpi' }}
            </span>
            <div class="flex flex-row items-center space-x-2">
              <span class="text-lg font-medium lg:text-2xl">
                {{ company.lastWeeklyUpdateKpi }}
              </span>
              <span
                class="text-base lg:text-xl"
                :class="company.lastWeeklyUpdateKpiVariation > 0 ? 'text-emerald-400' : 'text-red-400'"
              >
                ({{ company.lastWeeklyUpdateKpiVariationText }})
              </span>
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <span class="text-sm text-pv-gray lg:text-base">
              Meta lograda
            </span>
            <span class="text-lg font-medium lg:text-2xl">
              {{ company.totalWeeksGoalAchieved }}/{{ company.weeklyUpdates.length }} semanas
            </span>
          </div>
          <div class="flex flex-col space-y-1">
            <span class="text-sm text-pv-gray lg:text-base">
              Office hours pedidos
            </span>
            <span class="text-lg font-medium lg:text-2xl">
              {{ company.totalOfficeHoursRequested }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
