<script setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import { useUIStore } from '@/stores';
import useActionCable from '@/hooks/actioncable.js';
import { useAuctionsQuery } from '../queries/auctions.js';
import { useVirtualRoundStore } from '../stores';
import useVirtualRoundTimer from '../composables/virtual_round_timer.js';
import { auctionChannelKey, demoDayStreamingChannelKey } from '../utils/keys.js';
import DemoDayCompanyCard from './demo-day-company-card.vue';
import DemoDayAuctionMenu from './demo-day-auction-menu.vue';
import DemoDayCompanyMobileAccordion from './demo-day-company-mobile-accordion.vue';
import DemoDayCompaniesDashboard from './demo-day-companies-dashboard';
import DemoDayMobileCompanyTabs from './demo-day-mobile-company-tabs.vue';
import DemoDayCompanyDashboard from './demo-day-company-dashboard.vue';
import DemoDayCommunityInvestorApplicationModal from './demo-day-community-investor-application-modal.vue';
import DemoDayPresentationsFinishedBannerContent from './demo-day-presentations-finished-banner-content.vue';

const props = defineProps({
  batchSeason: { type: String, required: true },
  demoDay: { type: Object, required: true },
  demoDayCompanies: { type: Array, required: true },
  investorAttendance: { type: Object, required: true },
  communityFormSections: { type: Object, required: true },
  investorApplication: { type: Object, default: null },
});

const virtualRoundStore = useVirtualRoundStore();
virtualRoundStore.setDemoDay(props.demoDay);
virtualRoundStore.setInvestorAttendance(props.investorAttendance);
const { demoDay } = storeToRefs(virtualRoundStore);

const { virtualRoundTimer } = useVirtualRoundTimer();
const uiStore = useUIStore();

const { refetch: refetchAuctions, isFetching: isFetchingAuctions } = useAuctionsQuery(demoDay, {
  onSuccess: (data) => {
    virtualRoundStore.setAuctions(data);
  },
});

watch(virtualRoundTimer, () => {
  if (virtualRoundTimer.demoDayFinished) {
    uiStore.setBanner({
      isVisible: true,
      component: DemoDayPresentationsFinishedBannerContent,
    });
  }
});

const newOrderSymbol = Symbol();
const newMeetingSymbol = Symbol();
const { t } = useI18n();

useActionCable(auctionChannelKey, {
  received(event) {
    if (event.kind === 'auction_order_created') {
      uiStore.toast({
        id: newOrderSymbol,
        message: t('demoDay.virtualRound.someoneInvested'),
        type: 'success',
        duration: 15000,
      });
    } else if (event.kind === 'auction_updated') {
      refetchAuctions();
    } else if (event.kind === 'meeting_scheduled') {
      uiStore.toast({
        id: newMeetingSymbol,
        message: t('demoDay.virtualRound.someoneScheduledMeeting'),
        type: 'semi-dark',
        duration: 15000,
      });
    }
  },
});

useActionCable(demoDayStreamingChannelKey, {
  received(event) {
    if (event.kind === 'demo_day_updated') {
      virtualRoundStore.refetchDemoDay();
    }
  },
});

const communityApplicationModalOpen = ref(false);

</script>

<template>
  <div class="flex w-full flex-col">
    <span class="mt-4 text-base text-pv-gray md:text-2xl">
      Demo Day {{ batchSeason }}
    </span>
    <div class="flex flex-row items-center space-x-4">
      <span class="text-2xl font-medium capitalize md:mt-2 md:text-4xl">
        {{ $t('demoDay.virtualRound.title') }}
      </span>
      <a
        href="/demo_day/streaming"
        class="mt-0.5 flex flex-row items-center space-x-1 hover:opacity-75 md:mt-2.5"
      >
        <span class="text-base text-pv-yellow">
          {{ $t('demoDay.virtualRound.goToStreaming') }}
        </span>
        <inline-svg
          class="fill-current text-pv-yellow"
          :src="require('assets/images/icons/switch.svg')"
        />
      </a>
    </div>
  </div>

  <div class="mt-8 flex flex-col divide-y divide-blue-gray-700 overflow-hidden rounded-xl border border-blue-gray-700 lg:hidden">
    <demo-day-company-mobile-accordion
      v-for="(demoDayCompany, index) in demoDayCompanies"
      :key="demoDayCompany.id"
      :should-open-accordion-on-rewatch-event="index === 0"
      :demo-day-company="demoDayCompany"
    >
      <div class="bg-pv-blue-900 p-4">
        <demo-day-mobile-company-tabs
          :should-scroll-into-view-on-rewatch-event="index === 0"
          :demo-day-company="demoDayCompany"
          @open-community-application-modal="communityApplicationModalOpen = true"
        />
      </div>
    </demo-day-company-mobile-accordion>
  </div>

  <demo-day-companies-dashboard
    class="mt-8 hidden lg:flex"
    :demo-day-companies="demoDayCompanies"
  >
    <template #default="{ demoDayCompany }">
      <div class="flex w-full flex-row divide-x divide-blue-gray-700 bg-pv-blue-800 py-8">
        <demo-day-company-dashboard
          class="w-full pl-6 pr-4"
          :demo-day-company="demoDayCompany"
        >
          <template #card>
            <demo-day-company-card :demo-day-company="demoDayCompany" />
          </template>
        </demo-day-company-dashboard>
        <div class="flex w-112 flex-col items-center px-4">
          <kalio-spinner
            v-if="isFetchingAuctions"
            class="mt-12"
          />
          <demo-day-auction-menu
            v-else
            :demo-day-company="demoDayCompany"
            @open-community-application-modal="communityApplicationModalOpen = true"
          />
        </div>
      </div>
    </template>
  </demo-day-companies-dashboard>

  <demo-day-community-investor-application-modal
    :show="communityApplicationModalOpen"
    :form-sections="communityFormSections"
    :investor-application="investorApplication"
    @close="communityApplicationModalOpen = false"
  />
</template>
