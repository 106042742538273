<script setup>
import { computed } from 'vue';
import ProgressCheckpoint from '@/components/shared/progress-checkpoint.vue';

const props = defineProps({
  groupMeetingCommitments: { type: Array, required: true },
  groupMeeting: { type: Object, required: true },
});

const currentMeetingCommitment = computed(
  () => props.groupMeetingCommitments
    .find(commitment => commitment.groupMeetingId === props.groupMeeting.id),
);

const nextMeetingCreated = computed(() => !!props.groupMeeting.nextMeeting);
const nextMeetingCommitment = computed(() => {
  if (!nextMeetingCreated.value) return null;

  return props.groupMeetingCommitments.find(
    commitment => commitment.groupMeetingId === props.groupMeeting.nextMeeting.id,
  );
});
</script>

<template>
  <div class="bg-pv-blue-900 p-2">
    <div
      v-if="!currentMeetingCommitment && !nextMeetingCommitment"
      class="self-center py-2 text-pv-gray"
    >
      <span>
        ❌
      </span>
      <span class="ml-2">
        No hay compromisos creados
      </span>
    </div>
    <div
      v-else
      class="flex flex-col"
    >
      <div class="flex flex-row space-x-2">
        <progress-checkpoint
          state="current"
          position="start"
          :with-check-icon="false"
        />
        <div class="flex flex-col">
          <span class="text-sm italic text-blue-gray-400">
            Compromisos para hoy
          </span>
          <div
            v-if="currentMeetingCommitment"
            class="flex flex-col gap-1"
          >
            <div
              class="whitespace-pre-line"
              v-html="currentMeetingCommitment.body"
            />
            <p
              v-if="currentMeetingCommitment.achievements"
              class="text-sm italic"
            >
              {{ currentMeetingCommitment.achievements }}
            </p>
          </div>
          <span
            v-else
            class="mb-8 mt-1 italic text-blue-gray-400"
          >
            No hay compromiso para este coliseo
          </span>
        </div>
      </div>

      <div
        v-if="nextMeetingCommitment"
        class="flex flex-row space-x-2"
      >
        <progress-checkpoint
          state="next"
          position="end"
          :with-check-icon="false"
        />
        <div class="mt-4 flex flex-col">
          <span class="text-sm italic text-blue-gray-400">
            Compromisos para el próximo coliseo
          </span>
          <div
            class="mt-4 whitespace-pre-line text-sm"
            v-html="nextMeetingCommitment.body"
          />
        </div>
      </div>
    </div>
  </div>
</template>
