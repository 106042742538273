import api from './api';

export default {
  getAll(officeHourRequestId) {
    const path = `/api/v1/office_hour_requests/${officeHourRequestId}/library_item_recommendations`;

    return api({
      method: 'get',
      url: path,
    });
  },
};
