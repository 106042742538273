<script setup>
import { ref, computed, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import groupMeetingPreparationsApi from '@/api/group_meeting_preparations.js';
import weeklyUpdatesApi from '@/api/weekly_updates.js';
import { useGroupMeetingCommitmentsQuery } from '@/queries/group_meeting_commitments';
import BaseTabs from '../shared/base-tabs.vue';
import KalioProgramGoalPanel from '../program/kalio-program-goal-panel.vue';
import BaseWeeklyUpdateCard from '../weekly-updates/base-card.vue';
import BaseGroupMeetingCommitmentsCard from '../shared/base-group-meeting-commitments-card.vue';
import KalioSpinner from '../shared/kalio-spinner.vue';
import KalioWeeklyUpdatesChart from '../weekly-updates/chart.vue';
import KalioGroupMeetingsCompanyComments from './company-comments.vue';

const emit = defineEmits(['fetch-success']);
const props = defineProps({
  groupMeetings: { type: Array, required: true },
  companies: { type: Array, required: true },
});

const groupMeetingIndex = ref(props.groupMeetings.length - 1);
const selectedGroupMeeting = computed(() => props.groupMeetings[groupMeetingIndex.value]);

function nextMeeting() {
  if (groupMeetingIndex.value < props.groupMeetings.length - 1) {
    groupMeetingIndex.value += 1;
  }
}
function prevMeeting() {
  if (groupMeetingIndex.value > 0) {
    groupMeetingIndex.value -= 1;
  }
}

const selectedCompany = ref(props.companies[0]);
function selectCompany(index) {
  selectedCompany.value = props.companies[index];
}

const {
  data: groupMeetingPreparations,
  isFetching: isFetchingGroupMeetingPreparations,
} = useQuery(['groupMeetingPreparations', selectedCompany],
  () => groupMeetingPreparationsApi.getAll({ companyId: selectedCompany.value.id })
    .then(data => data.groupMeetingPreparations),
);

const groupMeetingPreparation = computed(
  () => groupMeetingPreparations.value?.find(
    prep => prep.groupMeetingId === selectedGroupMeeting.value.id,
  ),
);

const {
  data: weeklyUpdates,
  isFetching: isFetchingWeeklyUpdates,
} = useQuery(['weeklyUpdates', selectedCompany],
  () => weeklyUpdatesApi.getAll({ companyId: selectedCompany.value.id })
    .then(data => data.weeklyUpdates),
);

const weeklyUpdate = computed(
  () => weeklyUpdates.value?.find(
    update => update.groupMeetingId === selectedGroupMeeting.value.id,
  ),
);

const weeklyUpdatesPoints = computed(() => (
  weeklyUpdates.value?.map(update => ({ kpi: update.kpi, startDate: update.startDate }))),
);
const queryParams = new URLSearchParams(window.location.search);
const meetingIdParam = queryParams.get('meeting_id');
const meetingIdParamIndex = props.groupMeetings.findIndex(meeting => meeting.id === meetingIdParam);
if (meetingIdParamIndex !== -1) {
  groupMeetingIndex.value = meetingIdParamIndex;
}

watch(groupMeetingIndex, () => {
  const meetingId = props.groupMeetings[groupMeetingIndex.value].id;
  window.history.replaceState({}, null, `?meeting=${meetingId}`);
}, { immediate: true });

const chartOptions = computed(() => {
  const meetingIndex = groupMeetingIndex.value;

  return {
    elements: {
      point: {
        radius: (context) => (context.dataIndex === meetingIndex ? 6 : 8),
      },
    },
  };
});

const { data: selectedCompanyCommitments } = useGroupMeetingCommitmentsQuery(
  selectCompany,
  {
    onSuccess: () => { emit('fetch-success'); },
  },
);
</script>

<template>
  <div class="flex w-full grow flex-col rounded-lg border border-blue-gray-700 bg-pv-blue-900 p-3 lg:p-6">
    <div class="mt-4 flex flex-row items-start space-x-6 self-center">
      <button
        v-tooltip="'coliseo anterior'"
        class="flex items-center justify-center rounded-full border border-blue-gray-600 bg-pv-blue-700 p-1.5 ring-blue-gray-700 focus:ring"
        @click="prevMeeting"
      >
        <inline-svg
          :src="require('assets/images/icons/arrow.svg')"
          class="size-3 fill-current text-pv-yellow"
        />
      </button>
      <div class="flex flex-col items-center">
        <h2 class="text-xl font-medium">
          Coliseo {{ groupMeetingIndex + 1 }}
        </h2>
        <span class="text-pv-gray">
          {{ selectedGroupMeeting.meetingDate }}.
        </span>
      </div>
      <button
        v-tooltip="'siguiente coliseo'"
        class="flex items-center justify-center rounded-full border border-blue-gray-600 bg-pv-blue-700 p-1.5 ring-blue-gray-700 focus:ring"
        @click="nextMeeting"
      >
        <inline-svg
          :src="require('assets/images/icons/arrow.svg')"
          class="size-3 rotate-180 fill-current text-pv-yellow"
        />
      </button>
    </div>

    <div class="mt-8 w-full max-w-3xl self-center">
      <base-tabs
        class="self-center"
        :tabs="companies.map(company => company.name)"
        @select="selectCompany"
      />
    </div>

    <div class="mt-12 flex flex-col gap-4 xl:flex-row">
      <div class="flex grow flex-col lg:pr-4">
        <div class="flex flex-col space-y-8 lg:space-y-12">
          <div class="flex w-full flex-col rounded-lg border-blue-gray-700 bg-pv-blue-700 p-2">
            <kalio-weekly-updates-chart
              v-if="weeklyUpdates"
              :weekly-updates-points="weeklyUpdatesPoints"
              :legend="selectedCompany.programGoal?.kpiName || 'Métrica'"
              :options="chartOptions"
              :height="300"
            />
            <kalio-spinner
              v-else
              class="size-16 self-center"
            />
          </div>
          <div class="flex flex-col gap-x-8 gap-y-4 lg:flex-row">
            <div class="flex flex-col space-y-4 lg:w-1/2">
              <kalio-program-goal-panel :program-goal="selectedCompany.programGoal" />
              <span>
                Reporte semanal
              </span>
              <kalio-spinner
                v-if="isFetchingWeeklyUpdates"
                class="size-12 self-center"
              />
              <base-weekly-update-card
                v-else
                :weekly-update="weeklyUpdate"
                :program-goal="selectedCompany.programGoal"
              />
              <kalio-spinner
                v-if="isFetchingGroupMeetingPreparations"
                class="my-4 size-12 self-center"
              />
              <div
                v-else-if="groupMeetingPreparation"
                class="flex flex-col space-y-5"
              >
                <kalio-accordion-row
                  v-if="groupMeetingPreparation.kpiComments"
                  title="Sobre las métricas"
                  max-height-class="max-h-screen"
                  :with-divider="false"
                  is-open
                >
                  <div class="max-h-screen overflow-y-scroll rounded-lg bg-pv-blue-700 p-3">
                    <span class="text-sm text-blue-gray-200">
                      {{ groupMeetingPreparation.kpiComments }}
                    </span>
                  </div>
                </kalio-accordion-row>

                <kalio-accordion-row
                  v-if="groupMeetingPreparation.otherMeetingTopics"
                  title="Otros temas a hablar"
                  max-height-class="max-h-screen"
                  :with-divider="false"
                  is-open
                >
                  <div class="max-h-screen overflow-y-scroll rounded-lg bg-pv-blue-700 p-3">
                    <span class="text-sm text-blue-gray-200">
                      {{ groupMeetingPreparation.otherMeetingTopics }}
                    </span>
                  </div>
                </kalio-accordion-row>
              </div>
            </div>
            <div class="lg:w-1/2">
              <base-group-meeting-commitments-card
                v-if="selectedCompanyCommitments"
                :group-meeting="selectedGroupMeeting"
                :group-meeting-commitments="selectedCompanyCommitments"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="hidden h-full w-px bg-blue-gray-800 xl:flex" />
      <kalio-group-meetings-company-comments
        class="self-start lg:w-1/3 lg:shrink-0 lg:pl-6"
        :group-meeting="selectedGroupMeeting"
        :company="selectedCompany"
        with-header
      />
    </div>
  </div>
</template>
