<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useUrlSearchParams } from '@vueuse/core';
import { useReviewFiltersStore } from '@/stores';
import KalioSearchBar from '@/components/shared/kalio-search-bar.vue';
import { INITIAL_FILTERS } from '@/utils/review.js';

const props = defineProps({
  applicationProcesses: { type: Array, required: true },
  aasmStates: { type: Array, required: true },
  countries: { type: Array, required: true },
  qualifications: { type: Array, required: true },
  reviewers: { type: Array, required: true },
  startupApplicationTags: { type: Array, default: () => [] },
  industries: { type: Array, default: () => [] },
  loading: { type: Boolean, required: true },
});

const reviewFiltersStore = useReviewFiltersStore();
const { addFilters } = reviewFiltersStore;
const { appliedFilters } = storeToRefs(reviewFiltersStore);

if (Object.keys(appliedFilters.value).length === 0) {
  const initialApplicationProcess = props.applicationProcesses.slice(-1)[0];
  addFilters({ applicationProcessId: initialApplicationProcess.id });
}

const formValues = ref(Object.keys(INITIAL_FILTERS).reduce((acc, key) => {
  acc[key] = appliedFilters.value[key] ?? INITIAL_FILTERS[key];

  return acc;
}, {}));

const params = useUrlSearchParams();
function updateParams() {
  Object.keys(params).forEach(key => delete params[key]);
  Object.entries(appliedFilters.value).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      params[key] = value.join(',');
    } else {
      params[key] = value;
    }
  });
}

const changedFormValues = computed(() => Object.fromEntries(
  Object.entries(formValues.value).filter(([key, value]) => value !== INITIAL_FILTERS[key] && value.length !== 0),
));

function removeFilter(key) {
  formValues.value[key] = INITIAL_FILTERS[key];
}

function applyFilters() {
  addFilters(formValues.value);
  updateParams();
}

function handleSubmit(close) {
  applyFilters();
  close();
}

const { t } = useI18n();
function getFilterBadgeText(key) {
  const filterLabel = t(`startupApplication.review.filters.${key}`);

  return `${filterLabel}: ${changedFormValues.value[key]}`;
}

function getApplicationProcessLabel(id) {
  if (id === 'Todos') return 'Todos';

  return props.applicationProcesses.find((process) => process.id === Number(id)).batchName;
}

function getReviewerLabel(id) {
  if (id === 'Todos') return 'Todos';

  return props.reviewers.find((reviewer) => reviewer.id === Number(id)).fullName;
}

function getCountryLabel(code) {
  if (code === 'Todos') return 'Todos';

  return props.countries.find((country) => country.code === code).name;
}

const booleanSelectOptions = [
  { label: 'Todos', value: null },
  { label: 'Sí', value: true },
  { label: 'No', value: false },
];
</script>

<template>
  <div class="flex flex-row items-center gap-4">
    <kalio-search-bar
      v-model="formValues.search"
      placeholder="Buscar..."
      rules="required"
      name="search_filter"
      @search="applyFilters"
    />

    <popover class="relative">
      <popover-button class="flex items-center justify-center rounded-full bg-blue-gray-700 p-2 focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2 focus:ring-offset-pv-blue-700">
        <inline-svg
          :src="require('assets/images/icons/tune.svg')"
          class="size-5 fill-current"
        />
      </popover-button>
      <popover-panel
        v-slot="{ close }"
        class="absolute right-0 z-10 mt-3 flex flex-col gap-4 rounded-md border border-slate-700 bg-pv-blue-800 p-4 shadow sm:left-0 sm:right-auto"
      >
        <div class="flex flex-col">
          <span class="font-semibold">
            Filtros activos
          </span>
          <div
            v-if="Object.keys(changedFormValues).length > 0"
            class="mt-2 flex flex-wrap gap-2"
          >
            <kalio-badge
              v-for="key in Object.keys(changedFormValues)"
              :key="key"
              color="pv-light-blue"
              :text="getFilterBadgeText(key)"
              with-border
              :deletable="!loading"
              @delete="removeFilter(key)"
            />
          </div>
          <span
            v-else
            class="mb-0.5 mt-2 text-sm text-slate-400"
          >
            Sin filtros activos
          </span>
        </div>
        <kalio-form-wrapper
          class="flex flex-col gap-4"
          @submit="handleSubmit(close)"
        >
          <div class="flex flex-col gap-2 lg:flex-row">
            <div class="flex w-80 flex-col gap-1">
              <kalio-select-input
                v-model="formValues.applicationProcessId"
                :options="['Todos', ...applicationProcesses.map(process => process.id)]"
                :get-option-label="getApplicationProcessLabel"
                :label="$t('startupApplication.review.filters.applicationProcessId')"
                name="application_process_id"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.referred"
                :options="booleanSelectOptions"
                :reduce="option => option.value"
                :label="$t('startupApplication.review.filters.referred')"
                name="referred"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.hasFemaleFounder"
                :options="booleanSelectOptions"
                :reduce="option => option.value"
                :label="$t('startupApplication.review.filters.hasFemaleFounder')"
                name="has_female_founder"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.hasTechnicalFounder"
                :options="booleanSelectOptions"
                :reduce="option => option.value"
                :label="$t('startupApplication.review.filters.hasTechnicalFounder')"
                name="has_technicalFounder"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.aasmState"
                :options="['Todos', ...aasmStates]"
                :label="$t('startupApplication.review.filters.aasmState')"
                name="aasm_state"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.country"
                :options="['Todos', ...countries.map(country => country.code)]"
                :get-option-label="getCountryLabel"
                :label="$t('startupApplication.review.filters.country')"
                name="country"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.qualification"
                :options="['Todos', 'Sin calificación', ...qualifications]"
                :label="$t('startupApplication.review.filters.qualification')"
                name="qualification"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.discardEmailState"
                :options="['Todos', 'unprocessed', 'scheduled', 'sent']"
                :label="$t('startupApplication.review.filters.discardEmailState')"
                name="discard_email_sent"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.appliedMoreThanOnce"
                :options="booleanSelectOptions"
                :reduce="option => option.value"
                :label="$t('startupApplication.review.filters.appliedMoreThanOnce')"
                name="applied_more_than_once"
                size="small"
              />
            </div>
            <div class="flex w-80 flex-col gap-1">
              <kalio-select-input
                v-model="formValues.reachedInterview"
                :options="booleanSelectOptions"
                :reduce="option => option.value"
                :label="$t('startupApplication.review.filters.reachedInterview')"
                name="reached_interview"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.reviewerId"
                :options="['Todos', ...reviewers.map((reviewer) => reviewer.id)]"
                :get-option-label="getReviewerLabel"
                :label="$t('startupApplication.review.filters.reviewerId')"
                name="reviewer_id"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.tags"
                :options="startupApplicationTags"
                :label="$t('startupApplication.review.filters.tags')"
                name="tags"
                size="small"
                multiple
              />
              <kalio-select-input
                v-model="formValues.industries"
                :options="industries"
                :label="$t('startupApplication.review.filters.industries')"
                name="industries"
                size="small"
                multiple
              />
              <kalio-short-text-input
                v-model="formValues.educationInstitution"
                :label="$t('startupApplication.review.filters.educationInstitution')"
                name="education_institution"
                size="small"
              />
              <kalio-short-text-input
                v-model="formValues.previousCompany"
                :label="$t('startupApplication.review.filters.previousCompany')"
                name="previous_company"
                size="small"
              />
              <kalio-select-input
                v-model="formValues.applicationPeriods"
                :options="['Todos', 'early', 'general', 'late']"
                :label="$t('startupApplication.review.filters.applicationPeriods')"
                name="application_periods"
                size="small"
                multiple
              />
              <kalio-short-text-input
                v-model="formValues.revenueToFullTimeMonths"
                :label="$t('startupApplication.review.filters.revenueToFullTimeMonths')"
                name="revenue_to_full_time_months"
                size="small"
                rules="numeric"
              />
              <kalio-short-text-input
                v-model="formValues.revenueToWorkingInIdeaMonths"
                :label="$t('startupApplication.review.filters.revenueToWorkingInIdeaMonths')"
                name="revenue_to_working_in_idea_months"
                size="small"
                rules="numeric"
              />
            </div>
          </div>
          <kalio-button
            label="Aplicar filtros"
            class="w-full"
            :loading="loading"
            type="submit"
          />
        </kalio-form-wrapper>
      </popover-panel>
    </popover>
  </div>
</template>
