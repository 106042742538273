<script>
import KalioCopyTextButton from '../shared/kalio-copy-text-button.vue';

export default {
  name: 'kalio-library-item-small-card',
  components: { KalioCopyTextButton },
  props: {
    libraryItem: { type: Object, required: true },
  },
};
</script>

<template>
  <a
    :href="libraryItem.url"
    target="_blank"
    rel="noopener noreferrer"
    class="flex h-44 w-full cursor-pointer flex-col rounded-lg border border-blue-gray-700 bg-pv-blue-800 px-5 py-2 text-left hover:opacity-75"
  >
    <div class="flex flex-row items-center space-x-2">
      <span class="line-clamp-1 text-lg font-bold">
        {{ libraryItem.name }}
      </span>
      <kalio-copy-text-button
        :text="libraryItem.url"
        :title="$t('libraryItems.copyLink')"
        class="size-4 shrink-0 text-pv-gray"
      />
    </div>
    <div class="mt-2 flex flex-row flex-wrap items-center gap-3">
      <span class="text-sm">
        {{ libraryItem.author }}
      </span>
      <span class="rounded border px-2 py-0.5 text-xs text-white">
        {{ libraryItem.kind }}
      </span>
    </div>
    <p class="mt-3 line-clamp-3 text-left text-sm sm:text-base">
      {{ libraryItem.description }}
    </p>
  </a>
</template>
