<script>
import KalioFormSection from '../form/kalio-form-section';
import KalioFeedbackModal from '../shared/kalio-feedback-modal.vue';
import startupApplicationUpdateApi from '../../api/startup_application_updates';

export default {
  name: 'kalio-startup-application-update-form',
  components: {
    KalioFormSection,
    KalioFeedbackModal,
  },
  props: {
    startupApplicationUpdate: { type: Object, required: true },
    startupName: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      formSuccess: false,
      errorMessage: false,
      formAnswers: {
        ...this.startupApplicationUpdate,
        changedTeam: this.startupApplicationUpdate.changedTeam?.toString(),
        contactMe: this.startupApplicationUpdate.contactMe?.toString(),
        amountFundraised: this.startupApplicationUpdate.amountFundraised?.toString(),
        fundraisedCapital: this.startupApplicationUpdate.fundraisedCapital?.toString(),
      },
    };
  },
  created() {
    this.INVESTORS = ['Family and Friends', 'Inversionista Ángel', 'Fondo VC', 'CORFO', 'Otro'];
  },
  methods: {
    submitUpdateApplication() {
      this.loading = true;
      this.clearRedundantInputs();
      startupApplicationUpdateApi.update(this.startupApplicationUpdate.publicId, this.formAnswers)
        .then(() => {
          this.formSuccess = true;
        })
        .catch(error => {
          this.errorMessage = error.response.data.detail;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearRedundantInputs() {
      if (this.startupApplicationUpdate.alive) {
        this.formAnswers.currentFoundersState = null;
        this.formAnswers.contactMe = null;
      } else {
        this.formAnswers.changedTeam = null;
        this.formAnswers.fundraisedCapital = null;
      }
      if (this.formAnswers.changedTeam === 'false' || !this.formAnswers.changedTeam) {
        this.formAnswers.changedTeamInfo = null;
      }
      if (this.formAnswers.fundraisedCapital === 'false' || !this.formAnswers.fundraisedCapital) {
        this.formAnswers.investors = [];
        this.formAnswers.amountFundraised = null;
        this.formAnswers.fundraisingDetails = null;
      }
    },
  },
};
</script>

<template>
  <vee-form
    class="w-full"
    @submit="submitUpdateApplication"
  >
    <div class="flex flex-col space-y-12">
      <div class="flex w-full flex-col space-y-8">
        <kalio-form-section
          v-if="startupApplicationUpdate.alive"
          :title="`¡Qué bueno que siguen con ${startupName}! ¿Nos responderías unos detalles más?`"
        >
          <kalio-radio-inputs
            v-model="formAnswers.changedTeam"
            name="changed_team"
            rules="required"
            label="¿Se ha sumado o se ha ido alguien del equipo fundador?"
            :values="['true', 'false']"
            :radio-labels="['Sí', 'No']"
          />
          <kalio-long-text-input
            v-if="formAnswers.changedTeam === 'true'"
            v-model="formAnswers.changedTeamInfo"
            name="changed_team_info"
            rules="required"
            label="¿Quiénes se sumaron o se fueron y cuáles son sus roles?"
          />
          <kalio-radio-inputs
            v-model="formAnswers.fundraisedCapital"
            name="fundraised_capital"
            rules="required"
            label="¿Han levantado capital después de postular a Platanus?"
            :values="['true', 'false']"
            :radio-labels="['Sí', 'No']"
          />
          <kalio-base-input
            v-if="formAnswers.fundraisedCapital === 'true'"
            label="¿De quiénes han levantado capital?"
          >
            <template #input>
              <div
                v-for="investor in INVESTORS"
                :key="`option-${investor}`"
              >
                <kalio-checkbox
                  v-model="formAnswers.investors"
                  name="investors"
                  :value="investor"
                  :label="investor"
                />
              </div>
            </template>
          </kalio-base-input>
          <kalio-short-text-input
            v-if="formAnswers.fundraisedCapital === 'true'"
            v-model="formAnswers.amountFundraised"
            name="amount_fundraised"
            rules="numeric"
            label="¿Cuánto capital han levantado? Ejemplo: USD 150,000."
          />
          <kalio-long-text-input
            v-if="formAnswers.fundraisedCapital === 'true'"
            v-model="formAnswers.fundraisingDetails"
            name="fundraising_details"
            label="¿Nos quieren contar más detalles de su levantamiento de capital?"
          />
        </kalio-form-section>
        <kalio-form-section
          v-else
          :title="`Okey, no siguen con ${startupName}. ¿Nos podrías ayudar con dos detalles más?`"
        >
          <kalio-long-text-input
            v-model="formAnswers.currentFoundersState"
            name="current_founders_state"
            rules="required"
            label="¿En qué está ahora cada uno de los founders?"
          />
          <kalio-radio-inputs
            v-model="formAnswers.contactMe"
            name="cofounder_contact"
            rules="required"
            label="¿Te interesa que te contactemos con personas buscando co-founder?"
            :values="['true', 'false']"
            :radio-labels="['Sí', 'No']"
          />
        </kalio-form-section>
      </div>
      <kalio-button
        label="Guardar información"
        class="w-full sm:w-auto sm:self-center sm:px-24"
        type="submit"
        :loading="loading"
      />
    </div>
  </vee-form>
  <kalio-feedback-modal
    v-if="formSuccess"
    title="¡Gracias por tu colaboración!"
    accept="Entendido"
    @accept="formSuccess = false"
  />
  <kalio-feedback-modal
    v-if="!!errorMessage"
    :title="errorMessage"
    status="error"
    accept="Entendido"
    @accept="errorMessage = null"
  />
</template>
