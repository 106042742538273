import api from '../api';

export default {
  getAll(page, filters = {}, sort = 'date') {
    const path = `/internal/api/v1/startup_applications?page=${page}`;

    return api({
      method: 'get',
      params: { filters, sort },
      url: path,
    });
  },
  get(id) {
    const path = `/internal/api/v1/startup_applications/${id}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  update(id, startupApplication) {
    const path = `/internal/api/v1/startup_applications/${id}`;

    return api({
      method: 'patch',
      data: { startupApplication },
      url: path,
    });
  },
  sendDiscardEmail({ id, discardReason, discardReasonKeywords, discardFeedback }) {
    const path = `/internal/api/v1/startup_applications/${id}/discard_email`;

    return api({
      method: 'put',
      data: {
        startupApplication: {
          discardReason,
          discardFeedback,
          discardReasonKeywords,
        },
      },
      url: path,
    });
  },
  createQuestion(id, question) {
    const path = `/internal/api/v1/startup_applications/${id}/question`;

    return api({
      method: 'post',
      data: { question },
      url: path,
    });
  },
};
