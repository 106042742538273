<script setup>
import HomeLayout from '@/components/home/layout.vue';
import HomeStartupInfo from '@/components/home/startup-info.vue';
import HomeOfficeHours from '@/components/home/office-hours.vue';
import HomeLibraryItems from '@/components/home/library-items.vue';
import HomeStartupReferrals from '@/components/home/startup-referrals.vue';
import HomeCompanyUpdates from '@/components/home/company-updates.vue';

defineProps({
  company: { type: Object, required: true },
  officeHourOfferings: { type: Array, required: true },
  libraryItems: { type: Array, required: true },
});
</script>

<template>
  <home-layout>
    <div class="flex w-full flex-col gap-4">
      <home-startup-info :company="company" />
      <home-office-hours :office-hour-offerings="officeHourOfferings" />
      <home-startup-referrals />
    </div>
    <div class="flex w-full flex-col gap-4">
      <home-library-items :library-items="libraryItems" />
      <home-company-updates v-if="company.isTracking" />
    </div>
  </home-layout>
</template>
