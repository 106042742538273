<script>
import humps from 'humps';
import investorsApi from 'api/investors';
import KalioListWithFiltersAndSearchbar from '../shared/kalio-list-with-filters-and-searchbar.vue';
import KalioPagination from '../shared/kalio-pagination.vue';
import KalioInvestorCard from './kalio-investor-card.vue';
import KalioInvestmentFirmCard from './kalio-investment-firm-card.vue';

export default {
  name: 'KalioInvestorItemList',
  components: {
    KalioInvestorCard,
    KalioInvestmentFirmCard,
    KalioListWithFiltersAndSearchbar,
    KalioPagination,
  },
  props: {
    investors: { type: Array, required: true },
    companies: { type: Array, required: true },
    ratings: { type: Array, required: true },
    investmentStages: { type: Array, required: true },
    countries: { type: Array, required: true },
    investorTypes: { type: Array, required: true },
    query: { type: String, default: '' },
    totalCount: { type: Number, default: 1 },
  },
  data() {
    return {
      lastFilters: [],
      currentInvestors: this.investors,
      currentPage: 0,
      currentTotalCount: this.totalCount,
      loading: false,
    };
  },
  computed: {
    filters() {
      return {
        companiesInvestedIn: {
          label: this.$t('list.filters.investedCompany'),
          itemKey: 'allCompaniesInvested',
          options: this.companies.map(company => ({ value: company, label: company })),
          type: 'multiselect',
          compareFunction: (filterValue, itemValue) => (
            itemValue.find(item => item.name === filterValue)
          ),
        },
        countryName: {
          label: this.$t('list.filters.country'),
          itemKey: 'country.name',
          options: this.countries.map(country => ({ value: country, label: country })),
          type: 'multiselect',
        },
        avgInvestorLetter: {
          label: this.$t('list.filters.rating'),
          itemKey: 'avgInvestorLetter',
          options: this.ratings.map(category => ({ value: category, label: category })),
          type: 'checkbox',
        },
        investmentStages: {
          label: this.$t('list.filters.investmentStage'),
          itemKey: 'investmentStages',
          options: this.investmentStages.map(investmentStage => ({
            value: investmentStage,
            label: this.$t(`investors.stages.${investmentStage}`),
          })),
          compareFunction: (filterValue, itemValue) => (
            itemValue.find(item => humps.camelize(item.stage) === filterValue)
          ),
          type: 'checkbox',
        },
        kind: {
          label: this.$t('list.filters.investorType'),
          itemKey: 'kind',
          options: this.investorTypes.map(investorType => ({
            value: investorType,
            label: this.$t(`investors.kind.${investorType}`),
          })),
          type: 'checkbox',
        },
        leadRounds: {
          label: this.$t('list.filters.leadRounds'),
          itemKey: 'leadRounds',
          options: [
            { value: true, label: this.$t('form.yes') },
            { value: false, label: this.$t('form.no') },
          ],
          type: 'checkbox',
        },
        ticketMinMax: {
          label: this.$t('list.filters.investmentTicket'),
          type: 'numberRange',
        },
      };
    },
  },
  methods: {
    async getInvestors(filters) {
      this.lastFilters = filters;
      this.loading = true;
      const response = await investorsApi.index(1, filters);

      this.updateValues(response, 0);
    },
    async changePage(page) {
      this.loading = true;
      const response = await investorsApi.index(page + 1, this.lastFilters);
      this.updateValues(response, page);
    },
    updateValues(response, page) {
      this.currentInvestors = response.investors;
      this.currentPage = page;
      this.currentTotalCount = response.totalCount;
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="w-full max-w-6xl">
    <h1 class="mb-5 text-center text-4xl font-medium text-blue-gray-50">
      {{ $t('investors.listTitle') }}
    </h1>

    <kalio-list-with-filters-and-searchbar
      :items="currentInvestors"
      :query="query"
      search-path="/investors"
      :filters="filters"
      :get-data-from-api="getInvestors"
      :loading="loading"
      filters-from-backend
    >
      <template #item="{ item }">
        <kalio-investor-card
          v-if="item && item.className === 'UserDecorator'"
          :investor="item"
        />
        <kalio-investment-firm-card
          v-if="item && item.className === 'InvestmentFirm'"
          :investment-firm="item"
        />
      </template>
    </kalio-list-with-filters-and-searchbar>
  </div>
  <kalio-pagination
    :resources-per-page="25"
    :resources-length="currentTotalCount"
    :actual-page="currentPage"
    @set-new-page-index="changePage"
  />
</template>
