<script setup>
import HomeLayout from '@/components/home/layout.vue';
import HomeOfficeHours from '@/components/home/office-hours.vue';
import HomeLibraryItems from '@/components/home/library-items.vue';
import HomeStartupReferrals from '@/components/home/startup-referrals.vue';
import HomeBatchGroupMentorCompaniesCard from '@/components/home/batch-group-mentor-companies-card.vue';
import HomeBatchGroupMentorGroupMeetingCard from '@/components/home/batch-group-mentor-group-meeting-card.vue';

defineProps({
  groupMeeting: { type: Object, required: true },
  companies: { type: Array, required: true },
  officeHourOfferings: { type: Array, required: true },
  libraryItems: { type: Array, required: true },
  batchGroup: { type: Object, required: true },
});
</script>

<template>
  <home-layout>
    <div class="flex w-full flex-col space-y-4">
      <home-startup-referrals />
      <home-batch-group-mentor-companies-card
        :batch-group="batchGroup"
        :companies="companies"
      />
      <home-batch-group-mentor-group-meeting-card
        v-if="groupMeeting"
        :group-meeting="groupMeeting"
      />
    </div>
    <div class="flex w-full flex-col space-y-4">
      <home-office-hours :office-hour-offerings="officeHourOfferings" />
      <home-library-items :library-items="libraryItems" />
    </div>
  </home-layout>
</template>
