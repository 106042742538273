<script setup>
import { ref, computed } from 'vue';
import { useEventBus } from '@vueuse/core';
import useLikes from '@/hooks/likes.js';
import { rewatchDemoDayPitchKey } from '../utils/keys.js';
import DemoDayAuctionMenu from './demo-day-auction-menu.vue';
import DemoDayFounderCard from './demo-day-founder-card.vue';

const SCROLL_WAIT_MS = 200;

const props = defineProps({
  demoDayCompany: { type: Object, required: true },
  shouldScrollIntoViewOnRewatchEvent: { type: Boolean, default: false },
});

const video = ref(null);

const eventBus = useEventBus(rewatchDemoDayPitchKey);
eventBus.on(() => {
  if (!props.shouldScrollIntoViewOnRewatchEvent) return;

  setTimeout(() => video.value.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  }), SCROLL_WAIT_MS);
});

const company = computed(() => props.demoDayCompany.company);
const { handleLike, companyHaslike } = useLikes(company);

const selectedTab = ref('company');
const isCompanyTabSelected = computed(() => selectedTab.value === 'company');
const isAuctionTabSelected = computed(() => selectedTab.value === 'auction');
</script>

<template>
  <div class="flex flex-col space-y-6">
    <div class="flex w-full flex-row space-x-6 border-b border-blue-gray-700">
      <button
        class="relative pb-1.5 focus:outline-none"
        @click="selectedTab = 'company'"
      >
        <span
          class="pt-2 text-sm font-medium"
          :class="{ 'text-pv-gray': !isCompanyTabSelected }"
        >
          {{ $t('demoDay.virtualRound.companyInfo') }}
        </span>
        <div
          class="absolute bottom-0 h-1 w-full bg-pv-yellow"
          :class="{ 'hidden': isAuctionTabSelected }"
        />
      </button>
      <button
        class="relative pb-1.5 focus:outline-none"
        @click="selectedTab = 'auction'"
      >
        <span
          class="pt-2 text-sm font-medium"
          :class="{ 'text-pv-gray': !isAuctionTabSelected }"
        >
          {{ $t('demoDay.virtualRound.roundInfo') }}
        </span>
        <div
          class="absolute bottom-0 h-1 w-full bg-pv-yellow"
          :class="{ 'hidden': isCompanyTabSelected }"
        />
      </button>
    </div>

    <div
      v-if="isAuctionTabSelected"
      class="px-4"
    >
      <demo-day-auction-menu
        :demo-day-company="demoDayCompany"
        @open-community-application-modal="$emit('open-community-application-modal')"
      />
    </div>
    <div
      v-else
      class="flex flex-col"
    >
      <button
        class="flex flex-row items-center space-x-1 self-start rounded-lg border bg-blue-gray-800 px-2 py-1"
        @click="handleLike"
      >
        <inline-svg
          :src="require(`assets/images/icons/${companyHaslike ? 'solid' : 'outline'}/heart.svg`)"
          class="size-5"
          :class="companyHaslike ? 'fill-current text-red-400' : 'stroke-current text-blue-gray-50'"
        />
        <span>
          {{ $t('demoDay.company.like') }}
        </span>
      </button>
      <span class="mt-4 text-base font-medium">
        Founders
      </span>
      <div class="mt-4 flex flex-col space-y-3">
        <demo-day-founder-card
          v-for="founder in company.users"
          :key="founder.id"
          :founder="founder"
        />
      </div>
      <div
        v-if="demoDayCompany.pitchVideoUrl"
        ref="video"
        class="mt-8 flex flex-col space-y-4"
      >
        <span class="text-xl font-medium">
          {{ $t('demoDay.company.pitch') }}
        </span>
        <div
          ref="video"
          class="relative mt-6 w-full overflow-hidden rounded-lg border border-blue-gray-700 bg-pv-blue-800 pt-[55.25%]"
        >
          <iframe
            class="absolute inset-0 size-full"
            :src="demoDayCompany.pitchVideoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
      <div class="mt-4 flex flex-col space-y-4">
        <span class="text-xl font-medium">
          Key points
        </span>
        <div class="mt-2 size-full max-w-lg self-center">
          <img
            :src="demoDayCompany.slideUrl"
            alt="key points"
            class="size-full object-cover"
          >
        </div>
      </div>
    </div>
  </div>
</template>
