<script setup>
import { computed } from 'vue';
import i18n from '../../plugins/i18n';
import KalioButton from './kalio-button.vue';
import KalioModal from './kalio-modal.vue';

const props = defineProps({
  status: {
    type: String,
    default: 'success',
    validator: (value) => ['success', 'error'].includes(value),
  },
  title: {
    type: String,
    required: true,
  },
  body: {
    type: String,
    default: '',
  },
  showAccept: {
    type: Boolean,
    default: true,
  },
  accept: {
    type: String,
    default: i18n.global.t('form.close'),
  },
  close: {
    type: String,
    default: '',
  },
});

const icon = computed(() => (props.status === 'success' ? 'check.svg' : 'close.svg'));

defineEmits(['accept', 'close']);
</script>

<template>
  <kalio-modal
    custom-close-button
    class="-top-12 sm:-top-64"
    data-testid="feedback-modal"
  >
    <div class="flex flex-col items-center space-y-5 rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 p-6 sm:px-12">
      <div
        class="flex items-center justify-center rounded-full p-3"
        :class="status === 'success' ? 'bg-pv-yellow' : 'border-2 border-pv-yellow'"
      >
        <inline-svg
          class="size-6 fill-current"
          :class="status === 'success' ? 'text-blue-gray-50' : 'text-pv-yellow'"
          :src="require(`assets/images/icons/${icon}`)"
        />
      </div>
      <span class="text-xl font-medium text-cool-gray-50">
        {{ title }}
      </span>
      <p
        v-if="!!body"
        class="whitespace-pre-line text-base text-pv-gray"
      >
        {{ body }}
      </p>
      <slot />
      <div
        class="flex w-full max-w-xs flex-col items-center md:flex-row-reverse mobile:space-y-2"
        :class="!!close ? 'justify-between' : 'justify-center'"
      >
        <kalio-button
          v-if="showAccept"
          :label="accept"
          :class="!!close ? 'px-6' : 'px-16'"
          @click="$emit('accept')"
        />
        <button
          v-if="!!close"
          class="text-base text-blue-gray-50 hover:opacity-75 focus:outline-none"
          @click="$emit('close')"
        >
          {{ close }}
        </button>
      </div>
    </div>
  </kalio-modal>
</template>
