<script>
export default {
  props: {
    companies: { type: Array, required: true },
    batches: { type: Array, required: true },
    currentBatchId: { type: Number, default: null },
  },
  computed: {
    currentBatch() {
      return this.batches.find(batch => batch.id === this.currentBatchId);
    },
  },
  methods: {
    redirectToBatchList(batch) {
      if (batch?.id) {
        window.location = `/internal/batches/${batch.id}/companies`;
      } else {
        window.location = '/companies';
      }
    },
  },
};
</script>

<template>
  <div class="flex w-full flex-col items-start">
    <h1 class="mb-6 text-4xl text-white">
      Estado startups
    </h1>
    <kalio-select-input
      name="company"
      label="¿De qué generación quieres ver el estado?"
      :get-option-label="(option) => option.name"
      :options="batches"
      :value="currentBatch"
      @update:model-value="redirectToBatchList"
    />
    <div class="mt-4 w-full rounded-lg border border-pv-blue-700 bg-pv-blue-900 px-4 py-5">
      <h2 class="mb-5 text-lg font-medium text-blue-gray-300">
        {{ !!currentBatchId ? `Startups del Batch ${currentBatch.name}` : 'Todas las startups' }}
      </h2>
      <ul class="list-inside list-disc">
        <li
          v-for="company in companies"
          :key="company.id"
          class="text-base text-light-blue-300"
        >
          <a :href="`/internal/companies/${company.slug}/status`">
            {{ company.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
