<!-- eslint-disable no-magic-numbers -->
<script setup>
import { computed, inject } from 'vue';
import { screenKey } from '../../../../../utils/keys.js';

const props = defineProps({
  sizeMultiplier: { type: Number, default: 1 },
});
const screen = inject(screenKey);

function adjustSize(initialSize) {
  return parseInt(initialSize * props.sizeMultiplier, 10);
}

const iframeDimensions = computed(() => {
  if (screen.isMobile) {
    return {
      width: adjustSize(400),
      height: adjustSize(225),
    };
  }
  if (screen.isTablet) {
    return {
      width: adjustSize(550),
      height: adjustSize(310),
    };
  }
  if (screen.isMdScreen) {
    return {
      width: adjustSize(670),
      height: adjustSize(375),
    };
  }
  if (screen.isLgScreen) {
    return {
      width: adjustSize(600),
      height: adjustSize(338),
    };
  }
  if (screen.isXlScreen) {
    return {
      width: adjustSize(600),
      height: adjustSize(338),
    };
  }

  return {
    width: adjustSize(600),
    height: adjustSize(338),
  };
});
</script>

<template>
  <iframe
    :width="iframeDimensions.width"
    :height="iframeDimensions.height"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  />
</template>
