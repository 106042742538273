<script>
import { ref, computed } from 'vue';

export default {
  name: 'base-underline-tabs',
  props: {
    tabs: { type: Array, required: true },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium', 'big'].includes(value),
    },
    variant: {
      type: String,
      default: 'white',
      validator: (value) => ['white', 'yellow'].includes(value),
    },
    wrap: { type: Boolean, default: false },
    tabClasses: { type: String, default: '' },
    buttonClasses: { type: String, default: '' },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const selectedTabIndex = ref(0);

    function selectTab(index) {
      selectedTabIndex.value = index;
      emit('select', index);
    }

    function getIcon(tab) {
      return typeof tab === 'object' && tab.icon;
    }

    function getText(tab) {
      if (typeof tab === 'object') {
        return tab.title;
      }

      return tab;
    }

    const borderColorClass = computed(() => {
      const classes = {
        white: 'border-gray-300',
        yellow: 'border-yellow-500',
      };

      return classes[props.variant];
    });

    return {
      selectedTabIndex,
      selectTab,
      getIcon,
      getText,
      borderColorClass,
    };
  },
};
</script>

<template>
  <div
    class="border-b border-blue-gray-700"
    :class="{
      'overflow-scroll': !wrap,
    }"
  >
    <ul
      class="flex"
      :class="{
        'flex-wrap': wrap,
      }"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="`tab-${index}`"
        class="grow"
        :class="tabClasses"
      >
        <button
          class="group flex flex-row items-center gap-x-2 rounded-t-lg border-b-2 p-4 text-blue-gray-200"
          :class="`
            ${buttonClasses}
            ${selectedTabIndex === index ? borderColorClass : 'hover:text-blue-gray-500 border-transparent'}`
          "
          @click="selectTab(index)"
        >
          <inline-svg
            v-if="getIcon(tab)"
            :src="require(`assets/images/icons/${getIcon(tab)}`)"
            class="size-4 fill-current"
          />
          <span
            class="shrink-0"
            :class="{
              'text-xl font-bold': size === 'big',
              'text-base font-semibold': size === 'medium',
              'text-sm font-medium': size === 'small',
            }"
          >
            {{ getText(tab) }}
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>
