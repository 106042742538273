import api from '@/api/api';

export default {
  create(body) {
    return api({
      method: 'post',
      url: '/api/v1/demo_day/investment_legal_informations',
      data: body,
    });
  },
};
