<script setup>
import { inject, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { screenKey } from '@/utils/keys';
import KalioMeeting from '@/components/shared/kalio-meeting.vue';
import GroupMeetingWeeklyUpdates from '@/components/group-meetings/weekly-updates.vue';
import {
  useGroupMeetingStore,
  useRolesStore,
  useBatchStore,
} from '@/stores';

const props = defineProps({
  batchGroup: { type: Object, required: true },
  groupMeeting: { type: Object, required: true },
  groupMeetings: { type: Array, required: true },
  companies: { type: Array, required: true },
  callOwnerToken: { type: String, required: false, default: undefined },
  dailyUrl: { type: String, required: true },
});

const screen = inject(screenKey);
const batchStore = useBatchStore();
batchStore.addBatchGroup(props.batchGroup);

const groupMeetingStore = useGroupMeetingStore();
const { setGroupMeeting, setGroupMeetings, setCompanies } = groupMeetingStore;
const { selectedCompany } = storeToRefs(groupMeetingStore);

const rolesStore = useRolesStore();
const isMuteAllowed = computed(() => rolesStore.isPartner);

setGroupMeeting(props.groupMeeting);
setGroupMeetings(props.groupMeetings);
setCompanies(props.companies);
</script>

<template>
  <div class="flex w-full flex-col gap-2 md:flex-row">
    <div class="flex w-full flex-col">
      <div class="flex h-screen flex-col px-4 pb-32 pt-8">
        <slot />
        <kalio-meeting
          class="mt-4"
          show-fullscreen-button
          :is-mute-allowed="isMuteAllowed"
          :daily-url="props.dailyUrl"
          :owner-token="props.callOwnerToken"
        />
      </div>
      <div
        v-if="!screen.isMobile"
        class="flex w-full flex-col items-center"
      >
        <group-meeting-weekly-updates
          :key="selectedCompany?.id"
          class="-mt-24 w-full px-2 md:w-128 md:px-0"
        />
      </div>
    </div>
    <div class="flex size-full flex-col md:-mt-4 md:w-112">
      <group-meeting-sidebar />
    </div>
    <group-meeting-weekly-updates
      v-if="screen.isMobile"
      :key="selectedCompany?.id"
      class="mt-8 w-full px-2"
    />
  </div>
</template>
