import api from '../api';

export default {
  create(body) {
    const path = '/api/v1/apply/big5';

    return api({
      method: 'post',
      data: { big5: body },
      url: path,
    });
  },
};
