import humps from 'humps';
import api from './api';

export default {
  create(formAnswers) {
    const path = '/api/v1/startup_referrals';
    const data = { startupReferral: { formAnswers } };

    return api({
      method: 'post',
      data: humps.decamelizeKeys(data),
      url: path,
    });
  },
};
