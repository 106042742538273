<script setup>
import KalioModal from '@/components/shared/kalio-modal.vue';
</script>

<template>
  <kalio-modal custom-close-button>
    <div class="flex flex-col items-center space-y-6 rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 p-6">
      <kalio-header
        title="Permisos de cámara y micrófono"
        icon="solid/video-off.svg"
        size="medium"
        class="mr-4 mt-3 self-start"
      />
      <div class="flex flex-col space-y-4 text-left">
        <p>
          Para poder realizar la entrevista, necesitamos que habilites los permisos de cámara y micrófono.
        </p>
        <ol class="ml-6 list-decimal space-y-2">
          <li>
            Clickea en el ícono de la cámara y micrófono
            <img
              :src="require('assets/images/media-permissions.png')"
              class="inline-block size-6"
            >
            en la barra de direcciones.
          </li>
          <li>
            Selecciona "Permitir siempre", luego clickea "Listo".
          </li>
          <li>
            Recarga la página.
          </li>
        </ol>
      </div>
    </div>
  </kalio-modal>
</template>
