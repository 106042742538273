<script setup>
import { inject, computed, onMounted, ref } from 'vue';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import KalioHeader from '@/components/shared/kalio-header.vue';
import KalioSearchBar from '@/components/shared/kalio-search-bar.vue';
import BaseOfficeHourOfferingCard from '@/components/shared/base-office-hour-offering-card.vue';
import { screenKey } from '@/utils/keys.js';
import KalioOfficeHourModal from '@/components/office-hours/kalio-office-hour-modal.vue';
import { lowerDeaccentisize } from '@/filters';

const props = defineProps({
  offerings: { type: Array, required: true },
  hasOfficeHourOffering: { type: Boolean, required: true },
});

const screen = inject(screenKey);

const contactModalOpen = ref(false);
const success = ref(false);
const error = ref(false);
const selectedOffering = ref({});
const searchFilter = ref(null);

const filteredOfferings = computed(() => {
  if (!searchFilter.value) {
    return props.offerings;
  }

  return props.offerings
    .filter(offering => {
      const filter = lowerDeaccentisize(searchFilter.value);
      const fullName = lowerDeaccentisize(offering.user.fullName);
      const description = lowerDeaccentisize(offering.description);
      const company = lowerDeaccentisize(offering.user?.company?.name || '');
      const tags = offering.tags.map(tag => lowerDeaccentisize(tag.name)).join(' ');

      return [fullName, description, company, tags].some(string => string.includes(filter));
    });
});

onMounted(() => {
  const params = new URLSearchParams(window.location.search);
  searchFilter.value = params.get('full_name');
});

function openContactModal(offering) {
  selectedOffering.value = offering;
  contactModalOpen.value = true;
}

function closeContactModal() {
  selectedOffering.value = {};
  contactModalOpen.value = false;
}

function handleOfficeHourRequested() {
  closeContactModal();
  success.value = true;
}

function handleError() {
  contactModalOpen.value = false;
  error.value = true;
}
</script>

<template>
  <div class="flex w-full flex-col">
    <rails-flash-alert />
    <div class="flex w-full flex-col space-y-3 sm:space-y-12 mobile:max-w-lg">
      <div class="flex flex-col justify-between space-y-6 lg:flex-row lg:items-center lg:space-y-0">
        <kalio-header
          icon="sand-clock.svg"
          title="Office Hours"
          :size="screen.isMobile || screen.isTablet ? 'medium' : 'big'"
        />
        <span class="flex flex-col md:mt-6 md:w-auto md:flex-row md:items-center md:justify-end">
          <span class="mb-4 md:mb-0 md:mr-4">
            <kalio-button
              v-if="hasOfficeHourOffering"
              label="Editar mis Office Hours"
              href="/office_hour_offerings/my_offering/edit"
              variant="blue"
            />
            <kalio-button
              v-else
              label="Ofrecer Office Hours"
              href="/community/cal_connections/new"
              variant="blue"
            />
          </span>

          <span class="w-full md:w-64">
            <kalio-search-bar
              v-model="searchFilter"
              placeholder="Buscar..."
              rules="required"
              name="search_filter"
            />
          </span>
        </span>
      </div>

      <div class="grid grid-cols-1 gap-x-6 gap-y-4 lg:grid-cols-2 xl:grid-cols-3">
        <div
          v-for="offering in filteredOfferings"
          :key="offering.id"
          class="w-full"
        >
          <base-office-hour-offering-card
            :offering="offering"
            @schedule="openContactModal(offering)"
          />
        </div>
      </div>
      <kalio-office-hour-modal
        v-if="contactModalOpen"
        :offering="selectedOffering"
        @close="closeContactModal"
        @office-hour-requested="handleOfficeHourRequested"
        @error="handleError"
      />
      <kalio-feedback-modal
        v-if="success"
        title="¡Office Hour pedido con exito!"
        body="Recuerda que puedes pedir todos los office hour que necesites."
        accept="Ok"
        @accept="success = false"
      />
      <kalio-feedback-modal
        v-if="error"
        status="error"
        title="Hubo un error, intenta de nuevo más tarde"
        accept="Ok"
        @accept="error = false"
      />
    </div>
  </div>
</template>
