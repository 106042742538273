<script>
import { inject } from 'vue';
import { screenKey } from '../../utils/keys.js';
import KalioModal from './kalio-modal.vue';
import KalioHeader from './kalio-header.vue';

export default {
  name: 'kalio-contact-modal',
  components: { KalioModal, KalioHeader },
  props: {
    icon: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, default: null },
  },
  emits: ['close'],
  setup() {
    return {
      screen: inject(screenKey),
    };
  },
};
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-2xl"
    @close="$emit('close')"
  >
    <div class="rounded-lg bg-pv-blue-700">
      <div class="flex flex-col space-y-1 p-3 sm:p-5">
        <div class="mr-12">
          <kalio-header
            :title="title"
            :description="description"
            :icon="icon"
            :size="screen.isMobile ? 'small' : 'medium'"
          />
        </div>
        <div class="p-4 sm:px-8">
          <slot />
        </div>
      </div>
    </div>
  </kalio-modal>
</template>
