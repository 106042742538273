<script>
import { range } from 'lodash';

const RESOURCES_PER_PAGE = 25;

export default {
  props: {
    resourcesPerPage: {
      type: Number,
      default: RESOURCES_PER_PAGE,
    },
    actualPage: {
      type: Number,
      default: 0,
    },
    resourcesLength: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  emits: ['set-new-page-index'],
  computed: {
    firstPage() {
      return 0;
    },
    lastPage() {
      return this.pageCount - 1;
    },
    middlePageRange() {
      return this.rangePageCount.slice(this.middleRangeStart, this.middleRangeEnd + 1);
    },
    rangePageCount() {
      const minRangeLength = 2;
      if (this.pageCount <= minRangeLength) return [];

      return range(this.pageCount);
    },
    pageCount() {
      return Math.ceil(this.resourcesLength / this.resourcesPerPage);
    },
    middleRangeStart() {
      const rangeStart = Math.max(this.firstPage + 1, this.actualPage - 1);
      if (rangeStart >= this.lastPage - 1) return this.lastPage - 1;

      return rangeStart;
    },
    middleRangeEnd() {
      const rangeEnd = Math.min(this.lastPage - 1, this.actualPage + 1);
      if (rangeEnd <= this.firstPage + 1) {
        return 1;
      }

      return rangeEnd;
    },
  },
  methods: {
    changePage(increment) {
      if (increment && this.actualPage + 1 !== this.pageCount) {
        this.emitChangePage(this.actualPage + 1);
      }
      if (!increment && this.actualPage !== 0) {
        this.emitChangePage(this.actualPage - 1);
      }
    },
    setNewPage(pageNumber) {
      this.emitChangePage(pageNumber);
    },
    emitChangePage(newPageValue) {
      this.$emit('set-new-page-index', newPageValue);
    },
  },
};
</script>

<template>
  <span
    v-if="pageCount > 0"
    class="self-center"
  >
    <nav class="my-4 flex justify-center overflow-hidden rounded border border-blue-gray-300 bg-pv-blue-900 text-sm font-medium text-blue-gray-300">
      <button
        class="border-r border-blue-gray-300 px-2 py-1 md:px-4 md:py-2"
        :class="{'hover:bg-blue-gray-700': actualPage !== 0,
                 'cursor-default': actualPage == 0}"
        @click="changePage(false)"
      >
        Prev
      </button>
      <button
        class="border-r border-blue-gray-300 px-2 py-1 md:px-4 md:py-2"
        :class="{'bg-blue-gray-700': actualPage === 0,
                 'hover:bg-blue-gray-700': actualPage !== 0}"
        @click="setNewPage(0)"
      >
        1
      </button>
      <button
        v-if="actualPage >= 3"
        class="border-r border-blue-gray-300 px-2 py-1 md:px-4 md:py-2"
      >
        ...
      </button>
      <button
        v-for="(index) in middlePageRange"
        :key="`middle-${index}`"
        class="border-r border-blue-gray-300 px-2 py-1 md:px-4 md:py-2"
        :class="{'bg-blue-gray-700': actualPage === index,
                 'hover:bg-blue-gray-700': actualPage !== index}"
        @click="setNewPage(index)"
      >
        {{ index + 1 }}
      </button>
      <button
        v-if="actualPage < lastPage - 2 && middlePageRange.length < Math.ceil(pageCount / 2)"
        class="border-r border-blue-gray-300 px-2 py-1 md:px-4 md:py-2"
      >
        ...
      </button>
      <button
        v-if="pageCount > 1"
        class="border-r border-blue-gray-300 px-2 py-1 md:px-4 md:py-2"
        :class="{'bg-blue-gray-700': actualPage === pageCount - 1,
                 'hover:bg-blue-gray-700': actualPage !== pageCount - 1}"
        @click="setNewPage(pageCount - 1)"
      >
        {{ pageCount }}
      </button>
      <button
        class="border-none px-2 py-1 md:px-4 md:py-2"
        :class="{'hover:bg-blue-gray-700': pageCount - 1 !== actualPage,
                 'cursor-default': pageCount - 1 == actualPage}"
        @click="changePage(true)"
      >
        Next
      </button>
    </nav>
  </span>
</template>
