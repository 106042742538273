<script>
import { UNCAPPED_VALUE } from '../utils/virtual_round.js';

export default {
  name: 'demo-day-auction-order-information',
  props: {
    auctionOrder: { type: Object, required: true },
    investmentLegalInformationPath: { type: String, required: true },
  },
  setup() {
    return {
      UNCAPPED_VALUE,
    };
  },
  computed: {
    textColor() {
      if (this.auctionOrder.orderCompletelyIn) {
        return 'text-green-300';
      } else if (this.auctionOrder.orderPartiallyIn) {
        return 'text-yellow-500';
      }

      return 'text-red-400';
    },
    orderStatusText() {
      if (this.auctionOrder.orderCompletelyIn) {
        return this.$t('demoDay.virtualRound.auction.completelyIn');
      } else if (this.auctionOrder.orderPartiallyIn) {
        return this.$t('demoDay.virtualRound.auction.partiallyIn');
      }

      return this.$t('demoDay.virtualRound.auction.out');
    },
    cap() {
      return this.auctionOrder.cap;
    },
    formattedCap() {
      if (this.cap === this.UNCAPPED_VALUE) return this.$t('demoDay.virtualRound.auction.uncapped');

      return `$ ${this.cap.toLocaleString('en-US')} CAP`;
    },
  },
};
</script>

<template>
  <div class="mt-4 flex flex-col rounded-lg border border-blue-gray-700 bg-pv-blue-900 p-4">
    <span class="text-sm font-light">
      {{ $t('demoDay.virtualRound.yourLastOffer') }}
    </span>
    <span
      class="mt-1 text-base font-semibold"
      :class="textColor"
    >
      ${{ auctionOrder.amount.toLocaleString('en-US') }} @ {{ formattedCap }}
    </span>
    <ul class="ml-3 mt-4 flex list-disc flex-col space-y-2">
      <li class="text-base">
        <span
          class="text-sm font-light"
          v-html="orderStatusText"
        />
        <span
          v-if="!auctionOrder.orderCompletelyIn"
          class="text-sm font-light"
        >
          {{ ` ${$t('demoDay.virtualRound.auction.wantToKnowWhy')}` }}
        </span>
      </li>
      <li
        v-if="!auctionOrder.investmentLegalInformation"
        class="text-base"
      >
        <span class="text-sm font-light">
          {{ $t('demoDay.virtualRound.auction.rememberToFillLegalInformation') }}
          <a
            :href="investmentLegalInformationPath"
            class="text-blue-400 underline"
            target="_blank"
          >{{ $t('here') }}</a>.
        </span>
      </li>
    </ul>
  </div>
</template>
