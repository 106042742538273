<script setup>
import { ref, computed } from 'vue';

const emit = defineEmits(['select']);
const props = defineProps({
  tabs: { type: Array, required: true },
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['tiny', 'small', 'big'].includes(value),
  },
});

const selectedTabIndex = ref(0);

function selectTab(index) {
  selectedTabIndex.value = index;
  emit('select', index);
}

const textClasses = computed(() => {
  const options = {
    tiny: 'mx-4 text-sm',
    small: 'mx-6 text-base',
    big: 'mx-8 text-lg',
  };

  return options[props.size];
});

const buttonClasses = computed(() => {
  const options = {
    tiny: 'py-1',
    small: 'py-3',
    big: 'py-3',
  };

  return options[props.size];
});
</script>

<template>
  <div class="flex w-full flex-row justify-between gap-x-2 overflow-x-scroll rounded-xl bg-pv-blue-700 p-1">
    <button
      v-for="(tab, index) in tabs"
      :key="`tab-button-${index}`"
      class="relative flex shrink-0 grow items-center justify-center rounded-xl"
      :class="[
        selectedTabIndex === index ?
          'text-blue-gray-50' :
          'text-pv-gray transition duration-300 hover:bg-pv-blue-900',
        buttonClasses
      ]"
      @click="selectTab(index)"
    >
      <span
        class="z-20"
        :class="[textClasses, { 'text-blue-gray-50': selectedTabIndex === index }]"
      >
        {{ tab }}
      </span>
      <div
        v-if="selectedTabIndex === index"
        class="absolute size-full rounded-xl bg-pv-blue-900 ring ring-blue-gray-700"
      />
    </button>
  </div>
</template>
