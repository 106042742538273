<script>
import DemoDayInvestorApplicationListItem from './demo-day-investor-application-list-item.vue';
import DemoDayInvestorApplicationsFilters from './demo-day-investor-applications-filters.vue';

export default {
  name: 'demo-day-investor-applications-list',
  components: { DemoDayInvestorApplicationListItem, DemoDayInvestorApplicationsFilters },
  props: {
    allApplications: { type: Array, required: true },
    currentBatchName: { type: String, required: false, default: null },
  },
  emits: ['select-application', 'filter-applications'],
  data() {
    return {
      filteredApplications: [...this.allApplications],
    };
  },
  computed: {
    applicationsCountries() {
      return [...this.allApplications.reduce((acc, application) => {
        const country = application.formAnswers?.basicInfo?.country?.value.name;
        if (country) return acc.add(country);

        return acc;
      }, new Set())];
    },
    applicationsStates() {
      return [...this.allApplications.reduce((acc, application) => {
        if (application.aasmState) return acc.add(application.aasmState);

        return acc;
      }, new Set())];
    },
    applicationsBatches() {
      return [...this.allApplications.reduce((acc, application) => {
        if (application.demoDay?.batchName) return acc.add(application.demoDay.batchName);

        return acc;
      }, new Set())];
    },
  },
  methods: {
    filterApplications(newFilteredApplications) {
      this.filteredApplications = [...newFilteredApplications];
      this.$emit('filter-applications', newFilteredApplications);
    },
  },
};
</script>

<template>
  <div class="flex flex-col">
    <span class="mb-1 text-lg">
      Postulaciones
    </span>
    <demo-day-investor-applications-filters
      class="mb-4"
      :applications-countries="applicationsCountries"
      :applications-states="applicationsStates"
      :applications-batches="applicationsBatches"
      :current-batch-name="currentBatchName"
      :all-applications="allApplications"
      @filter-applications="filterApplications"
    />
    <div class="flex flex-col divide-y divide-blue-gray-700 rounded-lg bg-pv-blue-900">
      <demo-day-investor-application-list-item
        v-for="(application) in filteredApplications"
        :key="application.id"
        :application="application"
        @select-application="$emit('select-application', application)"
      />
    </div>
  </div>
</template>
