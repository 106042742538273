<script setup>
import { ref } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

defineEmits(['edit-tags', 'send-email', 'edit-industry', 'edit-qualification']);
defineProps({
  withEditQualificationButton: { type: Boolean, default: false },
  qualificationOptions: { type: Array, default: () => [] },
});

const showQualificationOptions = ref(false);
</script>

<template>
  <div>
    <Menu
      as="div"
      class="relative"
    >
      <div>
        <MenuButton as="div">
          <kalio-button variant="tertiary">
            <span class="text-sm">
              Más acciones
            </span>
            <inline-svg
              :src="require('assets/images/icons/outline/kebab.svg')"
              class="ml-2 h-3 w-auto fill-current"
            />
          </kalio-button>
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems class="absolute right-0 z-30 mt-2 flex w-44 origin-top-right flex-col divide-y divide-blue-gray-700 rounded-md bg-pv-blue-800 shadow-lg ring-1 ring-blue-gray-700 focus:outline-none">
          <MenuItem>
            <button
              class="flex flex-row items-center space-x-2 rounded-t-md p-3 text-sm text-blue-gray-50 hover:bg-pv-blue-700"
              @click="$emit('edit-tags')"
            >
              <inline-svg
                :src="require('assets/images/icons/solid/tag.svg')"
                class="size-3 fill-current"
              />
              <span class="shrink-0 text-sm">
                Editar tags
              </span>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              class="flex flex-row items-center space-x-2 p-3 text-sm text-blue-gray-50"
              @click="$emit('send-email')"
            >
              <inline-svg
                :src="require('images/icons/solid/envelope.svg')"
                class="size-3 fill-current"
              />
              <span class="shrink-0 text-sm">
                Correo a fundadores
              </span>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              class="flex flex-row items-center space-x-2 p-3 text-sm text-blue-gray-50 hover:bg-pv-blue-700"
              @click="$emit('edit-industry')"
            >
              <inline-svg
                :src="require('assets/images/icons/solid/building-office.svg')"
                class="size-3 shrink-0 fill-current"
              />
              <span class="shrink-0 text-sm">
                Editar industrias
              </span>
            </button>
          </MenuItem>
          <MenuItem v-if="withEditQualificationButton">
            <button
              class="relative flex flex-row items-center space-x-2 rounded-b-md p-3 text-sm text-blue-gray-50 hover:bg-pv-blue-700"
              @click.prevent="showQualificationOptions = !showQualificationOptions"
            >
              <inline-svg
                :src="require('assets/images/icons/solid/pencil-square.svg')"
                class="size-3 fill-current"
              />
              <span class="shrink-0 text-sm">
                Calificar
              </span>
              <div
                v-if="showQualificationOptions"
                class="absolute -left-36 top-0 flex w-32 flex-col overflow-hidden rounded-md border-blue-gray-700 bg-pv-blue-800 ring-1 ring-blue-gray-700"
              >
                <button
                  v-for="(qualification, index) in qualificationOptions"
                  :key="index"
                  class="p-4 hover:bg-pv-blue-700"
                  @click="$emit('edit-qualification', qualification)"
                >
                  <span>
                    {{ qualification }}
                  </span>
                </button>
              </div>
            </button>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
