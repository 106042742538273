<script setup>
import { inject, computed } from 'vue';
import { screenKey, currentUserKey } from '@/utils/keys.js';
import BaseBig5ResultsChart from '@/components/shared/base-big5-results-chart.vue';
import { getResultsWithText } from '@/utils/big5.js';

const props = defineProps({
  big5: { type: Object, required: true },
});

const resultsWithText = getResultsWithText(props.big5.answers);

const screen = inject(screenKey);
const currentUser = inject(currentUserKey);
const big5BelongsToCurrentUser = computed(() => currentUser.value.founderProfile?.id === props.big5.founderProfileId);
</script>

<template>
  <div class="flex w-full max-w-5xl flex-col space-y-10">
    <kalio-back-button
      v-if="big5BelongsToCurrentUser"
      href="/apply"
    />
    <div class="flex flex-col space-y-12 rounded-xl bg-pv-blue-900 px-6 py-8 sm:space-y-20 sm:px-9 sm:py-12">
      <kalio-header
        :title="screen.isMobile ? 'Test Big 5' : 'Test de personalidad Big 5'"
        icon="solid/user.svg"
      />
      <div class="w-full rounded-md bg-pv-blue-800 px-6 py-4">
        <base-big5-results-chart
          :labels="resultsWithText.map(score => score.title)"
          :data="resultsWithText.map(score => score.score)"
          :height="screen.isMobile ? 300 : 150"
          :width="300"
        />
      </div>
      <div class="flex flex-col space-y-16">
        <div
          v-for="(item, itemIndex) in resultsWithText"
          :key="itemIndex"
        >
          <h2 class="text-xl font-bold text-blue-gray-50 sm:text-3xl">
            {{ item.title }}
          </h2>
          <span class="mt-1 text-blue-gray-400 sm:text-lg">
            score: {{ item.score }} - {{ item.scoreText }}
          </span>
          <p class="mt-4 text-sm text-blue-gray-300 sm:text-base">
            {{ item.shortDescription }}
          </p>
          <base-big5-results-chart
            class="mt-4"
            :labels="item.facets.map(facet => facet.title)"
            :data="item.facets.map(facet => facet.score)"
            :height="200"
            :width="screen.isMobile ? 300 : 800"
            :suggested-max="20"
          />
          <div class="mt-8 flex flex-col space-y-8">
            <div
              v-for="(facet, facetIndex) in item.facets"
              :key="facetIndex"
            >
              <h3 class="text-lg font-medium text-blue-gray-50 sm:text-xl">
                {{ facet.title }}
              </h3>
              <span class="text-sm text-blue-gray-400 sm:text-base">
                score: {{ facet.score }} - {{ facet.scoreText }}
              </span>
              <p class="mt-4 text-sm text-blue-gray-300 sm:text-base">
                {{ facet.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
