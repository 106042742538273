<script setup>
import { inject } from 'vue';
import BaseInformationCard from '@/components/shared/base-information-card.vue';
import BaseLibraryItemSmallCard from '@/components/shared/base-library-item-small-card.vue';
import { screenKey } from '@/utils/keys.js';

defineProps({
  libraryItems: { type: Array, required: true },
});

const screen = inject(screenKey);
</script>

<template>
  <base-information-card title="Biblioteca de recursos">
    <p class="text-base">
      Hemos recopilados una gran cantidad de recursos propios y de terceros que pueden guiarte.
    </p>
    <div class="mt-4 max-w-xs self-center sm:max-w-xl lg:max-w-md xl:max-w-lg">
      <kalio-carousel
        :navigation="!screen.isMobile"
        :pagination="screen.isMobile"
        :autoplay="4000"
        wrap-around
        :items="libraryItems"
      >
        <template #item="{ item: libraryItem }">
          <base-library-item-small-card :library-item="libraryItem" />
        </template>
      </kalio-carousel>
    </div>
    <a
      href="/library_items"
      class="mt-3 flex flex-row items-center space-x-2 self-center text-pv-yellow hover:opacity-75"
    >
      <span class="text-sm">
        Ir a la biblioteca de recursos
      </span>
      <inline-svg
        :src="require('assets/images/icons/arrow.svg')"
        class="size-3 rotate-180 fill-current"
      />
    </a>
  </base-information-card>
</template>
