<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { legendMargin } from '@/utils/charts';

ChartJS.register(Tooltip, Legend, BarElement, LinearScale, ChartDataLabels, CategoryScale);

export default {
  name: 'base-startup-users-chart',
  components: { Bar },
  props: {
    applicationUpdates: { type: Array, required: true },
  },
  setup(props) {
    const users = computed(() => props.applicationUpdates.map((update) => update.totalUsers || 0));

    return {
      chartData: {
        labels: props.applicationUpdates.map((update) => format(new Date(update.createdAt), 'dd MMM yyyy')),
        datasets: [{
          label: 'Usuarios',
          data: users.value,
          backgroundColor: '#94A3B8',
        }],
      },
      chartOptions: {
        barThickness: 'flex',
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              color: 'white',
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              color: 'white',
            },
          },
        },
        parsing: {
          yAxisKey: 'amount',
          xAxisKey: 'month',
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: 'white',
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            color: 'white',
          },
        },
      },
      plugins: [legendMargin],
    };
  },
};

</script>

<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row items-center space-x-2">
      <inline-svg
        :src="require('assets/images/icons/user.svg')"
        class="size-5 fill-current"
      />
      <span class="font-semibold">
        Usuarios
      </span>
    </div>
    <Bar
      id="startup-users-chart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :plugins="plugins"
      :height="170"
      :width="300"
    />
  </div>
</template>
