<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { watch, nextTick, ref } from 'vue';
import { useField } from 'vee-validate';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';

const emit = defineEmits(['submit', 'update:modelValue']);
const props = defineProps({
  modelValue: { type: String, required: true },
  loading: { type: Boolean, default: false },
});

const chatInputRef = ref(null);
defineExpose({ chatInputRef });

const {
  value: inputValue,
  handleChange,
} = useField(name, null, { initialValue: props.modelValue });

function resizeTextarea() {
  if (!chatInputRef.value?.style) return;
  chatInputRef.value.style.height = 'auto'; // Reset height
  chatInputRef.value.style.height = `${chatInputRef.value.scrollHeight}px`;
}

function handleSubmit(event) {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
    emit('submit');
  }
}

watch(inputValue, () => {
  nextTick(resizeTextarea);
});
</script>

<template>
  <vee-form
    class="flex flex-row items-end rounded-lg border border-blue-gray-600 bg-pv-blue-700 p-2"
    :class="{ 'ring-1 ring-pv-light-blue': modelValue }"
    @submit="handleSubmit"
  >
    <textarea
      v-bind="$attrs"
      ref="chatInputRef"
      :value="inputValue"
      class="max-h-36 w-full grow resize-none bg-transparent p-1 pr-2 text-xs focus:outline-none"
      placeholder="Escribe un mensaje"
      rows="1"
      style="height: 1.5rem;"
      @input="handleChange"
      @keydown.enter="handleSubmit"
    />

    <div class="h-full">
      <kalio-spinner v-if="loading" />
      <inline-svg
        v-else
        :src="require('assets/images/icons/outline/paper-airplane.svg')"
        class="mb-1 size-4 stroke-current transition hover:stroke-pv-yellow"
        @click="emit('submit')"
      />
    </div>
  </vee-form>
</template>
