
<script setup>
import { ref, computed } from 'vue';
import KalioShortTextInput from '@/components/form/kalio-short-text-input.vue';
import KalioLongTextInput from '@/components/form/kalio-long-text-input.vue';

const props = defineProps({
  email: { type: String, required: true },
  subject: { type: String, default: '' },
});

const emailBody = ref(props.subject);
const emailSubject = ref('');
const dirty = ref(false);

const emailDataBody = computed(() => emailBody.value?.replaceAll('\n', '%0D%0A'));

</script>

<template>
  <div class="flex w-full flex-col space-y-6">
    <kalio-short-text-input
      v-model="emailSubject"
      name="emailSubject"
      label="Asunto"
    />
    <kalio-long-text-input
      v-model="emailBody"
      name="message"
      label="Mensaje"
    />
    <kalio-button
      :href="`mailto:${email}?subject=${emailSubject}&body=${emailDataBody}`"
      target="_blank"
      label="Enviar mensaje"
      class="self-center sm:px-24"
      @click="dirty = true"
    />
    <span
      v-if="dirty"
      class="max-w-sm self-center text-xs text-pv-gray"
    >
      Si no se abre nada, es probable que debas configurar tu aplicación de correo
      para que abra los links de email.
    </span>
  </div>
</template>
