<script setup>
import { ref, computed, inject } from 'vue';
import { getItems } from '@alheimsins/b5-johnson-120-ipip-neo-pi-r';
import { useMutation } from '@tanstack/vue-query';
import { shuffle } from 'lodash';
import BaseHorizontalRadioInputs from '@/components/shared/base-horizontal-radio-inputs.vue';
import big5Api from '@/api/apply/big5.js';
import { screenKey } from '@/utils/keys.js';
import { TOTAL_QUESTIONS, NUMBER_OF_PAGES, QUESTIONS_PER_PAGE } from '@/utils/big5.js';

const questions = ref(shuffle(getItems('es')).map(item => ({ ...item, score: null })));

const questionsAnswered = computed(() => questions.value.filter(q => q.score !== null));
const questionsAnsweredPercentage = computed(
  () => Math.ceil((questionsAnswered.value.length / TOTAL_QUESTIONS) * 100),
);

const currentPage = ref(0);
const pages = computed(() => {
  const results = [];

  for (let i = 0; i < questions.value.length; i += QUESTIONS_PER_PAGE) {
    results.push(questions.value.slice(i, i + QUESTIONS_PER_PAGE));
  }

  return results;
});

const { mutate, isLoading } = useMutation(
  () => big5Api.create({ answers: questions.value }),
  {
    onSuccess: () => {
      window.location.href = '/apply/profile/big5/results';
    },
  },
);

function submit() {
  if (currentPage.value < NUMBER_OF_PAGES - 1) {
    currentPage.value += 1;
  } else {
    mutate();
  }
}

const wordChoices = [
  'Muy en desacuerdo',
  'En desacuerdo',
  'Ni de acuerdo ni en desacuerdo',
  'De acuerdo',
  'Muy de acuerdo',
];

const screen = inject(screenKey);
</script>

<template>
  <div class="flex w-full max-w-5xl flex-col space-y-10">
    <kalio-back-button href="/apply" />
    <vee-form
      v-slot="{ meta, handleSubmit }"
      class="flex flex-col space-y-10 rounded-lg bg-pv-blue-900 px-6 py-8 sm:space-y-12 sm:px-9 sm:py-12"
    >
      <kalio-header
        :title="screen.isMobile ? 'Test Big 5' : 'Test de personalidad Big 5'"
        icon="solid/user.svg"
      />
      <div class="flex flex-row items-center space-x-2 px-4 lg:px-12">
        <div class="w-full rounded-full bg-pv-blue-700">
          <div
            class="h-3 rounded-full bg-pv-yellow sm:h-6"
            :style="{ width: `${questionsAnsweredPercentage}%` }"
          />
        </div>
        <span class="text-xs sm:text-base">
          {{ questionsAnsweredPercentage }}%
        </span>
      </div>
      <div class="hidden w-full flex-col md:flex">
        <div class="mb-2 mr-3 flex flex-row items-center self-end">
          <div class="mr-2 flex flex-col items-center text-xs font-light">
            <span>
              Muy en
            </span>
            <span>
              desacuerdo
            </span>
          </div>
          <div class="mr-4 flex flex-col items-center text-xs font-light">
            <span>
              En
            </span>
            <span>
              desacuerdo
            </span>
          </div>
          <span class="mr-4 text-xs">
            Neutro
          </span>
          <div class="mr-8 flex flex-col items-center text-xs font-light">
            <span>
              De
            </span>
            <span>
              acuerdo
            </span>
          </div>
          <div class="mr-2 flex flex-col items-center text-xs font-light">
            <span>
              Muy de
            </span>
            <span>
              acuerdo
            </span>
          </div>
        </div>
        <div
          v-for="(question, index) in pages[currentPage]"
          :key="index"
          class="flex flex-row justify-between gap-x-4 rounded-md p-4 pr-10"
          :class="{
            'bg-pv-blue-800': index % 2 === 0,
          }"
        >
          <span class="mr-8">
            {{ question.text }}
          </span>
          <base-horizontal-radio-inputs
            v-model="questions[currentPage * QUESTIONS_PER_PAGE + index].score"
            :name="`question-${index}`"
            :values="question.choices.map(choice => choice.score.toString())"
            rules="required"
            class="space-x-12"
          />
        </div>
      </div>
      <div class="flex w-full flex-col space-y-8 md:hidden">
        <kalio-radio-inputs
          v-for="(question, index) in pages[currentPage]"
          :key="index"
          v-model="questions[currentPage * QUESTIONS_PER_PAGE + index].score"
          :label="question.text"
          :name="`question-${index}`"
          :radio-labels="wordChoices"
          :values="question.choices.map(choice => choice.score.toString())"
          rules="required"
        />
      </div>
      <div class="flex flex-col items-center space-y-4 self-center">
        <div class="flex flex-row items-center space-x-4">
          <kalio-button
            v-if="currentPage > 0"
            label="Anterior"
            type="button"
            class="sm:px-20"
            variant="secondary"
            left-icon="long-arrow.svg"
            left-icon-classes="rotate-180 fill-current mr-1.5"
            @click="currentPage -= 1"
          />
          <kalio-button
            v-if="currentPage < NUMBER_OF_PAGES - 1"
            label="Siguiente"
            type="button"
            class="sm:px-20"
            right-icon="long-arrow.svg"
            @click="handleSubmit(submit)"
          />
          <kalio-button
            v-else
            label="Calcular puntaje"
            type="button"
            class="sm:px-20"
            :loading="isLoading"
            @click="handleSubmit(submit)"
          />
        </div>
        <span
          class="text-xs text-red-600 sm:text-sm"
          :class="{ 'invisible': !(meta.touched && !meta.valid) }"
        >
          Debes contestar todas las preguntas.
        </span>
      </div>
    </vee-form>
  </div>
</template>
