<script setup>
import { storeToRefs } from 'pinia';
import KalioStartupApplicationUpdatesTabs
  from 'components/startup-application-updates/kalio-startup-application-updates-tabs.vue';
import { useStartupApplicationStore } from '@/stores';
import BaseStartupRevenuesChart from '../charts/base-startup-revenues-chart.vue';
import BaseStartupUsersChart from '../charts/base-startup-users-chart.vue';

const startupApplicationStore = useStartupApplicationStore();
const { currentStartupApplication: startupApplication } = storeToRefs(startupApplicationStore);
</script>

<template>
  <div>
    <div class="flex flex-col space-y-12">
      <base-startup-users-chart :application-updates="startupApplication.startupApplicationUpdates" />
      <base-startup-revenues-chart
        :application-updates="startupApplication.startupApplicationUpdates"
        :startup-application="startupApplication"
      />
      <div class="mt-4 flex flex-col items-start space-y-2">
        <kalio-startup-application-updates-tabs
          :updates="startupApplication.startupApplicationUpdates"
          variant="dark"
        />
      </div>
    </div>
  </div>
</template>
