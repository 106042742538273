import api from './api';

export default {
  getAll(params = {}) {
    return api({
      url: '/api/v1/group_meeting_commitments',
      params,
    });
  },
  create(data) {
    return api({
      url: '/api/v1/group_meeting_commitments',
      method: 'post',
      data: { groupMeetingCommitment: data },
    });
  },
  update(id, data) {
    return api({
      url: `/api/v1/group_meeting_commitments/${id}`,
      method: 'patch',
      data: { groupMeetingCommitment: data },
    });
  },
};
