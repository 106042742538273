<script>
import { defineComponent, ref, computed, toRefs, nextTick } from 'vue';
import { useField } from 'vee-validate';
import KalioBaseInput from './kalio-base-input';

export default defineComponent({
  name: 'kalio-text-list-input',
  components: {
    KalioBaseInput,
  },
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    rules: { type: String, default: '' },
    modelValue: { type: Array, default: () => ([]) },
    label: { type: String, default: '' },
    description: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },
  emits: ['update:modelValue', 'newItemInput'],
  setup(props, { emit }) {
    const { name, rules, modelValue } = toRefs(props);
    const filteredRules = computed(() => {
      let _rules = [...rules.value.split('|')];

      if (modelValue.value.length > 0) {
        _rules = _rules.filter(rule => rule !== 'required');
      }

      return _rules.join('|');
    });

    const {
      value: inputValue,
      errorMessage,
      resetField,
      errors,
    } = useField(name, filteredRules, { syncVModel: false });

    function removeItem(index) {
      const newList = [...modelValue.value];
      newList.splice(index, 1);
      emit('update:modelValue', newList);
    }

    const isValid = computed(() => errors.value.length === 0);
    const input = ref(null);

    function addItem() {
      if (!isValid.value) return;

      emit('update:modelValue', [...modelValue.value, inputValue.value]);
      nextTick(resetField);
      input.value.focus();
    }

    const required = computed(() => rules.value.includes('required'));

    return {
      inputValue,
      errorMessage,
      input,
      removeItem,
      addItem,
      required,
      isValid,
      errors,
    };
  },
});
</script>

<template>
  <kalio-base-input
    :label="label"
    :description="description"
    :required="required"
  >
    <template #input>
      <div class="flex flex-col">
        <div
          class="flex w-full appearance-none flex-col divide-y overflow-hidden rounded-md border bg-blue-gray-900"
          :class="[
            errorMessage ?
              'divide-red-600 border-red-600' :
              'divide-blue-gray-700 border-blue-gray-700',
          ]"
        >
          <div
            v-for="(item, index) in modelValue"
            :key="index"
            class="flex"
          >
            <span class="flex-1 px-6 py-3">
              {{ item }}
            </span>
            <button
              type="button"
              class="px-6 py-3 focus:outline-none"
              @click="removeItem(index)"
            >
              <inline-svg
                :src="require('assets/images/icons/minus.svg')"
                class="size-4 fill-current text-pv-gray"
              />
            </button>
          </div>
          <div class="flex">
            <input
              v-bind="$attrs"
              ref="input"
              v-model="inputValue"
              :name="`${name}-index`"
              class="w-full bg-transparent px-6 py-3 outline-none"
              :placeholder="placeholder"
              @keypress.enter.exact.prevent="addItem"
            >
            <button
              type="button"
              class="bg-blue-gray-700 px-6 py-3 focus:outline-none disabled:cursor-not-allowed"
              :class="{ 'hover:opacity-75': inputValue && isValid }"
              :disabled="!inputValue || !isValid"
              @click="addItem"
            >
              <inline-svg
                :src="require('assets/images/icons/plus.svg')"
                class="size-4 fill-current text-blue-gray-200"
              />
            </button>
          </div>
        </div>
        <span
          v-if="errorMessage"
          class="mt-0.5 text-sm text-red-600"
        >
          {{ errorMessage }}
        </span>
      </div>
    </template>
  </kalio-base-input>
</template>
