import api from './api';

export default {
  create(body) {
    const path = '/internal/api/v1/investment_firms';

    return api({
      method: 'post',
      data: body,
      url: path,
    });
  },
};
