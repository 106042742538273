<script>
import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import KalioButton from 'components/shared/kalio-button.vue';
import KalioSpinner from 'components/shared/kalio-spinner.vue';
import applicationFounderReferenceApi from 'api/application_founder_references.js';

export default {
  name: 'kalio-startup-application-references',
  components: { KalioButton, KalioSpinner },
  props: {
    startupApplication: { type: Object, required: true },
  },
  emits: ['update-references'],
  data() {
    return {
      newReference: null,
      savedReferences: [],
      selectedTab: 0,
      adding: false,
      phoneEmailError: '',
      saveLoading: false,
      loading: true,
    };
  },
  computed: {
    founders() {
      return this.startupApplication.applicationFounders.map(
        (founder) => ({
          id: founder.id, firstName: founder.user.firstName,
          lastName: founder.user.lastName, country: founder.user.country,
        }),
      );
    },
  },
  mounted() {
    configure({
      generateMessage: localize({
        es: {
          messages: {
            phone: 'Si vas a ingresar un número, tiene que ser válido',
          },
        },
        en: {
          messages: {
            phone: 'If you are adding a number, it must be valid',
          },
        },
      }),
    });

    applicationFounderReferenceApi.getAll()
      .then((response) => {
        this.savedReferences = response.applicationFounderReferences;
        this.$emit('update-references', response.applicationFounderReferences);
        this.loading = false;
      });
  },
  methods: {
    addReference() {
      this.newReference = this.createNewReference();
    },
    createNewReference() {
      return {
        id: Math.random(),
        founder: null,
        fullName: '',
        email: '',
        phone: '',
        relationshipWithFounder: '',
      };
    },
    saveReference() {
      if (!this.newReference.email && !this.newReference.phone) {
        this.phoneEmailError = 'Debes agregar como mínimo un email o un número';

        return;
      }
      this.saveLoading = true;
      const { founder, fullName, email, phone, relationshipWithFounder } = this.newReference;
      applicationFounderReferenceApi.create(
        {
          applicationFounderReference: {
            fullName,
            email,
            phone,
            relationshipWithFounder,
            applicationFounderId: founder.id,
          },
        },
      )
        .then((response) => {
          this.phoneEmailError = '';
          this.savedReferences = [...this.savedReferences, response.applicationFounderReference];
          this.newReference = null;
          this.$emit('update-references', this.savedReferences);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    getFounderName(founder) {
      return `${founder.firstName} ${founder.lastName}`;
    },
    cancelNewReference() {
      this.newReference = null;
    },
  },
};
</script>

<template>
  <div class="mb-8 flex flex-col gap-2">
    <p class="text-xs">
      Antes de agendar la entrevista, les queremos pedir referencias de personas
      que los conozcan o que hayan trabajado con ustedes.
    </p>
    <p class="text-xs">
      La persona que está agendando la entrevista debe ingresar las referencias suyas y las de todos sus cofundadores.
    </p>
    <p class="text-xs">
      Es muy probable que conversemos con sus referencias durante el proceso.
    </p>
    <p class="text-xs">
      Nos encantaría que ingresen al menos dos referencias por persona, pero pueden ingresar más si quieren.
    </p>
  </div>
  <div v-if="!newReference">
    <div
      v-if="loading"
      class="flex h-14 items-center justify-center"
    >
      <kalio-spinner />
    </div>
    <div v-else>
      <div class="flex justify-end">
        <kalio-button
          label="Añadir referencia"
          variant="tertiary"
          left-icon="plus.svg"
          class="mb-8 w-full sm:w-auto"
          size="small"
          @click="addReference"
        />
      </div>
      <div class="grid grid-cols-2">
        <div
          v-for="reference in savedReferences"
          :key="reference.id"
          class="col-span-2 mb-8 lg:col-span-1"
        >
          <div class="flex items-start">
            <inline-svg
              :src="require('assets/images/icons/user.svg')"
              class="mr-4 size-14 fill-current"
            />
            <div class="flex flex-col">
              <span>
                {{ reference.fullName }}
              </span>
              <span class="text-xs">
                Referencia de {{ getFounderName(reference.founder) }}
              </span>
              <span
                v-if="reference.email"
                class="text-sm"
              >
                {{ reference.email }}
              </span>
              <span
                v-if="reference.phone"
                class="text-sm"
              >
                {{ reference.phone }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <vee-form
    v-else
    class="mt-8 flex flex-col gap-2"
    @submit="saveReference"
  >
    <span class="text-lg font-bold">
      Nueva referencia
    </span>
    <kalio-select-input
      v-model="newReference.founder"
      name="reference_founder"
      rules="required"
      size="small"
      label="Fundador referido"
      :options="founders"
      :get-option-label="(option) => getFounderName(option)"
    />
    <kalio-short-text-input
      v-model="newReference.fullName"
      size="small"
      name="reference_full_name"
      rules="required"
      label="Nombre completo"
    />
    <kalio-short-text-input
      v-model="newReference.email"
      size="small"
      name="reference_email"
      rules="email"
      label="Email"
      :error="phoneEmailError"
    />
    <kalio-tel-input
      v-model="newReference.phone"
      name="phone"
      label="Teléfono"
      size="small"
      :country-hint="newReference.founder?.country"
      :error="phoneEmailError"
    />
    <kalio-short-text-input
      v-model="newReference.relationshipWithFounder"
      size="small"
      name="reference_relationship_with_founder"
      rules="required"
      label="¿Cuál es la relación con esta persona?"
    />
    <div class="flex justify-between">
      <kalio-button
        label="Cancelar"
        variant="red"
        class="mt-4 w-full sm:w-auto"
        :disabled="saveLoading"
        size="small"
        @click="cancelNewReference"
      />
      <kalio-button
        label="Guardar referencia"
        variant="tertiary"
        class="mt-4 w-full sm:w-auto"
        type="submit"
        :loading="saveLoading"
        size="small"
      />
    </div>
  </vee-form>
</template>
