<script>
import { ref, computed } from 'vue';
import * as moment from 'moment';
import commentsApi from '../../api/comments';
import KalioButton from './kalio-button.vue';
import KalioComment from './kalio-comment.vue';

export default {
  name: 'KalioComments',
  components: {
    KalioButton,
    KalioComment,
  },
  props: {
    title: { type: String, default: null },
    resource: { type: Object, required: true },
    resourceType: { type: String, required: true },
    canWrite: { type: Boolean, default: true },
    name: { type: String, default: 'comments' },
    description: { type: String, default: null },
  },
  emits: ['comment-created'],
  setup(props, { emit }) {
    const newComment = ref('');
    const loading = ref(false);

    const comments = computed(() => [...props.resource.comments].sort(
      (a, b) => moment(a.createdAt) - moment(b.createdAt),
    ) || []);

    function createComment() {
      loading.value = true;
      commentsApi.create({
        body: newComment.value,
        resourceType: props.resourceType,
        resourceId: props.resource.id,
      }).then((response) => {
        newComment.value = '';
        emit('comment-created', response.comment, props.resource.id);
      }).finally(() => {
        loading.value = false;
      });
    }

    return {
      newComment,
      loading,
      comments,
      createComment,
    };
  },
};
</script>

<template>
  <div class="space-y-3">
    <h2 class="flex items-center">
      <inline-svg
        :src="require('assets/images/icons/comment.svg')"
        class="mr-3 size-6 fill-current text-blue-gray-50"
      />
      <span class="text-lg text-blue-gray-100">
        {{ title || $t('comments.title') }}
      </span>
    </h2>
    <div
      v-if="comments.length === 0"
      class="text-base text-blue-gray-100"
    >
      {{ $t('comments.noComments') }}
    </div>
    <div
      v-else
      class="mb-6 space-y-2"
    >
      <kalio-comment
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
      />
    </div>

    <div
      v-if="canWrite"
      class="flex flex-col space-y-2"
    >
      <span
        v-if="description"
        class="text-xs italic text-pv-gray"
      >
        {{ description }}
      </span>
      <kalio-trix-editor
        v-model="newComment"
        :name="name"
        variant="semi-dark"
      />
      <kalio-button
        class="ml-auto mt-2"
        :label="$t('actions.send')"
        :loading="loading"
        :disabled="!newComment"
        @click="createComment"
      />
    </div>
  </div>
</template>
