<script>
import { ref, computed, toRefs } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import KalioSpinner from '../shared/kalio-spinner.vue';
import groupMeetingCompanyCommentsApi from '../../api/group_meeting_company_comments';
import KalioGroupMeetingsCompanyComment from './company-comment.vue';

function sortByCreatedAt(a, b) {
  return new Date(a.createdAt) - new Date(b.createdAt);
}

export default {
  name: 'group-meetings-company-comments',
  components: { KalioSpinner, KalioGroupMeetingsCompanyComment },
  props: {
    groupMeeting: { type: Object, required: true },
    company: { type: Object, required: true },
  },
  setup(props) {
    const { groupMeeting, company } = toRefs(props);

    const { data, isError, isLoading } = useQuery(
      ['groupMeetingCompanyComments', groupMeeting],
      () => groupMeetingCompanyCommentsApi.getAll(groupMeeting.value.id),
    );

    const comments = computed(
      () => [...data.value?.groupMeetingCompanyComments.filter(comment => comment.companyId === company.value.id)]
        .sort((a, b) => sortByCreatedAt(a, b)),
    );

    const inputsVisible = ref(true);

    return {
      comments,
      isLoading,
      isError,
      inputsVisible,
    };
  },
};
</script>

<template>
  <div class="flex flex-col space-y-4">
    <h2 class="flex items-center">
      <inline-svg
        :src="require('assets/images/icons/comment.svg')"
        class="mr-3 size-6 fill-current text-blue-gray-50"
      />
      <span class="text-lg text-blue-gray-100">
        Comentarios
      </span>
    </h2>
    <div class="flex grow flex-col overflow-y-scroll">
      <div
        v-if="isLoading"
        class="flex grow items-center justify-center"
      >
        <kalio-spinner class="size-10 self-center" />
      </div>
      <kalio-alert
        v-else-if="isError"
        status="alert"
        title="Hubo un error cargando los comentarios."
        size="small"
        :deletable="false"
      />
      <div
        v-else-if="comments.length > 0"
        class="flex flex-col space-y-2"
      >
        <kalio-group-meetings-company-comment
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
        />
      </div>
      <span
        v-else
        class="self-center text-sm text-pv-gray"
      >
        No hay comentarios.
      </span>
    </div>
  </div>
</template>
