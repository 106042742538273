<script>
export default {
  props: {
    jobApplication: { type: Object, required: true },
  },
};
</script>

<template>
  <div class="flex shrink-0 flex-col divide-y divide-blue-gray-700">
    <button
      class="flex h-1/2 cursor-pointer items-center p-4"
      @click="$emit('open-application-modal')"
    >
      <inline-svg
        :src="require('assets/images/icons/visibility.svg')"
        class="mr-2 size-4 fill-current"
      />
      <p class="text-sm">
        {{ $t('jobApplications.actions.show') }}
      </p>
    </button>
    <button
      class="flex h-1/2 cursor-pointer items-center p-4"
      @click="window.open(jobApplication.resumePath, '_blank')"
    >
      <inline-svg
        :src="require('assets/images/icons/download.svg')"
        class="mr-2 size-4 fill-current"
      />
      <p class="text-sm">
        {{ $t('jobApplications.actions.downloadCV') }}
      </p>
    </button>
  </div>
</template>
