<script setup>
import { computed } from 'vue';
import CutOutCornerCard from '@/components/shared/cut-out-corner-card.vue';

const props = defineProps({
  user: { type: Object, required: true },
});

const companyName = computed(() => props.user.externalCompany?.name);
const position = computed(() => props.user.title);

</script>

<template>
  <div class="flex w-full gap-4 rounded-xl border border-blue-gray-700 bg-pv-blue-800 p-4">
    <cut-out-corner-card
      class="size-24 shrink-0"
      cut-size="28px"
      top-right-color="pv-blue-800"
      bottom-left-color="pv-blue-800"
    >
      <img
        class="size-full object-cover"
        :src="user.avatarUrl"
      >
    </cut-out-corner-card>
    <div class="mr-2 flex h-full grow flex-col">
      <div class="flex items-center gap-x-2">
        <p class="w-[90%] truncate text-lg font-medium">
          {{ user.fullName }}
        </p>
      </div>
      <div class="flex flex-wrap items-center gap-x-2.5">
        <span class="text-center text-pv-gray md:text-left">
          {{ companyName }}
        </span>
        <span
          v-if="position"
          class="rounded-full bg-pv-gray/30 px-2 py-0.5 text-xs text-pv-gray"
        >
          {{ position }}
        </span>
      </div>

      <div class="flex items-center gap-2">
        <p v-if="user.city">
          {{ user.city }}
        </p>
        <div class="-mb-3 -ml-3 -mt-2">
          <country-flag
            v-if="user.country"
            :country="user.country.code"
            class="text-xs"
          />
        </div>
      </div>

      <div class="flex gap-2">
        <kalio-copy-text-button
          title="Copiar email"
          :text="user.email"
          icon-path="social-media/email.svg"
          icon-classes="h-4 w-4 fill-current"
        />
        <a
          v-if="user.socialMedia.linkedin"
          :href="user.socialMedia.linkedin"
          class="transition hover:text-sky-700"
        >
          <inline-svg
            :src="require('assets/images/icons/social-media/linkedin.svg')"
            class="size-4 fill-current"
          />
        </a>
        <a
          v-if="user.socialMedia.twitter"
          :href="user.socialMedia.twitter"
          class="transition hover:text-sky-500"
        >
          <inline-svg
            :src="require('assets/images/icons/social-media/twitter.svg')"
            class="size-4 fill-current"
          />
        </a>
      </div>
    </div>
  </div>
</template>
