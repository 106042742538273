import api from '@/api/api';

export default {
  getAll(demoDayId) {
    const path = '/api/v1/demo_day/auctions';

    return api({
      method: 'get',
      url: path,
      params: { demoDayId },
    });
  },
  get({ id, investableId }) {
    let path;

    if (id) {
      path = `/api/v1/demo_day/auctions/${id}`;
    } else {
      path = `/api/v1/demo_day/companies/${investableId}/auction`;
    }

    return api({
      method: 'get',
      url: path,
    });
  },
};
