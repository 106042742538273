<script setup>
import { ref } from 'vue';

const COPY_TIMEOUT_IN_MS = 1500;

const props = defineProps({
  text: { type: String, required: true },
  title: { type: String, default: 'Copiar' },
  showTooltip: { type: Boolean, default: true },
});

const emit = defineEmits(['copied', 'clear']);
const copied = ref(false);
const currentTimeout = ref(null);

function clearCopyTimeout() {
  if (currentTimeout.value) {
    clearTimeout(currentTimeout);
  }
}

function setCopyTimeout() {
  copied.value = true;
  emit('copied');
  currentTimeout.value = setTimeout(() => {
    copied.value = false;
    emit('clear');
  }, COPY_TIMEOUT_IN_MS);
}

function copy() {
  clearCopyTimeout();
  setCopyTimeout();
  navigator.clipboard.writeText(props.text);
}
</script>

<template>
  <button
    v-tooltip="props.showTooltip && (title || 'Copiar')"
    class="focus:outline-none"
    @click.prevent="copy"
  >
    <slot />
  </button>
</template>
