<script setup>
import BaseInformationCard from '@/components/shared/base-information-card.vue';

defineProps({
  companies: { type: Array, required: true },
  batchGroup: { type: Object, required: true },
});
</script>

<template>
  <base-information-card title="Tu grupo">
    <div class="flex flex-col items-center space-y-2">
      <div class="flex flex-row flex-wrap items-center gap-x-4 gap-y-2">
        <div
          v-for="company in companies"
          :key="company.id"
          class="flex flex-col items-center space-y-1"
        >
          <img
            :src="company.isotypeUrl"
            class="size-20 shrink-0 rounded-lg"
          >
          <span>
            {{ company.name }}
          </span>
        </div>
      </div>
      <a
        :href="`/batch_groups/${batchGroup.id}/group_meetings`"
        class="mt-3 flex flex-row items-center space-x-2 self-center text-pv-yellow hover:opacity-75"
      >
        <span class="text-sm">
          Ir al dashboard
        </span>
        <inline-svg
          :src="require('assets/images/icons/arrow.svg')"
          class="size-3 rotate-180 fill-current"
        />
      </a>
    </div>
  </base-information-card>
</template>
