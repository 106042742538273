<script>
import { inject } from 'vue';
import { format, formatDistance, isBefore, sub } from 'date-fns';
import { es } from 'date-fns/locale';
import ProgressCheckpointBar from 'components/shared/progress-checkpoint-bar.vue';
import KalioConfirmationModal from 'components/shared/kalio-confirmation-modal.vue';
import KalioCalendlySchedule from 'components/shared/kalio-calendly-schedule.vue';
import KalioStartupApplicationUpdatesTabs
  from 'components/startup-application-updates/kalio-startup-application-updates-tabs.vue';
import BaseReviewDiscardEmailTemplate from '@/components/apply/review/mails/base-template.vue';
import { currentUserKey } from '@/utils/keys.js';
import applicationInterviewsApi from '../../api/application_interviews.js';
import KalioStartupApplicationReferences from './kalio-startup-application-references.vue';
import KalioStartupApplicationInProcessUpdateForm from './kalio-startup-application-in-process-update-form.vue';
import ApplicationInterviewCard from './application-interview-card.vue';

export default {
  name: 'kalio-startup-application-status-feedback',
  components: {
    ProgressCheckpointBar,
    KalioStartupApplicationReferences,
    KalioConfirmationModal,
    KalioCalendlySchedule,
    KalioStartupApplicationInProcessUpdateForm,
    KalioStartupApplicationUpdatesTabs,
    BaseReviewDiscardEmailTemplate,
    ApplicationInterviewCard,
  },
  props: {
    batchSeason: { type: String, required: true },
    resultsDate: { type: String, required: true },
    firstInterviewUrl: { type: String, default: null },
    secondInterviewUrl: { type: String, default: null },
    startupApplication: { type: Object, required: true },
    selectedState: {
      type: String,
      required: true,
      validator: (value) => [
        'submitted',
        'to_review',
        'in_short_interview',
        'in_long_interview',
        'selected',
        'discarded',
      ].includes(value),
    },
  },
  emits: ['download-answers'],
  setup() {
    return {
      currentUser: inject(currentUserKey),
    };
  },
  data() {
    return {
      currentCheckpoint: 0,
      references: [],
      referencesModalOpen: false,
      scheduledEventReceived: false,
      isDealAccepted: false,
      interviewToScheduleKind: this.startupApplication.passedToSecondInterview ?
        'second_interview' : 'first_interview',
      showUpdateModal: false,
      dynamicStartupApplication: { ...this.startupApplication },
    };
  },
  computed: {
    firstInterviewDate() {
      if (!this.dynamicStartupApplication.firstInterview) {
        return null;
      }

      return this.formatInterviewDate(this.dynamicStartupApplication.firstInterview.startDate);
    },
    secondInterviewDate() {
      if (!this.dynamicStartupApplication.secondInterview) {
        return null;
      }

      return this.formatInterviewDate(this.dynamicStartupApplication.secondInterview.startDate);
    },
    daysUntilUpdateAllowed() {
      const submittedDate = new Date(this.dynamicStartupApplication.submittedAt);
      const twoWeeksAgo = sub(Date.now(), { weeks: 2 });

      if (isBefore(submittedDate, twoWeeksAgo)) {
        return null;
      }

      return formatDistance(submittedDate, twoWeeksAgo, { locale: es });
    },
    title() {
      const titles = {
        submitted: `Postulación de ${this.dynamicStartupApplication.startupName}`,
        'to_review': `Postulación de ${this.dynamicStartupApplication.startupName}`,
        'in_short_interview': 'Entrevista con Platanus',
        'in_long_interview': 'Entrevista con Platanus',
        selected: 'Resultado de la postulación',
        discarded: 'Resultado de la postulación',
      };

      return titles[this.selectedState];
    },
    inProcessApplicationUpdates() {
      return this.dynamicStartupApplication.startupApplicationUpdates
        .filter((update) => update.kind === 'in_process');
    },
    guestEmails() {
      return this.dynamicStartupApplication.applicationFounders
        .filter((founder) => founder.email !== this.currentUser.email)
        .map((founder) => founder.email);
    },
  },
  methods: {
    formatInterviewDate(interviewDate) {
      const date = new Date(interviewDate);
      const day = format(date, 'dd');
      const month = this.$filters.capitalize(format(date, 'MMM', { locale: es }));
      const startTime = format(date, 'HH:mm a');
      const GMTOffset = format(date, 'xxx');

      return { month, day, startTime, GMTOffset };
    },
    handleCalendlyScheduledEvent(payload) {
      if (this.scheduledEventReceived) return;

      this.scheduledEventReceived = true;
      applicationInterviewsApi.create(payload.event.uri, this.interviewToScheduleKind);
    },
    changeCheckpoint(newCheckpoint, skipValidation) {
      const MINIMUM_REFERENCES = 2;
      if (this.references.length < MINIMUM_REFERENCES && !skipValidation) {
        this.referencesModalOpen = true;

        return;
      }
      if (skipValidation) this.referencesModalOpen = false;
      this.currentCheckpoint = newCheckpoint;
    },
    closeModal() {
      this.referencesModalOpen = false;
    },
    updateReferences(references) {
      this.references = references;
    },
    addUpdateToApplication(update) {
      this.dynamicStartupApplication = {
        ...this.dynamicStartupApplication,
        startupApplicationUpdates: [
          ...this.dynamicStartupApplication.startupApplicationUpdates,
          update,
        ],
      };
      this.showUpdateModal = false;
    },
  },
};
</script>

<template>
  <div class="flex h-full flex-col">
    <div>
      <h1 class="text-xl font-medium">
        {{ title }}
      </h1>
      <div class="h-px w-full bg-pv-gray" />
    </div>

    <div v-show="selectedState === 'submitted' || selectedState === 'to_review'">
      <div class="mt-6 flex flex-col items-start space-y-4">
        <span class="text-base">
          Sabrás el resultado de tu postulación antes del {{ resultsDate }}.
        </span>
      </div>
      <div class="mt-6 flex items-center justify-between">
        <div class="flex flex-col">
          <span class="text-xl font-medium">
            Actualizaciones
          </span>
          <span
            v-if="daysUntilUpdateAllowed"
            class="text-sm text-blue-gray-300"
          >
            Podrás subir actualizaciones en {{ daysUntilUpdateAllowed }}.
          </span>
          <span
            v-else
            class="text-sm text-pv-gray"
          >
            ¿Ha pasado algo que deberíamos saber?
          </span>
        </div>
        <kalio-button
          label="Subir actualización"
          size="small"
          :disabled="daysUntilUpdateAllowed"
          @click="showUpdateModal = true"
        />
      </div>
      <div
        v-if="inProcessApplicationUpdates && inProcessApplicationUpdates.length > 0"
        class="mt-4"
      >
        <kalio-startup-application-updates-tabs :updates="inProcessApplicationUpdates" />
      </div>
      <kalio-startup-application-in-process-update-form
        :startup-application="dynamicStartupApplication"
        :updates="inProcessApplicationUpdates"
        :show="showUpdateModal"
        @on-save="addUpdateToApplication"
        @close="showUpdateModal = false"
      />
      <div class="mt-10 flex flex-col">
        <span class="mb-2 text-xl">
          Descarga tus respuestas
        </span>
        <div>
          <kalio-button
            label="Descargar respuestas"
            size="small"
            variant="tertiary"
            @click="$emit('download-answers')"
          />
        </div>
      </div>
    </div>

    <div
      v-show="selectedState === 'in_short_interview'"
      class="mt-8 grow"
    >
      <div
        v-if="!!dynamicStartupApplication.firstInterview"
        class="flex flex-col"
      >
        <application-interview-card
          :interview="dynamicStartupApplication.firstInterview"
          :duration-minutes="15"
        />
        <div
          v-if="dynamicStartupApplication.firstInterview.scheduleStatus == 'waiting_reschedule'"
          class="mt-2 h-112 lg:h-144"
        >
          <kalio-calendly-schedule
            :prefill-name="dynamicStartupApplication.startupName"
            :prefill-email="currentUser.email"
            :prefill-guests="guestEmails"
            :meeting-url="firstInterviewUrl"
            @event-scheduled="handleCalendlyScheduledEvent"
          />
        </div>
      </div>
      <div
        v-else
        class="flex flex-col items-center gap-4"
      >
        <progress-checkpoint-bar
          :checkpoints="['Referencias', 'Agendar entrevista']"
          :current-checkpoint="currentCheckpoint"
          text-classes="text-base w-28 sm:w-48 text-center"
          size="small"
        />
        <div v-if="currentCheckpoint === 0">
          <kalio-startup-application-references
            :startup-application="dynamicStartupApplication"
            @update-references="updateReferences"
          />
          <div class="flex justify-end">
            <kalio-button
              label="Agendar entrevista"
              variant="primary"
              class="mt-8 w-full sm:w-auto"
              @click="changeCheckpoint(1)"
            />
          </div>
          <kalio-confirmation-modal
            v-if="referencesModalOpen"
            title="¿Quieres agendar la entrevista?"
            body="Recuerda que nos gustaría que ingreses al menos dos referencias"
            accept="Agendar entrevista"
            close="Cancelar"
            @accept="changeCheckpoint(1, true)"
            @close="closeModal"
          />
        </div>
        <div
          v-show="currentCheckpoint === 1"
          class="w-full"
        >
          <kalio-back-button
            class="mb-4"
            @click="changeCheckpoint(0, true)"
          />
          <div class="h-112 lg:h-144">
            <kalio-calendly-schedule
              :prefill-name="dynamicStartupApplication.startupName"
              :prefill-email="currentUser.email"
              :prefill-guests="guestEmails"
              :meeting-url="firstInterviewUrl"
              @event-scheduled="handleCalendlyScheduledEvent"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="selectedState === 'in_long_interview'"
      class="mt-8 grow"
    >
      <div
        v-if="!!dynamicStartupApplication.secondInterview"
        class="flex flex-col"
      >
        <application-interview-card
          :interview="dynamicStartupApplication.secondInterview"
          :duration-minutes="20"
        />
        <div
          v-if="dynamicStartupApplication.secondInterview.scheduleStatus == 'waiting_reschedule'"
          class="mt-2 h-112 lg:h-144"
        >
          <kalio-calendly-schedule
            :prefill-name="dynamicStartupApplication.startupName"
            :prefill-email="currentUser.email"
            :prefill-guests="guestEmails"
            :meeting-url="secondInterviewUrl"
            @event-scheduled="handleCalendlyScheduledEvent"
          />
        </div>
      </div>
      <div
        v-else-if="!isDealAccepted"
        class="flex flex-col items-center gap-4 rounded border border-pv-yellow p-4 text-left "
      >
        <p>
          Antes de continuar con la segunda entrevista queremos asegurarnos de que entiendes
          y estás de acuerdo con el deal que ofrecemos.
          Este consiste en que si tu startup es seleccionada para el programa recibirás
          una inversión es de
          <strong>
            200.000 USD a cambio de un 5.5% de la startup.
          </strong>
        </p>

        <p>
          Es importante que sepas que este deal <strong>
            no es negociable.
          </strong>
        </p>
        <kalio-button
          label="Aceptar"
          @click="isDealAccepted = true"
        />
      </div>

      <div
        v-else
        class="flex flex-col gap-2"
      >
        <kalio-alert
          title="Acuerdo aceptado"
          size="medium"
          :deletable="false"
        />
        <div class="h-112 lg:h-144">
          <kalio-calendly-schedule
            :prefill-name="dynamicStartupApplication.startupName"
            :prefill-email="currentUser.email"
            :prefill-guests="guestEmails"
            :meeting-url="secondInterviewUrl"
            @event-scheduled="handleCalendlyScheduledEvent"
          />
        </div>
      </div>
    </div>

    <div
      v-show="selectedState === 'selected'"
      class="mt-6"
    >
      <p class="text-base">
        🎉 Felicitaciones 🎉
        <br>
        <br>
        <b>
          {{ dynamicStartupApplication.startupName }}
        </b> fue seleccionada para el batch {{ batchSeason }}
        de Platanus.
        <br>
        <br>
        Pronto les llegará más información al respecto.
      </p>
    </div>

    <div
      v-show="selectedState === 'discarded'"
      class="mt-6"
    >
      <p
        v-if="startupApplication.discardEmailState === 'unprocessed'"
        class="text-base text-pv-gray"
      >
        Lamentablemente decidimos no avanzar hacia una siguiente etapa. Pronto les llegará un correo con las razones.
      </p>
      <base-review-discard-email-template
        v-else
        :discard-reason="startupApplication.discardReason"
        :model-value="startupApplication.discardFeedback"
        :application="startupApplication"
      />
    </div>
  </div>
</template>
