import api from './api';

export default {
  create(recordedInterviewId, recordedInterviewQuestion) {
    const path = `/api/v1/apply/recorded_interviews/${recordedInterviewId}/recorded_interview_questions`;
    const data = new FormData();
    Object.entries(recordedInterviewQuestion).forEach(([key, value]) => {
      data.append(`recorded_interview_question[${key}]`, value);
    });

    return api({
      method: 'POST',
      url: path,
      data,
      decamelizeKeys: false,
    });
  },
  new(recordedInterviewId) {
    const path = `/api/v1/apply/recorded_interviews/${recordedInterviewId}/recorded_interview_questions/new`;

    return api({
      method: 'GET',
      url: path,
    });
  },
};
