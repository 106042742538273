<script setup>
import { ref, computed, inject } from 'vue';
import Daily from '@daily-co/daily-js';
import { storeToRefs } from 'pinia';
import { currentUserKey, screenKey } from '@/utils/keys.js';
import { useDailyStore, useUIStore } from '@/stores';
import KalioConfirmationModal from '@/components/shared/kalio-confirmation-modal.vue';
import BaseDevicePermissionsModal from '@/components/shared/base-device-permissions-modal.vue';
import RecordedInterviewRoom from '@/components/recorded-interview/room.vue';
import RecordedInterviewInterview from '@/components/recorded-interview/interview.vue';
import { INTERVIEW_STEP, DAILY_STATUS } from '@/utils/recorded_interview.js';
import useActionCable from '@/hooks/actioncable.js';

const props = defineProps({
  recordedInterview: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);

const dailyStore = useDailyStore();
const { setCallObject, updateParticipants } = dailyStore;
const { callObject, participants, localParticipant } = storeToRefs(dailyStore);
const dailyStatus = ref(DAILY_STATUS.outside);
function handleJoiningMeeting() {
  dailyStatus.value = DAILY_STATUS.joining;
}

const uiStore = useUIStore();
const startInterviewConfirmationModalOpen = ref(false);
function handleStartInterview() {
  if (participants.value.find(p => p.owner)) {
    startInterviewConfirmationModalOpen.value = true;
  } else {
    uiStore.toast({
      message: 'Debe estar el fundador que inició la postulación para empezar.',
      type: 'error',
      position: 'top',
    });
  }
}

const interviewStep = ref(INTERVIEW_STEP.room);
const channel = useActionCable(
  { channel: 'RecordedInterviewChannel', id: props.recordedInterview.id },
  {
    received(event) {
      if (event.kind === 'start_interview') {
        interviewStep.value = INTERVIEW_STEP.interview;
        startInterviewConfirmationModalOpen.value = false;
      } else if (event.kind === 'end_interview') {
        interviewStep.value = INTERVIEW_STEP.finished;
      }
    },
  },
);

function startInterview() {
  channel.send({ kind: 'start_interview' });
}

function finishInterview() {
  channel.send({ kind: 'end_interview' });
}

function handleJoinedMeeting() {
  dailyStatus.value = DAILY_STATUS.joined;
  updateParticipants();
}

function handleError() {
  uiStore.toast({
    message: 'Hubo un error.',
    type: 'error',
    duration: 10000,
  });
}

const leaveMeetingConfirmationModalOpen = ref(false);
function leaveMeeting() {
  if (callObject.value) {
    callObject.value.leave().then(() => {
      dailyStatus.value = DAILY_STATUS.outside;
      interviewStep.value = INTERVIEW_STEP.room;
      participants.value = [];
      callObject.value.destroy();
    });
  }
  leaveMeetingConfirmationModalOpen.value = false;
}

const deviceErrorModalOpen = ref(false);
function handleDeviceError() {
  deviceErrorModalOpen.value = true;
}

const meetingToken = computed(
  () => (currentUser.value.id === props.recordedInterview.dailyRoomOwnerId ?
    props.recordedInterview.dailyRoomOwnerToken : ''),
);

function joinMeeting() {
  setCallObject(Daily.createCallObject({
    url: props.recordedInterview.dailyRoomUrl,
    token: meetingToken.value,
    audioSource: true,
    videoSource: true,
  }));

  callObject.value.join({ userName: currentUser.value.firstName });
  callObject.value.on('joining-meeting', handleJoiningMeeting)
    .on('joined-meeting', handleJoinedMeeting)
    .on('participant-joined', updateParticipants)
    .on('participant-updated', updateParticipants)
    .on('participant-left', updateParticipants)
    .on('error', handleError)
    .on('camera-error', handleDeviceError);
}

function toggleVideo() {
  callObject.value.setLocalVideo(!localParticipant.value.video);
}

function toggleAudio() {
  callObject.value.setLocalAudio(!localParticipant.value.audio);
}

const screen = inject(screenKey);
</script>

<template>
  <div
    class="flex w-full flex-col items-center"
    :class="interviewStep === INTERVIEW_STEP.room ? 'max-w-3xl' : 'max-w-5xl'"
  >
    <kalio-back-button href="/apply" />
    <kalio-header
      title="Entrevista con Platanus"
      icon="solid/video.svg"
      :size="screen.isMobile ? 'medium' : 'big'"
      class="mt-7 self-start sm:mt-10"
    />
    <p
      v-if="interviewStep === INTERVIEW_STEP.room"
      class="mt-12 sm:mt-16"
    >
      A continuación les vamos a hacer algunas preguntas. No habrá una persona
      de Platanus presente, pero veremos la grabación al
      momento de evaluar su postulación.
      <br>
      <br>
      Debes responder las preguntas como si se las estuvieras respondiendo a una persona.
    </p>
    <kalio-button
      v-if="dailyStatus === DAILY_STATUS.outside"
      label="Entrar a la sala"
      class="my-12 px-12"
      @click="joinMeeting"
    />
    <recorded-interview-room
      v-else-if="interviewStep === INTERVIEW_STEP.room"
      class="mt-12"
      :daily-status="dailyStatus"
      :recorded-interview="recordedInterview"
      @toggle-video="toggleVideo"
      @toggle-audio="toggleAudio"
      @leave-meeting="leaveMeetingConfirmationModalOpen = true"
      @join-meeting="joinMeeting"
      @start-interview="handleStartInterview"
    />
    <recorded-interview-interview
      v-else-if="interviewStep === INTERVIEW_STEP.interview || interviewStep === INTERVIEW_STEP.finished"
      class="mt-12"
      :interview-step="interviewStep"
      :recorded-interview="recordedInterview"
      @toggle-video="toggleVideo"
      @toggle-audio="toggleAudio"
      @leave-meeting="leaveMeetingConfirmationModalOpen = true"
      @join-meeting="joinMeeting"
      @finish-interview="finishInterview"
    />
    <kalio-confirmation-modal
      :show="startInterviewConfirmationModalOpen"
      title="¿Estás seguro de comenzar la entrevista?"
      body="Una vez que comiences la entrevista, no podrás editarla.
            Si falta alguno de tus cofundadores, te recomendamos esperar
            a que estén todos conectados."
      @accept="startInterview"
      @close="startInterviewConfirmationModalOpen = false"
    />
    <kalio-confirmation-modal
      :show="leaveMeetingConfirmationModalOpen"
      title="¿Estás seguro de salir de la sala?"
      @accept="leaveMeeting"
      @close="leaveMeetingConfirmationModalOpen = false"
    />
    <base-device-permissions-modal :show="deviceErrorModalOpen" />
  </div>
</template>
